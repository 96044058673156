import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { CommonModal } from '../Common/CommonModal';
import useDelete from '../../hooks/Common/useDelete';
import useModals from '../../hooks/Common/useModals';
import deleteDetectionServers from '../../web_api/methods/settings/detectionServers/deleteDetectionServers';

interface DetectionServerMassDeleteProps {
  checkedDetectionServers: number[];
  onShowMassDeleteModal: (detectionIds: number[]) => { [key: string]: string };
  onDetectionServerMassDelete: () => void;
}

const DetectionServerMassDelete: React.FC<DetectionServerMassDeleteProps> = ({
  checkedDetectionServers,
  onShowMassDeleteModal,
  onDetectionServerMassDelete,
}) => {
  const { show, toggleModal } = useModals([false]);

  const [names, setNames] = useState({});

  const handleToggleDeleteModal = () => {
    toggleModal(0);
    const serverNames = onShowMassDeleteModal(checkedDetectionServers);
    setNames(serverNames);
  };

  const {
    deleteItems: handleMassDelete,
    errors,
    isDeleting,
  } = useDelete(
    () => deleteDetectionServers(checkedDetectionServers),
    () => {
      toggleModal(0);
      onDetectionServerMassDelete();
      toast.success(`Detection${checkedDetectionServers.length > 1 ? 's' : ''} deleted successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    }
  );

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors?.length]);

  return (
    <>
      {checkedDetectionServers.length > 0 ? (
        <div className='d-flex flex-column flex-md-row p-1 flex-end'>
          <div className='btn btn-danger' onClick={handleToggleDeleteModal}>
            Delete servers
          </div>
        </div>
      ) : null}
      <CommonModal
        title={'Confirm'}
        show={show[0]}
        disabled={isDeleting}
        showModalCallback={() => toggleModal(0)}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3 text-white',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleMassDelete,
        }}
      >
        <div>Are you sure you want to delete the following detection servers?</div>
        {Object.entries(names).map(([key, value]) => (
          <div key={key} className='fw-bolder mt-3'>
            {key} - {value as string}
          </div>
        ))}
      </CommonModal>
    </>
  );
};

export default DetectionServerMassDelete;
