import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { ToolbarWrapper } from './ToolbarWrapper';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { EProductType } from '../redux/activeProductReducer';
import Forbidden from '../pages/Forbidden';
import { menuItem, menuItemList } from '../web_api/models';
import LoadingPage from './LoadingPage';

export default function MasterLayout() {
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem('user')!);
  const activeProduct = useSelector((state: RootState) => state.activeProduct.activeProduct);
  const userName = useSelector((state: RootState) => state.user.user_name);
  const permissions = useSelector((state: RootState) => state.permissions.permissions);
  const menu = useSelector((state: RootState) => state.menu.menu);
  const [isAllowed, setIsAllowed] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (new Date() > new Date(token.expiration_date)) {
      localStorage.clear();
      navigate(process.env.REACT_APP_HOMEPAGE + '/login');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (menu && userName) {
      setLoading(false);
    }
  }, [menu, userName]);

  const allUrls = extractAllUrls(menu);
  allUrls.push('/home');

  function extractAllUrls(menuData: menuItemList): string[] {
    const allUrls: string[] = [];

    function traverseMenuItems(items: menuItem[]) {
      items.forEach((item) => {
        if (item.url) {
          allUrls.push(item.url);
        }
        if (item.children && item.children.length > 0) {
          traverseMenuItems(item.children);
        }
      });
    }

    Object.values(menuData).forEach(traverseMenuItems);
    return allUrls;
  }

  useEffect(() => {
    // console.log(allUrls);
    // console.log('/' + window.location.pathname.split('/')[1]);
    setLoading(true)
    setIsAllowed(
      allUrls.includes('/' + window.location.pathname.split('/')[1]) ||
      allUrls.includes(window.location.pathname) ||
      window.location.pathname === '/login'
    );
    setLoading(false);
  }, [navigate, permissions, activeProduct, menu]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
          <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
            <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper' style={{ height: '100vh' }}>
              {activeProduct !== EProductType.NONE || userName !== 'orange@demo.com' ? <Sidebar /> : <></>}
              <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                  <ToolbarWrapper />
                  {/* {isAllowed ? 
                  <Outlet /> 
                  : <Forbidden />} */}
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
