import useModals from '../../../hooks/Common/useModals';
import { CommonModal } from '../../Common/CommonModal';
import CommonReport from '../../Common/CommonReport';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import CommonPlayer from '../../Common/CommonPlayer';
import { useEffect, useState } from 'react';
import { lfShelvesStatusList } from '../../../web_api/models';
import lfShelvesStatus from '../../../web_api/methods/fruits-vegetables/lfShelvesStatus';
import LoadingPage from '../../../layout/LoadingPage';
import getAzureFile from '../../../web_api/methods/files/getAzureFile';

const FruitsVegetablesImages = () => {
  const params = useParams();
  const locationId = Number(params.locationId);
  const { show, toggleModal } = useModals([false]);
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
  const currentLocation = cachedLocationsList.list.find((location) => location.id === locationId);
  const [playerProgress, setPlayerProgress] = useState(0);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [imageArray, setImageArray] = useState<string[]>([]);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);
  const [currentImage, setCurrentImage] = useState<string>('');

  const loadShelveStatus = async () => {
    try {
      setLoading(true);
      let forImageArray: string[] = [];
      let lfShelvesStatusRes = await lfShelvesStatus(
        parseInt(activeLocation.id),
        new Date(toolbarDateFilter.date),
        parseInt(toolbarHourFilter.hour),
        parseInt(toolbarHourFilter.toHour)
      );

      lfShelvesStatusRes.sort((a, b) => a.hour - b.hour);

      let uniqueFilenames = new Set();

      for (const item of lfShelvesStatusRes) {
        if (!uniqueFilenames.has(item.filename)) {
          // Add the unique filename to the set
          uniqueFilenames.add(item.filename);

          try {
            let newImage = await getAzureFile(item.filename);

            if (!forImageArray.includes(newImage)) {
              forImageArray.push(newImage);
            }

            // Update the current image if it's the first image
            if (forImageArray.length === 1) {
              setCurrentImage(forImageArray[0]);
            }
          } catch (error) {
            console.error(`Failed to fetch image: ${item.filename}`, error);
          }
        }
      }

      setImageArray(forImageArray);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadShelveStatus();
    // setCurrentImage('https://www.pixelstalk.net/wp-content/uploads/2016/07/1080p-Full-HD-Images.jpg')
    // setImageArray(['https://www.pixelstalk.net/wp-content/uploads/2016/07/1080p-Full-HD-Images.jpg', 'https://bloggertowp.org/wp-content/uploads/sites/9/2014/04/nature-lake-full-hd-wallpaper.jpg', 'https://wallpaperaccess.com/full/138728.jpg'])
  }, [activeLocation.id, toolbarDateFilter.date, toolbarHourFilter]);

  const handlePlayerClick = () => {
    const runPlayer = () => {
      let currentIndex = imageArray.indexOf(currentImage);

      if (currentIndex === -1) {
        currentIndex = 0;
      }

      let timeoutId;

      const setNextImage: () => NodeJS.Timeout | undefined = () => {
        if (currentIndex >= imageArray.length) {
          setCurrentImage(imageArray[0]);
          setPlayerProgress(0);
          setTimeoutIds(undefined);
          return;
        }

        setCurrentImage(imageArray[currentIndex]);
        currentIndex++;
        setPlayerProgress((currentIndex / imageArray.length) * 100);

        timeoutId = setTimeout(setNextImage, 1500);

        setTimeoutIds(timeoutId);
        return timeoutId;
      };
      timeoutId = setNextImage();
    };

    if (timeoutIds) {
      clearTimeout(timeoutIds);
      setTimeoutIds(undefined);
    } else {
      runPlayer();
    }
  };

  return (
    <>
      <div className='mb-5 d-flex justify-content-center'>
        <div className='w-100 bg-body rounded'>
          <CommonReport leftTitle={`Shelves`}>
            {!loading ? (
              <>
                <div className='px-2'>
                  <img className='w-100 img-fluid rounded' src={currentImage} />
                </div>

                {/* <AlertCanvasComponent

                                            // imageUrl={`https://img.freepik.com/free-photo/waist-up-portrait-handsome-serious-unshaven-male-keeps-hands-together-dressed-dark-blue-shirt-has-talk-with-interlocutor-stands-against-white-wall-self-confident-man-freelancer_273609-16320.jpg?size=626&ext=jpg`}
                                            imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${currentImage}`}
                                            actualHeight={3840}
                                            actualWidth={2160}

                                            canvasHeight={"500px"}
                                            canvasWidth={"100%"}
                                            multiple_bboxes={[]} big={true} /> */}
                <div className='mt-5'>
                  <CommonPlayer className='pe-2' handlePlayerClick={handlePlayerClick} playerProgress={playerProgress} timeoutIds={timeoutIds} />
                </div>
              </>
            ) : (
              <LoadingPage />
            )}
          </CommonReport>
        </div>
      </div>

      <CommonModal
        title={currentLocation?.name || 'last status'}
        size='xl'
        show={show[0]}
        showModalCallback={() => toggleModal(0)}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100 ps-15 pe-15 pb-10'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3',
          variant: '',
          class: 'd-none',
        }}
      >
        <img className='w-100 img-fluid rounded' src={currentImage} />
      </CommonModal>
    </>
  );
};

export default FruitsVegetablesImages;
