import React from 'react';
import { v4 as uuidv4 } from 'uuid';


type CommonTableProps = {
  columns: React.ReactNode,
  rows: React.ReactNode,
  title: string,
  subtitle: string,
  addButtonText: string,
  hasCheckedRows: number,
  onClickAdd: () => void,
  onClickDelete: () => void,
  tableClassName?: string,
  onCheckAll?: () => void
};

const CommonTable: React.FC<CommonTableProps> = ({
  columns,
  rows,
  title,
  subtitle,
  addButtonText,
  hasCheckedRows,
  onClickAdd,
  onClickDelete,
  tableClassName,
  onCheckAll
}) => {

  const uniqueId = uuidv4();

  return (
    <div className="card" style={{ overflow: "scroll" }}>
      <div>
        <div className="card-header border-0 pt-5 d-flex flex-column flex-sm-row">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold fs-3 mb-1">{title}</span>
            <span className="text-muted mt-1 fw-semibold fs-7">{subtitle}</span>
          </h3>
          <div className="d-flex flex-row gap-3 m-sm-auto me-sm-0 mt-4">
            {hasCheckedRows > 0 ?
              <a
                className="text-danger p-3 rounded bg-light-danger cursor-pointer d-block"
                onClick={onClickDelete}
              >
                Delete selected
              </a> : null}
            <a
              className="text-primary bg-light-primary p-3 rounded cursor-pointer"
              onClick={onClickAdd}
            >
              {addButtonText}
            </a>
          </div>
        </div>
        <div className="card-body py-0 py-sm-8">
          <div className="table-responsive">
            <table className={`table table-row-gray-300 align-middle gs-0 gy-4 ${tableClassName}`}>
              <thead>
                <tr className="fw-bold text-muted">
                  {columns}
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
