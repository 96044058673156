import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setValue } from '../redux/mostQueueAlertsReducer';
import { setValue as setDateFilter } from '../redux/dateFilterReducer';
import logo from '../assets/images/logo-white.svg';

import { RootState } from '../redux/store';
import DownloadUserManual from '../pages/UserManual/DownloadUserManual';
import { setDemoProduct } from '../redux/demoProductReducer';
import { EProductType, setActiveProduct } from '../redux/activeProductReducer';
import { unassignToken } from '../redux/userReducer';

export default function SidebarFooter() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector((state: RootState) => state.user.user_name);

  const logout = () => {
    localStorage.clear();

    dispatch(
      setValue({
        list: [],
      })
    );
    dispatch(
      setDateFilter({
        from: new Date().toISOString(),
        to: new Date().toISOString(),
      })
    );
    dispatch(unassignToken());

    navigate(process.env.REACT_APP_HOMEPAGE + '/login');
    // window.location.reload();
  };
  const resetProducts = () => {
    dispatch(
      setDemoProduct({
        demoProduct: EProductType.NONE,
      })
    );
    dispatch(
      setActiveProduct({
        activeProduct: EProductType.NONE,
      })
    );
    navigate(process.env.REACT_APP_HOMEPAGE + '/home');
    window.location.reload();
  };
  return (
    <>
      <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
        {/* <DownloadUserManual /> */}
      </div>
      <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6 ' id='kt_app_sidebar_footer'>
        <div
          onClick={resetProducts}
          className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
          title='Hub'
        >
          <span className='btn-label'>Back to Hub</span>
        </div>
        <span className='d-flex overflow-hidden text-muted flex-center my-3'>
          <span className='btn-label'>{userName}</span>
        </span>
        <div
          onClick={logout}
          className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
          title='Log out'
        >
          <span className='btn-label'>Log out</span>
        </div>
        {/* <div className='d-flex flex-center mt-5'>
          <img
            alt='Logo'
            src={logo}
            className='w-50'
          />
        </div> */}
      </div>
    </>
  );
}
