export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export function checkIsActiveMoreUrls(pathname: string, url: string[]) {
  const currentPath = getCurrentUrl(pathname).trim();
  const noQueryUrlList = url.map((currUrl) => {
    const newUrl = getCurrentUrl(currUrl);
    if (currUrl[0] === "/") {
      return newUrl
    }
    return "/" + newUrl
  });
  return noQueryUrlList.includes(currentPath);
}

