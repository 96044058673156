import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface selectedCameraState {
  value: string
  id: string
}

const initialState: selectedCameraState = {
  id: localStorage.getItem('selectedCameraId')!?.toString() != null ? localStorage.getItem('selectedCameraId')!?.toString() : '0',
  value: localStorage.getItem('selectedCameraName')!?.toString() != null ? localStorage.getItem('selectedCameraName')!?.toString() : 'All',
};

export const selectedCamera = createSlice({
  name: 'selectedCamera',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<selectedCameraState>) => {
      state.id = action.payload.id
      state.value = action.payload.value
      localStorage.setItem("selectedCameraId", action.payload.id)
      localStorage.setItem("selectedCameraName", action.payload.value)
    },
  },
});

export const { setValue } = selectedCamera.actions;

export const selectedCameraReducer = selectedCamera.reducer;
