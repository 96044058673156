import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../../redux/store';
import useGet from '../../hooks/Common/useGet';
import { useParams } from 'react-router-dom';
import getDetections from '../../web_api/methods/alerts/getDetections';
import AlertCard from './AlertCard';
import LoadingPage from '../../layout/LoadingPage';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import getDetectionVideos from '../../web_api/methods/alerts/getDetectionVideos';
import Slideshow, { EmbeddedVideoData } from '../Common/Slider';
import ExpandableComponent from '../Common/ExpandableComponent';
import deleteAlerts from '../../web_api/methods/alerts/deleteAlerts';
import { CommonModal } from '../Common/CommonModal';
interface RouteParams {
  [key: string]: string | undefined;
}
export default function Alerts() {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('alerts', `Alerts - ${detectionTypeName}`);
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const params = useParams<RouteParams>();
  const detectionType = params.detectionType;
  const detectionTypeName = params.detectionTypeName;
  const [videoUrls, setVideoUrls] = useState<EmbeddedVideoData[]>([]);
  const [videoExists, setVideoExists] = useState(false);
  const [selectedAlerts, setSelectedAlerts] = useState<string[]>([]);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { loading, data, loadItems, setData } = useGet([selectedUser.id, activeLocation.id, selectedCamera.id, pageIndex, pageSize], () =>
    getDetections(parseInt(detectionType!), parseInt(activeLocation.id), pageIndex + 1, pageSize, new Date(dateFilter.from), new Date(dateFilter.to))
  );

  const handleNext = () => {
    setPageIndex((prevPageIndex) => prevPageIndex + 1);
  }; 

  const handlePrev = () => {
    setPageIndex((prevPageIndex) => Math.max(prevPageIndex - 1, 0));
  };

  const handleAlertSelect = (alertID: string) => {
    if (!selectedAlerts.includes(alertID)) {
      setSelectedAlerts((prev) => [...prev, alertID]);
      return;
    }

    setSelectedAlerts((prev) => prev.filter((el) => el !== alertID));
  };

  const handleDeleteLocalEntries = (alerts: string[]) => {
    setData((prev) => prev?.filter((obj) => !alerts.includes(obj.detection_gu_id)));
    setSelectedAlerts([]);
  };

  const loadVideos = async () => {
    try {
      setVideoExists(false);
      let videoUrlsArray: EmbeddedVideoData[] = [];
      let response = await getDetectionVideos(detectionTypeName ?? 'none', parseInt(detectionType!));
      response.forEach((item) => {
        let x: EmbeddedVideoData = { src: item, title: detectionTypeName ?? '', description: '' };
        videoUrlsArray.push(x);
      });
      setVideoUrls(videoUrlsArray);
      if (videoUrlsArray.length !== 0) setVideoExists(true);
    } catch (error) {
      setVideoExists(false);
    }
  };
  useEffect(() => {
    loadVideos();
  }, [detectionType]);
  let loadAlerts = loadItems;
  useEffect(() => {
    setPageIndex(0); // reset page index when selectedUser changes
    loadAlerts();
  }, [activeLocation.id, selectedUser.id, dateFilter.from, dateFilter.to, detectionType]);

  const handleDeleteAlertsModal = () => {
    setShowConfirmDeleteModal((prev) => !prev);
  };

  const handleAlertCardDelete = (alertID: string) => {
    try {
      deleteAlerts([alertID]).then(() => {
        handleDeleteLocalEntries([alertID]);

        toast.success('Alert deleted successfully!', {
          position: 'top-right',
          theme: 'colored',
          autoClose: 2000,
        });
      });
    } catch (e) {
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  // useEffect(() => {
  //     if (videoUrls?.length !== 0)
  //         setVideoExists(true)
  // }, [videoUrls?.length]);
  return (
    <div className='container'>
      {videoExists && (
        <ExpandableComponent className='d-flex flex-column flex-md-column col-12 p-5 pb-0' label={'Examples'}>
          {videoUrls?.length === 1 ? (
            <div className='d-flex flex-center p-5'>
              <video width='800' style={{ borderRadius: '25px' }} controls autoPlay>
                <source src={videoUrls[0].src} type='video/mp4' />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <>
              <Slideshow data={videoUrls} autoplay={false} />
            </>
          )}
        </ExpandableComponent>
      )}
      <div className='d-flex flex-column flex-md-column col-12 p-5 pb-0'>
        <div className=''>
          <div
            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
            className='d-flex flex-column flex-md-row p-1 flex-end'
          >
            <div>
              <button className={`btn btn-danger ${selectedAlerts.length > 0 ? '' : 'd-none'}`} onClick={handleDeleteAlertsModal}>
                Delete selection
              </button>
            </div>
            <div>
              <button className='btn btn-primary' onClick={handlePrev} disabled={pageIndex === 0}>
                <i className='bi bi-chevron-double-left p-0' />
              </button>
              <button className='btn btn-secondary' disabled>
                {pageIndex + 1}
              </button>
              <button className='btn btn-primary' disabled={data === undefined || data.length < 30} onClick={handleNext}>
                <i className='bi bi-chevron-double-right p-0' />
              </button>
            </div>
          </div>
        </div>
        <div className='row'>
          {!loading ? (
            data &&
            data.map((alert) => (
              <>
                <AlertCard key={alert.detection_gu_id} alert={alert} onAlertSelect={handleAlertSelect} onAlertDelete={handleAlertCardDelete} />
              </>
            ))
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
      {/* <CommonModal
                size='xl'
                title={personItem!.person}
                show={showPicture}
                showModalCallback={() => toggleModal(0)}
                closeButton={true}
                confirmButton={false}
                bodyClass="flex-column w-100"
                closeButtonOptions={{
                    icon: 'bi bi-x',
                    variant: 'secondary',
                    class: "",
                }}
                confirmButtonOptions={{
                    icon: "bi bi-trash3",
                    variant: 'custom',
                    class: "bg-danger text-white",
                }}
            >
                <CanvasComponent

                    imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem?.filename}`}
                    actualHeight={personItem!.picture_height}
                    actualWidth={personItem!.picture_width}
                    bboxes={[{ x1: personItem!.bbox1, y1: personItem!.bbox2, x2: personItem!.bbox3, y2: personItem!.bbox4 }]}
                    canvasHeight={"500px"}
                    canvasWidth={"100%"} />
            </CommonModal > */}

      <CommonModal
        show={showConfirmDeleteModal}
        title='Are you sure you want to delete the selected alerts?'
        size='lg'
        bodyClass='d-flex flex-col w-100 gap-2 justify-content-start'
        closeButton={true}
        confirmButton={true}
        showModalCallback={handleDeleteAlertsModal}
        closeButtonOptions={{
          icon: 'bi bi-x-lg text-black',
          variant: 'secondary',
          class: 'd-flex flex-row justify-content-center align-items-center ',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3 text-white',
          variant: 'custom',
          class: 'bg-danger text-white d-flex flex-row align-items-center',
          onClick: () => {
            setShowConfirmDeleteModal(false);
            deleteAlerts(selectedAlerts);
            // setTimeout(() => window.location.reload(), 500);
            handleDeleteLocalEntries(selectedAlerts);
          },
        }}
      >
        <ul>
          {selectedAlerts.map((alert) => (
            <li>{`${alert}`}</li>
          ))}
        </ul>
      </CommonModal>
    </div>
  );
}
