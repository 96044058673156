import { Link, To } from "react-router-dom"
import { CommonTooltip } from "../CommonTooltip"

type CommonCardButtonProps = {
    containerClass?: string,
    text: string,
    link?: To,
    disabledIcon?: boolean,
    disabledButton?: boolean
    disabledButtonTooltip?: string
    icon?: string,
    iconTooltip?: string,
    handleClick?: () => void,
    handleIconClick?: () => void
}

const CommonCardButton: React.FC<CommonCardButtonProps> = ({
    containerClass,
    text,
    link,
    icon,
    iconTooltip,
    disabledIcon,
    disabledButton,
    disabledButtonTooltip,
    handleClick,
    handleIconClick
}) => {

    return (


        <div className={`d-flex align-items-center ${containerClass}`}>
            <CommonTooltip text={disabledButton && disabledButtonTooltip ? disabledButtonTooltip : ''} bg='' textColor=''>
                <Link className={`btn btn-primary w-100 ${disabledButton ? 'opacity-50' : ''}`} to={link ? link : ''} onClick={handleClick ? handleClick : undefined}>
                    <span className='text-white'>
                        {text}
                    </span>
                </Link>
            </CommonTooltip>

            <CommonTooltip text={iconTooltip || ''} bg='' textColor=''>
                <div className={`symbol w-20 d-flex flex-row-fluid ${icon ? 'visible' : 'invisible'}`} onClick={!disabledIcon ? handleIconClick : undefined}>
                    <span className='symbol-label bg-lighten cursor-pointer'>
                        <i className={`svg-icon-2 fa-3x ${icon}`} />
                    </span>
                </div>
            </CommonTooltip>

        </div>
    )
}

export default CommonCardButton