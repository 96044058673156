import clsx from 'clsx'
import { useLayout } from './core'
import { PageTitleWrapper } from './PageTitleWrapper'
import { Toolbar } from './Toolbar'

const ToolbarWrapper = () => {
    const {config, classes} = useLayout()
    if (!config.app?.toolbar?.display) {
      return null
    }
  
    const isPageTitleVisible = true
  
    return (
      <div
        id='kt_app_toolbar'
        className={clsx('app-toolbar bg-body', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
      >
        <div
          id='kt_app_toolbar_container'
          className={clsx(
            'app-container container d-flex flex-column flex-md-row justify-content-between',
            classes.toolbarContainer.join(' '),
            config.app?.toolbar?.containerClass,
            config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
            {
              'container-fluid': config.app?.toolbar?.container === 'fluid',
              'container-xxl': config.app?.toolbar?.container === 'fixed',
            }
          )}
        >
          {isPageTitleVisible && <PageTitleWrapper />}
          <Toolbar />
        </div>
      </div>
    )
  }
  

  
  export {ToolbarWrapper}
  