import { loginResBodySuccess } from "common/api/services/authService/models";
import { useState } from "react";
import loginWeb from "../../web_api/methods/account/login";
import { useDispatch } from "react-redux";
import getDetectionTypes from "../../web_api/methods/settings/detections/getDetectionTypes";
import { setValue } from '../../redux/userReducer';
import { setValue as setIsValid } from '../../redux/isValidReducer';
import { setCachedDetectionTypeList } from "../../redux/detectionTypeListReducer";

type useLoginProps = {
    email: string,
    password: string
};

const useLogin = (

    email: useLoginProps['email'],
    password: useLoginProps['password']
    
) => {

    const dispatch = useDispatch();

    const [isLoggingIn, setisLoggingIn] = useState(false);
    const [hasError, setHasError] = useState<any>(null);
    const [twoFactorAuth, setTwoFactorAuth] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    const storeUserCredentials = async (loginResponse: loginResBodySuccess) => {

        localStorage.setItem("user", JSON.stringify(loginResponse.access_token));
        localStorage.setItem("user_name", JSON.stringify(loginResponse.user_name));
        localStorage.setItem("is_valid", JSON.stringify(loginResponse.is_valid));
        localStorage.setItem("tenant_key", JSON.stringify(loginResponse.tenant_key));
        localStorage.setItem("two_factor_enabled", JSON.stringify(loginResponse.two_factor_enabled));
        localStorage.setItem("toolbar", 'people-counting');

        dispatch(setValue({
            user_name: loginResponse.user_name,
            access_token: loginResponse.access_token.token,
            tenant_key: loginResponse.tenant_key,
        }));

        dispatch(setIsValid({
            is_valid: loginResponse.is_valid
        }));

        if (loginResponse) {
            const detectionTypes = await getDetectionTypes();

            dispatch(setCachedDetectionTypeList({
                list: detectionTypes
            }));
        };
    };

    const handleLoginSubmit = async () => {
        try {
            setTwoFactorAuth(false)
            setisLoggingIn(true);
            const loginResponse: loginResBodySuccess = await loginWeb(email, password);
            if (loginResponse.two_factor_needed) {
                setTwoFactorAuth(true)
            }
            else {
                storeUserCredentials(loginResponse);
            };

            setisLoggingIn(false);
            // setHasError(false);
        } catch (error) {
            console.log(error);
            setisLoggingIn(false);
            setHasError(error);
        };
    };

    return ({
        isLoggingIn: isLoggingIn,
        hasError: hasError,
        twoFactorAuth: twoFactorAuth,
        handleLoginSubmit,
        storeUserCredentials
    });
};

export default useLogin;

