import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EProductType {
  NONE,
  RETAIL,
  HSSE,
  MOBILITY
}

export interface ActiveProductState {
  activeProduct: EProductType
}

// Retrieve the saved value from localStorage
const savedActiveProduct = localStorage.getItem('activeProduct');

// Define a function to convert string to enum value
function stringToEnum(value: string): EProductType {
  switch (value) {
    case 'NONE':
      return EProductType.NONE;
    case 'RETAIL':
      return EProductType.RETAIL;
    case 'HSSE':
      return EProductType.HSSE;
    case 'MOBILITY':
      return EProductType.MOBILITY;
    default:
      return EProductType.NONE; // Default to NONE if the value is not recognized
  }
}

const initialState: ActiveProductState = {
  activeProduct: savedActiveProduct !== null ? stringToEnum(savedActiveProduct) : EProductType.NONE,
};

export const activeProduct = createSlice({
  name: 'activeProduct',
  initialState,
  reducers: {
    setActiveProduct: (state, action: PayloadAction<ActiveProductState>) => {
      state.activeProduct = action.payload.activeProduct;
      localStorage.setItem("activeProduct", EProductType[action.payload.activeProduct]);
    },
  },
});

export const { setActiveProduct } = activeProduct.actions;

export const activeProductReducer = activeProduct.reducer;
