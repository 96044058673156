import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { EProductType } from "../../redux/activeProductReducer";

export type EmbeddedVideoData = {
    src: string;
    title: string;
    description: string;
};
type Props = {
    data: EmbeddedVideoData[];
    activeIndex?: (index: number) => void;
    autoplay?: boolean;
    width?: number
};

const Slideshow: React.FC<Props> = ({ data, activeIndex, autoplay, width }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const demoProduct = useSelector((state: RootState) => state.demoProduct.demoProduct);

    if (activeIndex) activeIndex(currentIndex);

    const handleCarouselChange = (index: number) => {
        setCurrentIndex(index);

        if (activeIndex) activeIndex(index);
    };

    const handleItemClick = (index: number) => {
        setCurrentIndex(index);

        if (activeIndex) activeIndex(index);
    };

    return (
        <Carousel
            centerMode={true}
            infiniteLoop={true}
            showStatus={false}
            autoPlay={autoplay ?? true}
            centerSlidePercentage={70}
            thumbWidth={125}
            onChange={handleCarouselChange}
            onClickItem={(index) => handleItemClick(index)}
            selectedItem={currentIndex}
        >
            {data.map((video, index) => {
                return (
                    demoProduct === EProductType.RETAIL ? (
                        <>
                            <iframe className='rounded-5 w-100' height='400px' src={`${video.src}?rel=0`} title={video.title} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; " allowFullScreen={false} />
                        </>
                    ) : (
                        <video
                            key={index}
                            width={width ?? 800}
                            style={{ borderRadius: '25px' }}
                            loop
                            controls
                            autoPlay={index === currentIndex && (autoplay ?? true)}
                        >
                            <source src={video.src} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )

                );
            })}
        </Carousel>
    );
};

export default Slideshow;
