import { ToastContainer } from "react-toastify";
import useGet from "../../hooks/Common/useGet"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import getLocations from "../../web_api/methods/settings/locations/getLocations";
import { useDispatch } from "react-redux";
import getDetectionServers from "../../web_api/methods/settings/detectionServers/getDetectionServers";
import DetectionServerCard from "./DetectionServerCard";
import { detectionServerInList } from "../../web_api/models";
import DetectionServerAdd from "./DetectionServerAdd";
import useModals from "../../hooks/Common/useModals";
import DetectionServerMassDelete from "./DetectionServerMassDelete";
import DetectionServerCardAdd from "./DetectionServerCardAdd";
import { setCachedDetectionServerList } from "../../redux/detectionServerListReducer";
import DetectionServerNewUserCards from "./DetectionServerNewUserCards";
import LoadingPage from "../../layout/LoadingPage";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";


const DetectionServers = () => {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'detection-servers',
            'Detection Servers'
        )
    })
    const dispatch = useDispatch();

    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);

    const { loading, data, setData } = useGet([], getDetectionServers, setCachedDetectionServerList);
    const { show, toggleModal } = useModals([false]);

    const [checking, setChecking] = useState<boolean>(true);
    const [checkedDetectionServers, setCheckedDetectionServers] = useState<number[]>([]);

    const checkExistingSettings = async () => {
        try {
            if (cachedLocationsList.length === 0) {
                const locationList = await getLocations();
                dispatch(setCachedLocationList({ list: locationList }));
            }
        } catch (e) {
            console.log(e)
        } finally {
            setChecking(false);
        };
    };

    const onDetectionServerCheck = (id: number) => {
        setCheckedDetectionServers((prevState) => {
            const isChecked = !prevState.includes(id);
            if (isChecked) {
                return [...prevState, id];
            } else {
                return prevState.filter((serverId) => serverId !== id);
            }
        });
    };

    const onDetectionServerDelete = (id: number) => {
        if (data) {
            const updatedList = data.filter(server => server.id !== id);
            setData(updatedList);
            dispatch(setCachedDetectionServerList({ list: updatedList }));
        };
    };

    const onDetectioServerMassDelete = () => {
        if (data) {
            const updatedList = data.filter(
                (detectionServer) => !checkedDetectionServers.includes(detectionServer.id)
            );
            setData(updatedList);
            setCheckedDetectionServers([]);
            dispatch(setCachedDetectionServerList({ list: updatedList }));
        }
    };

    const onDetectionServerAdd = (detectionServer: detectionServerInList) => {
        if (data) {
            const updatedList = [detectionServer, ...data];
            setData(updatedList);
            dispatch(setCachedDetectionServerList({ list: updatedList }));
        };
    };

    const onShowMassDeleteModal = (detectionServerIds: number[]) => {
        const names: { [key: string]: string } = {};

        const filteredServers = data?.filter(server => detectionServerIds.includes(server.id))

        filteredServers?.forEach(server => {
            names[server.id.toString()] = `${server.name}`;
        });

        return names;
    };

    useEffect(() => {
        checkExistingSettings();
    }, []);

    return (
        <>
            
            {loading || checking ?
              <LoadingPage /> :
                <>
                    { data && data.length > 0 && cachedLocationsList.length > 0 ? (
                        <>
                            <div className="container pt-3 px-5 d-flex justify-content-end">
                                <DetectionServerMassDelete
                                    onShowMassDeleteModal={onShowMassDeleteModal}
                                    onDetectionServerMassDelete={onDetectioServerMassDelete}
                                    checkedDetectionServers={checkedDetectionServers}
                                />
                                <DetectionServerAdd
                                    onDetectionServerAdd={onDetectionServerAdd}
                                    show={show[0]}
                                    toggleModal={() => toggleModal(0)}
                                    dataLength={data?.length || 0}
                                />
                            </div>
                            <div className="container px-sm-5">
                                <div className="row">
                                    {data.map((server) => {
                                        return <DetectionServerCard
                                            key={server.id}
                                            server={server}
                                            onDetectionServerCheck={onDetectionServerCheck}
                                            onDetectionServerDelete={onDetectionServerDelete}
                                        />
                                    })}
                                    <DetectionServerCardAdd
                                        toggleModal={() => toggleModal(0)}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                            <>
                                <DetectionServerNewUserCards
                                    show={show[0]}
                                    onDetectionServerAdd={onDetectionServerAdd}
                                    toggleModal={() => toggleModal(0)}
                                    dataLength={data?.length || 0}
                                />
                            </>
                    )}
                </>
            }
        </>
    )
}

export default DetectionServers