import callApiAxiosClient from '../../../axios/axios_client';
import { algorithmType } from '../../../models';

function getCooldownPeriods(): Promise<algorithmType> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<algorithmType>({
    method: 'get',
    url: 'settings/cooldown-periods',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getCooldownPeriods;
