import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface detectionsSettingsInListState {
    id: number
    detection_name: string
    camera_id: number
    camera_name: string
    min_nr_objects: number
    threshold: number
}

export interface detectionsSettingsListState {
    list: detectionsSettingsInListState[]
}

const initialState: detectionsSettingsListState = {
    list: localStorage.getItem("cachedDetectionsSettingsList") != null ? JSON.parse(localStorage.getItem("cachedDetectionsSettingsList") ?? '') : []
}

export const detectionsSettingsList = createSlice({
    name: 'detectionsSettingsList',
    initialState,
    reducers: {
        setCachedDetectionsSettingsList: (state, action: PayloadAction<detectionsSettingsListState>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedDetectionsSettingsList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedDetectionsSettingsList } = detectionsSettingsList.actions;

export const detectionsSettingsListReducer = detectionsSettingsList.reducer;
