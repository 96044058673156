import callApiAxiosClient from '../../../axios/axios_client';
import { countyList } from '../../../models';

function getCounties(): Promise<countyList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<countyList>({
    method: 'get',
    url: 'settings/counties',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getCounties;
