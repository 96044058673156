import { useEffect, useRef, useState } from 'react';
import * as ReactBootstrap from 'react-bootstrap';

type Props = {
  children?: React.ReactNode;
  size?: 'xl' | 'lg' | 'sm';
  show: boolean;
  closeButton: boolean;
  confirmButton: boolean;
  showModalCallback: (active: boolean) => void;
  title: string;
  closeButtonOptions: ButtonModal;
  confirmButtonOptions: ButtonModal;
  bodyClass?: string;
  disabled?: boolean;
  onExited?: () => void;
  headerElement?: React.ReactNode;
};

type ButtonModal = {
  text?: string;
  icon?: string;
  variant?: string;
  class?: string;
  onClick?: () => void;
  disabledBtn?: boolean;
};

const CommonModal: React.FC<Props> = ({
  children,
  size,
  show,
  showModalCallback,
  closeButton,
  confirmButton,
  title,
  closeButtonOptions,
  confirmButtonOptions,
  bodyClass,
  disabled,
  onExited,
  headerElement,
}) => {
  const [disableButtons, setDisableButtons] = useState(false);

  return (
    <ReactBootstrap.Modal
      size={size}
      show={show}
      onHide={() => {
        showModalCallback(false);
      }}
      backdrop={disabled || !show ? 'static' : undefined}
      keyboard={!disabled}
      onExit={() => setDisableButtons(true)}
      onEnter={() => setDisableButtons(false)}
      onExited={onExited}
    >
      <ReactBootstrap.Modal.Header className='d-flex flex-row align-items-center justify-content-between'>
        <h2>{title}</h2>
        <div className='d-flex flex-row align-items-center gap-3'>
          {headerElement && headerElement}
          <div
            className='btn btn-sm btn-icon btn-active-color-primary '
            onClick={() => {
              !disabled && show && !disableButtons && showModalCallback(false);
            }}
          >
            <i className='bi bi-x' style={{ fontSize: '2rem' }} />
          </div>
        </div>
      </ReactBootstrap.Modal.Header>
      <ReactBootstrap.Modal.Body
        className={`d-flex justify-content-center ${bodyClass}`}
        onKeyDown={(e: { key: string }) => {
          if (e.key === 'Enter' && !disabled && show && !disableButtons) {
            confirmButtonOptions?.onClick?.();
          }
        }}
      >
        {children}
      </ReactBootstrap.Modal.Body>
      <ReactBootstrap.Modal.Footer>
        {closeButton && (
          <ReactBootstrap.Button
            className={closeButtonOptions.class ? closeButtonOptions.class : 'fgo-btn-save-css'}
            disabled={!show || disabled || disableButtons}
            variant={closeButtonOptions.variant ? closeButtonOptions.variant : 'secondary'}
            onClick={() => {
              !disabled && !disableButtons && showModalCallback(false);
            }}
          >
            <i className={closeButtonOptions.icon ? closeButtonOptions.icon : 'bi bi-x'}></i>
            Close
          </ReactBootstrap.Button>
        )}
        {confirmButton && (
          <ReactBootstrap.Button
            disabled={!show || disabled || disableButtons}
            variant={confirmButtonOptions.variant ? confirmButtonOptions.variant : 'secondary'}
            className={confirmButtonOptions.class ? confirmButtonOptions.class : 'fgo-btn-save-css'}
            onClick={() => {
              confirmButtonOptions.onClick !== undefined && !disabled && !disableButtons && confirmButtonOptions.onClick();
            }}
          >
            {disabled ? (
              <ReactBootstrap.Spinner animation='border' size='sm' className='me-2' />
            ) : (
              <i className={confirmButtonOptions.icon ? confirmButtonOptions.icon : 'bi bi-save'}></i>
            )}
            {confirmButtonOptions.text ? confirmButtonOptions.text : 'Confirm'}
          </ReactBootstrap.Button>
        )}
      </ReactBootstrap.Modal.Footer>
    </ReactBootstrap.Modal>
  );
};

export { CommonModal };
