import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { menuItemList } from '../web_api/models';

export interface menuState {
  menu: menuItemList;
}

const initialState: menuState = {
  menu: localStorage.getItem('menu') != null ? JSON.parse(localStorage.getItem('menu') ?? '') : [],
};

export const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenu: (state, action: PayloadAction<menuItemList>) => {
      state.menu = action.payload;
      localStorage.setItem('menu', JSON.stringify(action.payload));
    },
  },
});

export const { setMenu } = menu.actions;

export const menuReducer = menu.reducer;
