import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { detectionItemInList, detectionList } from '../web_api/models';

export interface AlertsState {
  alerts: detectionList;
}
const initialState: AlertsState = {
  alerts: [],
};

export const alerts = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<detectionList>) => {
      state.alerts = action.payload;
    },
    addAlert: (state, action: PayloadAction<detectionItemInList>) => {
      state.alerts.push(action.payload);
    },
  },
});

export const { setAlerts, addAlert } = alerts.actions;

export const alertsReducer = alerts.reducer;
