/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { } from 'react'
import 'react-day-picker/dist/style.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { locationsList } from '../../../web_api/models';
import { Spinner } from 'react-bootstrap';

type Props = {
    locationsOnUser: locationsList,
    isLoadingLocations: boolean
}

const UserData: React.FC<Props> = ({ locationsOnUser, isLoadingLocations }) => {
    const userName = useSelector((state: RootState) => state.user.user_name);


    return (
        <>
            <div className='col-12 col-sm-4 col-md-3 col-xl-2 me-7 mb-3 d-flex d-sm-block justify-content-center justify-content-sm-start'>
                <div className="fs-3qx fw-bold bg-light text-inverse-primary rounded-2 h-100 d-flex">
                    <i className='text-inverse-light m-auto bi bi-person' style={{ fontSize: '8rem' }}>
                    </i>
                </div>
            </div>
            <div className='flex-grow-1 d-flex flex-column align-items-center d-sm-block col-sm-10'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex align-items-center mb-2'>
                            <a href='#' className='text-gray-800 fs-2 fw-bolder me-1'>
                                {userName}
                            </a>
                            <a href='#'>
                            </a>
                        </div>
                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 justify-content-center justify-content-sm-start'>
                            <span className='d-flex align-items-center text-gray-400 me-sm-5 mb-sm-2'> {userName} </span>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-8'>
                        <div className='d-flex flex-wrap'>
                            {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-2 fw-bolder'>56.324</div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>Entries</div>
                            </div> */}

                            <div className={`border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-sm-6 mb-sm-3 ${isLoadingLocations ? "d-flex flex-col justify-content-center align-items-center" : ""}`}
                            style={isLoadingLocations ? {display: 'flex', flexDirection:'column'} : {}}
                            >
                                {isLoadingLocations ?
                                    <Spinner animation="border" role="statusUser"></Spinner> 
                                :
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='fs-2 fw-bolder'>{locationsOnUser.length}</div>
                                </div>
                                }

                                <div className='fw-bold fs-6 text-gray-400 text-center'>Locations</div>
                            </div>

                            {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-2 fw-bolder'>65%</div>
                                </div>

                                <div className='fw-bold fs-6 text-gray-400'>Other stats</div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { UserData }
