/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { DayWeekMonthYearEnum, productFillPercentageList } from '../../web_api/models';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import LoadingPage from '../../layout/LoadingPage';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import bakeryProductFillPercentage from '../../web_api/methods/bakeryDash/productFillPercentage';
import { compareLocationState } from '../../redux/compareLocation1Reducer';
import { compareUserState } from '../../redux/compareUser1Reducer';

type Props = {
  className: string;
  chartHeight: string;
  chartColor: string;
  type: ApexChart['type'];
  filter: DayWeekMonthYearEnum;
  nr: number;
  top: boolean;
  barColors: ({ value }: { value: number }) => string;
};

const TopFlopCharts: React.FC<Props> = ({ className, chartHeight, chartColor, type, filter, nr, top, barColors }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
  const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
  const compareUser1 = useSelector((state: RootState) => state.compareUser1);
  const compareUser2 = useSelector((state: RootState) => state.compareUser2);
  const [values, setValues] = useState<number[][]>([]);
  const [names, setNames] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const labels = [
    [compareUser1.id !== '0' ? `${compareLocation1.value}(${compareUser1.value})` : compareLocation1.value],
    [compareUser2.id !== '0' ? `${compareLocation2.value}(${compareUser2.value})` : compareLocation2.value],
  ];
  useEffect(() => {
    loadProductFillPercentage();
  }, [compareLocation1, compareLocation2, compareUser1, compareUser2, dateFilter, nr]);

  const loadProductFillPercentage = async () => {
    try {
      setLoading(true);
      const response1: productFillPercentageList = await bakeryProductFillPercentage(
        parseInt(compareLocation1.id),
        parseInt(compareLocation2.id),
        new Date(dateFilter.from),
        new Date(dateFilter.to),
        nr,
        top,
        compareUser1.id
      );
      const values1: number[] = [];
      const forNames: string[] = [];
      const values2: number[] = [];
      response1.forEach((item) => {
        values1.push(item.fill_percentage1);
        values2.push(item.fill_percentage2);
        forNames.push(item.product);
      });
      setValues([values1, values2]);
      setNames(forNames);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, chartColor, names, values, labels, type, filter, barColors));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values, labels, names]);

  return loading ? (
    <LoadingPage />
  ) : (
    <div className={`card ${className}`}>
      <div className='card-body p-5 d-flex flex-column'>
        <div
          ref={chartRef}
          className='mixed-widget-3-chart card-rounded-bottom'
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  );
};

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  xAxis: string[],
  yAxis: number[][],
  labels: string[][],
  type: ApexChart['type'],
  filter: DayWeekMonthYearEnum,
  barColors: ({ value }: { value: number }) => string
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800');
  const strokeColor = getCSSVariableValue('--kt-gray-300');
  const baseColor = getCSSVariableValue('--kt-' + chartColor);
  let title = '';
  if (filter === DayWeekMonthYearEnum.DAY) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
  } else if (filter === DayWeekMonthYearEnum.WEEK) {
    const date = new Date();
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNum = Math.ceil(((date.valueOf() - firstDayOfYear.valueOf()) / 86400000 + firstDayOfYear.getDay() + 1) / 7);
    title = `Week ${weekNum} ${date.toLocaleDateString('en-US', { month: 'long' })}`;
  } else if (filter === DayWeekMonthYearEnum.MONTH) {
    const date = new Date();
    title = date.toLocaleDateString('en-US', { month: 'long' });
  } else {
    const date = new Date();
    title = date.getFullYear().toString();
  }
  let seriesSets = new Array();
  yAxis.forEach((item, index) => {
    seriesSets.push({ name: labels[index], data: item });
  });
  return {
    series: seriesSets,
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: 'Total detections - ' + title,
          },
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: true, // Set to true for a horizontal stacked bar chart
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return `${val}%`;
      },
      distributed: true,
      style: {
        colors: ['white'],
      },
    },

    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '9px',
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    colors: [barColors],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
};
export { TopFlopCharts };
