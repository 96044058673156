/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown1 from '../../dropdown/Dropdown1'
import { KTSVG } from '../../helpers'
import { Chart1MixedCharts1 } from './Chart1MixedCharts1'
import { DayWeekMonthYearEnum, evolutionIndex } from '../../../web_api/models'
import peopleCountingEvolutionIndex from '../../../web_api/methods/peopleCountingDash/evolutionIndex'
import { numberFormat } from '../../../web_api/methods/helpers'


type Props = {
    className: string
}

const MixedCharts1: React.FC<Props> = ({ className }) => {
    var [index, setIndex] = useState<number>(1)
    const [yearValue, setYearValue] = useState<number>(0)
    const [yearEvo, setyearEvo] = useState<number>(0)
    const [monthValue, setMonthValue] = useState<number>(0)
    const [monthEvo, setMonthEvo] = useState<number>(0)
    const [weekValue, setWeekValue] = useState<number>(0)
    const [weekEvo, setWeekEvo] = useState<number>(0)
    const [dayValue, setDayValue] = useState<number>(0)
    const [dayEvo, setDayEvo] = useState<number>(0)

    useEffect(() => {
        loadEvolutionIndexes();
    }, [index]);

    let loadEvolutionIndexes = async () => {
        try {
            let evolutionIndexes: evolutionIndex = await peopleCountingEvolutionIndex();
            setYearValue(evolutionIndexes.year_value)
            setyearEvo(evolutionIndexes.year_evolution)
            setMonthValue(evolutionIndexes.month_value)
            setMonthEvo(evolutionIndexes.month_evolution)
            setWeekValue(evolutionIndexes.week_value)
            setWeekEvo(evolutionIndexes.week_evolution)
            setDayValue(evolutionIndexes.day_value)
            setDayEvo(evolutionIndexes.day_evolution)
        }
        catch (e) {
            console.log(e)
        }
    }

    const changingComponent = useMemo(() => {
        return (index == 1) ?
            (
                <Chart1MixedCharts1
                    type='bar'
                    className='w-sm-100'
                    chartColor='primary'
                    chartHeight='150px'
                    filter={DayWeekMonthYearEnum.YEAR}

                />
            )
            :
            (index == 2) ?
                (
                    <Chart1MixedCharts1
                        type='area'
                        className='w-sm-100'
                        chartColor='danger'
                        chartHeight='150px'
                        filter={DayWeekMonthYearEnum.MONTH}
                    />
                )
                :
                (index == 3) ?
                    (
                        <Chart1MixedCharts1
                            type='bar'
                            className='w-sm-100'
                            chartColor='warning'
                            chartHeight='150px'
                            filter={DayWeekMonthYearEnum.WEEK}
                        />
                    )
                    :
                    (
                        <Chart1MixedCharts1
                            type='area'
                            className='w-sm-100'
                            chartColor='success'
                            chartHeight='150px'
                            filter={DayWeekMonthYearEnum.DAY}
                        />
                    )
    }, [index])

    useEffect(() => {
        setInterval(() => {
            setIndex(index % 4 == 0 ? 4 : index % 4)
            index++;
        }, 30000)
    }, [])
    return (
        <div className={`card ${className}`}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total detections</span>

                    <span className='text-muted fw-semibold fs-7'>Recent statistics comparison</span>
                </h3>

                <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                       
                    </button>
                    <Dropdown1 />
                </div>
            </div>
            <div className='card-body p-0 d-flex flex-column'>
                <div className='card-px bg-body flex-grow-1'>
                    <div className='row g-0'>
                        <div className={'col mr-8 cursor-pointer'} onClick={() => setIndex(1)}>
                            <div className={'col-sm-8 rounded-4 p-3 ' + (index == 1 ? 'bg-light-primary' : '')}>
                                <div className='fs-7 text-muted fw-semibold'>Year&nbsp;
                                    <span className={'fw-bolder me-2 rounded-2 fs-7 px-1 ' +
                                        (yearEvo > 0 ? 'bg-light-success text-success' : yearEvo < 0 ? 'bg-light-danger text-danger' : 'bg-light-info text-info')}>
                                        {yearEvo > 0 ? '+' : ''}
                                        {(yearEvo != null && yearEvo != undefined) && yearEvo.toFixed(2)}%
                                    </span>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-6 fw-bold'>{numberFormat(yearValue) + ' detections'}</div>
                                  
                                </div>
                            </div>
                        </div>
                        <div className={'col cursor-pointer'} onClick={() => setIndex(2)}>
                            <div className={'col-sm-8 rounded-4 p-3 ' + (index == 2 ? 'bg-light-primary' : '')}>
                                <div className='fs-7 text-muted fw-semibold'>Month&nbsp;
                                    <span className={'fw-bolder me-2 rounded-2 fs-7 px-1 ' +
                                        (monthEvo > 0 ? 'bg-light-success text-success' : monthEvo < 0 ? 'bg-light-danger text-danger' : 'bg-light-info text-info')}>
                                        {monthEvo > 0 ? '+' : ''}
                                        {(monthEvo != null && monthEvo != undefined) && monthEvo.toFixed(2)}%
                                    </span>
                                    {/* <span className='fw-bolder me-2 text-success bg-light-success rounded-2 fs-7 px-1'>
                                        +
                                        8,7%
                                    </span> */}
                                </div>
                                <div className='fs-6 fw-bold'>{numberFormat(monthValue) + ' detections'}</div>
                            </div>
                        </div>
                    </div>
                    <div className='row g-0 mt-8'>
                        <div className={'col mr-8 cursor-pointer'} onClick={() => setIndex(3)}>
                            <div className={'col-sm-8 rounded-4 p-3 ' + (index == 3 ? 'bg-light-primary' : '')}>
                                <div className='fs-7 text-muted fw-semibold'>Week&nbsp;
                                    <span className={'fw-bolder me-2 rounded-2 fs-7 px-1 ' +
                                        (weekEvo > 0 ? 'bg-light-success text-success' : weekEvo < 0 ? 'bg-light-danger text-danger' : 'bg-light-info text-info')}>
                                        {weekEvo > 0 ? '+' : ''}
                                        {(weekEvo != null && weekEvo != undefined) && weekEvo.toFixed(2)}%
                                    </span>
                                    {/* <span className='fw-bolder me-2 text-success bg-light-success rounded-2 fs-7 px-1'>
                                        +
                                        11,5%
                                    </span> */}
                                </div>
                                <div className='fs-6 fw-bold'>{numberFormat(weekValue) + ' detections'}</div>
                            </div>
                        </div>
                        <div className={'col cursor-pointer'} onClick={() => setIndex(4)}>
                            <div className={'col-sm-8 rounded-4 p-3 ' + (index == 4 ? 'bg-light-primary' : '')}>
                                <div className='fs-7 text-muted fw-semibold'>Today&nbsp;
                                    <span className={'fw-bolder me-2 rounded-2 fs-7 px-1 ' +
                                        (dayEvo > 0 ? 'bg-light-success text-success' : dayEvo < 0 ? 'bg-light-danger text-danger' : 'bg-light-info text-info')}>
                                        {dayEvo > 0 ? '+' : ''}
                                        {(dayEvo != null && dayEvo != undefined) && dayEvo.toFixed(2)}%
                                    </span>
                                    {/* <span className='fw-bolder me-2 text-success bg-light-success rounded-2 fs-7 px-1'>
                                        +
                                        3,5%
                                    </span> */}
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fs-6 fw-bold'>{numberFormat(dayValue) + ' detections'}</div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <>{changingComponent}</>
            </div>
        </div>
    )
}
export { MixedCharts1 }