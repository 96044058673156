import { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { lprLocationInList } from '../../web_api/models';
import { LeafMap } from '../Map/LeafMap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getLPRLocations from '../../web_api/methods/traffic/getLPRLocations';
import { setLocations } from '../../redux/trafficReducer';
import LoadingPage from '../../layout/LoadingPage';

const MapTrafficWrapper = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('traffic-map', 'Traffic Map');
  });

  const cachedLocationsList = useSelector((state: RootState) => state.traffic.locations);
  const [locationMarkers, setLocationMarkers] = useState<{ id: number; position: { lat: number; long: number }; name: string }[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  const loadTrafficLocations = async () => {
    setLoading(true);

    const res = await getLPRLocations();
    console.log('res', res);
    console.log('cachedLocationsList', cachedLocationsList);
    if (cachedLocationsList.length === 0) dispatch(setLocations(res));
    setLocationMarkers(
      res.map((location: lprLocationInList) => {
        return { id: location.location_id, position: { lat: location.lat, long: location.long }, name: location.name };
      })
    );
    setLoading(false);
  };

  useEffect(() => {
    loadTrafficLocations();
  }, []);

  return loading ? (
    <LoadingPage />
  ) : (
    <div style={{ height: '93vh' }}>
      <LeafMap className={'h-100'} allowMultipleMarkers={true} allowDelete={false} markers={locationMarkers} allowAdd={false} />
    </div>
  );
};

export default MapTrafficWrapper;
