import React, { useEffect, useState } from 'react';
import { DayWeekMonthYearEnum, alertByPeriodList, interval } from '../../web_api/models';
import { AlertsNumberChart } from './AlertsNumberChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import peopleCountingEvolutionValue from '../../web_api/methods/peopleCountingDash/evolutionValue';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
import getAlertsByPeriod from '../../web_api/methods/hsse/getAlertsByPeriod';
type Props = {
  className?: string;
  filter: interval;
};

const AlertsNumber: React.FC<Props> = ({ className, filter }) => {
  const dayWeekMonthYear = convertStringToEnumValue(filter); //dependency
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  //dependencies
  const [barOrArea, setBarOrArea] = useState<boolean>(false);
  const [values, setValues] = useState<number[]>([]);
  const [valuesPrev, setValuesPrev] = useState<number[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadEvolutionValues(filter);
  }, [dayWeekMonthYear, activeLocation, selectedUser]);

  let loadEvolutionValues = async (filter: interval) => {
    try {
      setLoaded(false);
      let evolutionValues: alertByPeriodList = await getAlertsByPeriod(filter);
      let forValues = new Array();
      let forValuesPrev = new Array();
      let forDate = new Array();

      evolutionValues.forEach((item) => {
        forValues.push(item.detections_number ? item.detections_number : 0);
        forValuesPrev.push(item.detections_number_compared ? item.detections_number_compared : 0);

        if (filter === 'year') forDate.push(monthNames[parseInt(item.date ? item.date.slice(5, 7) : item.date_compared.slice(5, 7)) - 1]);
        else if (filter === 'month')
          forDate.push(
            new Date(item.date ? item.date : item.date_compared)
              .toLocaleString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/\//g, '-')
          );
        else if (filter === 'week') forDate.push(dayNames[new Date(item.date ? item.date : item.date_compared).getDay() - 1]);
        // else if (filter === DayWeekMonthYearEnum.DAY) forDate.push(item.date.slice(item.date.length - 3, item.date.length) + ':00');
      });
      setValues(forValues);
      setValuesPrev(forValuesPrev);
      setDates(forDate);
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={`card h-100 ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Number of alerts</span>
          <span className='text-muted fw-semibold fs-7'>Current and last {dayWeekMonthYear}</span>
        </h3>
        <div className='card-title btn btn-light-primary btn-icon btn-sm' onClick={() => setBarOrArea(!barOrArea)}>
          <i className={`bi-${!barOrArea ? 'bar-chart' : 'graph-down'}`} />
        </div>
      </div>
      <div className='card-body p-0'>
        {values.length === 0 && (
          <div className='d-flex flex-row align-items-center justify-content-center gap-4 h-200px w-100'>
            <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
            <div className='fs-3 text-secondary-emphasis'>No alerts recorded</div>
          </div>
        )}
        {(values.length > 0 || valuesPrev.length > 0) && (
          <AlertsNumberChart
            type={barOrArea ? 'bar' : 'area'}
            className='w-sm-100'
            chartColor='danger'
            chartSecondaryColor='info'
            chartHeight='150px'
            filter={dayWeekMonthYear}
            dates={dates}
            values={values}
            valuesPrev={valuesPrev}
            loaded={loaded}
          />
        )}
      </div>
    </div>
  );
};
export { AlertsNumber };
