import callApiAxiosClient from '../../../axios/axios_client';

type addLocationParams = {
  name?: string;
  lat?: number;
  long?: number;
  county_id?: number;
};

async function addLocation({ name, lat, long, county_id }: addLocationParams): Promise<{ id: number }> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'settings/locations',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      name: name,
      lat: lat,
      lng: long,
      county_id: county_id,
    },
  });
}
export default addLocation;
