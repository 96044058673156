import callApiAxiosClient from "../../../axios/axios_client";

function deleteLocations(ids: number[]): Promise<void> {
    if (ids === null) {
        throw new Error("Invalid location id");
    }
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "delete",
        url: "settings/locations",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            ids: ids
        }
    })
}
export default deleteLocations;