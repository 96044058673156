import callApiAxiosClient from '../../axios/axios_client';
import { trainingPictureWithPolygons } from '../../models';

function getTrainingImages(modelID: number): Promise<trainingPictureWithPolygons[]> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<trainingPictureWithPolygons[]>({
    method: 'get',
    url: 'training/images',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      model_id: modelID,
    },
  });
}
export default getTrainingImages;
