import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DateFilterState {
  from: string
  to: string
}
const initialState: DateFilterState = {
  from: new Date().toISOString(),
  to: new Date().toISOString()
};

export const dateFilter = createSlice({
  name: 'dateFilter',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<DateFilterState>) => {
      state.from = action.payload.from
      state.to = action.payload.to
    },
  },
});

export const { setValue } = dateFilter.actions;

export const dateFilterReducer = dateFilter.reducer;
