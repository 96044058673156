import { v4 as uuidv4 } from 'uuid';
import LoadingPage from '../../../layout/LoadingPage';
import { Link } from 'react-router-dom';
type Props = {
    buttons: React.ReactNode,
    items: React.ReactNode,
    loading?: boolean
    background?: string,
    headingSmall?: string,
    headingLarge?: string,
    containerClass?: string,
    handleCheckClick?: () => void,
    handleTrashIconClick?: () => void
    style?: React.CSSProperties
    linkTo?: string
    canLink?: boolean
}

const CommonCard: React.FC<Props> = ({
    buttons,
    items,
    background,
    loading,
    headingSmall,
    headingLarge,
    containerClass,
    handleCheckClick,
    handleTrashIconClick,
    style,
    linkTo,
    canLink
}) => {
    const uniqueId = uuidv4();


    const handleLinkClick = (e: any) => {
        if (!canLink) {
            e.preventDefault(); // Prevent the default link behavior
            // Optionally, you can add some logic here to handle the case where the link is disabled.
        }
    };

    return (
        <div className={`card ${containerClass}`} style={style}>
            <div className='card-body p-0'>
                {/* begin::Header */}
                {loading ? (
                    <LoadingPage />
                ) :
                    (
                        // <Link
                        //     to={`${(canLink) ? `${linkTo}` : `#`}`}
                        //     onClick={(e: any) => e.preventDefault()}
                        // >
                        <div className={`px-9 pt-7 card-rounded h-275px w-100`} style={{
                            backgroundImage: background,
                            backgroundSize: 'cover'
                        }}>
                            {/* begin::Heading */}

                            <div className='d-flex flex-stack'>
                                <div className={`form-check form-check-sm form-check-custom form-check-solid ${handleCheckClick ? "" : "invisible"}`}>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value='1'
                                        data-kt-check='true'
                                        data-kt-check-target='.widget-9-check'
                                        id={uniqueId}
                                        onClick={handleCheckClick}
                                    />
                                </div>

                                <div className={`ms-1 ${handleTrashIconClick ? "" : "invisible"}`}>
                                    {/* begin::Menu */}
                                    <button
                                        type='button'
                                        className={`btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3`}
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        onClick={handleTrashIconClick}
                                    >
                                        <i className='svg-icon-2 bi bi-trash fa-3x' />
                                    </button>
                                    {/* <Dropdown1 /> */}
                                    {/* end::Menu */}
                                </div>

                            </div>
                            {/* end::Heading */}
                            {/* begin::Balance */}
                            <Link
                                className='d-flex flex-start flex-column h-100'
                                to={`${(canLink) ? `${linkTo}` : `#`}`}
                                onClick={handleLinkClick}
                            >
                                <div className='d-flex text-center flex-column text-white pt-8 mx-auto'>
                                    <span className='fw-semibold fs-7' style={{ textShadow: 'black 1px 0 7px' }}>{headingSmall}</span>
                                    <span className='fw-bold fs-2x pt-1' style={{ textShadow: 'black 1px 0 7px' }}>{headingLarge}</span>
                                </div>
                            </Link>
                            {/* end::Balance */}
                        </div>


                    )}

                {/* end::Header */}
                {/* begin::Items */}
                <div
                    className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
                    style={{ marginTop: '-50px' }}
                >
                    <div>
                        {items}
                    </div>
                    {buttons}
                </div>
            </div>
            {/* begin::Body */}

            {/* end::Body */}
        </div >
    )
}

export { CommonCard }
