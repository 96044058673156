import callApiAxiosClient from "../../axios/axios_client";
import moment from "moment";
import { productEvolutionList } from "../../models";


async function bakeryProductEvolution(locationId: number, date: Date | undefined, product: string): Promise<productEvolutionList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const dateParam = moment(date).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<productEvolutionList>({
        method: "get",
        url: "programmability/product-evolution",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            locationId: locationId,
            date: dateParam,
            product: product,
        }
    })
}
export default bakeryProductEvolution;