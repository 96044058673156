import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface detectionTypesInList {
    product_id: number,
    detection_type: number,
    detection_name: string
}

export interface detectionTypeListState {
    list: detectionTypesInList[]
}

const initialState: detectionTypeListState = {
    list: localStorage.getItem("cachedDetectionTypeList") != null ? JSON.parse(localStorage.getItem("cachedDetectionTypeList") ?? '') : []
}

export const detectionTypeList = createSlice({
    name: 'detectionTypeList',
    initialState,
    reducers: {
        setCachedDetectionTypeList: (state, action: PayloadAction<detectionTypeListState>) => {
            state.list = action.payload.list.reverse()
            localStorage.setItem("cachedDetectionTypeList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedDetectionTypeList } = detectionTypeList.actions;

export const detectionListReducer = detectionTypeList.reducer;
