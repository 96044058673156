import { toast } from 'react-toastify';
import useDelete from '../../hooks/Common/useDelete';
import useModals from '../../hooks/Common/useModals';
import deleteDetection from '../../web_api/methods/settings/detections/deleteDetections';
import { CommonCard } from '../Common/CommonCard/CommonCard';
import CommonCardButton from '../Common/CommonCard/CommonCardButton';
import CommonCardItem from '../Common/CommonCard/CommonCardItem';
import { CommonModal } from '../Common/CommonModal';
import { percentageFormat } from '../../web_api/methods/helpers';
import { useEffect, useState } from 'react';
import { cameraDetectionInList, objectClassesList, trainingModelInList, trainingSessionList } from '../../web_api/models';
import addDetection from '../../web_api/methods/settings/detections/addDetection';
import deleteTrainingModel from '../../web_api/methods/training/deleteTrainingModel';
import bg_img from '../../assets/images/bg_img.jpeg';
type TrainingCardProps = {
  trainingModel: trainingModelInList;
  onDetectionCheck: (id: number) => void;
  onDetectionDelete: (id: number) => void;
};

const TrainingCard: React.FC<TrainingCardProps> = ({ trainingModel, onDetectionCheck, onDetectionDelete }) => {
  const { show, toggleModal } = useModals([false]);

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const {
    deleteItems: handleDeleteDetection,
    errors,
    isDeleting,
  } = useDelete(
    () => deleteTrainingModel(trainingModel.id),
    () => {
      toggleModal(0);
      onDetectionDelete(trainingModel.id);
      toast.success('Detection deleted successfully!', {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    }
  );

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors?.length]);

  return (
    <>
      <div className='animated zoomIn col-12 col-md-6 col-xxl-4'>
        <CommonCard
          key={trainingModel.id}
          containerClass={`my-4 h-min-300px animated ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100`}
          background={`url(${bg_img})`}
          headingLarge={trainingModel.name}
          headingSmall={trainingModel.description}
          handleTrashIconClick={() => toggleModal(0)}
          handleCheckClick={() => onDetectionCheck(trainingModel.id)}
          canLink
          linkTo={`${process.env.REACT_APP_HOMEPAGE}/training/${trainingModel.id}`}
          items={
            <>
              <CommonCardItem textLarge='Runs' textSmall='' value={trainingModel.runs} />
              <CommonCardItem textLarge='Date' textSmall='' value={`${new Date(trainingModel.created_date).toLocaleDateString('en-gb')}`} />
            </>
          }
          buttons={<></>}
        />
      </div>
      <CommonModal
        title={'Confirm'}
        show={show[0]}
        disabled={isDeleting}
        showModalCallback={() => toggleModal(0)}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3 text-white',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleDeleteDetection,
        }}
      >
        <div className='text-justify'>Are you sure you want to delete the following the camera's detection?</div>
        <div className='fw-bolder mt-2'>{`${trainingModel.id} - ${trainingModel.name} - ${trainingModel.id}`}</div>
      </CommonModal>
    </>
  );
};

export default TrainingCard;
