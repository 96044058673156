import callApiAxiosClient from '../../axios/axios_client';
import { polygonObjectList } from '../../models';

async function addTrainingDetectionRegions(imageId: number, polygons: polygonObjectList): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'training/detection-region',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      image_id: imageId,
      polygons: polygons,
    },
  });
}

export default addTrainingDetectionRegions;
