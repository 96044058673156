import { configureStore } from '@reduxjs/toolkit';
import { activeLocationReducer } from './activeLocationReducer';
import { audioAlertsReducer } from './audioAlertsReducer';
import { avgTimeCashRegisterAllLocationsReducer } from './avgTimeCashRegisterAllLocationsReducer';
import { avgTimeCashRegisterOpenListReducer } from './avgTimeCashRegisterOpenListReducer';
import { compareWithLocationReducer } from './compareWithLocationReducer';
import { dateFilterReducer } from './dateFilterReducer';
import { hourParametersReducer } from './hourParametersReducer';
import { isValidReducer } from './isValidReducer';
import { activeProductReducer } from './activeProductReducer';
import { mostQueueAlertsReducer } from './mostQueueAlertsReducer';
import { queueDurationAllLocationsReducer } from './queueDurationAllLocationsReducer';
import { queueDurationListReducer } from './queueDurationListReducer';
import { totalQueueAlertsReducer } from './totalQueueAlertsReducer';
import { userReducer } from './userReducer';
import { toolbarReducer } from './toolbarReducer';
import { userListReducer } from './userListReducer';
import { selectedUserReducer } from './selectedUserReducer';
import { compareUser1Reducer } from './compareUser1Reducer';
import { compareLocation1Reducer } from './compareLocation1Reducer';
import { compareUser2Reducer } from './compareUser2Reducer';
import { compareLocation2Reducer } from './compareLocation2Reducer';
import { camerasListReducer } from './camerasListReducer';
import { cachedLocationsReducer } from './cachedLocationsReducer';
import { personListReducer } from './personListReducer';
import { selectedCameraReducer } from './selectedCameraReducer';
import { selectedPersonReducer } from './selectedPersonReducer';
import { distinctPersonListReducer } from './distinctPersonListReducer';
import { toolbarDateFilterReducer } from './toolbarDateFilterReducer';
import { toolbarHourFilterReducer } from './toolbarHourFilterReducer';
import { detectionListReducer } from './detectionTypeListReducer';
import { detectionTypesReducer } from './detectionTypesReducer';
import { cameraModelListReducer } from './cameraModelListReducer';
import { detectionServerListReducer } from './detectionServerListReducer';
import { dayWeekMonthYearReducer } from './dayWeekMonthYearReducer';
import { selectedDetectionTypeReducer } from './selectedDetectionTypeReducer';
import { cameraImagesReducer } from './cameraImagesReducer';
import { demoProductReducer } from './demoProductReducer';
import { detectionsSettingsListReducer } from './detectionsSettingsReducer';
import { selectedMetricReducer } from './selectedMetricReducer';
import { toolbarSearchFilterReducer } from './toolbarSearchFilterReducer';
import { permissionsReducer } from './permissionsReducer';
import { addItemsReducer } from './addItemsReducer';
import { trafficReducer } from './trafficReducer';
import { trainingReducer } from './trainingReducer';
import { alertsReducer } from './alertsReducer';
import { menuReducer } from './menuReducer';
import { clusterLocationsReducer } from './clusterLocationsReducer';

export const store = configureStore({
  reducer: {
    user: userReducer,
    activeLocation: activeLocationReducer,
    compareWithLocation: compareWithLocationReducer,
    audioAlerts: audioAlertsReducer,
    mostQueueAlerts: mostQueueAlertsReducer,
    dateFilter: dateFilterReducer,
    totalQueueAlerts: totalQueueAlertsReducer,
    hourParameters: hourParametersReducer,
    avgTimeCashRegisterAllLocations: avgTimeCashRegisterAllLocationsReducer,
    queueDurationAllLocations: queueDurationAllLocationsReducer,
    avgTimeCashRegisterOpenList: avgTimeCashRegisterOpenListReducer,
    queueDurationList: queueDurationListReducer,
    isValid: isValidReducer,
    activeProduct: activeProductReducer,
    demoProduct: demoProductReducer,
    toolbar: toolbarReducer,
    userList: userListReducer,
    distinctPersonList: distinctPersonListReducer,
    selectedUser: selectedUserReducer,
    selectedCamera: selectedCameraReducer,
    selectedDetectionType: selectedDetectionTypeReducer,
    selectedPerson: selectedPersonReducer,
    compareUser1: compareUser1Reducer,
    compareUser2: compareUser2Reducer,
    compareLocation1: compareLocation1Reducer,
    compareLocation2: compareLocation2Reducer,
    toolbarDateFilter: toolbarDateFilterReducer,
    toolbarHourFilter: toolbarHourFilterReducer,
    toolbarSearchFilter: toolbarSearchFilterReducer,
    camerasList: camerasListReducer,
    personList: personListReducer,
    cachedLocations: cachedLocationsReducer,
    detectionList: detectionListReducer,
    detectionsSettingsList: detectionsSettingsListReducer,
    detectionTypes: detectionTypesReducer,
    cameraModelList: cameraModelListReducer,
    detectionServerList: detectionServerListReducer,
    dayWeekMonthYear: dayWeekMonthYearReducer,
    cameraImages: cameraImagesReducer,
    selectedMetric: selectedMetricReducer,
    permissions: permissionsReducer,
    addItems: addItemsReducer,
    traffic: trafficReducer,
    training: trainingReducer,
    alerts: alertsReducer,
    menu: menuReducer,
    cluster: clusterLocationsReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
