import callApiAxiosClient from "../../axios/axios_client";
import { dailyReportsList } from "../../models";
import moment from "moment";


async function peopleCountingDailyReoprts(fromParam: Date, toParam: Date): Promise<dailyReportsList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<dailyReportsList>({
        method: "get",
        url: "programmability/daily-reports",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            from: from,
            to: to
        }
    })
}
export default peopleCountingDailyReoprts;