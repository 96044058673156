import callApiAxiosClient from '../../axios/axios_client';
import { detectionSettingsList } from '../../models';

function getDetectionSettings(detectionId?: number, locationId?: number): Promise<detectionSettingsList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<detectionSettingsList>({
    method: 'get',
    url: 'settings/get-detection-settings',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      detectionId: detectionId,
      locationId: locationId,
    },
  });
}
export default getDetectionSettings;
