/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSSVariableValue } from '../../assets/ts/_utils'
import * as ReactBootstrap from 'react-bootstrap'
import { numberFormat } from '../../../web_api/methods/helpers'
import LoadingPage from '../../../layout/LoadingPage'


type Props = {
    className: string
    chartColor: string
    secondaryColor: string
    values: number[],
    valuesLastYear: number[]
    months: string[],
    average: number,
    max: number,
    loading: boolean
}

const ChartsWidget8: React.FC<Props> = ({ className, chartColor, secondaryColor, values, valuesLastYear, months, average, max, loading }) => {
    const chartRef = useRef<HTMLDivElement | null>(null)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
 
    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, secondaryColor, values, monthNames, valuesLastYear, max))
        if (chart) {
            chart.render()
        }

        return chart
    }

    useEffect(() => {
        const chart = refreshChart()

        return () => {
            if (chart) {
                chart.destroy()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartRef, values, months, valuesLastYear, max])

    return (
        <div className={`card ${className}`}>
            <div className='card-body p-0 d-flex justify-content-between flex-column overflow-hidden'>
                <div className='flex-grow-1 card-p pb-0'>
                    <div className='d-flex flex-stack flex-wrap'>
                        <div className='me-2'>
                            <a href='#' className='text-dark text-hover-primary fw-bold fs-3'>
                                Monthly reports
                            </a>
                            <div className='text-muted fs-7 fw-semibold'>Monthly average</div>
                        </div>
                        <div className={`fw-bold fs-7 text-${chartColor}`}>{numberFormat(average)} entries</div>
                    </div>
                </div>
                {
                    loading ?
                        (<div ref={chartRef} className='mixed-widget-10-chart card-rounded-bottom'></div>)
                        :
                        (<LoadingPage />)
                }
            </div>
        </div>
    )
}

const chartOptions = (chartColor: string, secondaryColor: string, values: number[], months: string[], valuesLastYear: number[], max: number): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-500')
    const borderColor = getCSSVariableValue('--kt-gray-200')
    const secondaryColorKT = getCSSVariableValue('--kt-' + secondaryColor)
    const baseColor = getCSSVariableValue('--kt-' + chartColor)

    return {
        series: [
            {
                name: 'Current',
                data: values,
            },
            {
                name: 'Last year',
                data: valuesLastYear,
            },
        ],
        chart: {
            fontFamily: 'inherit',
            type: 'bar',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '60%',
                borderRadius: 5,
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories: months,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                style: {
                    colors: 'labelColor',
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            min: 0,
            max: max,
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: '12px',
                },
            },
        },
        fill: {
            type: 'solid',
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0,
                },
            },
        },
        tooltip: {
            enabled: true,
            style: {
                fontSize: '9px',
            },
            y: {
                formatter: function (val) {
                    return numberFormat(val) + ' entries'
                },
            },
        },
        colors: [baseColor, secondaryColorKT],
        grid: {
            padding: {
                top: 10,
            },
            borderColor: borderColor,
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: false,
                },
            },
        },
    }
}

export { ChartsWidget8 }
