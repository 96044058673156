import CommonAnnotatedImage from '../../Common/CommonAnnotatedImage';
import placeholderImage from '../../../assets/images/placeholder-denmark.jpg';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ELoadType, polygonObjectList } from '../../../web_api/models';
import useGet from '../../../hooks/Common/useGet';
import addDetectionRegion from '../../../web_api/methods/settings/detectionRegions/addDetectionRegion';
import getDetectionRegions from '../../../web_api/methods/settings/detectionRegions/getDetectionRegions';
import modifyDetectionRegions from '../../../web_api/methods/settings/detectionRegions/modifyDetectionRegions';
import { ToastContainer, toast } from 'react-toastify';
import deleteDetectionRegions from '../../../web_api/methods/settings/detectionRegions/deleteDetectionRegions';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import { activeLocation } from '../../../redux/activeLocationReducer';
import getSnapshot from '../../../web_api/methods/files/getSnapshot';
import LoadingPage from '../../../layout/LoadingPage';
import useLoadImage from '../../../hooks/FileManagement/useLoadImage';

const DetectionInfoAnnotation = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('ddetection-info-annotate', `Annotate detection ${id}`);
  });
  const params = useParams();
  const id = params.id;
  const cameraId = params.cameraId;

  const { data, setData, loadItems } = useGet([], () => getDetectionRegions(id));

  const [save, setSave] = useState(false);

  const saveChanges = async (currentPolygons: polygonObjectList) => {
    if (save && data) {
      console.log('saveChanges', data);

      const originalPolygons = [...data.polygons];

      console.log('originalPolygons', originalPolygons);

      const addedPolygons = currentPolygons.filter((currentPolygon) => !originalPolygons.some((p) => p.id === currentPolygon.id));

      console.log('addedPolygons', addedPolygons);

      const deletedPolygons = originalPolygons.filter((originalPolygon) => !currentPolygons.some((p) => p.id === originalPolygon.id));

      console.log('deletedPolygons', deletedPolygons);

      const deletedPolygonIds = deletedPolygons.map((polygon) => polygon.id);

      const updatedPolygons = currentPolygons.filter((currentPolygon) => {
        console.log('currentPolygon', currentPolygon);
        const originalPolygon = originalPolygons.find((p) => p.id === currentPolygon.id);
        console.log('originalPolygon', originalPolygon);
        if (!originalPolygon) {
          return false;
        }
        const polygonChanged = originalPolygon.polygon !== currentPolygon.polygon;
        const bodyChanged = JSON.stringify(originalPolygon.body) !== JSON.stringify(currentPolygon.body);
        return polygonChanged || bodyChanged;
      });

      console.log('updatedPolygons', updatedPolygons);

      const errors: string[] = [];

      const handleAddAnnotation = async () => {
        try {
          let addedDetectionRegion = await addDetectionRegion(id ? id : -1, addedPolygons);
        } catch (error) {
          console.log(error);
          errors.push(`Add Error: ${error}`);
        }
      };

      const handleDeleteAnnotation = async () => {
        try {
          let deletedDetectionRegions = await deleteDetectionRegions(deletedPolygonIds);
        } catch (error) {
          console.log(error);
          errors.push(`Delete Error: ${error}`);
        }
      };

      const handleUpdateAnnotation = async () => {
        try {
          await modifyDetectionRegions(id ? id : -1, updatedPolygons).then((res) => {
            loadItems();
          });
        } catch (error) {
          console.log(error);
          errors.push(`Update Error: ${error}`);
        }
      };

      if (addedPolygons.length > 0) {
        await handleAddAnnotation().then(async (res) => {
          if (deletedPolygons.length > 0) {
            await handleDeleteAnnotation().then(async (res) => {
              if (updatedPolygons.length > 0) {
                await handleUpdateAnnotation();
              }
            });
          } else {
            if (updatedPolygons.length > 0) {
              await handleUpdateAnnotation();
            }
          }
        });
      } else {
        if (deletedPolygons.length > 0) {
          await handleDeleteAnnotation().then(async (res) => {
            if (updatedPolygons.length > 0) {
              await handleUpdateAnnotation();
            }
          });
        } else {
          if (updatedPolygons.length > 0) {
            await handleUpdateAnnotation();
          }
        }
      }

      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(error, {
            position: 'top-right',
            theme: 'colored',
            autoClose: 2000,
          });
        });
      } else if (addedPolygons.length > 0 || deletedPolygons.length > 0 || updatedPolygons.length > 0) {
        toast.success('Changes saved successfully!', {
          position: 'top-right',
          theme: 'colored',
          autoClose: 2000,
        });
      }
      setData({
        detection_setting_id: Number(id),
        polygons: currentPolygons,
      });
      setSave(false);
    }
  };

  const { loadingImage, url } = useLoadImage(cameraId ? parseInt(cameraId) : undefined, false, getSnapshot, ELoadType.SINGLE);
  return (
    <>
      <div className='d-flex mb-2 gap-3 justify-content-start py-3 px-3 px-sm-8'>
        <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections/${id}`}>
          <button className='btn btn-primary'>
            <i className='bi bi-arrow-return-left'></i>
            Return
          </button>
        </Link>
        <button className='btn btn-success' onClick={() => setSave(true)}>
          <i className='bi bi-check-lg'></i>
          Save Annotation
        </button>
      </div>
      {data && (
        <div className='d-flex justify-content-center'>
          <div className='bg-body p-2 rounded w-100' style={{ maxWidth: '75%' }}>
            {loadingImage ? (
              <LoadingPage />
            ) : (
              <CommonAnnotatedImage img={url} className='rounded img-fluid' saveChanges={saveChanges} data={data.polygons} save={save} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DetectionInfoAnnotation;
