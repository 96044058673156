import callApiAxiosClient from '../../axios/axios_client';
import { alertByAreaList, interval } from '../../models';

async function getAlertsByArea(interval: interval): Promise<alertByAreaList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  return callApiAxiosClient<alertByAreaList>({
    method: 'get',
    url: 'programmability/get-hsse-alerts-by-area',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      interval: interval,
    },
  });
}
export default getAlertsByArea;
