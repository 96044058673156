import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useDispatch } from 'react-redux';
import { setCluster } from '../redux/clusterLocationsReducer';

const ToolbarMap = () => {
  const cluster = useSelector((state: RootState) => state.cluster.cluster);
  const dispatch = useDispatch();

  return (
    <div className=''>
      <div className='form-check form-switch form-check-custom form-check-solid'>
        <input
          className='form-check-input h-20px w-30px'
          type='checkbox'
          value=''
          id='flexSwitchChecked'
          checked={cluster}
          onChange={() => {
            dispatch(setCluster({ cluster: !cluster }));
            console.log('new cluster', !cluster);
          }}
        />
        <label className='form-check-label fs-base fw-bold' htmlFor='flexSwitchChecked'>
          Cluster Locations
        </label>
      </div>
    </div>
  );
};

export default ToolbarMap;
