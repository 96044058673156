import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { DayWeekMonthYear } from "../Common/CommonFilters/DayWeekMonthYear";
import { AlertEvolution } from "./AlertEvolution";
import { AlertTypes } from "./AlertTypes";
import { AlertsByArea } from "./AlertsByArea";
import { AlertsNumber } from "./AlertsNumber";
import { RecentAlerts } from "./RecentAlerts";
import { useEffect } from "react";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";


export default function Mobility() {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'hsse',
            'Mobility'
        )
    })
    const dayWeekMonthYear = useSelector((state: RootState) => state.dayWeekMonthYear.filter)
    return (
        <div className="container">
            <div className="d-flex flex-column flex-xxl-row">

                <div className="d-flex col-xxl-8 p-5">
                    <AlertsNumber className="w-sm-100 w-100 animated zoomIn" filter={dayWeekMonthYear} />
                </div>
                <div className="d-flex flex-column col-xxl-4 p-0">
                    <div className="d-flex flex-column flex-sm-row w-sm-100">
                        <div className="p-5 w-sm-100">
                            <DayWeekMonthYear className="animated zoomIn" week={true} month={true} year={true} />
                        </div>
                    </div>
                    <div className="d-flex flex-row w-sm-100 h-100">
                        <div className="p-5 w-sm-100 w-100">
                            <RecentAlerts className="h-sm-100 mb-xl-5 animated zoomIn" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex flex-column col-xxl-4 p-5">
                    <AlertEvolution className="animated zoomIn" filter={dayWeekMonthYear} alert={"Bd. Unirii"} />
                </div>
                <div className="d-flex flex-column col-xxl-4 p-5">
                    <AlertEvolution className="animated zoomIn" filter={dayWeekMonthYear} alert={"Calea Mosilor"} />
                </div>
                <div className="d-flex flex-column col-xxl-4 p-5">
                    <AlertEvolution className="animated zoomIn" filter={dayWeekMonthYear} alert={"Piata Victoriei"} />
                </div>
            </div>
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex col-xxl-8 flex-column p-5 animated zoomIn">
                    <AlertTypes className="w-sm-100 w-100 animated zoomIn" filter={dayWeekMonthYear} />
                </div>
                <div className="d-flex flex-column col-xxl-4 p-5">
                    <AlertsByArea className="h-100 animated zoomIn" filter={dayWeekMonthYear} />
                </div>

            </div>

        </div>
    )
}