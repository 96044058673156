import callApiAxiosClient from '../../axios/axios_client';
import { heatmapLocationDataByHourList } from '../../models';

function getHeatmapLocDataByHour(locationId: number, date: string): Promise<heatmapLocationDataByHourList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<heatmapLocationDataByHourList>({
    method: 'get',
    url: 'programmability/heatmap-location-data-by-hour',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      locationId: locationId,
      date: date,
    },
  });
}
export default getHeatmapLocDataByHour;
