import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { setSelectedDetectionType } from '../redux/selectedDetectionTypeReducer';
import { setValue as setSelectedCamera } from '../redux/selectedCameraReducer';
import { setValue as setActiveLocation } from '../redux/activeLocationReducer';
import * as ReactBootstrap from 'react-bootstrap';
import { DayPicker } from 'react-day-picker';
import { setValue as setHeatmapDateFilter } from '../redux/toolbarDateFilterReducer';

const ToolbarLocationAndDate = () => {
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations.list);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter.date);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(new Date(toolbarDateFilter));

  const [show, setShow] = useState(false);

  const footer = selectedDay ? (
    <p className='m-auto'>
      {selectedDay
        .toLocaleString('en-US', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
        .replace(/\//g, '-')}
      .
    </p>
  ) : (
    <p className='m-auto'>Pick a day.</p>
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
  });

  const handleShowModal = () => setShow(true);

  const handleCloseModal = () => {
    setShow(false);
    dispatch(
      setHeatmapDateFilter({
        date: selectedDay!.toString(),
      })
    );
  };

  return (
    <div className='d-flex flex-column flex-md-row gap-3 gap-md-0 align-items-center mt-6 mt-md-0 w-100 w-md-auto'>
      {/* begin::Wrapper */}

      <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Location:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75 w-md-auto'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={activeLocation.value}
          onChange={(e) => {
            dispatch(
              setActiveLocation({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
            dispatch(
              setSelectedCamera({
                value: 'All',
                id: '0',
              })
            );
            dispatch(
              setSelectedDetectionType({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {cachedLocationList.map((location) => {
            return (
              <option key={location.id} value={location.name || ''} data-id={location.id}>
                {location.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Day:</span>
        {/* end::Label */}

        {/* begin::Select */}
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
            <i className='bi bi-calendar mb-1 align-self-center' />
            <span className='text-nowrap'>{footer}</span>
          </div>
        </div>
        {/* end::Select */}
        {/* <div className='cursor-pointer symbol symbol-35px symbol-md-40px d-flex flex-row mx-2 fs-7 fw-bold text-gray-700 '>
          <i className="bi bi-person mx-2 fs-3 fw-bold text-gray-700 " />
          <span className='m-auto '>{userName}</span>
          <i className="bi bi-caret-down-fill ms-1 m-auto pt-1" style={{ fontSize: '1rem' }} />

        </div> */}
        {/* begin::Actions */}

        {/* end::Actions */}
      </div>
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row'>
              <DayPicker mode='single' selected={selectedDay} onSelect={setSelectedDay} disabled={{ after: new Date() }} />
            </div>
            {/* <div className='d-flex flex-row px-5'>
              <span className="d-flex fs-3 fw-bold flex-start">Hour</span>
              <div className='d-flex m-auto flex-row'>
                <select defaultValue={fromHour} className="d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5" onChange={handleFromHourChange}>
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>{i}</option>
                  ))}
                </select>
                <span className="d-flex m-auto">:</span>
                <select defaultValue={toHour} className="d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5" onChange={handleToHourChange}>
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>{i}</option>
                  ))}
                </select>
              </div>
            </div> */}
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarLocationAndDate };
