import { useEffect, useMemo, useState } from 'react';
import { ChartTypeEnum, ComparisonEnum, DayWeekMonthYearEnum, MixedOrSeparateEnum, avgTimes } from '../../web_api/models';

import { ComparisonMixedChart } from '../../_metronic/widgets/charts/ComparisonMixedChart';
import { ChartsWidget12 } from '../../_metronic/widgets/charts/ChartsWidget12';
import { ComparisonSeparateChart } from '../../_metronic/widgets/charts/ComparisonSeparateChart';
import { format } from 'date-fns';
import { Modal, Button } from 'react-bootstrap';
import { DateRange, DayPicker } from 'react-day-picker';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import useModals from '../../hooks/Common/useModals';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import queueManagementAvgCashRegisterOpen from '../../web_api/methods/queueManagementDash/avgCashRegisterOpen';
import { getTimeInExactMinutes, percentageFormat } from '../../web_api/methods/helpers';
import { CommonBarChart } from '../Common/CommonBarChart';
import { barColors } from '../FruitsVegetables/FruitsVegetablesInfo/FruitsVegetablesCharts';
import { TopFlopCharts } from './TopFlopCharts';
import { QueueManagementComparison } from './QueueManagementComparison';
import { ComparisonTab } from './ComparisonTab';

export default function EvolutionComparison() {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('comparison', 'Comparison (BETA)');
  });
  const [mixedOrSeparate, setMixedOrSeparate] = useState<MixedOrSeparateEnum>(MixedOrSeparateEnum.MIXED);
  const [chartType, setChartType] = useState<ChartTypeEnum>(ChartTypeEnum.AREA);
  const [chartComparison, setChartComparison] = useState<ComparisonEnum>(ComparisonEnum.QUEUE_MANAGEMENT);

  const { show, toggleModal } = useModals([false]);

  const defaultSelected: DateRange = {
    from: new Date(),
    to: new Date(),
  };
  const [topNr, setTopNr] = useState<number>(1);
  const [flopNr, setFlopNr] = useState<number>(1);

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const [range2, setRange2] = useState<DateRange | undefined>(defaultSelected);

  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
  const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
  const compareUser1 = useSelector((state: RootState) => state.compareUser1);
  const compareUser2 = useSelector((state: RootState) => state.compareUser2);
  const [values1, setValues1] = useState<number[]>([]);
  const [labels1, setLabels1] = useState<string[]>([]);
  const [values2, setValues2] = useState<number[]>([]);
  const [labels2, setLabels2] = useState<string[]>([]);
  const [averagesLoading, setAveragesLoading] = useState<boolean>(false);
  const [averagesError, setAveragesError] = useState<boolean>(false);

  const hasAllLocationsSelected = compareLocation1.id === '0' || compareLocation2.id === '0';

  //for charts
  const labels = [
    [compareUser1.id !== '0' ? `${compareLocation1.value}(${compareUser1.value})` : compareLocation1.value],
    [compareUser2.id !== '0' ? `${compareLocation2.value}(${compareUser2.value})` : compareLocation2.value],
  ];
  // Bakery Food Names Dataset 1 (Numele Produselor de Băcănie - Set de Date 1) - TOP values (descending)
  const bakeryLabels1 = [
    'Croasant',
    'Baghetă',
    'Plăcintă daneză',
    'Rulou cu scorțișoară',
    'Pâine de secară',
    'Brioșă',
    'Eclair',
    'Muffin',
    'Brioșă',
    'Gogoașă',
  ];
  const bakeryValues1 = [
    [0.98, 0.93, 0.88, 0.83, 0.78, 0.73, 0.68, 0.63, 0.58, 0.53],
    [0.85, 0.8, 0.75, 0.7, 0.65, 0.6, 0.55, 0.5, 0.45, 0.4],
  ];

  // Bakery Food Names Dataset 2 (Numele Produselor de Băcănie - Set de Date 2) - FLOP values (ascending)
  const bakeryLabels2 = ['Bagel', 'Scone', 'Cupcake', 'Brownie', 'Pancake', 'Waffle', 'Focaccia', 'Shortbread', 'Pâine Pita', 'Muffin Englezesc'];
  const bakeryValues2 = [
    [0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85],
    [0.13, 0.16, 0.19, 0.22, 0.25, 0.28, 0.31, 0.34, 0.37, 0.4],
  ];

  // Fruits Dataset 1 (Set de Date Fructe - 1) - TOP values (descending)
  const fruitLabels1 = ['Măr', 'Banane', 'Portocală', 'Struguri', 'Capsuni', 'Pepene roșu', 'Ananas', 'Mango', 'Kiwi', 'Piersică'];
  const fruitValues1 = [
    [0.82, 0.79, 0.76, 0.73, 0.7, 0.67, 0.64, 0.61, 0.58, 0.55],
    [0.85, 0.82, 0.79, 0.76, 0.73, 0.7, 0.67, 0.64, 0.61, 0.58],
  ];

  // Fruits Dataset 2 (Set de Date Fructe - 2) - FLOP values (ascending)
  const fruitLabels2 = ['Păr', 'Prună', 'Cireșă', 'Afine', 'Zmeură', 'Mure', 'Caisă', 'Rodie', 'Mandarină', 'Lămâie'];
  const fruitValues2 = [
    [0.23, 0.26, 0.29, 0.32, 0.35, 0.38, 0.41, 0.44, 0.47, 0.5],
    [0.2, 0.23, 0.26, 0.29, 0.32, 0.35, 0.38, 0.41, 0.44, 0.47],
  ];

  function calculateAverage(response: avgTimes[]): { avg_response: number; avg_queue: number } {
    let totalSecondsResponse = 0;
    let totalSecondsQueue = 0;
    let countResponse = 0;
    let countQueue = 0;

    // Loop through each item in the response
    for (const item of response) {
      // Convert the time to exact seconds
      const timeInSecondsResponse = getTimeInSeconds(item.avg_time);
      const timeInSecondsQueue = getTimeInSeconds(item.queue_duration);
      // Add the time to the total
      if (timeInSecondsResponse) {
        totalSecondsResponse += timeInSecondsResponse;
        countResponse++;
      }
      if (timeInSecondsQueue) {
        totalSecondsQueue += timeInSecondsQueue;
        // Increment the count
        countQueue++;
      }
    }

    // Calculate the average in seconds
    const averageSecondsResponse = totalSecondsResponse / countResponse;
    const averageSecondsQueue = totalSecondsQueue / countQueue;

    // Convert average to minutes as a decimal
    const averageMinutesResponse = averageSecondsResponse / 60;
    const averageMinutesQueue = averageSecondsQueue / 60;

    return { avg_response: parseFloat(averageMinutesResponse.toFixed(2)), avg_queue: parseFloat(averageMinutesQueue.toFixed(2)) };
  }

  function getTimeInSeconds(timeStr: string): number | null {
    // Check if the string contains "m" (minutes)
    if (timeStr.includes('m')) {
      const minutes = parseInt(timeStr.split('m')[0], 10);
      let seconds = 0;

      // Check if the string contains "s" (seconds)
      if (timeStr.includes('s')) {
        seconds = parseInt(timeStr.split('m')[1].split('s')[0], 10);
      }

      // Calculate total time in seconds
      return minutes * 60 + seconds;
    }

    return null; // Return null for times not in the "XmYs" format
  }

  let selectedRange = <p className='w-100'>Please pick the first day.</p>;
  useEffect(() => {
    loadAverages();
  }, [compareLocation1, compareLocation2, dateFilter, compareUser1, compareUser2]);

  const loadAverages = async () => {
    try {
      const fromDate = new Date(dateFilter.from);
      const toDate = new Date(dateFilter.to);

      const daysList = [];
      let currentDate = fromDate;
      while (currentDate <= toDate) {
        const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}T00:00:00`;
        daysList.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let forValues: any[] = [];
      let forNames: any[] = [];
      let forValues2: any[] = [];
      setAveragesError(false);
      setAveragesLoading(true);
      let averagesLocation1 = await queueManagementAvgCashRegisterOpen(
        parseInt(compareLocation1.id),
        new Date(dateFilter.from),
        new Date(dateFilter.to),
        compareUser1.id
      );
      let averagesLocation2 = await queueManagementAvgCashRegisterOpen(
        parseInt(compareLocation2.id),
        new Date(dateFilter.from),
        new Date(dateFilter.to),
        compareUser2.id
      );
      if (averagesLocation1)
        if (compareLocation1.id === '0') {
          let averages = calculateAverage(averagesLocation1);
          forValues.push(averages.avg_response);
          forValues2.push(averages.avg_queue);
          forNames.push(`Average (${compareUser1.value})`);
        } else {
          let for1 = getTimeInSeconds(averagesLocation1[0].avg_time);
          let for2 = getTimeInSeconds(averagesLocation1[0].queue_duration);
          forValues.push(for1 !== null ? (for1 / 60).toFixed(2) : 0);
          forValues2.push(for2 !== null ? (for2 / 60).toFixed(2) : 0);
          forNames.push(averagesLocation1[0].location_id);
        }

      if (averagesLocation2)
        if (compareLocation2.id === '0') {
          let averages = calculateAverage(averagesLocation2);
          forValues.push(averages.avg_response);
          forValues2.push(averages.avg_queue);
          forNames.push(`Average (${compareUser2.value})`);
        } else {
          let for1 = getTimeInSeconds(averagesLocation2[0].avg_time);
          let for2 = getTimeInSeconds(averagesLocation2[0].queue_duration);
          forValues.push(for1 !== null ? (for1 / 60).toFixed(2) : 0);
          forValues2.push(for2 !== null ? (for2 / 60).toFixed(2) : 0);
          forNames.push(averagesLocation2[0].location_id);
        }
      setValues1(forValues);
      setLabels1(forNames);
      setValues2(forValues2);
      setLabels2(forNames);
      setAveragesLoading(false);
    } catch (error) {
      setAveragesError(true);
    } finally {
      setAveragesLoading(false);
    }
  };
  if (range?.from) {
    if (!range.to) {
      selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      selectedRange = (
        <p className='w-100'>
          {format(range.from, 'PPP')} - {format(range.to, 'PPP')}
        </p>
      );
    }
  }

  let selectedRange2 = <p className='w-100'>Please pick the first day.</p>;

  if (range2?.from) {
    if (!range2.to) {
      selectedRange2 = <p className='w-100'>{format(range2.from, 'PPP')}</p>;
    } else if (range2.to) {
      selectedRange2 = (
        <p className='w-100'>
          {format(range2.from, 'PPP')} - {format(range2.to, 'PPP')}
        </p>
      );
    }
  }

  const handleCallbackComparison = (active: ComparisonEnum) => {
    setChartComparison(active);
  };

  const changingComponent = useMemo(() => {
    switch (chartComparison) {
      case ComparisonEnum.QUEUE_MANAGEMENT:
        return (
          <QueueManagementComparison
            chartType={chartType}
            setChartType={setChartType}
            chartComparison={chartComparison}
            dateFilter={dateFilter}
            averagesLoading={averagesLoading}
            values1={values1}
            values2={values2}
            labels1={labels1}
            labels2={labels2}
            averagesError={averagesError}
          />
        );
      case ComparisonEnum.BAKERY:
        return (
          <ComparisonTab
            param1Nr={topNr}
            param2Nr={flopNr}
            setParam1Nr={setTopNr}
            setParam2Nr={setFlopNr}
            cat1='Top'
            cat2='Flop'
            title='Shelf availability'
          />
        );
      case ComparisonEnum.FRUITS_AND_VEGETABLES:
        return (
          <ComparisonTab
            param1Nr={topNr}
            param2Nr={flopNr}
            setParam1Nr={setTopNr}
            setParam2Nr={setFlopNr}
            cat1='Top'
            cat2='Flop'
            title='Shelf availability'
          />
        );
    }
  }, [chartComparison, chartType, dateFilter, averagesLoading, values1, values2, labels1, labels2, averagesError, topNr, flopNr]);

  return (
    <div className='container'>
      <div className='d-flex flex-column'>
        {/* Header */}
        <div className='d-flex flex-column col-sm-12 p-0'>
          <div className='d-flex flex-column w-sm-100 h-100 p-2 '>
            <span className='fs-4 fw-semibold py-7 text-gray-400'>Please select 2 locations to compare</span>
            <ChartsWidget12 className='h-100' callbackComparison={handleCallbackComparison} />
          </div>
        </div>

        {changingComponent}
      </div>
      <Modal size='lg' show={show[0]} onHide={() => toggleModal(0)}>
        <Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-row'>
            <DayPicker
              id='1'
              fromYear={2020}
              toYear={2024}
              captionLayout='dropdown'
              defaultMonth={new Date()}
              mode='range'
              selected={range}
              onSelect={setRange}
              footer={selectedRange}
            />
            <DayPicker
              id='2'
              fromYear={2020}
              toYear={2024}
              captionLayout='dropdown'
              defaultMonth={new Date()}
              mode='range'
              selected={range2}
              onSelect={setRange2}
              footer={selectedRange2}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => toggleModal(0)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
