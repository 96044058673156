import callApiAxiosClient from '../../../axios/axios_client';
import { camerasList } from '../../../models';

interface UpdateCameraArgs {
  camera_id: number | null;
  name: string | null;
  ip_address: string | null;
  port: string | null;
  user: string | null;
  password: string | null;
  long?: number;
  lat?: number;
  width?: number;
  height?: number;
}

async function updateCamera({ camera_id, name, ip_address, port, user, password, lat, long, width, height }: UpdateCameraArgs): Promise<camerasList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  const data =
    long && lat
      ? {
          camera_id: camera_id,
          name: name,
          ip_address: ip_address,
          port: port,
          user: user,
          password: password,
          long: long,
          lat: lat,
          width: width,
          height: height,
        }
      : {
          camera_id: camera_id,
          name: name,
          ip_address: ip_address,
          port: port,
          user: user,
          password: password,
          width: width,
          height: height,
        };

  return callApiAxiosClient<camerasList>({
    method: 'put',
    url: 'settings/cameras',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: data,
  });
}
export default updateCamera;
