
import React, { useEffect, useRef, useState } from 'react'
import { DayWeekMonthYearEnum } from '../../../web_api/models'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../redux/store'
import { setValue } from '../../../redux/dayWeekMonthYearReducer'


type Props = {
  className?: string
  day?: boolean
  week?: boolean
  month?: boolean
  year?: boolean
}

const DayWeekMonthYear: React.FC<Props> = ({ className, day, week, month, year }) => {

  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const dayWeekMonthYear = useSelector((state: RootState) => state.dayWeekMonthYear.filter)

  return (
    <div className={`d-flex flex-row card ${className} card-body`}>
      <span className='fs-7 fw-bold text-gray-700  m-auto mx-0 p-5 '>
        Filter By:
      </span>
      <div className='d-flex m-auto ms-0'>
        <ul className='nav'>
          {day && (
            <li className='nav-item' onClick={() => {
              dispatch(setValue({
                filter: DayWeekMonthYearEnum.DAY
              })); setLoading(false)
            }}>
              <div
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (dayWeekMonthYear === DayWeekMonthYearEnum.DAY ? ' active' : '')}
                data-bs-toggle='tab'
              >
                Day
              </div>
            </li>
          )}
          {week && (
            <li className='nav-item' onClick={() => {
              dispatch(setValue({
                filter: DayWeekMonthYearEnum.WEEK
              })); setLoading(false)
            }}>
              <div
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (dayWeekMonthYear === DayWeekMonthYearEnum.WEEK ? ' active' : '')}
                data-bs-toggle='tab'
              >
                Week
              </div>
            </li>
          )}
          {month && (
            <li className='nav-item' onClick={() => {
              dispatch(setValue({
                filter: DayWeekMonthYearEnum.MONTH
              })); setLoading(false)
            }}>
              <div
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (dayWeekMonthYear === DayWeekMonthYearEnum.MONTH ? ' active' : '')}
                data-bs-toggle='tab'
              >
                Month
              </div>
            </li>
          )}
          {year && (
            <li className='nav-item' onClick={() => {
              dispatch(setValue({
                filter: DayWeekMonthYearEnum.YEAR
              })); setLoading(false)
            }}>
              <div
                className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (dayWeekMonthYear === DayWeekMonthYearEnum.YEAR ? ' active' : '')}
                data-bs-toggle='tab'
              >
                Year
              </div>
            </li>
          )}

        </ul>
      </div>
    </div>
  )

}
export { DayWeekMonthYear }
