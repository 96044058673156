import { lprCameraList } from '../../models';
import callApiAxiosClient from '../../axios/axios_client';

function getLPRCameras(): Promise<lprCameraList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<lprCameraList>({
    method: 'get',
    url: 'programmability/get-lpr-cameras',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getLPRCameras;
