import { lprTotalByCountyList } from '../../models';
import callApiAxiosClient from '../../axios/axios_client';
import moment from 'moment';

interface getLPRTotalsByCountyArgs {
  camera_id?: number;
  location_id?: number;
  from_param: Date;
  to_param: Date;
}

function getLPRTotalsByCounty({ camera_id, location_id, from_param, to_param }: getLPRTotalsByCountyArgs): Promise<lprTotalByCountyList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  const from = moment(from_param).format('YYYYMMDD');
  const to = moment(to_param).format('YYYYMMDD');

  return callApiAxiosClient<lprTotalByCountyList>({
    method: 'get',
    url: 'programmability/get-lpr-totals-by-county',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      camera_id: camera_id,
      location_id: location_id,
      from: from,
      to: to,
    },
  });
}
export default getLPRTotalsByCounty;
