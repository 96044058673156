import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToolbarState {
  toolbar: string;
  title: string;
  displayBack?: boolean;
}
const initialState: ToolbarState = {
  toolbar: localStorage.getItem('toolbar')!?.toString() != null ? localStorage.getItem('toolbar')!?.toString() : 'default',
  title: localStorage.getItem('title')!?.toString() != null ? localStorage.getItem('title')!?.toString() : '',
  displayBack: false,
};

export const toolbar = createSlice({
  name: 'toolbar',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<ToolbarState>) => {
      state.toolbar = action.payload.toolbar;
      state.title = action.payload.title;
      localStorage.setItem('toolbar', action.payload.toolbar);
      localStorage.setItem('title', action.payload.title);
    },
    setBack: (state, action: PayloadAction<boolean>) => {
      state.displayBack = action.payload;
    },
  },
});

export const { setValue, setBack } = toolbar.actions;

export const toolbarReducer = toolbar.reducer;
