import callApiAxiosClient from "../../../axios/axios_client";

function lost2fa(mail: string): Promise<void> {
    return callApiAxiosClient<void>({
        method: "post",
        url: "account/disable-2fa",
        headers: {
            "Content-Type": "Application/json"
        },
        data: {
            mail: mail
        }
    })
}

export default lost2fa;