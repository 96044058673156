import React, { useEffect } from 'react';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../../assets/images/Penny-Logo.png'
// import logo from '../../../assets/images/logo-white.svg'


import { missingProductList, shelfStatusList } from '../../../web_api/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { bakeryProductId, bakeryProductNameFormat, bakeryStatusColor } from '../../../web_api/methods/helpers';
import { ShelfStatusColorsEnum, ShelfStatusDescriptionEnum, ShelfStatusEnum, mostQueueAlertsItemInList, mostQueueAlertsList } from "../../../web_api/models"

interface TableProps {
    headers: string[];
    data: shelfStatusList[];
    location: { id: number, name: string };
    user_name: string;
    date: string
    missingProducts: missingProductList;
    fileName: string
}

const styles = StyleSheet.create({
    page: {
        padding: 20,

    },
    title: {
        fontSize: 18,

    },
    subtitle: {
        fontSize: 13,
    },
    purple: {
        backgroundColor: '#7239ea',
    },
    emptyLow: {
        backgroundColor: '#f1416c',
    },
    half: {
        backgroundColor: '#ffc700',
    },
    almost: {
        backgroundColor: '#009ef7',
    },
    full: {
        backgroundColor: '#50cd89',
    },
    occlusion: {
        backgroundColor: '#e4e6ef',
    },
    shelf: {
        color: 'white',
        borderRadius: 8,
        marginVertical: 10,
        height: '20%'
    },
    basicInformationView: {
        padding: 5,
        borderStyle: 'solid',
        color: 'white',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        borderRadius: 5,
        width: '100%',
        marginVertical: 10
    },
    header: {
        padding: 15,
        width: '100%',
        marginVertical: 10,
        backgroundColor: '#393945'
    },
    basicInformationText: {
        fontSize: 10,
        marginVertical: 5,
    },
    icon: {
        color: 'white',
        marginHorizontal: 'auto',
        marginTop: 10

    },
    centered_text: {
        margin: 'auto',
        fontSize: '8'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexBasis: '16.66%',
        maxWidth: '16.66%'
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        borderTop: 0,
        marginVertical: 20,
        borderRadius: 5
    },
    entireShelf: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        borderTop: 0,
        marginVertical: 30,
        borderRadius: 5,

    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCell: {
        fontSize: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        flexGrow: 1,
        textAlign: 'left',
        width: '100%'
    },
    tableHeader: {
        fontSize: 12,
        backgroundColor: '#bfbfbf',
    },
});
const Shelf: React.FC = () => {
    return (
        <View style={[styles.shelf, styles.emptyLow]}>
            <View style={styles.icon}>
                <Text style={styles.centered_text}>{`test`}</Text>

            </View>
            <Text style={styles.centered_text}>{`test`}</Text>
        </View>
    )
}
const PDF: React.FC<TableProps> = ({ headers, data, location, user_name, date, missingProducts, fileName }) => {

    return (
        <div>
            <View style={styles.header}>
                <View>
                    <View style={[styles.basicInformationView, styles.row]}>
                        <View>
                            <Text style={styles.basicInformationText}>
                                Email: <Text>{user_name}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Location: <Text>{location.id !== 0 ? (location.id + ' - ' + location.name) : (location.name)}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Date range: <Text>{date}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Issued: <Text>{new Date().toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</Text>
                            </Text>
                        </View>
                        <Image style={{ width: '80px' }} src={logo} />

                    </View>
                </View>
            </View>
            {/* <Text style={styles.subtitle}>
                {location.id !== 0 ? (location.id + ' - ' + location.name) : (location.name)}
            </Text>
            <Text style={styles.subtitle}>
                {date}
            </Text> */}


            <View style={styles.table}>
                <Text>Products missing the most</Text>
                <View style={styles.tableRow}>
                    {headers.map((header, index) => (
                        <Text
                            key={index}
                            style={[styles.tableCell, styles.tableHeader, index === 0 || index === 2 || index === 3 ? { width: '120px' } : {}]}
                        >
                            {header}
                        </Text>
                    ))}
                </View>
                {(missingProducts !== undefined) && missingProducts.map((cellData, index) => (
                    <View style={styles.tableRow}>

                        <>
                            {/* <Text
                            style={[styles.tableCell, { width: '120px' }]}
                        >
                            {index + 1}
                        </Text> */}
                            <Text
                                style={[styles.tableCell]}
                            >
                                {bakeryProductId(cellData.product)}
                            </Text>
                            <Text
                                style={[styles.tableCell, { width: '500%' }]}
                            >
                                {bakeryProductNameFormat(cellData.product)}
                            </Text>
                            <Text
                                style={[styles.tableCell]}
                            >
                                {Math.round(100 - cellData.missing_points * 100)} %
                            </Text>
                            <Text
                                style={[styles.tableCell]}
                            >
                                {Math.round(cellData.availability * 100)} %
                            </Text>
                        </>
                    </View>

                ))}
                <Text style={[styles.tableCell]}>'Fill' column represents the average fill percentage of each shelf based on the selected time interval. </Text>
                <Text style={[styles.tableCell]}>'Availability' column represents how much of the time the shelf contains at least one product.</Text>
            </View>
            {/* <View style={styles.basicInformationView}>
                <Text>here we ll have a table</Text>
            </View> */}
            {/* cercuri */}
            {data.map((shelf, index) => {
                if (shelf.length !== 0)
                    return (
                        <View style={styles.entireShelf}>
                            <Text style={styles.row}>Bakery shelf at {shelf[index].hour}:00</Text>

                            <View style={styles.row}>

                                {shelf.map((item) => {
                                    return (
                                        <View style={[styles.shelf, styles.col, bakeryStatusColor(item.fill) === (ShelfStatusColorsEnum.EMPTY || ShelfStatusColorsEnum.LOW) ? styles.emptyLow : bakeryStatusColor(item.fill) === ShelfStatusColorsEnum.HALF ? styles.half : bakeryStatusColor(item.fill) === ShelfStatusColorsEnum.ALMOST ? styles.almost : bakeryStatusColor(item.fill) === ShelfStatusColorsEnum.FULL ? styles.full : styles.occlusion]}>
                                            <View style={styles.icon}>{ShelfStatusColorsEnum.ALMOST}
                                                <Text style={styles.centered_text}>{item.fill}</Text>

                                            </View>
                                            <Text style={styles.centered_text}>{bakeryProductNameFormat(item.product)}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </View>
                    )
            })}

            {/* audio alerts */}

        </div >
    );
};

export default function BakeryPDF({ headers, data, location, user_name, date, missingProducts, fileName }: TableProps) {
    return (
        <div className='d-flex flex-column' style={{ width: '100%', height: '90vh' }}>
            <div className='d-flex flex-row' style={{ flex: 1 }}>
                <PDFViewer style={{ width: '100%', height: '100%' }}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <PDF
                                headers={headers}
                                data={data}
                                location={location}
                                user_name={user_name}
                                date={date}
                                missingProducts={missingProducts}
                                fileName={fileName}
                            />
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
            <div className='d-flex flex-row'>

                <PDFDownloadLink document={<Document>
                    <Page size="A4" style={styles.page}>
                        <PDF
                            headers={headers}
                            data={data}
                            location={location}
                            user_name={user_name}
                            date={date}
                            missingProducts={missingProducts}
                            fileName={fileName}
                        />
                    </Page>
                </Document>} fileName={`${fileName}.pdf`} className='btn btn-primary my-3'>
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                </PDFDownloadLink>
            </div>
        </div>
    );
}