import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useGet from '../../hooks/Common/useGet';
import useModals from '../../hooks/Common/useModals';

import { RootState } from '../../redux/store';
import { setCachedCameraList } from '../../redux/camerasListReducer';
import { setCachedLocationList } from '../../redux/cachedLocationsReducer';
import { setcachedCameraModelList } from '../../redux/cameraModelListReducer';
import { setCachedDetectionServerList } from '../../redux/detectionServerListReducer';

import LoadingPage from '../../layout/LoadingPage';
import CommonPagination from '../Common/CommonPagination';

import DetectionAdd from './DetectionAdd';
import DetectionCard from './DetectionCard';
import DetectionMassDelete from './DetectionMassDelete';
import DetectionCardAdd from './DetectionCardAdd';
import DetectionNewUserCards from './DetectionNewUserCards';

import getDetections from '../../web_api/methods/settings/detections/getDetections';
import { cameraDetectionInList } from '../../web_api/models';
import getLocations from '../../web_api/methods/settings/locations/getLocations';
import getCameraModels from '../../web_api/methods/settings/cameraModels/getCameraModels';
import getCameras from '../../web_api/methods/settings/cameras/getCameras';
import getDetectionServers from '../../web_api/methods/settings/detectionServers/getDetectionServers';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { setCachedDetectionsSettingsList } from '../../redux/detectionsSettingsReducer';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { CommonModal } from '../Common/CommonModal';
import getCooldownPeriods from '../../web_api/methods/settings/cooldownPeriods/getCooldownPeriods';
import putCooldownPeriods from '../../web_api/methods/settings/cooldownPeriods/putCooldownPeriods';

const Detections = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('detections', `Detections`);
  });
  const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
  const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
  const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);

  const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
  const selectedDetectionType = useSelector((state: RootState) => state.selectedDetectionType);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);

  const [checkedDetections, setCheckedDetections] = useState<number[]>([]);
  const [checking, setChecking] = useState(true);
  const [originalCooldownPeriods, setOriginalCooldownPeriods] = useState<{ period1: number; period2: number }>();
  const [cooldownPeriod1, setCooldownPeriod1] = useState<number>(0);
  const [cooldownPeriod2, setCooldownPeriod2] = useState<number>(0);

  const [page, setPage] = useState({
    index: 0,
    size: 29,
  });

  const loadCooldownPeriods = async () => {
    const res = await getCooldownPeriods();
    setCooldownPeriod1(res.cooldown_period1);
    setCooldownPeriod2(res.cooldown_period2);
    setOriginalCooldownPeriods({ period1: res.cooldown_period1, period2: res.cooldown_period2 });
  };

  useEffect(() => {
    loadCooldownPeriods();
  }, []);

  const { data, loading, setData } = useGet(
    [selectedCamera.id, selectedDetectionType.id, activeLocation.id, page.index],
    () =>
      getDetections({
        page_index: page.index,
        page_size: page.size,
        camera_id: Number(selectedCamera.id),
        location_id: Number(activeLocation.id),
        detection_id: Number(selectedDetectionType.id),
      }),
    setCachedDetectionsSettingsList
  );

  const hasDetections =
    cachedCameraModelList.length > 0 &&
    cachedDetectionServerList.length > 0 &&
    cachedCameraList.length > 0 &&
    cachedLocationsList.length > 0 &&
    data &&
    data.length > 0;

  const { show, toggleModal } = useModals([false, false]); //0 is DetectionnewUserCards, 1 is DetectionSettings

  const dispatch = useDispatch();

  const checkExistingSettings = async () => {
    try {
      if (cachedCameraList.length === 0) {
        const cameraList = await getCameras();
        dispatch(setCachedCameraList({ list: cameraList }));
      }

      if (cachedLocationsList.length === 0) {
        const locationList = await getLocations();
        dispatch(setCachedLocationList({ list: locationList }));
      }

      if (cachedCameraModelList.length === 0) {
        const cameraModelList = await getCameraModels();
        dispatch(setcachedCameraModelList({ list: cameraModelList }));
      }

      if (cachedDetectionServerList.length === 0) {
        const detectionServerList = await getDetectionServers();
        dispatch(setCachedDetectionServerList({ list: detectionServerList }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChecking(false);
    }
  };

  const onDetectionCheck = (id: number) => {
    setCheckedDetections((prevState) => {
      const isChecked = !prevState.includes(id);
      if (isChecked) {
        return [...prevState, id];
      } else {
        return prevState.filter((cameraId) => cameraId !== id);
      }
    });
  };

  const onDetectionDelete = (id: number) => {
    if (data) {
      const updatedList = data.filter((detection) => detection.id !== id);
      setData(updatedList);
    }
  };

  const onDetectionMassDelete = () => {
    if (data) {
      const updatedList = data.filter((cameraItem) => !checkedDetections.includes(cameraItem.id));
      setData(updatedList);
      setCheckedDetections([]);
    }
  };

  const onDetectionAdd = (detection: cameraDetectionInList) => {
    if (data) {
      const updatedList = [detection, ...data];
      setData(updatedList);
      if (page.index !== 0) {
        setPage({ ...page, index: 0 });
      }
    }
  };

  const onShowMassDeleteModal = (detectionIds: number[]) => {
    const names: { [key: string]: string } = {};

    const filteredCameras = data?.filter((camera) => detectionIds.includes(camera.id));

    filteredCameras?.forEach((camera) => {
      const name = `${camera.camera_name} - ${camera.detection_name}`;
      names[camera.id.toString()] = name;
    });

    return names;
  };

  const handleIndexChange = (action: string) => {
    if (!loading) {
      if (action === 'next') {
        setPage({ ...page, index: page.index + 1 });
      } else if (action === 'previous') {
        setPage({ ...page, index: Math.max(page.index - 1, 0) });
      }
      setCheckedDetections([]);
    }
  };

  const handleCooldownPeriodUpdate = async () => {
    await putCooldownPeriods(cooldownPeriod1, cooldownPeriod2);
    toggleModal(1);
    toast.success('Updated cooldown periods');
  };

  const handleCloseCooldown = () => {
    setCooldownPeriod1(originalCooldownPeriods?.period1!);
    setCooldownPeriod2(originalCooldownPeriods?.period2!);
  };

  useEffect(() => {
    checkExistingSettings();
  }, []);

  useEffect(() => {
    setPage({ ...page, index: 0 });
  }, [selectedCamera.id, selectedDetectionType.id, activeLocation.id]);

  return (
    <>
      {checking || hasDetections === undefined || (page.index === 0 && loading) ? (
        <LoadingPage />
      ) : (
        <>
          {hasDetections ? (
            <>
              <div className='container pt-3 px-4 d-flex flex-wrap justify-content-between'>
                <div className='d-flex'>
                  <DetectionAdd onDetectionAdd={onDetectionAdd} dataLength={data?.length} show={show[0]} toggleModal={() => toggleModal(0)} />
                  <DetectionMassDelete
                    checkedDetections={checkedDetections}
                    onShowMassDeleteModal={onShowMassDeleteModal}
                    onDetectionMassDelete={onDetectionMassDelete}
                  />
                </div>
                <div className='d-flex'>
                  <Button variant='primary' size='sm' className='my-1 pe-4 settings-tooltip' onClick={() => toggleModal(1)}>
                    <i className='bi bi-gear fs-3' />
                  </Button>
                  <CommonPagination
                    disablePrevious={page.index === 0 || loading}
                    disableNext={loading || data?.[0].has_next_page === false}
                    startIndex={page.index + 1}
                    handlePrevChange={() => handleIndexChange('previous')}
                    handleNextChange={() => handleIndexChange('next')}
                  />
                </div>
              </div>
              {loading && !checking ? (
                <LoadingPage />
              ) : (
                <>
                  <div className='container px-sm-5'>
                    <div className='row'>
                      {data?.map((item) => (
                        <DetectionCard
                          detectionItem={item}
                          key={item.id}
                          onDetectionCheck={onDetectionCheck}
                          onDetectionDelete={onDetectionDelete}
                          onDetectionAdd={onDetectionAdd}
                        />
                      ))}
                      <DetectionCardAdd toggleModal={() => toggleModal(0)} />
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <DetectionNewUserCards
                show={show[0]}
                toggleModal={() => toggleModal(0)}
                dataLength={data?.length || 0}
                onDetectionAdd={onDetectionAdd}
              />
            </>
          )}
        </>
      )}
      <Tooltip anchorSelect='.settings-tooltip'>Detections settings</Tooltip>
      <CommonModal
        closeButton={true}
        closeButtonOptions={{
          icon: 'bi bi-x-lg',
          variant: 'secondary',
          class: '',
        }}
        confirmButton={true}
        confirmButtonOptions={{
          icon: 'bi bi-check-lg text-white',
          variant: 'custom',
          class: 'bg-success text-white',
          text: 'Confirm',
          onClick: handleCooldownPeriodUpdate,
        }}
        show={show[1]}
        showModalCallback={() => {
          handleCloseCooldown();
          toggleModal(1);
        }}
        title='Detections Settings'
      >
        <div className='d-flex flex-column gap-3 w-100 px-10'>
          <InputGroup>
            <InputGroup.Text id='basic-addon1'>Cooldown period 1</InputGroup.Text>
            <Form.Control
              placeholder='Period'
              aria-label='Period'
              aria-describedby='basic-addon1'
              value={cooldownPeriod1}
              className='d-flex justify-content-end align-items-end'
              onChange={(e) => setCooldownPeriod1(parseInt(e.currentTarget.value))}
              type='number'
            />
            <InputGroup.Text id='basic-addon1'>minutes</InputGroup.Text>
          </InputGroup>
          <InputGroup>
            <InputGroup.Text id='basic-addon1'>Cooldown period 2</InputGroup.Text>
            <Form.Control
              placeholder='Period'
              aria-label='Period'
              aria-describedby='basic-addon2'
              value={cooldownPeriod2}
              className='d-flex justify-content-end align-items-end'
              onChange={(e) => setCooldownPeriod2(parseInt(e.currentTarget.value))}
              type='number'
            />
            <InputGroup.Text id='basic-addon1'>minutes</InputGroup.Text>
          </InputGroup>
        </div>
      </CommonModal>
    </>
  );
};

export default Detections;
