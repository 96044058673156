import { useDispatch } from 'react-redux';
import { setValue, setBack } from '../../redux/toolbarReducer';

const useToolbarSetter = () => {
  const dispatch = useDispatch();

  const setToolbar = (toolbar: string, title: string) => {
    dispatch(
      setValue({
        toolbar: toolbar,
        title: title,
      })
    );
  };

  const setBackButton = (displayBack: boolean) => {
    dispatch(setBack(displayBack));
  };

  return {
    setToolbar,
    setBackButton,
  };
};

export default useToolbarSetter;
