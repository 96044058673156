import callApiAxiosClient from "../../axios/axios_client";


function resetPassword(old_password: string, new_password: string, confirm_new_password: string): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!);
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "post",
        url: "account/reset-password-lite",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            old_password: old_password,
            new_password: new_password,
            confirm_new_password: confirm_new_password
        }
    })
}
export default resetPassword;