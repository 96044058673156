import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { trainingModelList, objectClassesList, objectClassInList, trainingPictureWithPolygons } from '../web_api/models';

export interface trainingState {
  trainings: trainingModelList;
  object_classes: objectClassesList;
  pictures: trainingPictureWithPolygons[];
  annotation_type: objectClassInList;
}

const initialState: trainingState = {
  trainings: [],
  object_classes: [],
  pictures: [],
  annotation_type: { id: -1, name: 'All' },
};

export const training = createSlice({
  name: 'training',
  initialState,
  reducers: {
    setTrainings: (state, action: PayloadAction<trainingModelList>) => {
      state.trainings = action.payload;
    },
    setObjectClasses: (state, action: PayloadAction<objectClassesList>) => {
      state.object_classes = action.payload;
    },
    setPictures: (state, action: PayloadAction<trainingPictureWithPolygons[]>) => {
      state.pictures = action.payload;
    },
    setAnnotationType: (state, action: PayloadAction<objectClassInList>) => {
      state.annotation_type = action.payload;
    },
  },
});

export const { setTrainings, setObjectClasses, setPictures, setAnnotationType } = training.actions;

export const trainingReducer = training.reducer;
