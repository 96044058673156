import React from 'react';
type Props = {
  text?: string;
  date?: string;
};

const RecentAlertItem: React.FC<Props> = ({ text, date }) => {
  return (
    <div className='my-1 cursor-pointer hover-grow' style={{ height: '4rem' }}>
      <div className='card bg-light-secondary h-100 d-flex flex-row'>
        <div className='d-flex col-2'>
          <i className='bi bi-exclamation-triangle-fill text-danger fs-2x w-100 d-flex flex-center' />
        </div>
        <div className='d-flex flex-column col-10'>
          <div className='  d-flex flex-row flex-start m-auto ms-0 mb-0'>
            <h6 className='mb-0'>{text}</h6>
          </div>
          <div className='  d-flex flex-row flex-start m-auto ms-0 mt-0'>
            <span className='fw-semibold'>{date}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export { RecentAlertItem };
