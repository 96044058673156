import React, { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import ReactApexChart from 'react-apexcharts';
import getHeatmapByHour from '../../web_api/methods/heatmap/getHeatmapByHour';
import { heatmapByHourList, heatmapLocationDataByHourInList, heatmapLocationDataByHourList, planData } from '../../web_api/models';
import { Dropdown } from 'react-bootstrap';
import heatmapDummy from './HeatmapDummy.json';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getPlanData from '../../web_api/methods/settings/locations/getPlanData';
import Heatmap from 'visual-heatmap';
import pennyPlan from '../Locations/Penny_Plan.jpg';
import getHeatmapLocDataByHour from '../../web_api/methods/heatmap/getHeatmapLocDataByHour';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import { HeatmapRenderer } from 'visual-heatmap/dist/types/heatmap';

const HeatmapDashboard = () => {
  const { setToolbar } = useToolbarSetter();

  const [cameraData, setCameraData] = useState<heatmapByHourList>([]);
  const [locationData, setLocationData] = useState<heatmapLocationDataByHourList>([]);
  const [currentLocationData, setCurrentLocationData] = useState<heatmapLocationDataByHourInList>();
  const [currentHeatmapData, setCurrentHeatmapData] = useState<number[]>([]);
  const [selectedCamera, setSelectedCamera] = useState<string>('');
  const [selectedData, setSelectedData] = useState<heatmapByHourList>([]);
  const [totalDetectionsPerHour, setTotalDetectionsPerHour] = useState<Record<number, number>>({});
  const [barchartSeries, setBarchartSeries] = useState<any[]>([]);
  const [planData, setPlanData] = useState<planData>();
  const [planPicture, setPlanPicture] = useState<string>('');
  const [heatmapInstance, setHeatmapInstance] = useState<HeatmapRenderer>();

  const [activeHour, setActiveHour] = useState<number>(9);
  const [playerProgress, setPlayerProgress] = useState(0);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout>();
  var [index, setIndex] = useState<number>(0);
  const [hours, setHours] = useState<number[]>([]);

  const userName = useSelector((state: RootState) => state.user.user_name);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter.date);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const computePlanPicture = async () => {
    const res = await getAzureFile('alerts-media/' + tenantKey + '/locations/' + activeLocation.id + '.jpg');
    console.log('url plan pic', res);
    setPlanPicture(res);

    const img = new Image();
    img.src = res;
    let instance = Heatmap('#heatmap', {
      gradient: [
        { color: [0, 0, 255, 0.1], offset: 0 },
        { color: [0, 0, 255, 0.9], offset: 0.2 },
        { color: [0, 255, 0, 1.0], offset: 0.45 },
        { color: [255, 255, 0, 1.0], offset: 0.85 },
        { color: [255, 0, 0, 1.0], offset: 1.0 },
      ],
      backgroundImage: {
        x: 0,
        y: 0,
        url: res !== '' ? img.src : pennyPlan,
      },
    });
    setHeatmapInstance(instance);

    return res;
  };

  useEffect(() => {
    computePlanPicture();
  }, [activeLocation]);

  const [heatmapInstanceData, setHeatmapInstanceData] = useState<any>([
    { x: 10, y: 20, value: 15 },
    { x: 30, y: 40, value: 25 },
    { x: 200, y: 40, value: 25 },
  ]);

  useEffect(() => {
    setToolbar('heatmap', 'Store Heatmap');
  });

  const loadInitialData = async () => {
    console.log('current Date', toolbarDateFilter);

    if (userName === 'demo@securif.ai') {
      setCameraData(heatmapDummy);
      return;
    }

    let dateObj = new Date(toolbarDateFilter);

    // Format the date to 'YYYY-MM-DD'
    const formattedDate =
      dateObj.getFullYear() +
      '-' +
      (dateObj.getMonth() + 1 >= 10 ? dateObj.getMonth() + 1 : '0' + (dateObj.getMonth() + 1)) +
      '-' +
      dateObj.getDate();

    const cameraData = await getHeatmapByHour(parseInt(activeLocation.id), formattedDate);
    setCameraData(cameraData);

    console.log('camera data', cameraData);

    const locationDataRes = await getHeatmapLocDataByHour(parseInt(activeLocation.id), formattedDate);
    setLocationData(locationDataRes);

    console.log('locationDataRes', locationDataRes);

    if (locationDataRes.length > 0) {
      setCurrentLocationData(locationDataRes[0]);
      setActiveHour(locationDataRes[0].hour);
      setHours(locationDataRes.map((loc) => loc.hour).sort((a, b) => a - b));
    }

    const planDataRes = await getPlanData(parseInt(activeLocation.id));
    setPlanData(planDataRes);

    console.log('planDataRes', planDataRes);
  };

  useEffect(() => {
    loadInitialData();
  }, [activeLocation, toolbarDateFilter]);

  useEffect(() => {
    const heatmapElement = document.getElementById('heatmap');
    if (!heatmapElement) {
      console.error('Heatmap element not found');
      return;
    }

    console.log('planData', planData);
    console.log('plan picture', planPicture);

    // const img = new Image();

    // if (planPicture !== '') {
    //   img.src = planPicture;
    //   let instance = Heatmap('#heatmap', {
    //     gradient: [
    //       { color: [0, 0, 255, 0.1], offset: 0 },
    //       { color: [0, 0, 255, 0.9], offset: 0.2 },
    //       { color: [0, 255, 0, 1.0], offset: 0.45 },
    //       { color: [255, 255, 0, 1.0], offset: 0.85 },
    //       { color: [255, 0, 0, 1.0], offset: 1.0 },
    //     ],
    //     backgroundImage: {
    //       x: 0,
    //       y: 0,
    //       url: planPicture !== '' ? img.src : pennyPlan,
    //     },
    //   });

    if (heatmapInstance) {
      heatmapInstance.setOpacity(1);
      heatmapInstance.setIntensity(1);
      heatmapInstance.setSize(30);
      console.log('rendering HeatmapInstanceData', heatmapInstanceData);
      heatmapInstance.renderData(heatmapInstanceData);
      heatmapInstance.render();
      console.log('img width', heatmapInstance.width);
      console.log('img height', heatmapInstance.height);
    }
    // }
  }, [heatmapInstanceData, planData, planPicture, activeLocation, activeHour]);

  useEffect(() => {
    setCurrentHeatmapData(currentLocationData?.data.split(',').map(Number) || []);
  }, [currentLocationData]);

  useEffect(() => {
    setCurrentLocationData(locationData[hours.findIndex((hour) => hour === activeHour)]);
    if (currentHeatmapData.length === 0) return;
    console.log('currentheatmapData', currentHeatmapData);
    console.log('planData currentHeatmapData', planData);
    console.log('currentLocationData', currentLocationData);
    // const viewportW =
    if (heatmapInstance && planData) {
      const heatmapInstanceDataRes = currentHeatmapData.map((value, index) => {
        return {
          x:
            ((currentLocationData?.resolution || planData.resolution) / 2 +
              (index % ((planData?.picture_width || 1600) / planData.resolution)) * (currentLocationData?.resolution || planData.resolution)) *
            (heatmapInstance.width / planData.picture_width),
          y:
            ((currentLocationData?.resolution || planData.resolution) / 2 +
              Math.floor(index / ((planData?.picture_width || 1600) / planData.resolution)) *
                (currentLocationData?.resolution || planData.resolution)) *
            (heatmapInstance.height / planData.picture_height),
          value,
        };
      });
      console.log('new HeatmapinstanceData', heatmapInstanceDataRes);
      setHeatmapInstanceData(heatmapInstanceDataRes);
    }
  }, [currentHeatmapData, activeHour, planData]);

  const treemapOptions = {
    legend: {
      show: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      treemap: {
        colorScale: {},
      },
    },
  };

  // Calculate total detections per camera
  const totalDetectionsPerCamera = cameraData.reduce((acc, entry) => {
    acc[entry.camera_name] = (acc[entry.camera_name] || 0) + entry.object_count;
    return acc;
  }, {} as Record<string, number>);

  // Sort by total detections (descending)
  const sortedDetections = Object.entries(totalDetectionsPerCamera).sort(([, a], [, b]) => b - a);

  // Transform data for the first treemap
  const treemapSeries = [
    {
      data: sortedDetections.map(([x, y]) => ({ x, y })),
    },
  ];

  useEffect(() => {
    // Calculate total detections per hour
    const totalDetectionsPerHour =
      selectedCamera === ''
        ? cameraData.reduce((acc, entry) => {
            acc[entry.hour] = (acc[entry.hour] || 0) + entry.object_count;
            return acc;
          }, {} as Record<number, number>)
        : selectedData.reduce((acc, entry) => {
            acc[entry.hour] = (acc[entry.hour] || 0) + entry.object_count;
            return acc;
          }, {} as Record<number, number>);

    setTotalDetectionsPerHour(totalDetectionsPerHour);

    // Transform data for the second barchart
    const barchartSeries = [
      {
        data: Object.values(totalDetectionsPerHour),
        name: 'Detections',
      },
    ];

    setBarchartSeries(barchartSeries);
  }, [cameraData, selectedCamera]);

  const barchartOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: 'rounded',
      },
    },
    xaxis: {
      categories: Object.keys(totalDetectionsPerHour).map((x) => `${x}:00`),
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      x: {
        formatter: (val: any) => {
          return `${val} - ${parseInt(val) + 1}:00`;
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      parentHeightOffset: 0,
    },
  };

  const handlePlayerClick = () => {
    const initialActiveHour = activeHour;

    const runPlayer = () => {
      const locationDataHours = locationData.map((x) => x.hour);
      const uniqueHours = locationDataHours.filter((hour, index) => locationDataHours.indexOf(hour) === index);

      let currentIndex = uniqueHours.indexOf(initialActiveHour);

      if (currentIndex === -1) {
        currentIndex = uniqueHours.findIndex((hour) => hour > initialActiveHour);
        if (currentIndex === -1) {
          currentIndex = 0;
        }
      }

      let timeoutId;

      const setNextHour: () => NodeJS.Timeout | undefined = () => {
        if (currentIndex >= uniqueHours.length) {
          setActiveHour(locationData[0].hour);
          setPlayerProgress(0);
          setTimeoutIds(undefined);
          return;
        }

        setActiveHour(uniqueHours[currentIndex]);

        currentIndex++;

        setPlayerProgress((currentIndex / uniqueHours.length) * 100);

        timeoutId = setTimeout(setNextHour, 1000);

        setTimeoutIds(timeoutId);
        return timeoutId;
      };
      timeoutId = setNextHour();
    };

    if (timeoutIds) {
      clearTimeout(timeoutIds);
      setTimeoutIds(undefined);
    } else {
      runPlayer();
    }
  };

  return (
    <div className='container p-4'>
      <div className='d-flex flex-md-row justify-content-between gap-4'>
        <div className='card p-3' style={{ width: '70%', height: 'fit-content' }}>
          <div className='d-flex flex-row align-items-end gap-2'>
            <div className='h4'>0%</div>
            <div
              style={{
                width: '200px',
                height: '10px',
                background: `linear-gradient(to right, rgba(0,0,255,0.1) 0%, rgba(0,0,255,0.9) 20%, rgba(0,255,0,1) 45%, rgba(255,255,0,1) 85%, rgba(255,0,0,1) 100%)`,
              }}
              className='mb-3'
            />
            <div className='h4'>100%</div>
          </div>
          {/* <div className='bg-secondary d-flex align-items-center justify-content-center gap-3 rounded' style={{ height: '70vh' }}>
            <i className='bi bi-exclamation-triangle fs-1 text-secondary-emphasis'></i>
            <div className='text-secondary-emphasis fw-bold'>No Images</div>
          </div> */}
          <div id='heatmap' style={{ width: '100%', height: '400px' }} />
          {locationData.length !== 0 && (
            <>
              <div className='d-flex flex-row w-100 mb-3'>
                <div
                  className='btn m-md-auto btn-active-light'
                  onClick={() => {
                    if (index > 0) {
                      setIndex((index) => {
                        return index - 1;
                      });
                      setActiveHour(hours[index - 1]);
                    }
                  }}
                >
                  <p className='text-dark m-md-auto'>Prev</p>
                  {/* <i className="bi bi-arrow-left text-white" /> */}
                </div>
                <p className='text-dark opacity-75 fw-semibold fs-6 m-auto col-sm-4 text-center'>{activeHour}:00</p>
                <div
                  className='btn m-md-auto btn-active-light'
                  onClick={() => {
                    if (
                      index < hours.length - 1 &&
                      locationData.filter((x) => x.hour === activeHour + 1).length !== 0
                      // &&
                      // locationData.filter((x) => x.hour === activeHour + 1)[0].filename !==
                      //   locationData.filter((x) => x.hour === activeHour)[0].filename
                    ) {
                      setIndex((index) => {
                        return index + 1;
                      });
                      setActiveHour(hours[index + 1]);
                    }
                  }}
                >
                  <p className='text-dark m-md-auto'>Next</p>
                </div>
              </div>
              <div className='py-md-6 pb-6 d-flex align-items-center animated w-100 px-3'>
                <i
                  className={`${timeoutIds ? 'bi bi-pause-fill' : 'bi bi-play-fill'} cursor-pointer mb-1`}
                  onClick={handlePlayerClick}
                  style={{ fontSize: '3.5rem' }}
                ></i>
                <div
                  className='progress ml-3 flex-grow-1'
                  role='progressbar'
                  aria-label='Animated striped example'
                  aria-valuenow={playerProgress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div className='progress-bar progress-bar-striped progress-bar-animated bg-primary' style={{ width: `${playerProgress}%` }}></div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className='d-flex flex-column gap-4'>
          <div className='card p-3 pb-0'>
            {cameraData.length > 0 ? (
              <div className='d-flex flex-column align-items-end'>
                <div>
                  <div className='h3 text-secondary-emphasis pt-1'>Total detections per camera</div>
                </div>
                <ReactApexChart options={treemapOptions} series={treemapSeries} height={350} width={400} type='treemap' />
              </div>
            ) : (
              <div className='d-flex flex-row w-400px h-350px align-items-center justify-content-center gap-3'>
                <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
                <div className='fs-3 text-secondary-emphasis'>No detections</div>
              </div>
            )}
          </div>
          <div className='card p-3 pb-0'>
            {cameraData.length > 0 ? (
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                  <Dropdown drop='end'>
                    <Dropdown.Toggle variant='light' id='dropdown-basic'>
                      {selectedCamera !== '' ? `${selectedCamera}` : 'All Cameras'}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setSelectedCamera('');
                          setSelectedData(cameraData);
                        }}
                      >
                        All Cameras
                      </Dropdown.Item>
                      {sortedDetections.map(([camera_name]) => (
                        <Dropdown.Item
                          key={camera_name}
                          onClick={() => {
                            setSelectedCamera(camera_name);
                            setSelectedData(cameraData.filter((entry) => entry.camera_name === camera_name));
                          }}
                        >
                          {camera_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className='h3 text-secondary-emphasis pe-3'>Total detections</div>
                </div>
                <ReactApexChart options={barchartOptions} series={barchartSeries} height={300} width={400} type='bar' />
              </div>
            ) : (
              <div className='d-flex flex-row w-400px h-350px align-items-center justify-content-center gap-3'>
                <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
                <div className='fs-3 text-secondary-emphasis'>No detections</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeatmapDashboard;
