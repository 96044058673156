import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface cameraModelInListState {
    id: number
    name: string
    command: string
    command_local: string
}

export interface cameraModelList {
    list: cameraModelInListState[]
}

const initialState: cameraModelList = {
    list: localStorage.getItem("cachedCameraModelList") != null ? JSON.parse(localStorage.getItem("cachedCameraModelList") ?? '') : []
}

export const cameraModelList = createSlice({
    name: 'cameraModelList',
    initialState,
    reducers: {
        setcachedCameraModelList: (state, action: PayloadAction<cameraModelList>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedCameraModelList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setcachedCameraModelList } = cameraModelList.actions;

export const cameraModelListReducer = cameraModelList.reducer;
