import callApiAxiosClient from '../../axios/axios_client';

async function updateRoleId(role: number, user: string) {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'put',
    url: 'account/role',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      role: role,
      user_id: user,
    },
  });
}
export default updateRoleId;
