import callApiAxiosClient from "../../../axios/axios_client";

function disable2fa(): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "post",
        url: "account/disable-2fa",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        }
    })
}
export default disable2fa;