import callApiAxiosClient from "../../axios/axios_client";

async function getAzureFile(path: string): Promise<string> {
    const isCloud = process.env.REACT_APP_CLOUD
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    if (isCloud === 'true')
        try {
            if (path != null || path != '') {
                const response: Blob = await callApiAxiosClient({
                    method: "get",
                    url: `media/get-file`,
                    headers: {
                        "Authorization": authorization
                    },
                    responseType: 'blob', // Set the response type to blob
                    params: {
                        path: path
                    }
                });
                if (response == null) return URL.createObjectURL(new Blob([]));
                // Convert blob response to object URL
                const objectURL = URL.createObjectURL(response);
                return objectURL;
            }
            return URL.createObjectURL(new Blob([]));


        } catch (error) {
            console.error('Error fetching image:', error);
            return URL.createObjectURL(new Blob([]));
            throw error; // You can either return a default/fallback image URL here or throw the error to handle it in the component
        }
    else {

        return `/${path}`;
    }
}
export default getAzureFile;