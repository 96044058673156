import callApiAxiosClient from '../../axios/axios_client';
import { alertByPeriodList, interval } from '../../models';

async function getAlertsByPeriod(interval: interval): Promise<alertByPeriodList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  return callApiAxiosClient<alertByPeriodList>({
    method: 'get',
    url: 'programmability/get-hsse-alerts-by-period',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      interval: interval,
    },
  });
}
export default getAlertsByPeriod;
