import callApiAxiosClient from '../../../axios/axios_client';
import { detectionServerInList } from '../../../models';

async function updateDetectionServer(params: Partial<detectionServerInList>): Promise<detectionServerInList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  console.log('detsv in call', params);
  return callApiAxiosClient<detectionServerInList>({
    method: 'put',
    url: 'settings/modify-detection-servers',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      id: params.id,
      name: params.name,
      location_id: params.location_id,
      ip_address: params.ip_address,
      external_endpoint: params.external_endpoint,
      audio_alerts: params.audio_alerts,
    },
  });
}
export default updateDetectionServer;
