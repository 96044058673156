import React, { useEffect } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LeafMap } from '../Map/LeafMap';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getLPRLocations from '../../web_api/methods/traffic/getLPRLocations';
import { useDispatch } from 'react-redux';
import { setLocations } from '../../redux/trafficReducer';
import locationsLPR from './dummy/lprByVehicleType.json';
import lprLocationsDummy from './dummy/lprLocations.json';

const getIconForType = (type: string) => {
  switch (type) {
    case 'Total':
      return 'fas fa-warehouse';
    case 'Cars':
      return 'fas fa-car-side';
    case 'Vans':
      return 'fas fa-truck';
    case 'Bicycles':
      return 'fas fa-bicycle';
    case 'Carriages':
      return 'fas fa-trailer';
    case 'Motorcycles':
      return 'fas fa-motorcycle';
    default:
      return 'fas fa-fighter-jet';
  }
};

const TrafficDashboard = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('traffic-dashboard', 'Traffic Dashboard');
  });

  const cachedLocationsList = useSelector((state: RootState) => state.traffic.locations);
  const userName = useSelector((state: RootState) => state.user.user_name);

  const [selectedLocation, setSelectedLocation] = React.useState<number>(-1);
  // add state for total detections by type by adding all detections from all locations by type
  const [detectionsByType, setDetectionsByType] = React.useState<{ type: string; value: number }[]>([]);
  const dispatch = useDispatch();

  const loadInitial = async () => {
    if (userName === 'orange@demo.com') {
      dispatch(setLocations(lprLocationsDummy));
      return;
    }
    if (cachedLocationsList.length === 0) dispatch(setLocations(await getLPRLocations()));
  };

  useEffect(() => {
    loadInitial();
  }, []);

  useEffect(() => {
    if (selectedLocation === -1) {
      const detections = locationsLPR.map((x) => x.detectionByType);
      const totalDetections = detections.reduce((acc, val) => {
        val.forEach((x) => {
          const index = acc.findIndex((y) => y.type === x.type);
          if (index === -1) {
            acc.push({ type: x.type, value: x.value });
          } else {
            acc[index].value += x.value;
          }
        });
        return acc;
      }, []);
      setDetectionsByType(totalDetections);
    } else {
      setDetectionsByType(locationsLPR.find((x) => x.id === selectedLocation)?.detectionByType || []);
    }
  }, [selectedLocation]);

  return (
    <div className='p-4 pb-0 h-100 w-100'>
      <div className='d-flex flex-column flex-md-row align-items-center h-100 w-100'>
        <div className='d-flex flex-column col-12 col-md-4 pe-md-3 pb-2 gap-3'>
          {detectionsByType.map((x) => (
            <Card className='shadow-sm' key={x.type}>
              <Card.Body className='d-flex flex-row justify-content-between align-items-center'>
                <Card.Title className='fs-4 d-flex flex-row align-items-center gap-5'>
                  <i className={`${getIconForType(x.type)}`} style={{ fontSize: '4rem' }}></i> {x.type}
                </Card.Title>
                <Card.Text className='display-4'>{x.value}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className='d-flex flex-column gap-3 col-md-8 p-3 justify-content-center'>
          <div className='d-flex flex-row align-items-center justify-content-start gap-3'>
            <Dropdown>
              <Dropdown.Toggle variant='secondary btn-sm' id='dropdown-basic'>
                {selectedLocation === -1 ? `Select Location` : cachedLocationsList.find((x) => x.location_id === selectedLocation)?.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item key={-1} onClick={() => setSelectedLocation(-1)}>
                  All Locations
                </Dropdown.Item>
                {cachedLocationsList.map((x) => (
                  <Dropdown.Item key={x.location_id} onClick={() => setSelectedLocation(x.location_id)}>
                    {x.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {selectedLocation !== -1 && (
              <>
                <Link to={`/traffic/dashboard/${selectedLocation}`} className='' key={selectedLocation}>
                  <Button variant='warning btn-sm px-3 info-tooltip' onClick={() => setSelectedLocation(-1)}>
                    <i className='fas fa-info fs-5 p-0'></i>
                  </Button>
                </Link>
                <Button variant='danger btn-sm  px-3' onClick={() => setSelectedLocation(-1)}>
                  <i className='fas fa-times fs-5 p-0'></i>
                </Button>
              </>
            )}
          </div>
          <div className='h-500px w-100 shadow-md display-sm-none'>
            <LeafMap
              className={'h-500px'}
              allowMultipleMarkers={true}
              allowDelete={false}
              markers={
                selectedLocation === -1
                  ? cachedLocationsList.map((x) => ({ id: x.location_id, position: { lat: x.lat, long: x.long }, name: x.name }))
                  : [
                      {
                        id: selectedLocation,
                        position: {
                          lat: cachedLocationsList.find((x) => x.location_id === selectedLocation)!.lat || 0,
                          long: cachedLocationsList.find((x) => x.location_id === selectedLocation)!.long || 0,
                        },
                        name: cachedLocationsList.find((x) => x.location_id === selectedLocation)!.name,
                      },
                    ]
              }
              allowAdd={false}
              draggableMarkers={false}
              isLink={true}
            />
          </div>
        </div>
      </div>
      <Tooltip anchorSelect='.info-tooltip' place='top'>
        Click or just select one of the markers on the map!
      </Tooltip>
    </div>
  );
};

export default TrafficDashboard;
