import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EProductType {
  NONE,
  RETAIL,
  HSSE,
  MOBILITY
}

export interface DemoProductState {
  demoProduct: EProductType
}

// Retrieve the saved value from localStorage
const savedDemoProduct = localStorage.getItem('demoProduct');

// Define a function to convert string to enum value
function stringToEnum(value: string): EProductType {
  switch (value) {
    case 'NONE':
      return EProductType.NONE;
    case 'RETAIL':
      return EProductType.RETAIL;
    case 'HSSE':
      return EProductType.HSSE;
    case 'MOBILITY':
      return EProductType.MOBILITY;
    default:
      return EProductType.NONE; // Default to NONE if the value is not recognized
  }
}

const initialState: DemoProductState = {
  demoProduct: savedDemoProduct !== null ? stringToEnum(savedDemoProduct) : EProductType.NONE,
};

export const demoProduct = createSlice({
  name: 'demoProduct',
  initialState,
  reducers: {
    setDemoProduct: (state, action: PayloadAction<DemoProductState>) => {
      state.demoProduct = action.payload.demoProduct;
      localStorage.setItem("demoProduct", EProductType[action.payload.demoProduct]);
    },
  },
});

export const { setDemoProduct } = demoProduct.actions;

export const demoProductReducer = demoProduct.reducer;
