import callApiAxiosClient from '../../axios/axios_client';
import { areaInPlanList } from '../../models';

function addAreaInPlan(area_plan_items: areaInPlanList): Promise<areaInPlanList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<areaInPlanList>({
    method: 'post',
    url: 'settings/area-in-plan',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      area_plan_items: area_plan_items,
    },
  });
}
export default addAreaInPlan;
