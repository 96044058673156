import React, { useEffect, useState } from 'react';
import { ChartsWidget5 } from '../../_metronic/widgets/charts/ChartsWidget5';
import bgpurple from '../../assets/images/wave-bg-purple.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import queueManagementAudioAlerts from '../../web_api/methods/queueManagementDash/audioAlerts';
import queueManagementAvgCashRegisterOpen from '../../web_api/methods/queueManagementDash/avgCashRegisterOpen';
import { setValue } from '../../redux/audioAlertsReducer';
import { audioAlertsList } from '../../web_api/models';
import { numberToTimeFormat } from '../../web_api/methods/helpers';

type Props = {
  className: string;
  from: Date;
  to: Date;
  callbackData: (list: audioAlertsList, headers: string[]) => void;
};

const AudioAlertsQueueManagement: React.FC<Props> = ({ className, from, to, callbackData }) => {
  const [values, setValues] = useState<number>(0);
  const [avgTimeToOpenCashRegister, setAvgTime] = useState<string>('No data');

  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const avgTimeToOpenCashRegisterAllLocations = useSelector((state: RootState) => state.avgTimeCashRegisterAllLocations.value);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const avgTimeCashRegisterOpenList = useSelector((state: RootState) => state.avgTimeCashRegisterOpenList.list);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    loadData();
  }, [
    activeLocation,
    dateFilter,
    hourParameters,
    //  avgTimeCashRegisterOpenList,
    selectedUser,
  ]);

  useEffect(() => {
    if (avgTimeCashRegisterOpenList.find((x) => x.id.toString() === activeLocation.id)?.avgTime === undefined) setAvgTime('No data');
    else setAvgTime(numberToTimeFormat(avgTimeCashRegisterOpenList.find((x) => x.id.toString() === activeLocation.id)!?.avgTime));
  }, [avgTimeCashRegisterOpenList]);

  const loadData = async () => {
    try {
      let audioAlerts = await queueManagementAudioAlerts(
        parseInt(activeLocation.id),
        from,
        to,
        parseInt(hourParameters.from),
        parseInt(hourParameters.to),
        selectedUser.id
      );
      let headers = ['Location', 'Nr', 'Date'];
      callbackData(audioAlerts, headers);
      let sum = 0;
      audioAlerts.forEach((item) => {
        sum += item.total;
      });
      setValues(sum);
      dispatch(
        setValue({
          value: sum.toString(),
        })
      );
      // let avgTime = await queueManagementAvgCashRegisterOpen(parseInt(activeLocation.id), from, to);
      if (avgTimeCashRegisterOpenList.find((x) => x.id.toString() === activeLocation.id)?.avgTime === undefined) setAvgTime('No data');
      else setAvgTime(numberToTimeFormat(avgTimeCashRegisterOpenList.find((x) => x.id.toString() === activeLocation.id)!?.avgTime));
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <ChartsWidget5
      className={className}
      description='Active Projects'
      color='#7239EA'
      img={bgpurple}
      icon='bi bi-headset'
      data={{ number: values, time: activeLocation.id === '0' ? avgTimeToOpenCashRegisterAllLocations : avgTimeToOpenCashRegister }}
      title='Audio alerts'
      label='sent'
      subtitle='Response time'
      loading={loading}
    />
  );
};
export { AudioAlertsQueueManagement };
