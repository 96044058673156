import { useState } from 'react';
import disable2fa from '../../web_api/methods/account/2FA/disable-2fa';
import { CommonModal } from '../Common/CommonModal';
import verify2fa from '../../web_api/methods/account/2FA/verify-2fa';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { toast } from 'react-toastify';

type TwoFactorAuthDisableModalProps = {
  show: boolean;
  toggleModal: () => void;
};

const TwoFactorAuthDisableModal: React.FC<TwoFactorAuthDisableModalProps> = ({ show, toggleModal }) => {
  const [otp, setOtp] = useState<string>('');
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const mail = useSelector((state: RootState) => state.user.user_name);

  const handleDisable = async () => {
    try {
      setIsVerifying(true);
      const verifyTwoFactorAuth = await verify2fa(otp, mail);
      if (verifyTwoFactorAuth.is_valid) {
        const disableTwoFactorAuth = await disable2fa();
        localStorage.setItem('two_factor_enabled', JSON.stringify('false'));
        toast.success(`Two Factor Authentication has been disabled!`, {
          position: 'top-right',
          theme: 'colored',
        });
        toggleModal();
      }
      setIsVerifying(false);
    } catch (e) {
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
      setIsVerifying(false);
    }
  };

  const handleInputChange = (otp: string) => {
    const sanitizedInput = otp.replace(/[^0-9]/g, '');
    const otpValue = sanitizedInput.slice(0, 6);
    setOtp(otpValue);
  };

  const handleToggleModal = () => {
    toggleModal();
    setTimeout(
      () => {
        setOtp('');
      },
      show ? 200 : 0
    );
  };

  return (
    <>
      <CommonModal
        title='Two-factor Authentification'
        show={show}
        disabled={isVerifying}
        showModalCallback={handleToggleModal}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-check',
          variant: 'custom',
          class: 'bg-danger text-white',
          text: 'Disable',
          onClick: otp.length === 6 ? handleDisable : undefined,
        }}
      >
        <div className='px-4 py-1 px-sm-8 py-sm-2'>
          <div className='text-gray-400 fs-5 fw-semibold'>Insert your 6 digit code in order to disable two factor authentication:</div>
          <div className='w-100 mt-5'>
            <input
              type='tel'
              autoFocus
              value={otp}
              onChange={(e) => handleInputChange(e.target.value)}
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter authentication code'
              name='code'
              maxLength={6}
              pattern='[0-9]*'
              inputMode='numeric'
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default TwoFactorAuthDisableModal;
