import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AudioAlertsState {
  value: string
}
const initialState: AudioAlertsState = {
  value: localStorage.getItem('audioAlerts')!?.toString()!=null?localStorage.getItem('audioAlerts')!?.toString():'undefined'
};

export const audioAlerts = createSlice({
  name: 'audioAlerts',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<AudioAlertsState>) => {
      state.value = action.payload.value
      localStorage.setItem("audioAlerts", action.payload.value)

    },
  },
});

export const { setValue } = audioAlerts.actions;

export const audioAlertsReducer = audioAlerts.reducer;
