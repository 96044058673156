import callApiAxiosClient from '../../../axios/axios_client';

function addPlanPicture(picture: Blob, locationId: number, image_width: number, image_height: number): Promise<string> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<string>({
    method: 'post',
    url: 'settings/locations/plan',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authorization,
    },
    data: {
      image: picture,
      locationId: locationId,
      imageWidth: image_width,
      imageHeight: image_height,
    },
  });
}
export default addPlanPicture;
