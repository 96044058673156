import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface cameraImagesState {
  list: cameraImageItem[]
}
type cameraImageItem = {
  id: number
  url: string
}
const initialState: cameraImagesState = {
  list: []
};

export const cameraImage = createSlice({
  name: 'cameraImage',
  initialState,
  reducers: {
    setCameraImage: (state, action: PayloadAction<cameraImagesState>) => {
      state.list = [...action.payload.list]; // Create a new array
    },
    addCameraImage: (state, action: PayloadAction<cameraImageItem>) => {
      var list = state.list
      const index = list.findIndex((item) => item.id === action.payload.id);

      if (index !== -1) {
        // If the index exists, update the existing entry
        list[index] = action.payload
      } else {
        // If the index is -1, the camera doesn't exist, so add it to the list
        list.push(action.payload)
      }
      state.list = [...state.list];
    }
  },
});


export const { setCameraImage, addCameraImage } = cameraImage.actions;

export const cameraImagesReducer = cameraImage.reducer;
