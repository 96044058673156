import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface personInListState {
    id: number
    detection_id: string
    date: Date
    camera_id: number
    recognition_confidence: number
    person: string
    bbox1: number
    bbox2: number
    bbox3: number
    bbox4: number
    picture_width: number
    picture_height: number
    filename: string
}

export interface personListState {
    list: personInListState[]
}

const initialState: personListState = {
    list: localStorage.getItem("cachedPersonList") != null ? JSON.parse(localStorage.getItem("cachedPersonList") ?? '') : []
}

export const personList = createSlice({
    name: 'personList',
    initialState,
    reducers: {
        setCachedPersonList: (state, action: PayloadAction<personListState>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedPersonList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedPersonList } = personList.actions;

export const personListReducer = personList.reducer;
