import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ToolbarDateFilterState {
  date: string
}
const initialState: ToolbarDateFilterState = {
  date: new Date().toString()
};

export const toolbarDateFilter = createSlice({
  name: 'toolbarDateFilter',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<ToolbarDateFilterState>) => {
      state.date = action.payload.date
    },
  },
});

export const { setValue } = toolbarDateFilter.actions;

export const toolbarDateFilterReducer = toolbarDateFilter.reducer;
