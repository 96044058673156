import callApiAxiosClient from "../../axios/axios_client";
import { DayWeekMonthYearEnum, evolutionValueList } from "../../models";



async function peopleCountingEvolutionValue(interval:DayWeekMonthYearEnum,from?:Date,to?:Date): Promise<evolutionValueList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<evolutionValueList>({
        method: "get",
        url: "programmability/evolution-value",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params:{
            interval:interval.toString().toLowerCase()
        }
    })
}
export default peopleCountingEvolutionValue;