import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { categoryList } from '../web_api/models';

export interface addItemsState {
  changes: categoryList;
  json: categoryList;
}

const initialState: addItemsState = {
  changes: [],
  json: [],
};

export const addItems = createSlice({
  name: 'addItems',
  initialState,
  reducers: {
    setAddItems: (state, action: PayloadAction<addItemsState>) => {
      state.changes = action.payload.changes;
      state.json = action.payload.json;
    },
  },
});

export const { setAddItems } = addItems.actions;

export const addItemsReducer = addItems.reducer;
