import React, { useEffect, useRef } from 'react';

interface IBoundingBox {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface ICanvasProps {
  imageUrl: string;
  actualHeight: number;
  actualWidth: number;
  bboxes: IBoundingBox[];
  canvasHeight: string;
  canvasWidth: string;
}

const CanvasComponent: React.FC<ICanvasProps> = ({ imageUrl, actualHeight, actualWidth, bboxes, canvasHeight, canvasWidth }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.style.width = canvasWidth;
    canvas.style.height = canvasHeight;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    let rectColor = 'yellow';
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const height = canvas.height;
      const width = canvas.width;

      bboxes.forEach((item) => {
        let x1 = (item.x1 * width) / actualWidth;
        let y1 = (item.y1 * height) / actualHeight;
        let x2 = (item.x2 * width) / actualWidth;
        let y2 = (item.y2 * height) / actualHeight;
        let w = x2 - x1;
        let h = y2 - y1;
        ctx.beginPath();
        ctx.rect(x1, y1, w, h);
        ctx.font = '8px Arial';
        ctx.lineWidth = 3;
        ctx.strokeStyle = rectColor;
        ctx.stroke();
      });
    };
    img.src = imageUrl;
  }, [actualHeight, actualWidth, bboxes, canvasHeight, canvasWidth, imageUrl]);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default CanvasComponent;
