import React, { useEffect, useRef, useState } from 'react'
import reportProblem from '../../web_api/methods/support/reportProblem';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';


export default function ReportAProblem() {
    const [hasError, setHasError] = useState<boolean>(false)
    const [thankYouMessage, setThankYouMessage] = useState<string>('')
    const subject = useRef<HTMLInputElement>(null)
    const image = useRef<HTMLInputElement>(null)
    const description = useRef<HTMLTextAreaElement>(null)
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            const subjectValue = subject.current!.value
            const descriptionValue = description.current!.value
            setThankYouMessage('');

            reportProblem(subjectValue, descriptionValue)
                .then(async () => {
                    subject.current!.value = ''; // Clear subject field
                    description.current!.value = ''; // Clear description field
                    setThankYouMessage('Thank you for your feedback!');
                })
                .catch(() => {
                    setHasError(true)
                })
        }
    };
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'report-a-problem',
            'Report a problem'
        )
    })
    async function handleReportProblem() {
        const subjectValue = subject.current!.value
        const descriptionValue = description.current!.value
        setThankYouMessage('');

        reportProblem(subjectValue, descriptionValue)
            .then(async () => {
                subject.current!.value = ''; // Clear subject field
                description.current!.value = ''; // Clear description field
                setThankYouMessage('Thank you for your feedback!');
            })
            .catch(() => {
                setHasError(true)
            })
    }
    return (
        <>
            <div className='d-flex flex-center'>
                <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
                    <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                        <div className='w-lg-500px p-10'>
                            <div className='text-center mb-15'>
                                <h3 className='text-dark fw-bolder'>
                                    Tell us what is the problem that you encountered.
                                </h3>
                                <h2 className='text-gray-500 fw-semibold fs-6'>
                                    We will try to fix it as soon as possible.
                                </h2>
                            </div>
                            <div className='text-center'>
                                <form onKeyPress={handleKeyPress}>
                                    <div className='fv-row my-6 d-flex flex-wrap'>
                                        <label className='form-label fs-6 fw-bolder text-dark mb-2' htmlFor='email'>Subject</label>
                                        <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={subject} type={'text'} id='subject' placeholder='Subject' />
                                    </div>
                                    <div className='fv-row my-6 d-flex flex-wrap '>
                                        <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='pass'>Description</label>
                                        <textarea
                                            className={'form-control bg-transparent' + (hasError ? ' border border-danger' : '')}
                                            ref={description}
                                            id='description'
                                            placeholder='Description'
                                        />
                                    </div>
                                    <div className='fv-row my-6 d-flex flex-wrap '>
                                        <label className='form-label fw-bolder text-dark fs-6 mb-2' htmlFor='pass'>Upload an Image (work in progress)</label>
                                        <input className={'form-control bg-transparent' + ((hasError) ? ' border border-danger' : '')} ref={image} type={'file'} id='image' placeholder='Image' />



                                    </div>
                                    <div className='d-grid my-6'>
                                        <button className='btn btn-primary' type={'button'} onClick={handleReportProblem}>Report problem</button>
                                    </div>
                                </form>
                            </div>
                            <span>{thankYouMessage}</span>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
