import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Action } from "redux";


type AxiosCall<T> = () => Promise<T>;

type ReduxSetter<T> = (data: { list: T }) => Action;

type Dependencies = (string | number | boolean | Date)[];

type InitialCallback = () => void;

const useGet = <T,>(

    dependencies: Dependencies,
    axiosCall: AxiosCall<T>,
    reduxSetter?: ReduxSetter<T>,
    initialCallback?: InitialCallback
    
) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [loadedItems, setLoadedItems] = useState<T>();

    const loadItems = async () => {
        try {
            setLoading(true);
            const loadedItems: T = await axiosCall();
            setLoadedItems(loadedItems);
            if(reduxSetter) {
                dispatch(reduxSetter({ list: loadedItems }));
            }
            if (initialCallback) {
                initialCallback();
            };
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, [...dependencies]);

    return {
        loading,
        loadItems,
        data: loadedItems,
        setData: setLoadedItems
    };
};

export default useGet;
