import callApiAxiosClient from '../../../axios/axios_client';

function putCooldownPeriods(cooldown_period1: number, cooldown_period2: number): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'put',
    url: 'settings/cooldown-periods',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      cooldown_period1: cooldown_period1,
      cooldown_period2: cooldown_period2,
    },
  });
}
export default putCooldownPeriods;
