import callApiAxiosClient from "../../axios/axios_client";
import moment from "moment";
import { productFillPercentageList } from "../../models";


async function bakeryProductFillPercentage(firstLocationId: number, secondLocationId: number, from: Date | undefined, to: Date | undefined, nr: number, top: boolean, user_id: string): Promise<productFillPercentageList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const fromParam = moment(from).format("YYYYMMDD");
    const toParam = moment(to).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<productFillPercentageList>({
        method: "get",
        url: "programmability/product-fill-percentage",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            first_location_id: firstLocationId,
            second_location_id: secondLocationId,
            from: fromParam,
            to: toParam,
            nr: nr,
            top: top,
            user_id: user_id
        }
    })
}
export default bakeryProductFillPercentage;