import callApiAxiosClient from '../../../axios/axios_client';
import { detectionServerInList } from '../../../models';

async function addDetectionServer(params: Partial<detectionServerInList>): Promise<detectionServerInList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'settings/add-detection-servers',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      name: params.name,
      location_id: params.location_id,
      ip_address: params.ip_address,
      audio_alerts: params.audio_alerts,
      external_endpoint: params.external_endpoint,
    },
  });
}
export default addDetectionServer;
