import React, { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import Form from 'react-bootstrap/Form';
import { Button, Dropdown } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import picture from './dummy/traffic_camera_pic.jpg';
import useModals from '../../hooks/Common/useModals';
import { CommonModal } from '../Common/CommonModal';
import CanvasComponent from '../FaceRecognition/CanvasComponent';
import AlertCanvasComponent from '../Alerts/AlertCanvasComponent';
import { Tooltip } from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { LeafMap } from '../Map/LeafMap';
import { marker } from 'leaflet';
import { id } from 'date-fns/locale';
import { color, line } from 'd3';
import { set } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getLatestLPR from '../../web_api/methods/traffic/getLatestLPR';
import { lprDetectionInList, lprDetectionList } from '../../web_api/models';
import getAzureFile from '../../web_api/methods/files/getAzureFile';

const counties = [
  { full_name: 'Alba', abbreviation: 'AB' },
  { full_name: 'Arad', abbreviation: 'AR' },
  { full_name: 'Argeș', abbreviation: 'AG' },
  { full_name: 'Bacău', abbreviation: 'BC' },
  { full_name: 'Bihor', abbreviation: 'BH' },
  { full_name: 'Bistrița-Năsăud', abbreviation: 'BN' },
  { full_name: 'Botoșani', abbreviation: 'BT' },
  { full_name: 'Brașov', abbreviation: 'BV' },
  { full_name: 'Brăila', abbreviation: 'BR' },
  { full_name: 'Buzău', abbreviation: 'BZ' },
  { full_name: 'Caraș-Severin', abbreviation: 'CS' },
  { full_name: 'Călărași', abbreviation: 'CL' },
  { full_name: 'Cluj', abbreviation: 'CJ' },
  { full_name: 'Constanța', abbreviation: 'CT' },
  { full_name: 'Covasna', abbreviation: 'CV' },
  { full_name: 'Dâmbovița', abbreviation: 'DB' },
  { full_name: 'Dolj', abbreviation: 'DJ' },
  { full_name: 'Galați', abbreviation: 'GL' },
  { full_name: 'Giurgiu', abbreviation: 'GR' },
  { full_name: 'Gorj', abbreviation: 'GJ' },
  { full_name: 'Harghita', abbreviation: 'HR' },
  { full_name: 'Hunedoara', abbreviation: 'HD' },
  { full_name: 'Ialomița', abbreviation: 'IL' },
  { full_name: 'Iași', abbreviation: 'IS' },
  { full_name: 'Ilfov', abbreviation: 'IF' },
  { full_name: 'Maramureș', abbreviation: 'MM' },
  { full_name: 'Mehedinți', abbreviation: 'MH' },
  { full_name: 'Mureș', abbreviation: 'MS' },
  { full_name: 'Neamț', abbreviation: 'NT' },
  { full_name: 'Olt', abbreviation: 'OT' },
  { full_name: 'Prahova', abbreviation: 'PH' },
  { full_name: 'Satu Mare', abbreviation: 'SM' },
  { full_name: 'Sălaj', abbreviation: 'SJ' },
  { full_name: 'Sibiu', abbreviation: 'SB' },
  { full_name: 'Suceava', abbreviation: 'SV' },
  { full_name: 'Teleorman', abbreviation: 'TR' },
  { full_name: 'Timiș', abbreviation: 'TM' },
  { full_name: 'Tulcea', abbreviation: 'TL' },
  { full_name: 'Vaslui', abbreviation: 'VS' },
  { full_name: 'Vâlcea', abbreviation: 'VL' },
  { full_name: 'Vrancea', abbreviation: 'VN' },
  { full_name: 'București', abbreviation: 'B' },
  { full_name: 'Călărași', abbreviation: 'CL' },
];

//{ id: number; position: { lat: number; long: number; }; name: string; }
const response = [
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'AB 01 ABC',
    confidence: 0.85,
    detection_date: '2024-04-15',
    camera_name: 'Camera1',
    camera_location: 'Street A',
    camera_id: 1,
    vehicle_type: 'Car',
    county: 'AB',
    location_id: 1,
    markers: {
      id: 1,
      position: { lat: 45.9432, long: 24.9668 },
      name: 'Alba',
      info: 'Street A - Camera1 - 2024-04-15 - 85% confidence',
    },
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'AB 01 ABC',
    confidence: 0.85,
    detection_date: '2024-04-16',
    camera_name: 'Camera1',
    camera_location: 'Street A',
    camera_id: 1,
    vehicle_type: 'Car',
    county: 'AB',
    location_id: 1,
    markers: {
      id: 2,
      position: { lat: 46.1866, long: 21.3126 },
      name: 'Arad',
      info: 'Street A - Camera1 - 2024-04-16 - 85% confidence',
    },
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'AB 01 ABC',
    confidence: 0.85,
    detection_date: '2024-04-17',
    camera_name: 'Camera1',
    camera_location: 'Street A',
    camera_id: 1,
    vehicle_type: 'Car',
    county: 'AB',
    location_id: 1,
    markers: {
      id: 3,
      position: { lat: 44.9365, long: 26.1426 },
      name: 'Argeș',
      info: 'Street A - Camera1 - 2024-04-17 - 85% confidence',
    },
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'AB 01 ABC',
    confidence: 0.85,
    detection_date: '2024-04-18',
    camera_name: 'Camera1',
    camera_location: 'Street A',
    camera_id: 1,
    vehicle_type: 'Car',
    county: 'AB',
    location_id: 1,
    markers: {
      id: 4,
      position: { lat: 46.5779, long: 26.9196 },
      name: 'Bacău',
      info: 'Street A - Camera1 - 2024-04-18 - 85% confidence',
    },
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'CJ 23 XYZ',
    confidence: 0.92,
    detection_date: '2024-04-14',
    camera_name: 'Camera2',
    camera_location: 'Street B',
    camera_id: 2,
    vehicle_type: 'Van',
    county: 'CJ',
    location_id: 1,
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'TM 34 DEF',
    confidence: 0.78,
    detection_date: '2024-04-13',
    camera_name: 'Camera3',
    camera_location: 'Street C',
    camera_id: 1,
    vehicle_type: 'Van',
    county: 'TM',
    location_id: 2,
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'MS 45 GHI',
    confidence: 0.95,
    detection_date: '2024-04-12',
    camera_name: 'Camera4',
    camera_location: 'Street D',
    camera_id: 3,
    vehicle_type: 'Lorry',
    county: 'MS',
    location_id: 2,
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'B 01 ERU',
    confidence: 0.88,
    detection_date: '2024-04-11',
    camera_name: 'Camera5',
    camera_location: 'Street E',
    camera_id: 3,
    vehicle_type: 'Lorry',
    county: 'B',
    location_id: 3,
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'SV 67 MNO',
    confidence: 0.91,
    detection_date: '2024-04-10',
    camera_name: 'Camera6',
    camera_location: 'Street F',
    camera_id: 2,
    vehicle_type: 'Car',
    county: 'SV',
    location_id: 3,
  },
  {
    filename: 'traffic_camera_pic.jpg',
    plate_number: 'IF 78 PQR',
    confidence: 0.82,
    detection_date: '2024-04-09',
    camera_name: 'Camera7',
    camera_location: 'Street G',
    camera_id: 1,
    vehicle_type: 'Car',
    county: 'IF',
    location_id: 1,
  },
  // Add more objects as needed
];

const cameras = [
  {
    id: 1,
    name: 'Camera 1',
    location: 'Location 1',
    location_id: 1,
  },
  {
    id: 2,
    name: 'Camera 2',
    location: 'Location 2',
    location_id: 2,
  },
  {
    id: 3,
    name: 'Camera 3',
    location: 'Location 3',
    location_id: 3,
  },
];

const locations = [
  {
    id: 1,
    name: 'Location 1',
  },
  {
    id: 2,
    name: 'Location 2',
  },
  {
    id: 3,
    name: 'Location 3',
  },
];

const TrafficSearch = () => {
  const { setToolbar, setBackButton } = useToolbarSetter();
  useEffect(() => {
    setToolbar('traffic-search', `Traffic Search - ${selectedLicencePlate}`);
    setBackButton(true);

    return () => {
      setBackButton(false);
    };
  });

  const params = useParams();
  const userName = useSelector((state: RootState) => state.user.user_name);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);

  const selectedLicencePlate = String(params.id);
  const [selectedItem, setSelectedItem] = useState<lprDetectionInList>();
  const [results, setResults] = useState<lprDetectionList>([]);
  const [lines, setLines] = useState<any>([]);
  const [progress, setProgress] = useState(0);
  const [timeoutIds, setTimeoutIds] = useState<NodeJS.Timeout>();
  const [highlightedMarkerId, setHighlightedMarkerId] = useState<number>(-1);

  const { show, toggleModal } = useModals([false]); // 0 for changesModal, 1 for confirmDeleteModal

  const computeUrlAlert = async (alert: lprDetectionInList) => {
    const res = await getAzureFile(alert.filename);
    return res;
  };

  const loadItems = async () => {
    if (userName === 'orange@demo.com') {
      return;
    }

    let res = await getLatestLPR({ from_param: new Date(dateFilter.from), to_param: new Date(dateFilter.to), search_term: selectedLicencePlate });
    res = await Promise.all(
      res.map(async (alert) => {
        alert.filename = await computeUrlAlert(alert);
        return alert;
      })
    );
    setResults(res);
  };

  useEffect(() => {
    setLines([]);
    loadItems().then(() => {
      for (let i = 0; i < results.length; i++) {
        if (i === results.length - 1) break;
        const line = {
          id: i,
          positionStart: {
            lat: results[i].camera_location_lat,
            long: results[i].camera_location_long,
          },
          positionEnd: {
            lat: results[i + 1].camera_location_lat,
            long: results[i + 1].camera_location_long,
          },
          color: 'yellow',
        };
        console.log('adding line', line);
        setLines((prev: any) => {
          return [...prev, line];
        });
      }
    });
    // order results by detection_date
    // setResults(response.filter((item) => item.plate_number === selectedLicencePlate).sort((a, b) => (a.detection_date > b.detection_date ? 1 : -1)));
  }, []);

  const image = new Image();
  image.src = picture;

  const markers =
    results &&
    results.map((res, index) => {
      if (res && res.camera_location_lat && res.camera_location_long) {
        return {
          id: index,
          position: { lat: res.camera_location_lat, long: res.camera_location_long },
          name: res.camera_location_name,
          info: `${res.camera_name} - ${res.detection_time} - ${res.confidence * 100}% confidence`,
        };
      } else return { id: 0, position: { lat: 0, long: 0 }, name: '', info: '' };
    });

  const setAllLinesStart = () => {
    // change the line.color to yellow for all lines
    setLines((prev: any) => {
      return prev.map((line: any) => {
        return { ...line, color: 'yellow' };
      });
    });
  };

  const handlePlayerClick = () => {
    const runPlayer = () => {
      let currentIndex = lines[0].id;
      let timeoutId;

      const setNextState: () => NodeJS.Timeout | undefined = () => {
        if (currentIndex >= lines.length) {
          setProgress(0);
          setTimeoutIds(undefined);
          setAllLinesStart();
          setHighlightedMarkerId(-1);
          return;
        }

        setHighlightedMarkerId(currentIndex + 2);

        currentIndex++;

        setProgress((currentIndex / lines.length) * 100);

        // change the color of the line to green
        setLines((prev: any) => {
          return prev.map((line: any) => {
            if (line.id === currentIndex - 1) {
              return { ...line, color: 'green' };
            }
            return line;
          });
        });

        timeoutId = setTimeout(setNextState, 1500);

        setTimeoutIds(timeoutId);
        return timeoutId;
      };
      timeoutId = setNextState();
    };

    if (timeoutIds) {
      clearTimeout(timeoutIds);
      setTimeoutIds(undefined);
    } else {
      runPlayer();
    }
  };

  return (
    <div className='d-flex flex-column h-100vh justify-content-between'>
      <div className='d-flex flex-row h-100vh'>
        <div
          className='d-flex flex-column gap-3 col-md-4 border-end border-dark-50 border-2 p-3'
          style={{ overflowY: 'scroll', height: '80vh', position: 'relative' }}
        >
          <table className='table align-middle '>
            <thead className='thead-dark'>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>Photo</th>
                <th>Conf.</th>
                <th>Date</th>
                <th>Camera</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody className='fw-bold text-gray-600'>
              {results.map((item, index) => (
                <tr key={index}>
                  <td
                    onClick={() => {
                      setSelectedItem(item);
                      toggleModal(0);
                    }}
                    style={{ cursor: 'pointer', width: '100px' }}
                  >
                    {/* <img src={`./${item.filename}`} alt='item' /> */}
                    <div
                      className='position-absolute cursor-pointer '
                      style={{
                        // make it the same height and width as the image
                        width: '100px',
                        height: `${100 * (9 / 16)}px`, // 16 : 9 is the aspect ratio of the image
                        top: '',
                        backgroundColor: 'transparent',
                        zIndex: '',
                        opacity: '0',
                        transition: 'opacity 0.2s',
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                        e.currentTarget.style.opacity = '0.8';
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.style.opacity = '0';
                      }}
                    >
                      <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                        <button
                          type='button'
                          className='btn rounded btn-outline-dark border text-white'
                          style={{ opacity: '1', transition: 'opacity 0.4s' }}
                        >
                          View Image
                        </button>
                      </div>
                    </div>
                    {userName === 'orange@demo.com' ? (
                      <CanvasComponent
                        // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                        imageUrl={picture}
                        actualHeight={image.naturalHeight}
                        actualWidth={image.naturalWidth}
                        bboxes={[{ x1: 200, y1: 100, x2: 550, y2: 500 }]}
                        canvasHeight={`${100 * (9 / 16)}px`}
                        canvasWidth={'100px'}
                      />
                    ) : (
                      <CanvasComponent
                        // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                        imageUrl={item.filename}
                        actualHeight={item.picture_height}
                        actualWidth={item.picture_width}
                        bboxes={[{ x1: 200, y1: 100, x2: 550, y2: 500 }]}
                        canvasHeight={`${100 * (9 / 16)}px`}
                        canvasWidth={'100px'}
                      />
                    )}
                  </td>
                  <td>{`${item.confidence * 100} %`}</td>
                  <td>{item.detection_time}</td>
                  <td>{item.camera_name}</td>
                  <td>{item.camera_location_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='d-flex flex-column gap-3 col-md-8 p-3 justify-content-center'>
          <div className='h-500px'>
            <LeafMap
              className={'h-500px bg-secondary mt-5'}
              allowMultipleMarkers={true}
              allowDelete={false}
              markers={markers}
              allowAdd={false}
              lines={lines}
              draggableMarkers={false}
              highlightMarkerId={highlightedMarkerId === -1 ? 1 : highlightedMarkerId}
            />
          </div>
        </div>
        {selectedItem && (
          <CommonModal
            closeButton={true}
            title={`Photo of ${selectedItem?.plate_number}`}
            show={show[0]}
            showModalCallback={() => toggleModal(0)}
            size='lg'
            closeButtonOptions={{}}
            confirmButton={false}
            confirmButtonOptions={{}}
          >
            <div className='d-flex flex-column justify-content-center align-items-center'>
              {userName === 'orange@demo.com' ? (
                <AlertCanvasComponent
                  // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                  imageUrl={picture}
                  actualHeight={image.naturalHeight}
                  actualWidth={image.naturalWidth}
                  multiple_bboxes={[{ bboxes: [200, 100, 550, 500], detail_description: `${selectedItem?.plate_number}` }]}
                  canvasHeight={`${750 * (9 / 16)}px`}
                  canvasWidth={'750px'}
                  big={true}
                />
              ) : (
                <AlertCanvasComponent
                  // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                  imageUrl={selectedItem!.filename}
                  actualHeight={selectedItem!.picture_height}
                  actualWidth={selectedItem!.picture_width}
                  multiple_bboxes={[{ bboxes: [200, 100, 550, 500], detail_description: `${selectedItem?.plate_number}` }]}
                  canvasHeight={`${750 * (9 / 16)}px`}
                  canvasWidth={'750px'}
                  big={true}
                />
              )}
            </div>
          </CommonModal>
        )}
      </div>
      <div className='py-md-6 pb-6 d-flex align-items-center animated w-100 px-3 row-md-12'>
        <i
          className={`${timeoutIds ? 'bi bi-pause-fill' : 'bi bi-play-fill'} cursor-pointer mb-1`}
          onClick={handlePlayerClick}
          style={{ fontSize: '3.5rem' }}
        ></i>
        <div
          className='progress ml-3 flex-grow-1 bg-secondary me-5'
          role='progressbar'
          aria-label='Animated striped example'
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
        >
          <div className='progress-bar progress-bar-striped progress-bar-animated bg-primary' style={{ width: `${progress}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default TrafficSearch;
