import callApiAxiosClient from "../../axios/axios_client";
import moment from "moment";
import { lfShelvesStatusList } from "../../models";


async function lfShelvesStatus(locationId: number, date: Date | undefined, fromHour: number, toHour: number): Promise<lfShelvesStatusList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const dateParam = moment(date).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<lfShelvesStatusList>({
        method: "get",
        url: "programmability/lf-shelves-status",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params: {
            locationId: locationId,
            date: dateParam,
            fromHour: fromHour,
            toHour: toHour,
        }
    })
}
export default lfShelvesStatus;