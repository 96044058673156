// hooks
import { useLocation } from 'react-router-dom';

// utils
import { useLayout } from '../../core';
import { useEffect, useState } from 'react';
import { checkIsActiveMoreUrls } from '../../../_metronic/helpers';

interface Props {
  to?: string;
  childrenUrlList?: string[];
}

export const useSidebarMenuItemWithSub = <T extends object>({ to, childrenUrlList }: Props) => {
  // hooks
  const { pathname } = useLocation();
  const { config } = useLayout();

  // states
  const { app } = config;
  const [active, setActive] = useState(false);

  const [activeChildList, setActiveChildList] = useState(false);

  useEffect(() => {
    if (childrenUrlList == null) {
      return;
    }
    const hasActiveChildren = checkIsActiveMoreUrls(pathname, childrenUrlList);
    setActiveChildList(hasActiveChildren);
  }, [pathname]);

  useEffect(() => {
    if (childrenUrlList == null) {
      return;
    }
    const hasActiveChildren = checkIsActiveMoreUrls(pathname, childrenUrlList);
    if (!hasActiveChildren) {
      setActive(false);
    }
  }, [childrenUrlList]);

  // function

  function handleChangeActiveState() {
    setActive((oldState) => !oldState);
  }

  return {
    // states
    app,
    active,
    activeChildList,

    // methods
    handleChangeActiveState,
  };
};
