import React, { useEffect, useState } from 'react'
import { DayWeekMonthYearEnum } from '../../../web_api/models';
import { ChartsWidget11 } from './ChartsWidget11'
import { ChartsWidget4 } from './ChartsWidget4'

export default function MixedCharts2() {
    var [filterAgeGender, setFilterAgeGender] = useState<DayWeekMonthYearEnum>(DayWeekMonthYearEnum.DAY)
    const [changingComponent, setChangingComponent] = useState<React.ReactNode | null>(null);
    useEffect(() => {
        var from = new Date()
        var to = new Date()
        if (filterAgeGender === DayWeekMonthYearEnum.DAY) {
            from = new Date(new Date().setHours(0, 0, 0, 0));
            to.setDate(to.getDate() + 1)
        }
        else if (filterAgeGender === DayWeekMonthYearEnum.WEEK) {
            from.setDate(from.getDate() - 7)
        }
        else {
            from = new Date(new Date().setMonth(new Date().getMonth() - 1));
        }
        setChangingComponent(
            <div className="d-flex flex-row w-sm-100 h-100 overflow-hidden">
                <div className="d-flex flex-column ">
                    <div className="">
                        <ChartsWidget4
                            className='h-md-100 mb-xl-5 '
                            from={from}
                            to={to}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column ">
                    <div className="">
                        <ChartsWidget11
                            className='h-md-100 mb-xl-5 '
                            from={from}
                            to={to}
                        />
                    </div>
                </div>
            </div>
        )
    }, [filterAgeGender])

    return (
        <div className="card h-100">
            <div className='card-header border-0 pt-5 d-flex flex-row'>

                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Age and gender
                        {/* <span className='fs-7 badge badge-info'>coming soon</span> */}
                    </span>

                    <span className='text-muted fw-semibold fs-7'>See what your main public is</span>
                </h3>
                <div className='d-flex align-items-center flex-shrink-0'>
                    <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
                        Filter By:
                    </span>
                    <div className='flex-shrink-0 '>
                        <ul className='nav'>
                            <li className='nav-item' onClick={() => setFilterAgeGender(DayWeekMonthYearEnum.DAY)}>
                                <a
                                    className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filterAgeGender === DayWeekMonthYearEnum.DAY ? ' active' : '')}
                                    data-bs-toggle='tab'
                                >
                                    Today
                                </a>
                            </li>

                            <li className='nav-item' onClick={() => setFilterAgeGender(DayWeekMonthYearEnum.WEEK)}>
                                <a
                                    className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filterAgeGender === DayWeekMonthYearEnum.WEEK ? ' active' : '')}
                                    data-bs-toggle='tab'
                                >
                                    Week
                                </a>
                            </li>

                            <li className='nav-item' onClick={() => setFilterAgeGender(DayWeekMonthYearEnum.MONTH)}>
                                <a
                                    className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filterAgeGender === DayWeekMonthYearEnum.MONTH ? ' active' : '')}
                                    data-bs-toggle='tab'
                                >
                                    Month
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
            {changingComponent}
        </div>
    )
}
