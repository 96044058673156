/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../assets/ts/_utils';
import { ComparisonEnum, audioAlertsList } from '../../../web_api/models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import queueManagementAudioAlerts from '../../../web_api/methods/queueManagementDash/audioAlerts';
import LoadingPage from '../../../layout/LoadingPage';
import queueManagementAvgCashRegisterOpen from '../../../web_api/methods/queueManagementDash/avgCashRegisterOpen';

type Props = {
  className: string;
  chartHeight: string;
  chartColor: string;
  chartSecondaryColor: string;
  type: ApexChart['type'];
  comparison: ComparisonEnum;
  from: Date;
  to: Date;
};

const ComparisonMixedChart: React.FC<Props> = ({ className, chartHeight, chartColor, type, chartSecondaryColor, comparison }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [values1, setValues1] = useState<number[]>([]);
  const [values2, setValues2] = useState<number[]>([]);
  const [oneDaySelected, setOneDaySelected] = useState<boolean>(false);
  const [labels, setLabels] = useState<string[]>([]);

  const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
  const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
  const compareUser1 = useSelector((state: RootState) => state.compareUser1);
  const compareUser2 = useSelector((state: RootState) => state.compareUser2);

  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);

  const hours = [
    '07:00',
    '08:00',
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
  ];

  const [loading, setLoading] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  interface DailyAverage {
    total: number;
    count: number;
    average?: number;
  }

  function computeAudioAlertsAverage(audioAlerts: audioAlertsList, daysList: string[]) {
    const dailyAverages: { [day: string]: DailyAverage } = {};

    // Initialize dailyAverages with entries for all dates in daysList
    daysList.forEach((day) => {
      // Format the date to match your specified format
      const date = new Date(day);
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      dailyAverages[formattedDate] = { total: 0, count: 0, average: 0 };
    });

    // Loop through the results
    audioAlerts.forEach((result) => {
      // Extract time and total from the result
      const { time, total } = result;

      // Extract date without time and format it
      const date = new Date(time);
      const formattedDate = `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;

      // If the day is not yet in the dailyAverages object, create an entry
      if (!dailyAverages[formattedDate]) {
        dailyAverages[formattedDate] = { total: 0, count: 0, average: 0 };
      }

      // Add the total to the daily total and increment the count
      dailyAverages[formattedDate].total += total;
      dailyAverages[formattedDate].count++;
    });

    // Calculate the average for each day
    Object.keys(dailyAverages).forEach((day) => {
      const { total, count } = dailyAverages[day];
      dailyAverages[day].average = count === 0 ? 0 : parseFloat((total / count).toFixed(2));
    });

    return dailyAverages;
  }

  const loadAudioAlerts = async () => {
    try {
      //days
      const fromDate = new Date(dateFilter.from);
      const toDate = new Date(dateFilter.to);

      const daysList = [];
      let currentDate = fromDate;
      while (currentDate <= toDate) {
        const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate
          .getDate()
          .toString()
          .padStart(2, '0')}T00:00:00`;
        daysList.push(dateString);
        currentDate.setDate(currentDate.getDate() + 1);
      }
      let isOneDaySelected = daysList.length === 1;
      setOneDaySelected(isOneDaySelected);

      let forValues: any[] = [];
      let forValues2: any[] = [];
      let forNames: any[] = [];
      let audioAlerts = await queueManagementAudioAlerts(
        parseInt(compareLocation1.id),
        new Date(dateFilter.from),
        new Date(dateFilter.to),
        0,
        24,
        compareUser1.id
      );

      if (compareLocation1.id === '0') {
        // toate locatiile
        let average = computeAudioAlertsAverage(audioAlerts, daysList);

        Object.keys(average).forEach((key) => {
          forValues.push(average[key].average);
          const date = new Date(key);
          const day = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });

          if (!isOneDaySelected) {
            const formattedDate = `${day} ${month}`;
            if (compareLocation2.id === '0') {
              forNames.push(formattedDate);
            }
          } else {
            const fillAllValuesWithAverage = new Array(15).fill((average[key].average! / 15).toFixed(2));
            forValues = fillAllValuesWithAverage;
            forNames = hours;
          }
        });
      } else {
        // o locatie
        if (!isOneDaySelected) {
          // mai multe zile
          audioAlerts.forEach((item) => {
            forValues.push(item.total);
            const date = new Date(item.time);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });

            const formattedDate = `${day} ${month}`;
            forNames.push(formattedDate);
          });
        } else {
          forNames = hours;
          hours.forEach((item) => {
            const foundIndex = audioAlerts.findIndex((alert) => alert.time === item.split(':')[0]);
            if (foundIndex !== -1) {
              forValues.push(audioAlerts[foundIndex].total);
            } else {
              forValues.push(0);
            }
          });
        }
      }
      let audioAlertsCompare = await queueManagementAudioAlerts(
        parseInt(compareLocation2.id),
        new Date(dateFilter.from),
        new Date(dateFilter.to),
        0,
        24,
        compareUser2.id
      );
      if (compareLocation2.id === '0') {
        // toate locatiile
        let average = computeAudioAlertsAverage(audioAlertsCompare, daysList);
        Object.keys(average).forEach((key) => {
          forValues2.push(average[key].average);
          const date = new Date(key);
          const day = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });

          if (!isOneDaySelected) {
            const formattedDate = `${day} ${month}`;
            if (compareLocation1.id === '0') {
              forNames.push(formattedDate);
            }
          } else {
            const fillAllValuesWithAverage = new Array(15).fill((average[key].average! / 15).toFixed(2));
            forValues2 = fillAllValuesWithAverage;
            forNames = hours;
          }
        });
      } else {
        // o locatie
        if (!isOneDaySelected) {
          // mai multe zile
          audioAlertsCompare.forEach((item) => {
            forValues2.push(item.total);
            const date = new Date(item.time);
            const day = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });

            const formattedDate = `${day} ${month}`;
            forNames.push(formattedDate);
          });
        } else {
          forNames = hours;
          hours.forEach((item) => {
            const foundIndex = audioAlertsCompare.findIndex((alert) => alert.time === item.split(':')[0]);
            if (foundIndex !== -1) {
              forValues2.push(audioAlertsCompare[foundIndex].total);
            } else {
              forValues2.push(0);
            }
          });
        }
      }
      setValues1(forValues);
      setValues2(forValues2);
      setLabels(forNames);
    } catch (e) {
      console.log(e);
      setHasErrors(true);
    }
  };

  const loadData = async (comparisonType: ComparisonEnum) => {
    try {
      setLoading(true);
      setHasErrors(false);
      switch (comparisonType) {
        case 'Queue Management':
          await loadAudioAlerts();
          break;
        case 'Bakery':
          break;
        case 'Fruits and Vegetables':
          break;
        default:
          break;
      }
    } catch (e) {
      setHasErrors(true);
    } finally {
      setLoading(false);
    }
  };

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    var chart = new ApexCharts(
      chartRef.current,
      chartOptions(
        chartColor,
        labels,
        values1,
        values2,
        type,
        chartSecondaryColor,
        compareLocation1.value !== 'All' ? compareLocation1.value : `Average(${compareUser1.value})`,
        compareLocation2.value !== 'All' ? compareLocation2.value : `Average(${compareUser2.value})`
      )
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, values1, labels, values2, type]);

  useEffect(() => {
    loadData(comparison); //dupa range
  }, [comparison, compareLocation1, compareLocation2, dateFilter, hourParameters, compareUser1, compareUser2]);

  const chartOptions = (
    chartColor: string,
    xAxis1: string[],
    yAxis1: number[],
    yAxis2: number[],
    type: ApexChart['type'],
    chartSecondaryColor: string,
    location1: string,
    location2: string
  ): ApexOptions => {
    const labelColor = getCSSVariableValue('--kt-gray-800');
    const strokeColor = getCSSVariableValue('--kt-gray-300');
    const baseColor = getCSSVariableValue('--kt-' + chartColor);
    const secondaryColor = getCSSVariableValue('--kt-' + chartSecondaryColor);
    return {
      series: [
        {
          name: location1,
          data: yAxis1,
        },
        {
          name: location2,
          data: yAxis2,
        },
      ],
      chart: {
        fontFamily: 'inherit',
        type: type,
        height: 350,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {},
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: [baseColor, secondaryColor],
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 0.7,
          opacityTo: 0.5,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor, secondaryColor],
      },
      xaxis: {
        categories: oneDaySelected ? hours : xAxis1,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
        crosshairs: {
          position: 'front',
          stroke: {
            color: baseColor,
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
          formatter: undefined,
          offsetY: 0,
          style: {
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: '12px',
        },
        y: {
          formatter: function (val) {
            return val.toString();
          },
        },
      },
      colors: [baseColor, secondaryColor],
      grid: {
        borderColor: strokeColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: [baseColor, secondaryColor],
        strokeWidth: 3,
      },
    };
  };
  return (
    <div className={`card ${className}`}>
      <div className='card-body p-5 pb-0 d-flex flex-column'>
        {loading && (
          <div className='position-absolute' style={{ right: '0', left: '0' }}>
            <LoadingPage />
          </div>
        )}
        {hasErrors && (
          <div className='position-absolute d-flex flex-column justify-content-center align-items-center h-100 w-100' style={{}}>
            <i className='bi bi-exclamation-triangle text-danger opacity-75' style={{ fontSize: '10rem' }} />
            <span className='fs-8 mt-2 fw-semibold text-gray-400'>An error occured, try refreshing the page.</span>
          </div>
        )}
        <div
          ref={chartRef}
          className={`mixed-widget-3-chart card-rounded-bottom ${loading ? 'opacity-0' : ''}`}
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  );
};

export { ComparisonMixedChart };
