import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CompareWithLocationState {
  value: string
  id: string
}
const initialState: CompareWithLocationState = {
  value: localStorage.getItem('compareWithLocation')!?.toString() != null ? localStorage.getItem('compareWithLocation')!?.toString() : 'null',
  id: localStorage.getItem('compareWithLocationId')!?.toString() != null ? localStorage.getItem('compareWithLocationId')!?.toString() : 'null'
};

export const compareWithLocation = createSlice({
  name: 'compareWithLocation',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<CompareWithLocationState>) => {
      state.value = action.payload.value
      state.id = action.payload.id
      localStorage.setItem("compareWithLocation", action.payload.value)
      localStorage.setItem("compareWithLocationId", action.payload.id)

    },
  },
});

export const { setValue } = compareWithLocation.actions;

export const compareWithLocationReducer = compareWithLocation.reducer;
