import {
  DayWeekMonthYearEnum,
  ShelfStatusColorsEnum,
  ShelfStatusDescriptionEnum,
  ShelfStatusEnum,
  audioAlertsList,
  avgTimeCashRegisterOpenList,
  mostQueueAlertsItemInList,
  mostQueueAlertsList,
  queueDurationList,
} from '../../models';

export function numberFormat(number: number | undefined): string {
  if (number === undefined || number === null) return 'undefined';
  return number.toLocaleString('en-US', { minimumFractionDigits: 0 }).replace(/,/g, ' ');
}

export function percentageFormat(value: number): string {
  const percentage = value * 100;
  return percentage.toFixed(0) + '%';
}

export function dateToShortDateStringFormat(date: Date): string {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const shortString = `${day} ${month}`;
  return shortString;
}

export function mostQueueAlertsFormat(listToFormat: mostQueueAlertsList): mostQueueAlertsList {
  let list: mostQueueAlertsItemInList[] = [];
  listToFormat.forEach((item) => {
    let x = list.find((x) => x.id === item.id && x.name === item.name);
    if (x !== undefined) x.value += item.value;
    else {
      let push: mostQueueAlertsItemInList = { id: item.id, name: item.name, value: item.value, day: item.day };
      list.push(push);
    }
  });
  list.sort((a, b) => b.value - a.value);
  return list;
}

export function exportCSV(headers: string[][], categories: any[], values: any[], day: string): void {
  let categ = [categories];

  let rows = headers;
  for (let i = 0; i < values.length; i++) {
    rows.push([categ[0][i], values[i].toString()]);
  }

  let csvContent = rows.map((row) => row.join(';')).join('\n');
  let blob = new Blob([csvContent], { type: 'text/csv' });
  let url = URL.createObjectURL(blob);

  let link = document.createElement('a');
  link.href = url;
  link.download = day + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function exportBigCSVQueueManagement(
  headers: string[],
  data: audioAlertsList,
  headersQueueAlerts: string[],
  headersAvgTimes: string[],
  dataQueueAlerts: mostQueueAlertsList,
  location: { id: string; name: string },
  user_name: string,
  date: string,
  totalQueueAlerts: string,
  totalAudioAlerts: string,
  responseTimeAll: string,
  queueDurationAll: string,
  responseTimeList: avgTimeCashRegisterOpenList,
  queueDurationList: queueDurationList,
  fileName: string,
  separator: string
): void {
  let rows = [
    ['Queue management'],
    [date],
    [`${location.id} - ${location.name}`],
    [user_name],
    [],
    ['Totals'],
    ['Audio alerts', 'Queue alerts', 'Avg response time', 'Avg queue duration'],
    [totalAudioAlerts, totalQueueAlerts, responseTimeAll, queueDurationAll],
    [],
    ['Audio alerts'],
    headers,
  ];
  for (let i = 0; i < data.length; i++) {
    if (headersQueueAlerts.length !== 3)
      rows.push([
        data[i].location_id.toString(),
        dataQueueAlerts.find((x) => x.id === data[i].location_id)?.name!,
        data[i].total.toString(),
        data[i].time.length > 2
          ? new Date(data[i].time).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })
          : `${data[i].time}:00`,
      ]);
    else
      rows.push([
        data[i].location_id.toString(),
        data[i].total.toString(),
        data[i].time.length > 2
          ? new Date(data[i].time).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })
          : `${data[i].time}:00`,
      ]);
  }
  rows.push([]);
  rows.push(['Queue alerts']);
  rows.push(headersQueueAlerts);
  for (let i = 0; i < dataQueueAlerts.length; i++) {
    if (headersQueueAlerts.length !== 3)
      rows.push([
        dataQueueAlerts[i].id.toString(),
        dataQueueAlerts[i].name,
        dataQueueAlerts[i].value.toString(),
        new Date(dataQueueAlerts[i].day).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }),
      ]);
    else
      rows.push([
        dataQueueAlerts[i].name,
        dataQueueAlerts[i].value.toString(),
        new Date(dataQueueAlerts[i].day).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }),
      ]);
  }
  rows.push([]);
  rows.push(['Avg times']);
  rows.push(headersAvgTimes);
  for (let i = 0; i < responseTimeList.length; i++) {
    rows.push([
      responseTimeList[i].id.toString(),
      responseTimeList[i].name,
      numberToTimeFormat(responseTimeList[i].avgTime),
      numberToTimeFormat(queueDurationList[i].queue_duration),
    ]);
  }
  let csvContent = rows.map((row) => row.join(`${separator}`)).join('\n');

  let blob = new Blob([csvContent], { type: 'text/csv' });
  let url = URL.createObjectURL(blob);

  let link = document.createElement('a');
  link.href = url;
  link.download = fileName + '.csv';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function getTimeInMinutes(timeStr: string): number {
  const parts = timeStr.split(/[ms]/g).filter(Boolean);

  let minutes = 0;
  let seconds = 0;

  if (parts.length === 1 && timeStr.endsWith('s')) {
    seconds = parseInt(parts[0], 10);
  } else if (parts.length === 2) {
    minutes = parseInt(parts[0], 10);
    seconds = parseInt(parts[1], 10);
  }

  if (seconds > 30) {
    return minutes + 1;
  } else {
    return minutes;
  }
}

export function getTimeInExactMinutes(timeStr: string): number {
  // Initialize minutes and seconds to 0
  let minutes = 0;
  let seconds = 0;

  // Check if the string contains "m" (minutes)
  if (timeStr.includes('m')) {
    minutes = parseInt(timeStr.split('m')[0], 10);
    seconds = parseInt(timeStr.split('m')[1].split('s')[0], 10);
  } else if (timeStr.includes('s')) {
    seconds = parseInt(timeStr.split('s')[0], 10);
  }

  const timeInMinutes = minutes + seconds / 60;
  return timeInMinutes;
}

export function numberToTimeFormat(number: number): string {
  let wholeMinutes = Math.floor(number); // extract whole minutes
  let seconds = Math.round((number - wholeMinutes) * 60); // extract seconds and round to nearest integer

  if (isNaN(wholeMinutes) || isNaN(seconds)) {
    return 'undefined';
  }

  if (number < 1) {
    // if it's less than a minute
    return `${seconds}s`;
  } else if (seconds === 0) {
    return `${wholeMinutes}m`;
  } else {
    return `${wholeMinutes}m${seconds}s`;
  }
}

export function bakeryStatusColor(string: string): ShelfStatusColorsEnum {
  return string === ShelfStatusEnum.ALMOST
    ? ShelfStatusColorsEnum.ALMOST
    : string === ShelfStatusEnum.EMPTY
    ? ShelfStatusColorsEnum.EMPTY
    : string === ShelfStatusEnum.FULL
    ? ShelfStatusColorsEnum.FULL
    : string === ShelfStatusEnum.HALF
    ? ShelfStatusColorsEnum.HALF
    : string === ShelfStatusEnum.LOW
    ? ShelfStatusColorsEnum.LOW
    : ShelfStatusColorsEnum.OCCLUSION;
}

export function bakeryStatusDescription(string: string): ShelfStatusDescriptionEnum {
  return string === ShelfStatusEnum.ALMOST
    ? ShelfStatusDescriptionEnum.ALMOST
    : string === ShelfStatusEnum.EMPTY
    ? ShelfStatusDescriptionEnum.EMPTY
    : string === ShelfStatusEnum.FULL
    ? ShelfStatusDescriptionEnum.FULL
    : string === ShelfStatusEnum.HALF
    ? ShelfStatusDescriptionEnum.HALF
    : string === ShelfStatusEnum.LOW
    ? ShelfStatusDescriptionEnum.LOW
    : ShelfStatusDescriptionEnum.OCCLUSION;
}
export function bakeryStatusToNumber(string: string): number {
  return string === ShelfStatusEnum.ALMOST
    ? 3
    : string === ShelfStatusEnum.EMPTY
    ? 0
    : string === ShelfStatusEnum.FULL
    ? 4
    : string === ShelfStatusEnum.HALF
    ? 2
    : string === ShelfStatusEnum.LOW
    ? 1
    : -1;
}

export function bakeryNumberToStatus(number: number): string {
  return number === 3
    ? ShelfStatusEnum.ALMOST
    : number === 0
    ? ShelfStatusEnum.EMPTY
    : number === 4
    ? ShelfStatusEnum.FULL
    : number === 2
    ? ShelfStatusEnum.HALF
    : number === 1
    ? ShelfStatusEnum.LOW
    : ShelfStatusEnum.OCCLUSION;
}

export function bakeryProductNameFormat(string: string): string {
  return string.substring(string.indexOf('_'), string.length).replace(/_/g, ' ');
}

export function bakeryProductId(string: string): string {
  return string.substring(0, string.indexOf('_'));
}

export function LFProductNameFormat(string: string): string {
  return string.replace(/_/g, ' ');
}

export function extractPolygonGeometry(svgString: string): string {
  // 1. Parse the SVG
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgString, 'image/svg+xml');

  // 2. Extract points
  const polygon = svgDoc.querySelector('polygon');
  const pointsAttr = polygon!.getAttribute('points');
  const pointPairs = pointsAttr!.split(' ');

  // 3. Format into WKT string
  const wktPoints = pointPairs.map((pair) => pair.replace(',', ' ')).join(', ');

  console.log(`final polygonPOLYGON((${wktPoints}))`);

  return `POLYGON((${wktPoints}))`;
}

export function convertToSvgString(polygonWKT: string): string {
  //converteste stringul de trimis in API inapoi in forma de afisare
  const polygonRegex = /POLYGON\s*\(\((.*?)\)\)/;
  const match = polygonWKT.match(polygonRegex);

  if (match && match[1]) {
    const pointsString = match[1];
    const pointsArray = pointsString.split(/\s*,\s*/).map((point) => point.split(/\s+/).map(Number));

    const pointsSvgString = pointsArray.map(([x, y]) => `${x},${y}`).join(' ');
    const svgString = `<svg><polygon points="${pointsSvgString}" /></svg>`;
    return svgString;
  }

  return '';
}

export function convertPointsToSvgString(pointsArray: number[][]): string {
  //converteste obiectul din API de forma List<List<int>> spre forma de afisare
  const pointsSvgString = pointsArray.map(([x, y]) => `${x},${y}`).join(' ');
  const svgString = `<svg><polygon points="${pointsSvgString}" /></svg>`;
  return svgString;
}

export function convertStringToEnumValue(value: string): DayWeekMonthYearEnum {
  return DayWeekMonthYearEnum[value.toUpperCase() as keyof typeof DayWeekMonthYearEnum];
}

export function upperCaseFirstLetterOfString(value: string): string {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
}
