import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import addRTConnId from './addRTConnId';

class SignalRService {
  private connection: HubConnection | null;

  constructor() {
    this.connection = null;
    this.startConnection();
  }

  startConnection = () => {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_ALERTS_HUB}`)
      // .withUrl('http://localhost:5005/alerts/alerts-hub') // Replace with your actual SignalR hub URL
      .withAutomaticReconnect()
      .build();

    this.connection
      .start()
      .then(async () => {
        console.log('SignalR Connected');
        console.log('connId', this.connection?.connectionId);
        if (this.connection?.connectionId) await addRTConnId(this.connection.connectionId);
      })
      .catch((err) => console.error('SignalR Connection Error: ', err));
  };

  receiveAlert = (callback: (alert: any) => void) => {
    if (this.connection) {
      this.connection.on('ReceiveAlert', (alert) => {
        callback(alert);
      });
    }
  };

  // If you need to send messages to the server, implement methods here
}

export default new SignalRService();
