import React, { ChangeEvent, useEffect, useState } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import useGet from '../../hooks/Common/useGet';
import getCategories from '../../web_api/methods/categories/getCategories';
import { categoryInList, categoryList } from '../../web_api/models';
import ProduceFormField from './ProduceFormField';
import { v4 as uuid } from 'uuid';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import useModals from '../../hooks/Common/useModals';
import ChangesModal from './ChangesModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { setAddItems } from '../../redux/addItemsReducer';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

enum AddType {
  Category = 'category',
  SubCategory = 'subCategory',
  Produce = 'produce',
}

const AddNewProduce: React.FC = () => {
  const [produce, setProduce] = useState<categoryInList>({
    id: 0,
    name: '',
    temporary_id: '',
    parent_id: 0,
    algorithm_type_id: 0,
    description: null,
    depth: 0,
  });

  const defaultProduce = {
    id: 0,
    name: '',
    parent_id: 0,
    algorithm_type_id: 0,
    description: null,
    depth: 0,
  };

  const { loading, data, loadItems } = useGet([], getCategories);

  const [selectedCategory, setSelectedCategory] = useState<number | string | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | string | null>(null);
  const [selectedProduce, setSelectedProduce] = useState<number | string | null>(null);
  const reduxState = useSelector((state: RootState) => state.addItems);

  const [subCategories, setSubCategories] = useState<categoryList>([]);

  const [showForm, setShowForm] = useState('');
  const [json, setJson] = useState<categoryList>([]);
  const [changes, setChanges] = useState<categoryList>([]);

  const { show, toggleModal } = useModals([false, false]); // 0 for changesModal, 1 for confirmDeleteModal
  const dispatch = useDispatch();

  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('add-new-produce', 'Add New Produce');
  });

  const getItemsForParent = (parent_id: number | string | null) => {
    if (!json) return [];
    if (typeof parent_id === 'string') {
      return json.filter((item) => item.temporary_parent_id === parent_id);
    }
    return json.filter((item) => item.parent_id === parent_id);
  };

  const calculateSubCategories = (parent_id: number | string | null) => {
    if (!json) return [];
    if (typeof parent_id === 'string') {
      setSubCategories(json.filter((item) => item.temporary_parent_id === parent_id));
      return;
    }
    setSubCategories(json.filter((item) => item.parent_id === parent_id));
  };

  useEffect(() => {
    if (reduxState.json.length > 0) {
      setJson(reduxState.json);
    }
    if (reduxState.changes.length > 0) {
      setChanges(reduxState.changes);
    }
    if (reduxState.json.length === 0 && reduxState.changes.length === 0) {
      setJson(data || []);
    }
  }, [data?.length, loading]);

  useEffect(() => {
    calculateSubCategories(selectedCategory);
  }, [json]);

  useEffect(() => {
    console.log('changes', changes);
  }, [changes]);

  useEffect(() => {
    console.log('produce.images', produce.images);
  }, [produce.images]);

  useEffect(() => {
    console.log('produce', produce);
  }, [produce]);

  const handleAddNewCategory = () => {
    setShowForm(AddType.Category);
  };

  const handleAddNewSubCategory = () => {
    setShowForm(AddType.SubCategory);
  };

  const handleAddNewProduce = () => {
    setShowForm(AddType.Produce);
  };

  const getObjectById = (id: number | string | null) => {
    if (!json) return null;
    if (typeof id === 'string') {
      return json.find((item) => item.temporary_id === id);
    }
    return json.find((item) => item.id === id);
  };

  useEffect(() => {
    setProduce(getObjectById(selectedProduce) || produce);
  }, [selectedProduce]);

  useEffect(() => {
    dispatch(setAddItems({ changes, json }));
  }, [changes, json]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log('leaving the page');
      if (changes.length > 0) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        console.log('changes', changes);
        return message; // For modern browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      console.log('removing event listener');
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [changes]);

  const handleSaveChanges = async () => {
    console.log('changes', changes);
    // await addTrainingCategories(changes).then((response) => {
    // console.log('response', response);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedProduce(null);
    setProduce(defaultProduce);
    setChanges([]);
    dispatch(setAddItems({ changes: [], json: [] }));
    loadItems();
    // });
  };

  const handleDiscardChanges = () => {
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setSelectedProduce(null);
    setProduce(defaultProduce);
    setChanges([]);
    loadItems();
    dispatch(setAddItems({ changes: [], json: [] }));
  };

  const handleAddChanges = (item: categoryInList) => {
    const copy = { ...item };
    delete copy.images;
    setChanges((prev) => {
      if (prev.filter((i) => i.temporary_id !== copy.temporary_id || i.id !== copy.id)) {
        prev = prev.filter((i) => i.temporary_id !== copy.temporary_id || i.id !== copy.id);
        return [...prev, copy];
      }
      return [...prev, copy];
    });
    setProduce({
      id: 0,
      name: '',
      parent_id: 0,
      algorithm_type_id: 0,
      description: null,
      depth: 0,
    });
    setSelectedProduce(null);
    json &&
      setJson((prev) => {
        if (prev.filter((i) => i.temporary_id !== item.temporary_id || i.id !== item.id)) {
          prev = prev.filter((i) => i.temporary_id !== item.temporary_id || i.id !== item.id);
          return [...prev, item];
        }
        return [...prev, item];
      });
    setShowForm('');
  };

  const findObjectInList = (obj: categoryInList) => {
    return json?.find(
      (item) =>
        (item.name || '') === (obj.name || '') &&
        item.parent_id === obj.parent_id &&
        item.depth === obj.depth &&
        item.algorithm_type_id === obj.algorithm_type_id &&
        (item.description || '') === (obj.description || '') &&
        item.images === obj.images &&
        Object.keys(item).length === Object.keys(obj).length
    );
  };
  // let blocker = useBlocker(({ currentLocation, nextLocation }) => value !== '' && currentLocation.pathname !== nextLocation.pathname);

  return (
    <div className='d-flex flex-column p-7'>
      {/* Save, Changes and Discard Buttons */}
      <div className='d-flex flex-row justify-content-between'>
        <ReactBootstrap.Button
          variant={`link`}
          type='button'
          className={`btn-lg d-flex flex-row align-items-center`}
          style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
          onClick={() => {
            selectedCategory !== null && selectedSubCategory === null && selectedProduce === null && setSelectedCategory(null);
            selectedCategory !== null && selectedSubCategory !== null && selectedProduce === null && setSelectedSubCategory(null);
            selectedCategory !== null && selectedSubCategory !== null && selectedProduce !== null && setSelectedProduce(null);
          }}
          disabled={selectedCategory === null && selectedSubCategory === null && selectedProduce === null}
        >
          <i className={`bi bi-arrow-left fs-2 me-2`}></i>
        </ReactBootstrap.Button>
        <div className='d-flex flex-row justify-content-end gap-3'>
          {changes.length > 0 && (
            <button
              type='button'
              className='btn btn-sm btn-success d-flex flex-row align-items-center'
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content', alignSelf: 'end' }}
              onClick={handleSaveChanges}
            >
              <i className='bi bi-floppy-fill text-white'></i>
              Save
            </button>
          )}
          <button
            type='button'
            className='btn btn-sm btn-warning position-relative'
            style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content', alignSelf: 'end' }}
            onClick={() => {
              if (changes.length > 0) toggleModal(0);
            }}
          >
            <div className='d-flex flex-row align-items-center'>
              <i className='bi bi-archive'></i>
              Changes
            </div>
            {changes.length > 0 && (
              <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>{changes.length}</span>
            )}
          </button>

          {changes.length > 0 && (
            <button
              type='button'
              className='btn btn-sm btn-danger d-flex flex-row align-items-center'
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content', alignSelf: 'end' }}
              onClick={() => toggleModal(1)}
            >
              <i className='bi bi-trash text-white'></i>
              Discard
            </button>
          )}
        </div>
      </div>
      {/* Categories List */}
      {selectedCategory === null && (
        <div className='d-flex flex-column gap-2 ps-3 pb-5'>
          <ReactBootstrap.Button disabled={true} variant='link' style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}>
            Categories
          </ReactBootstrap.Button>
          <div className='d-flex flex-row flex-wrap gap-1'>
            {(getItemsForParent(null) || []).length === 0 && (
              <ReactBootstrap.Button variant='light' className='w-auto bg-white rounded p-7' onClick={() => {}} disabled>
                No items found.
              </ReactBootstrap.Button>
            )}
            {getItemsForParent(null).map((item: categoryInList) => (
              <ReactBootstrap.Button
                variant='light'
                className='w-auto bg-white rounded p-7'
                key={item.id || item.temporary_id}
                onClick={() => {
                  setSelectedCategory(item.id || item.temporary_id || null);
                  calculateSubCategories(item.id || item.temporary_id || null);
                  setShowForm('');
                }}
              >
                {item.name}
              </ReactBootstrap.Button>
            ))}
            <ReactBootstrap.Button
              variant='secondary'
              className='w-auto rounded p-7'
              key={produce.id}
              onClick={() => {
                handleAddNewCategory();
              }}
            >
              <i className='bi bi-plus-lg fs-3' />
              Add New
            </ReactBootstrap.Button>
          </div>
        </div>
      )}
      {/* Sub Categories List */}
      {selectedCategory !== null && selectedSubCategory === null && (
        <div className='d-flex flex-column gap-2 ps-3 pb-5'>
          <div className='d-flex flex-row gap-3 align-items-center'>
            <ReactBootstrap.Button
              variant='link'
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
              onClick={() => {
                setSelectedCategory(null);
                setSelectedProduce(null);
                setProduce(defaultProduce);
                setShowForm('');
              }}
            >
              {getObjectById(selectedCategory)!.name}
            </ReactBootstrap.Button>
            <i className='bi bi-arrow-right'></i>
            <ReactBootstrap.Button variant='link' disabled={true} style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}>
              Sub Categories
            </ReactBootstrap.Button>
          </div>
          <div className='d-flex flex-row flex-wrap gap-1'>
            {(subCategories || []).length === 0 && (
              <ReactBootstrap.Button variant='light' className='w-auto bg-white rounded p-7' onClick={() => {}} disabled>
                No items found.
              </ReactBootstrap.Button>
            )}
            {subCategories.map((item: categoryInList) => (
              <ReactBootstrap.Button
                variant='light'
                className='w-auto bg-white rounded p-7'
                key={item.id || item.temporary_id}
                onClick={() => {
                  setSelectedSubCategory(item.id || item.temporary_id || null);
                  setShowForm('');
                }}
              >
                {item.name}
              </ReactBootstrap.Button>
            ))}
            <ReactBootstrap.Button
              variant='secondary'
              className='w-auto rounded p-7'
              key={produce.id}
              onClick={() => {
                handleAddNewSubCategory();
              }}
            >
              <i className='bi bi-plus-lg fs-3' />
              Add New
            </ReactBootstrap.Button>
          </div>
        </div>
      )}
      {/* Produce List */}
      {selectedCategory !== null && selectedSubCategory !== null && (
        <div className='d-flex flex-column gap-2 ps-3  pb-5'>
          <div className='d-flex flex-row gap-3 align-items-center'>
            <ReactBootstrap.Button
              variant='link'
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
              onClick={() => {
                setSelectedCategory(null);
                setSelectedSubCategory(null);
                setSelectedProduce(null);
                setProduce(defaultProduce);
              }}
            >
              {getObjectById(selectedCategory)!.name}
            </ReactBootstrap.Button>
            <i className='bi bi-arrow-right'></i>
            <ReactBootstrap.Button
              variant='link'
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
              onClick={() => {
                setSelectedSubCategory(null);
                setSelectedProduce(null);
                setProduce(defaultProduce);
              }}
            >
              {getObjectById(selectedSubCategory)!.name}
            </ReactBootstrap.Button>
            <i className='bi bi-arrow-right'></i>
            <ReactBootstrap.Button variant='link' disabled={true} style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}>
              Items
            </ReactBootstrap.Button>
          </div>
          <div className='d-flex flex-row flex-wrap gap-1'>
            {(getItemsForParent(selectedSubCategory) || []).length === 0 && (
              <ReactBootstrap.Button variant='light' className='w-auto bg-white rounded p-7' onClick={() => {}} disabled>
                No items found.
              </ReactBootstrap.Button>
            )}
            {getItemsForParent(selectedSubCategory).map((item: categoryInList) => (
              <ReactBootstrap.Button
                variant='light'
                className='w-auto bg-white rounded p-7'
                key={item.id || item.temporary_id}
                onClick={() => {
                  setSelectedProduce(item.id || item.temporary_id || null);
                }}
              >
                {item.name}
              </ReactBootstrap.Button>
            ))}
            <ReactBootstrap.Button
              variant='secondary'
              className='w-auto rounded p-7'
              key={produce.id}
              onClick={() => {
                handleAddNewProduce();
                setSelectedProduce(null);
                setProduce(defaultProduce);
              }}
            >
              <i className='bi bi-plus-lg fs-3' />
              Add New
            </ReactBootstrap.Button>
          </div>
        </div>
      )}
      {/* Produce Form */}
      {selectedProduce !== null && (
        <div className='bg-white px-7 pb-7 pt-2 rounded ms-3 d-flex flex-row gap-5 align-items-center'>
          <div className='col-md-4 border-end border-2 border-black pe-3'>
            <ReactBootstrap.Button
              variant={`link`}
              type='button'
              className={`btn-lg d-flex flex-row align-items-center`}
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
              onClick={() => {
                setProduce(defaultProduce);
                setSelectedProduce(null);
              }}
            >
              <i className={`bi bi-arrow-left fs-2 me-2`}></i>
            </ReactBootstrap.Button>
            <ProduceFormField title='Category Name' value={getObjectById(selectedCategory)!.name} disabled={true} />
            <ProduceFormField title='Sub Category name' value={getObjectById(selectedSubCategory)!.name} disabled={true} />
            <ProduceFormField
              title='Produce Name'
              value={produce.name}
              onChange={(e) =>
                setProduce({
                  ...produce,
                  name: e.target.value,
                })
              }
            />
            <ProduceFormField
              title='Description'
              value={produce.description || ''}
              onChange={(e) =>
                setProduce({
                  ...produce,
                  description: e.target.value,
                })
              }
            />
            <div className='d-flex flex-row w-100 justify-content-between '>
              <ReactBootstrap.Button
                variant={`danger`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center mt-5`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => {
                  setProduce(defaultProduce);
                  setSelectedProduce(null);
                }}
              >
                <i className={`bi bi-arrow-left fs-2 me-2`}></i>
                Abandon
              </ReactBootstrap.Button>
              <ReactBootstrap.Button
                variant={`${!findObjectInList(produce) ? 'primary' : 'secondary'}`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center mt-5`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => handleAddChanges(produce)}
                disabled={findObjectInList(produce) ? true : false}
              >
                <i className={`bi ${findObjectInList(produce) ? 'bi-lock-fill' : 'bi-save'} fs-2 me-2`}></i>
                Save Changes
              </ReactBootstrap.Button>
            </div>
          </div>
          <div className={`col-md-8 pe-3 d-flex flex-column ${!produce.images ? 'justify-content-center' : 'justify-content-between'} h-100`}>
            {produce.images && (
              <div className='d-flex flex-row gap-3 flex-wrap pb-5 pt-3'>
                {produce.images.map((image: File, index: number) => (
                  <div key={index} className='position-relative d-flex flex-row w-100px me-2'>
                    <img src={URL.createObjectURL(image)} alt='produce' style={{ maxWidth: '100px', width: 'auto', height: 'auto' }} />
                    <span
                      className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'
                      onClick={() => {
                        setProduce({ ...produce, images: produce.images!.filter((img, i) => i !== index) });
                      }}
                    >
                      <i className='bi bi-trash text-white'></i>
                    </span>
                  </div>
                ))}
              </div>
            )}
            <div>
              <Form.Group controlId='formFile' className={`mb-3 ${produce.images ? 'border-top border-black border-2 pt-2' : ''}`}>
                <Form.Label>Add Pictures</Form.Label>
                <Form.Control
                  type='file'
                  multiple
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    console.log('event', event.currentTarget.files);
                    if (event.currentTarget.files) {
                      for (let i = 0; i < event.currentTarget.files.length; i++) {
                        const formData = new FormData();
                        formData.append(event.currentTarget.files[i].name, event.currentTarget.files[i]);
                        console.log('formData', i, event);
                        const temp = event.currentTarget.files[i];
                        // await addTrainingPicture(formData).then((response) => {
                        setProduce((prev) => {
                          console.log('here', i, event);
                          return {
                            ...prev,
                            images: [...(prev.images || []), temp],
                            temp_images: [...(prev.temp_images || []), temp.name], //response
                          };
                        });
                        // });
                        // setFilesToUpload(event.currentTarget.files);
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </div>
      )}
      {/* Add Category Form */}
      {showForm.length > 0 && showForm === AddType.Category && selectedSubCategory === null && selectedProduce === null && (
        <div className='bg-white px-7 pb-7 pt-2 rounded ms-3'>
          <div className='col-md-4 border-end border-2 border-black pe-3'>
            <ReactBootstrap.Button
              variant={`link`}
              type='button'
              className={`btn-lg d-flex flex-row align-items-center`}
              style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
              onClick={() => {
                setProduce(defaultProduce);
                setSelectedProduce(null);
                setSelectedCategory(null);
                setSelectedSubCategory(null);
                setShowForm('');
              }}
            >
              <i className={`bi bi-arrow-left fs-2 me-2`}></i>
            </ReactBootstrap.Button>
            <ProduceFormField
              title='Category Name'
              value={produce.name}
              onChange={(e) =>
                setProduce({ name: e.target.value, temporary_id: uuid(), algorithm_type_id: 76, depth: 0, description: null, parent_id: null })
              }
              autofocus={true}
            />
            <div className='d-flex flex-row w-100 justify-content-between '>
              <ReactBootstrap.Button
                variant={`danger`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center mt-5`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => {
                  setProduce(defaultProduce);
                  setSelectedProduce(null);
                  setSelectedProduce(null);
                  setSelectedCategory(null);
                  setSelectedSubCategory(null);
                  setShowForm('');
                }}
              >
                <i className={`bi bi-arrow-left fs-2 me-2`}></i>
                Abandon
              </ReactBootstrap.Button>
              <ReactBootstrap.Button
                variant={`${produce.name.length > 0 ? 'primary' : 'secondary'}`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center mt-5`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => handleAddChanges(produce)}
                disabled={!(produce.name.length > 0) ? true : false}
              >
                <i className={`bi ${!(produce.name.length > 0) ? 'bi-lock-fill' : 'bi-save'} fs-2 me-2`}></i>
                Save Changes
              </ReactBootstrap.Button>
            </div>
          </div>
        </div>
      )}
      {/* Add Sub Category Form */}
      {showForm.length > 0 &&
        showForm === AddType.SubCategory &&
        selectedSubCategory === null &&
        selectedCategory !== null &&
        selectedProduce === null && (
          <div className='bg-white px-7 pb-7 pt-2 rounded ms-3'>
            <div className='col-md-4 border-end border-2 border-black pe-3'>
              <ReactBootstrap.Button
                variant={`link`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => {
                  setProduce(defaultProduce);
                  setSelectedProduce(null);
                  setShowForm('');
                }}
              >
                <i className={`bi bi-arrow-left fs-2 me-2`}></i>
              </ReactBootstrap.Button>
              <ProduceFormField
                title='Sub Category Name'
                value={produce.name}
                onChange={(e) => {
                  if (typeof selectedCategory === 'string') {
                    setProduce({
                      temporary_id: uuid(),
                      name: e.target.value,
                      algorithm_type_id: 76,
                      depth: getObjectById(selectedCategory)!.depth + 1,
                      description: null,
                      temporary_parent_id: selectedCategory,
                    });
                  } else {
                    setProduce({
                      temporary_id: uuid(),
                      name: e.target.value,
                      algorithm_type_id: 76,
                      depth: getObjectById(selectedCategory)!.depth + 1,
                      description: null,
                      parent_id: selectedCategory,
                    });
                  }
                }}
                autofocus={true}
              />
              <div className='d-flex flex-row w-100 justify-content-between '>
                <ReactBootstrap.Button
                  variant={`danger`}
                  type='button'
                  className={`btn-lg d-flex flex-row align-items-center mt-5`}
                  style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                  onClick={() => {
                    setProduce(defaultProduce);
                    setSelectedProduce(null);
                    setShowForm('');
                  }}
                >
                  <i className={`bi bi-arrow-left fs-2 me-2`}></i>
                  Abandon
                </ReactBootstrap.Button>
                <ReactBootstrap.Button
                  variant={`${produce.name.length > 0 ? 'primary' : 'secondary'}`}
                  type='button'
                  className={`btn-lg d-flex flex-row align-items-center mt-5`}
                  style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                  onClick={() => handleAddChanges(produce)}
                  disabled={!(produce.name.length > 0) ? true : false}
                >
                  <i className={`bi ${!(produce.name.length > 0) ? 'bi-lock-fill' : 'bi-save'} fs-2 me-2`}></i>
                  Save Changes
                </ReactBootstrap.Button>
              </div>
            </div>
          </div>
        )}
      {/* Add Produce Form */}
      {showForm.length > 0 &&
        showForm === AddType.Produce &&
        selectedSubCategory !== null &&
        selectedCategory !== null &&
        selectedProduce === null && (
          <div className='bg-white px-7 pb-7 pt-2 rounded ms-3'>
            <div className='col-md-4 border-end border-2 border-black pe-3'>
              <ReactBootstrap.Button
                variant={`link`}
                type='button'
                className={`btn-lg d-flex flex-row align-items-center`}
                style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                onClick={() => {
                  setProduce(defaultProduce);
                  setSelectedProduce(null);
                  setShowForm('');
                }}
              >
                <i className={`bi bi-arrow-left fs-2 me-2`}></i>
              </ReactBootstrap.Button>
              <ProduceFormField
                title='Produce Name'
                value={produce.name}
                onChange={(e) => {
                  if (typeof selectedSubCategory === 'string') {
                    setProduce({
                      temporary_id: uuid(),
                      name: e.target.value,
                      algorithm_type_id: 76,
                      depth: getObjectById(selectedSubCategory)!.depth + 1,
                      description: null,
                      temporary_parent_id: selectedSubCategory,
                    });
                  } else {
                    setProduce({
                      temporary_id: uuid(),
                      name: e.target.value,
                      algorithm_type_id: 76,
                      depth: getObjectById(selectedSubCategory)!.depth + 1,
                      description: null,
                      parent_id: selectedSubCategory,
                    });
                  }
                }}
                autofocus={true}
              />
              <div className='d-flex flex-row w-100 justify-content-between '>
                <ReactBootstrap.Button
                  variant={`danger`}
                  type='button'
                  className={`btn-lg d-flex flex-row align-items-center mt-5`}
                  style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                  onClick={() => {
                    setProduce(defaultProduce);
                    setSelectedProduce(null);
                    setShowForm('');
                  }}
                >
                  <i className={`bi bi-arrow-left fs-2 me-2`}></i>
                  Abandon
                </ReactBootstrap.Button>
                <ReactBootstrap.Button
                  variant={`${produce.name.length > 0 ? 'primary' : 'secondary'}`}
                  type='button'
                  className={`btn-lg d-flex flex-row align-items-center mt-5`}
                  style={{ maxWidth: '200px', width: 'fit-content', height: 'fit-content' }}
                  onClick={() => handleAddChanges(produce)}
                  disabled={!(produce.name.length > 0) ? true : false}
                >
                  <i className={`bi ${!(produce.name.length > 0) ? 'bi-lock-fill' : 'bi-save'} fs-2 me-2`}></i>
                  Save Changes
                </ReactBootstrap.Button>
              </div>
            </div>
          </div>
        )}
      {/* Changes Modal and Confirm Delete Modal */}
      <ChangesModal show={show[0]} onModalToggle={() => toggleModal(0)} changes={changes} getObjectById={getObjectById} />
      <ConfirmDeleteModal show={show[1]} onModalToggle={() => toggleModal(1)} onDelete={handleDiscardChanges} />
    </div>
  );
};

export default AddNewProduce;
