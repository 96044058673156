import React, { ChangeEventHandler } from 'react';
import { FormControlProps } from 'react-bootstrap';

type CommonFormFieldProps = {
  containerClass?: string;
  labelClass?: string;
  inputClass?: string;
  feedbackClass?: string;
  id: string;
  label: string;
  type: string;
  placeholder: string;
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  invalidFeedback?: string;
  disabled?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  endIcon?: string;
  startIcon?: string;
  autofocus?: boolean;
  step?: string;
};

const CommonFormField: React.FC<CommonFormFieldProps> = ({
  containerClass,
  labelClass,
  inputClass,
  feedbackClass,
  id,
  label,
  placeholder,
  type,
  value,
  onChange,
  invalidFeedback,
  disabled = false,
  onKeyDown,
  endIcon,
  startIcon,
  autofocus,
  step = 'any',
}) => {
  const isInvalid = inputClass?.includes('is-invalid');
  const feedbackVisibilityClass = isInvalid ? '' : 'invisible';

  var input = document.getElementById(id);

  input?.addEventListener('keypress', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  });

  return (
    <div className={`fv-row d-flex flex-wrap ${containerClass}`}>
      <label className={`form-label fw-bolder text-dark fs-6 mb-2 ${labelClass}`} htmlFor={id}>
        {label}
      </label>
      {startIcon && !endIcon && (
        <div className='input-group'>
          <span className='input-group-text'>
            <i className={startIcon} />
          </span>
          <input
            className={`form-control bg-transparent border ${inputClass}`}
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
            autoFocus={autofocus}
            step={step}
          />
        </div>
      )}
      {endIcon && !startIcon && (
        <div className='input-group'>
          <input
            className={`form-control bg-transparent border ${inputClass}`}
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
            autoFocus={autofocus}
            step={step}
          />
          <span className='input-group-text'>
            <i className={endIcon} />
          </span>
        </div>
      )}
      {endIcon && startIcon && (
        <div className='input-group'>
          <span className='input-group-text'>
            <i className={startIcon} />
          </span>
          <input
            className={`form-control bg-transparent border ${inputClass}`}
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoFocus={autofocus}
            onKeyDown={onKeyDown}
            step={step}
          />
          <span className='input-group-text'>
            <i className={endIcon} />
          </span>
        </div>
      )}
      {!endIcon && !startIcon && (
        <div className='input-group'>
          <input
            className={`form-control bg-transparent border ${inputClass}`}
            type={type}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            onKeyDown={onKeyDown}
            autoFocus={autofocus}
            step={step}
          />
        </div>
      )}
      <div className={`text-danger text-start ms-3 mt-1 fs-9 ${feedbackClass} ${feedbackVisibilityClass}`}>{invalidFeedback}</div>
    </div>
  );
};

export default CommonFormField;
