// @ts-nocheck
import { useEffect, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import jsonFile from './geoBoundaries-ROU-ADM1_full.json';
import statistics from './statisticsDummy.json';
import { scaleQuantile } from 'd3-scale';
import * as d3 from 'd3';
import { Tooltip } from 'react-tooltip';
import { Metric } from '../../layout/ToolbarStatistics';
import { RootState } from '../../redux/store';

const MapStatistics = () => {
  const [content, setContent] = useState<any>();
  const selectedMetric = useSelector((state: RootState) => state.selectedMetric);

  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('statistics', `Statistics Map`);
  });

  const [region, setRegion] = useState<any>(null);

  const [analysisData, setAnalysisData] = useState<any[]>(statistics.map((s) => ({ county: s.county, value: s.avg_queue_duration })));

  const colorScale = scaleQuantile(
    analysisData.map((d) => d.value),
    d3.schemeBlues[9]
  );

  useEffect(() => {
    switch (selectedMetric.value) {
      case 'avg-queue-duration':
        setAnalysisData(statistics.map((s) => ({ ...s, value: s.avg_queue_duration })));
        break;
      case 'avg-response-time':
        setAnalysisData(statistics.map((s) => ({ ...s, value: s.avg_response_time })));
        break;
      case 'avg-lf-missing-products':
        setAnalysisData(statistics.map((s) => ({ ...s, value: s.avg_lf_missing_products })));
        break;
      case 'avg-bakery-missing-products':
        setAnalysisData(statistics.map((s) => ({ ...s, value: s.avg_bakery_missing_products })));
        break;
      default:
        setAnalysisData(statistics.map((s) => ({ ...s, value: s.avg_queue_duration })));
        break;
    }
  }, [selectedMetric]);

  const makePascalCase = (str: string) => {
    const string = str.replace(/(\w)(\w*)/g, function (g0, g1, g2) {
      return g1.toUpperCase() + g2.toLowerCase();
    });
    return string.replaceAll('-', ' ');
  };

  const width = 1000;

  return (
    <div className='d-flex flex-column justify-content-between w-100 h-100'>
      <div id='map'>
        <ComposableMap
          width={width}
          height={405}
          projection={'geoMercator'}
          projectionConfig={{
            rotate: [-25.0, -45.5, 0],
            scale: 5000,
            center: [0.2, 0.45],
          }}
        >
          <Geographies geography={jsonFile}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const cur = analysisData.find((s) => s.county.toLowerCase() === geo.properties.shapeName.toLowerCase());

                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke='#EAEAEC'
                    amplitude={4}
                    bbox={0}
                    onClick={() => {
                      setRegion(cur);
                    }}
                    onMouseEnter={() => {
                      setContent(cur);
                    }}
                    onMouseLeave={() => {
                      setContent('');
                    }}
                    style={{
                      default: { fill: cur ? colorScale(cur.value) : '#808080', outline: 'none' },
                      hover: { fill: '#1e1e2d', outline: 'none' },
                      pressed: { fill: '#02A', outline: 'none' },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ComposableMap>
        <Tooltip
          anchorSelect='#map'
          id='tooltip'
          float
          content={content}
          render={({ content, activeAnchor }) => {
            if (content === null || !activeAnchor) return null;
            return (
              <span className='text-light p-2 rounded-3'>
                {`(${content.overall_rank}) `}
                {makePascalCase(selectedMetric.value)} :{' '}
                {selectedMetric.value === Metric.BAKERY
                  ? content.avg_bakery_missing_products
                  : selectedMetric.value === Metric.QUEUE
                  ? content.avg_queue_duration
                  : selectedMetric.value === Metric.LF
                  ? content.avg_lf_missing_products
                  : content.avg_response_time}{' '}
                {selectedMetric.value === Metric.QUEUE || selectedMetric.value === Metric.RESPONSE ? 'minutes' : 'products missing'}
              </span>
            );
          }}
        />
      </div>
      {region && (
        <div className='p-3 bg-secondary'>
          <div className='w-100 card shadow-sm bg-light p-5 pt-3 pb-3 d-flex flex-column bg-secondary-subtle' id='details'>
            <div className='d-flex flex-row align-items-center justify-content-between'>
              <div className='h2'>{`${region.county} (${region.overall_rank} overall rank)`}</div>
              <i
                className='bi bi-x-lg fs-3 text-black bg-secondary p-1 rounded'
                // bgcolor grey on hover
                style={{ cursor: 'pointer', boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.5)' }}
                onClick={() => setRegion('')}
              />
            </div>
            <div className='d-flex flex-row gap-2'>
              {/* Ranks */}
              <div className='d-flex flex-column col-md-4 gap-2 pe-3'>
                <div className='h3'>Ranks</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>Metric 1: {region.rank_queue_duration}</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>Metric 2: {region.rank_response_time}</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>
                  Metric 3: {region.rank_bakery_missing_products}
                </div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>
                  Metric 4: {region.rank_lf_missing_products}
                </div>
              </div>
              {/* Averages */}
              <div className='d-flex flex-column col-md-4 gap-2 pe-3'>
                <div className='h3'>Averages</div>
                <div
                  className={`fs-4 d-inline-flex flex-row align-items-center gap-2 card align-self-start px-3 bg-secondary text-secondary-emphasis`}
                >
                  Metric 1:
                  <div className={`${region.growth_queue_duration < 0 ? 'text-success' : 'text-danger'}`}>{region.avg_queue_duration} minutes</div>
                  <i className={`bi ${region.growth_queue_duration < 0 ? 'bi-arrow-down text-success' : 'bi-arrow-up text-danger'}`} />
                </div>
                <div
                  className={`fs-4 d-inline-flex flex-row align-items-center gap-2 card align-self-start px-3 bg-secondary text-secondary-emphasis`}
                >
                  Metric 2:
                  <div className={`${region.growth_response_time < 0 ? 'text-success' : 'text-danger'}`}>{region.avg_response_time} minutes</div>
                  <i className={`bi ${region.growth_response_time < 0 ? 'bi-arrow-down text-success' : 'bi-arrow-up text-danger'}`} />
                </div>
                <div
                  className={`fs-4 d-inline-flex flex-row align-items-center gap-2 card align-self-start px-3 bg-secondary text-secondary-emphasis`}
                >
                  Metric 3:
                  <div className={`${region.growth_bakery_missing_products < 0 ? 'text-success' : 'text-danger'}`}>
                    {region.avg_bakery_missing_products} missing products
                  </div>
                  <i className={`bi ${region.growth_bakery_missing_products < 0 ? 'bi-arrow-down text-success' : 'bi-arrow-up text-danger'}`} />
                </div>
                <div
                  className={`fs-4 d-inline-flex flex-row align-items-center gap-2 card align-self-start px-3 bg-secondary text-secondary-emphasis`}
                >
                  Metric 4:
                  <div className={`${region.growth_lf_missing_products < 0 ? 'text-success' : 'text-danger'}`}>
                    {region.avg_lf_missing_products} missing products
                  </div>
                  <i className={`bi ${region.growth_lf_missing_products < 0 ? 'bi-arrow-down text-success' : 'bi-arrow-up text-danger'}`} />
                </div>
              </div>
              {/* Totals */}
              <div className='d-flex flex-column col-md-4 gap-2'>
                <div className='h3'>Totals</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>Metric 1: {region.total_queue_alerts}</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>Metric 2: {region.total_audio_alerts}</div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>
                  Metric 3: {region.total_bakery_missing_products} products missing
                </div>
                <div className='fs-4 card align-self-start px-3 bg-secondary text-secondary-emphasis'>
                  Metric 4: {region.total_lf_missing_products} products missing
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(MapStatistics);
