import { Link, useParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { RootState } from '../../redux/store';
import LoadingPage from '../../layout/LoadingPage';
import { Button, Card, Form } from 'react-bootstrap';
import useModals from '../../hooks/Common/useModals';
import { CommonModal } from '../Common/CommonModal';
import {
  TrainingStatus,
  polygonObjectList,
  trainingPictureList,
  trainingPictureWithPolygons,
  trainingSession,
  trainingSessionList,
  trainingSessionWithPictures,
  trainingSessionWithPicturesList,
} from '../../web_api/models';
import CommonAnnotatedImageRect from '../Common/CommonAnnotatedImageRect';
import { toast } from 'react-toastify';
import { setPictures, setObjectClasses, setTrainings, setAnnotationType } from '../../redux/trainingReducer';
import getTrainingImages from '../../web_api/methods/training/getTrainingImages';
import getTrainingModels from '../../web_api/methods/training/getTrainingModels';
import getTrainingObjectClasses from '../../web_api/methods/training/getTrainingObjectClasses';
import addTrainingPicture from '../../web_api/methods/training/addTrainingImage';
import pictureDefault from '../../assets/images/training/50fa49c4-13e0-4594-9024-f29df1b14e3c/f35c2b03-a8f8-4f26-a50f-6b5e183eaccf.jpg';
import addTrainingDetectionRegions from '../../web_api/methods/training/addTrainingDetectionRegions';
import deleteTrainingDetectionRegions from '../../web_api/methods/training/deleteTrainingDetectionRegions';
import modifyTrainingDetectionregions from '../../web_api/methods/training/modifyTrainingDetectionRegions';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import addTrainingSession from '../../web_api/methods/training/addTrainingSession';
import modifyTrainingSession from '../../web_api/methods/training/modifyTrainingSession';

const TrainingInfo = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('detections-info', `Detections - ${id}`);
  });
  const params = useParams();
  const dispatch = useDispatch();

  const id = Number(params.id);

  const trainings = useSelector((state: RootState) => state.training.trainings);
  const object_classes = useSelector((state: RootState) => state.training.object_classes);
  const trainingPics = useSelector((state: RootState) => state.training.pictures);
  const annotationType = useSelector((state: RootState) => state.training.annotation_type);

  const [trainingPastRuns, setTrainingPastRuns] = useState<trainingSessionWithPicturesList>([]);

  const { show, toggleModal } = useModals([false, false]); // 0: annotate picture, 1: edit past training

  const currentTraining = trainings.find((training) => training.id === id);
  const [selectedImage, setSelectedImage] = useState<trainingPictureWithPolygons>();
  const [selectedImages, setSelectedImages] = useState<trainingPictureWithPolygons[]>([]);

  const [selectedPastRun, setSelectedPastRun] = useState<trainingSessionWithPictures>({
    session: {
      id: 0,
      created_date: '',
      loss: 0,
      accuracy: 0,
      epochs: 0,
      model_id: 0,
      epochs_ran: 0,
      status: TrainingStatus.PREPARING,
    },
    pictures: [],
  });

  const [save, setSave] = useState(false);

  useEffect(() => {
    const getInitialData = async () => {
      const trainings = await getTrainingModels();
      dispatch(setTrainings(trainings));
      const pastRuns = trainings.find((training) => training.id === id)!.training_past_runs || [];
      const newPastRuns: trainingSessionWithPicturesList = [];
      if (pastRuns.length > 0) {
        pastRuns.forEach((pastRun) => {
          const newPictures: trainingPictureList = [];
          if (pastRun.pictures.length > 0) {
            pastRun.pictures.forEach(async (pic) => {
              const newFileName = await computeUrl(pic.filename);
              newPictures.push({ ...pic, filename: newFileName });
            });
          }
          newPastRuns.push({ session: pastRun.session, pictures: newPictures });
        });
      }
      setTrainingPastRuns(newPastRuns);
      const pics = await getTrainingImages(id);
      for (const pic of pics) {
        pic.filename = await computeUrl(pic.filename);
      }
      console.log('trainingPastRuns', trainingPastRuns);
      dispatch(setPictures(pics));
      if (object_classes.length === 0) {
        const object_classes_response = await getTrainingObjectClasses();
        dispatch(setObjectClasses(object_classes_response));
      }
    };
    getInitialData();
  }, []);

  const parseDate = (date: string) => {
    // from 30/04/2024 to 2024-04-30
    const [trueDate, time] = date.split('T');
    return trueDate;
  };

  const saveChanges = (newPolygons: polygonObjectList, changedPolygons: polygonObjectList, deletedPolygons: polygonObjectList, img: string) => {
    if (save && trainingPics) {
      const obj = trainingPics.find((picture) => picture.filename === img);

      const originalPolygons = [...obj!.polygons];

      console.log('originalPolygons', originalPolygons);

      const addedPolygons = newPolygons;

      console.log('addedPolygons', addedPolygons);

      console.log('deletedPolygons', deletedPolygons);

      const deletedPolygonIds = deletedPolygons.map((polygon) => polygon.id);

      const updatedPolygons = changedPolygons;

      const errors: string[] = [];

      const handleAddAnnotation = async () => {
        try {
          if (!selectedImage) return;
          await addTrainingDetectionRegions(selectedImage?.id, addedPolygons);
          dispatch(
            setPictures(
              trainingPics.map((picture) => (picture.filename === img ? { ...picture, polygons: [...picture.polygons, ...newPolygons] } : picture))
            )
          );
        } catch (error) {
          errors.push(`${error}`);
        }
      };

      const handleDeleteAnnotation = async () => {
        try {
          await deleteTrainingDetectionRegions(deletedPolygonIds);
          dispatch(
            setPictures(
              trainingPics.map((picture) =>
                picture.filename === img
                  ? { ...picture, polygons: picture.polygons.filter((polygon) => !deletedPolygonIds.includes(polygon.id)) }
                  : picture
              )
            )
          );
        } catch (error) {
          errors.push(`${error}`);
        }
      };

      const handleUpdateAnnotation = async () => {
        try {
          if (!selectedImage) return;
          await modifyTrainingDetectionregions(selectedImage?.id, updatedPolygons);
          dispatch(
            setPictures(
              trainingPics.map((picture) =>
                picture.filename === img ? { ...picture, polygons: [...picture.polygons, ...updatedPolygons] } : picture
              )
            )
          );
        } catch (error) {
          errors.push(`${error}`);
        }
      };

      if (addedPolygons.length > 0) {
        handleAddAnnotation();
      }

      if (deletedPolygons.length > 0) {
        handleDeleteAnnotation();
      }

      if (updatedPolygons.length > 0) {
        handleUpdateAnnotation();
      }

      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(error, {
            position: 'top-right',
            theme: 'colored',
            autoClose: 2000,
          });
        });
      } else if (addedPolygons.length > 0 || deletedPolygons.length > 0 || updatedPolygons.length > 0) {
        toast.success('Changes saved successfully!', {
          position: 'top-right',
          theme: 'colored',
          autoClose: 2000,
        });
      }
      setSave(false);
    }
  };

  const computeUrl = async (filename: string) => {
    const res = await getAzureFile(filename).then((res) => res);
    return res;
  };

  const handleSave = () => {
    setSave(true);
  };

  const toggleLeft = () => {
    const currentPicIndex = trainingPics.findIndex((pic) => pic.filename === selectedImage?.filename);
    if (currentPicIndex > 0) {
      setSave(true);
      setTimeout(() => {
        setSelectedImage(undefined);
        setTimeout(() => {
          setSelectedImage(trainingPics[currentPicIndex - 1]);
        }, 200);
      }, 200);
    }
  };

  const toggleRight = () => {
    const currentPicIndex = trainingPics.findIndex((pic) => pic.filename === selectedImage?.filename);
    if (currentPicIndex < trainingPics.length - 1) {
      setSave(true);
      setTimeout(() => {
        setSelectedImage(undefined);
        setTimeout(() => {
          setSelectedImage(trainingPics[currentPicIndex + 1]);
        }, 200);
      }, 200);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!show[0]) {
        return;
      }
      if (event.key === 'a' || event.key === 'ArrowLeft') {
        toggleLeft();
      } else if (event.key === 'd' || event.key === 'ArrowRight') {
        toggleRight();
      } else if (event.key === 's' || event.key === 'S') {
        handleSave();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [show[0], selectedImage]);

  const handleCreatePastRun = async () => {
    const newPastRun: trainingSessionWithPictures = {
      session: {
        id: 0,
        created_date: new Date().toISOString(),
        loss: 0,
        accuracy: 0,
        epochs: 0,
        model_id: id,
        epochs_ran: 0,
        status: TrainingStatus.PREPARING,
      },
      pictures: selectedImages.map((pic) => {
        return { id: pic.id, filename: pic.filename, scope: pic.scope };
      }),
    };
    await addTrainingSession(
      newPastRun.session.loss,
      newPastRun.session.accuracy,
      newPastRun.session.model_id,
      newPastRun.session.epochs,
      selectedImages.map((pic) => pic.id)
    );
    setTrainingPastRuns([...trainingPastRuns, newPastRun]);
    dispatch(setTrainings(trainings.map((training) => (training.id === id ? { ...training, training_past_runs: trainingPastRuns } : training))));
  };

  const handleRunTraining = () => {
    const file = new File([JSON.stringify(selectedImages)], 'annotations.json', {
      type: 'text/plain',
    });

    const link = document.createElement('a');
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    handleCreatePastRun();
  };

  const handleChangePastRun = async () => {
    await modifyTrainingSession(selectedPastRun.session);
    setTrainingPastRuns(trainingPastRuns.map((run) => (run.session.id === selectedPastRun.session.id ? selectedPastRun : run)));
    dispatch(setTrainings(trainings.map((training) => (training.id === id ? { ...training, training_past_runs: trainingPastRuns } : training))));
    setSelectedPastRun({
      session: {
        id: 0,
        created_date: '',
        loss: 0,
        accuracy: 0,
        epochs: 0,
        model_id: 0,
        epochs_ran: 0,
        status: TrainingStatus.PREPARING,
      },
      pictures: [],
    });
  };

  const isAnnotated = (pic: trainingPictureWithPolygons) => {
    return pic.polygons.length > 0;
  };

  return (
    <>
      <div className='container pt-3 px-4'>
        <div className='d-flex p-1 flex-row justify-content-between align-items-center pb-4'>
          <Link to={`${process.env.REACT_APP_HOMEPAGE}/training`}>
            <button className='btn btn-primary'>
              <i className='bi bi-arrow-return-left'></i>
              Return
            </button>
          </Link>
        </div>
        <div className='d-flex flex-row gap-5'>
          <div className='d-flex flex-column gap-4 col-md-4'>
            <div className='d-flex flex-column gap-1 p-4 card shadow-sm'>
              <div className='h2'>{currentTraining?.name}</div>
              <div className='d-flex flex-row gap-3 align-items-center'>
                <div>Runs:</div>
                <Card className='fs-5 p-2 py-0' bg='secondary'>
                  {currentTraining?.runs}
                </Card>
              </div>
              <div className='d-flex flex-row gap-3'>
                <div className='fs-6'> Object Classes: </div>
                <div className='d-flex flex-row gap-2 flex-wrap'>
                  {object_classes.map((object_class, index) => (
                    <Card className='rounded' bg='secondary'>
                      <div key={object_class.id} className={`fs-5 p-2 py-0`}>
                        {object_class.name}
                      </div>
                    </Card>
                  ))}
                </div>
              </div>
              <Button variant='btn btn-primary btn-sm align-self-end' onClick={handleRunTraining}>
                Run Training
              </Button>
            </div>
            <div className='d-flex flex-column gap-1 p-4 card shadow-sm'>
              <div className='h2'>Training Past Runs</div>
              {trainingPastRuns.map((run, index) => (
                <div
                  key={run.session.id}
                  className={`d-flex flex-row gap-2 ps-4 fs-5 justify-content-between pe-10 align-items-center ${
                    index < trainingPastRuns.length - 1 ? 'border-bottom border-2 pb-2' : ''
                  }`}
                >
                  <div>{new Date(run.session.created_date).toLocaleDateString('en-gb')}</div>
                  <div>Loss: {run.session.loss}</div>
                  <div>Accuracy: {run.session.accuracy}</div>
                  <Button
                    variant='btn btn-secondary btn-sm'
                    className='p-1 px-2'
                    onClick={() => {
                      setSelectedPastRun(run);
                      toggleModal(1);
                    }}
                  >
                    <i className='bi bi-eye p-0'></i>
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <div className='card p-5 flex-column justify-content-between shadow-sm w-100'>
            <div className='d-flex flex-row flex-wrap gap-5 align-items-center pb-4 justify-content-start'>
              {trainingPics.length === 0 ? (
                <div className='fs-4 fw-bold'>No Pictures</div>
              ) : (
                trainingPics.map((pic, index) => {
                  return (
                    <div
                      key={index}
                      style={{ objectFit: 'cover' }}
                      className={`border border-5 ${isAnnotated(pic) ? 'border-success' : 'border-warning'} rounded shadow-sm position-relative`}
                      onDoubleClick={() => {
                        setSelectedImage(pic);
                        toggleModal(0);
                      }}
                      onClick={() => {
                        if (selectedImages.includes(pic)) {
                          setSelectedImages(selectedImages.filter((selectedPic) => selectedPic !== pic));
                        } else {
                          setSelectedImages((prev) => [...prev, pic]);
                        }
                      }}
                    >
                      <img src={pic.filename} alt='pic' style={{ maxWidth: '100px', width: 'auto', height: 'auto' }} />
                      {selectedImages.includes(pic) && (
                        <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success'>
                          <i className='bi bi-check text-white'></i>
                        </span>
                      )}
                    </div>
                  );
                })
              )}
            </div>
            <div>
              <Form.Group controlId='formFile' className={`border-top border-black border-2 pt-2`}>
                <Form.Label>Add Pictures</Form.Label>
                <Form.Control
                  type='file'
                  multiple
                  onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                    if (event.currentTarget.files) {
                      for (let i = 0; i < event.currentTarget.files.length; i++) {
                        const formData = new FormData();
                        const file = event.currentTarget.files[i];
                        formData.append(file.name, file);

                        // Convert the file to a blob
                        const blob = new Blob([file], { type: file.type });

                        // Assuming addTrainingPicture function accepts a blob
                        await addTrainingPicture(blob, id).then(async () => {
                          const pics = await getTrainingImages(id);
                          for (const pic of pics) {
                            pic.filename = await computeUrl(pic.filename);
                          }
                          dispatch(setPictures(pics));
                        });
                      }
                    }
                  }}
                />
              </Form.Group>
            </div>
          </div>
        </div>
        <CommonModal
          closeButton={false}
          confirmButton={false}
          show={show[0]}
          showModalCallback={() => toggleModal(0)}
          title={'Annotate Picture'}
          confirmButtonOptions={{
            onClick: () => {
              toggleModal(0);
            },
            icon: 'bi bi-check-lg fs-3',
            class: 'btn-success',
          }}
          closeButtonOptions={{
            onClick: () => {
              toggleModal(0);
            },
            icon: 'bi bi-x-lg',
          }}
          size='lg'
          headerElement={
            <div className='d-flex flex-row gap-3 align-items-center card p-2 px-5 shadow-sm'>
              <div className='fs-3'>Annotate for: </div>
              <select
                className='form-select w-md-125px h-md-50'
                value={annotationType.id}
                onChange={(e) => {
                  dispatch(setAnnotationType(object_classes.find((object_class) => object_class.name === e.target.value) || object_classes[0]));
                }}
              >
                <option value={-1} disabled>
                  All
                </option>
                {object_classes.map((object_class) => (
                  <option key={object_class.id} value={object_class.id}>
                    {object_class.name}
                  </option>
                ))}
              </select>
            </div>
          }
        >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <div>
              {/* <img src={selectedImage?.src} alt='pic' style={{ maxWidth: '700px', width: 'auto', height: 'auto' }} /> */}
              {selectedImage?.filename && (
                <CommonAnnotatedImageRect
                  img={selectedImage.filename}
                  className='rounded img-fluid'
                  saveChanges={saveChanges}
                  data={selectedImage.polygons}
                  // data={[]}
                  save={save}
                />
              )}
            </div>
            <div className='d-flex flex-row gap-2'>
              <Button variant='btn btn-primary btn-sm' onClick={toggleLeft}>
                Previous
              </Button>
              <Button variant='btn btn-primary btn-sm' onClick={toggleRight}>
                Next
              </Button>
            </div>
          </div>
        </CommonModal>
        <CommonModal
          closeButton={true}
          confirmButton={true}
          show={show[1]}
          showModalCallback={() => toggleModal(1)}
          title={'Edit Past Run'}
          confirmButtonOptions={{
            onClick: () => {
              toggleModal(1);
              handleChangePastRun();
            },
            icon: 'bi bi-check-lg fs-3',
            class: 'btn-success',
            text: 'Save',
          }}
          closeButtonOptions={{
            onClick: () => {
              toggleModal(1);
            },
            icon: 'bi bi-x-lg',
          }}
        >
          <div className='d-flex flex-column gap-3'>
            <div className='d-flex flex-row align-items-center gap-4'>
              <div className='h3'>Date</div>
              <input
                type='date'
                value={parseDate(selectedPastRun?.session.created_date)}
                onChange={(e) =>
                  setSelectedPastRun({ ...selectedPastRun, session: { ...selectedPastRun.session, created_date: e.currentTarget.value } })
                }
                className='form-control'
              />
            </div>
            <div className='d-flex flex-row align-items-center gap-4'>
              <div className='d-flex flex-row align-items-center gap-2'>
                <div className='h3 pe-0'>Accuracy</div>
                <div className='h3'>%</div>
              </div>
              <input
                type='number'
                value={(selectedPastRun?.session.accuracy * 100).toFixed(0)}
                onChange={(e) =>
                  setSelectedPastRun({ ...selectedPastRun, session: { ...selectedPastRun.session, accuracy: parseInt(e.currentTarget.value) / 100 } })
                }
                className='form-control'
              />
            </div>
            <div className='d-flex flex-row align-items-center gap-4'>
              <div className='d-flex flex-row align-items-center gap-2'>
                <div className='h3 pe-0'>Loss</div>
                <div className='h3'>%</div>
              </div>
              <input
                type='number'
                value={(selectedPastRun?.session.loss * 100).toFixed(0)}
                onChange={(e) =>
                  setSelectedPastRun({ ...selectedPastRun, session: { ...selectedPastRun.session, loss: parseInt(e.currentTarget.value) / 100 } })
                }
                className='form-control'
              />
            </div>
            <div className='d-flex flex-row flex-wrap gap-5 align-items-center pb-4 justify-content-start'>
              {!selectedPastRun.pictures || selectedPastRun.pictures.length === 0 ? (
                <div className='fs-4 fw-bold'>No Pictures</div>
              ) : (
                selectedPastRun.pictures.map((pic, index) => {
                  return (
                    <div key={index} style={{ objectFit: 'cover' }} className={`rounded shadow-sm position-relative`}>
                      <img src={pic.filename} alt='pic' style={{ maxWidth: '100px', width: 'auto', height: 'auto' }} />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </CommonModal>
      </div>
    </>
  );
};

export default TrainingInfo;
