import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface selectedMetricState {
  value: string;
}

const initialState: selectedMetricState = {
  value: 'avg-queue-duration',
};

export const selectedMetric = createSlice({
  name: 'selectedMetric',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<selectedMetricState>) => {
      state.value = action.payload.value;
    },
  },
});

export const { setValue } = selectedMetric.actions;

export const selectedMetricReducer = selectedMetric.reducer;
