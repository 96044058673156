import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface selectedUserState {
  value: string
  id: string
}

const initialState: selectedUserState = {
  id: localStorage.getItem('selectedUserId')!?.toString() != null ? localStorage.getItem('selectedUserId')!?.toString() : '0',
  value: localStorage.getItem('selectedUserName')!?.toString() != null ? localStorage.getItem('selectedUserName')!?.toString() : 'All'
};

export const selectedUser = createSlice({
  name: 'selectedUser',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<selectedUserState>) => {
      state.id = action.payload.id
      state.value = action.payload.value
      localStorage.setItem("selectedUserId", action.payload.id)
      localStorage.setItem("selectedUserName", action.payload.value)

    },
  },
});

export const { setValue } = selectedUser.actions;

export const selectedUserReducer = selectedUser.reducer;
