import { useEffect, useState, useMemo } from "react"
import { ValueDateLocation } from "../../../web_api/models"
import { exportCSV, numberFormat } from "../../../../src/web_api/methods/helpers/index"
import * as ReactBootstrap from 'react-bootstrap'
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import { useDispatch } from "react-redux"
import { setValue } from "../../../redux/activeLocationReducer"
import LoadingPage from "../../../layout/LoadingPage"


type Props = {
  className: string
  color: string
  img?: string
}

const DataWidgetPerDays: React.FC<Props> = ({ className, color, img }) => {
  const [entries, setEntries] = useState<ValueDateLocation[]>([])
  const [days, setDays] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [activeDay, setActiveDay] = useState<string>(new Date().toDateString())
  const [index, setIndex] = useState<number>(0)
  const [sum, setSum] = useState<number>(0)
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const mostQueueAlertsList = useSelector((state: RootState) => state.mostQueueAlerts.list);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const dispatch = useDispatch()
  useEffect(() => {
    const fromDate = new Date(dateFilter.from);
    const toDate = new Date(dateFilter.to);

    const daysList = [];
    let currentDate = fromDate;
    while (currentDate <= toDate) {
      const dateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}T00:00:00`;
      daysList.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setLoading(true)
    setDays(daysList)
    setIndex(daysList.length - 1)
    setActiveDay(daysList[daysList.length - 1])
  }, [dateFilter])

  //download files
  const handleDownload = () => {
    days.forEach((day) => {
      let names = new Array()
      let values = new Array()
      mostQueueAlertsList.filter(x => x.day == day).forEach((item) => {
        names.push(item.name)
        values.push(item.value)
      })
      let activeLocationType = activeLocation.id === '0' ? 'Location' : 'Cash register'
      exportCSV([[activeLocationType, 'Queue alerts']], names, values, activeLocation.value + ' ' + new Date(day).toLocaleString('en-US', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }))
    })
  };

  const changingComponent = useMemo(() => {
    let sum = 0
    mostQueueAlertsList.filter(x => x.day === activeDay).forEach((item) => {
      sum += item.value
    })
    setLoading(true)
    setSum(sum)
    return (
      <div className='card-body d-flex flex-column pt-0 pb-0 overflow-auto h-200px'>
        {mostQueueAlertsList.filter(x => x.day === activeDay).map((item, index) => (
          <div key={`${item.id}-${item.name}`} className='d-flex align-items-center flex-column mt-3 w-100'>
            <div className='d-flex fw-bold fs-6 text-dark opacity-75 w-100 mt-auto mb-2'>
              <span className="d-flex col-sm-8 flex-start cursor-pointer" onClick={() => {
                if (activeLocation.id === "0") dispatch(setValue({
                  value: item.name,
                  id: item.id.toString()
                }))
              }}>
                {
                  activeLocation.id === '0' &&
                  <span className='bg-info text-inverse-info rounded-2 p-2'>
                    {item.id}
                  </span>
                }
                {/* <span>
                  {activeLocation.id === '0' ? ' - ' : ''}
                </span> */}
                <span className='m-auto ms-2'>
                  {item.name}
                </span>
              </span>
              <span className="d-flex col-sm-4 flex-end">
                <span className="m-auto mx-0">
                  {((item.value / sum * 100) != null && (item.value / sum * 100) != undefined) && (item.value / sum * 100).toFixed(2) + '% - ' + numberFormat(item.value) + ' alerts'}
                </span>
              </span>
            </div>

            <div className='h-8px mx-3 w-100 bg-info bg-opacity-50 rounded'>
              <div
                className='bg-info rounded h-8px'
                role='figure'
                style={{ width: (item.value / mostQueueAlertsList.filter(x => x.day == activeDay)[0].value) * 100 + '%' }}
                aria-valuenow={50}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </div>
        ))}
      </div>
    )
  }, [mostQueueAlertsList, activeDay])


  return (
    <>
      <div
        className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-100 ${className} animated zoomIn`}
        style={{
          backgroundColor: color,
          backgroundImage: `url('${img}')`
        }}
      >
        <div className='card-header pt-5'>
          <div className='d-flex flex-column'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'>Queue alerts - {sum} generated</span>

              <span className="text-muted mt-1 fw-semibold fs-7"> {new Date(activeDay).toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric'
              })} </span>
            </h3>

          </div>
          <div className='flex-shrink-0 my-auto'>
            <ul className='nav' onClick={handleDownload}>
              <li className='nav-item m-auto'>
                <div className={'nav-link btn btn-sm btn-color-dark btn-active-color-primary btn-active-light fw-semibold fs-7 text-center p-2'} title='Download'>
                  <i className="bi bi-save p-0 mb-1" />
                </div>
              </li>
            </ul>
          </div>
        </div>

        {
          loading ?
            (changingComponent)
            :
            (<LoadingPage />)
        }
        <div className='d-flex align-items-center flex-column py-0 w-100 card-footer'>
          {(mostQueueAlertsList.length !== 0 && days.length > 1) &&
            (
              <div className="d-flex flex-row w-100 mb-3">
                <div className="btn m-auto btn-active-light" onClick={() => {
                  if (index > 0) {
                    setIndex((index) => {
                      return index - 1
                    });
                    setActiveDay(days[index - 1])
                  }
                }}>
                  <p className="text-dark m-auto">Prev</p>
                  {/* <i className="bi bi-arrow-left text-white" /> */}
                </div>
                <p className='text-dark opacity-75 fw-semibold fs-6 m-auto col-sm-4 text-center'>{new Date(activeDay).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}</p>
                <div className="btn m-auto btn-active-light" onClick={() => {
                  if (index < days.length - 1) {
                    setIndex((index) => {
                      return index + 1
                    });
                    setActiveDay(days[index + 1])
                  }
                }}>
                  <p className="text-dark m-auto">Next</p>
                  {/* <i className="bi bi-arrow-right text-white" /> */}
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  )
}

export { DataWidgetPerDays }

