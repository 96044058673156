import { Navigate, Route, Routes } from 'react-router-dom';
import MasterLayout from '../layout/MasterLayout';
import PasswordReset from '../pages/Account/PasswordReset/PasswordReset';
import Users from '../pages/Account/Users/Users';
import Alerts from '../pages/Alerts/Alerts';
import BakeryAnalytics from '../pages/Bakery/Analytics/BakeryAnalytics';
import LocationInfoBakery from '../pages/Bakery/Analytics/LocationInfoBakery';
import BakeryReports from '../pages/Bakery/Reports/BakeryReports';
import CameraModels from '../pages/CameraModels/CameraModels';
import CameraInfo from '../pages/Cameras/CameraInfo/CameraInfo';
import Cameras from '../pages/Cameras/Cameras';
import DetectionServers from '../pages/DetectionServers/DetectionServers';
import DetectionInfo from '../pages/Detections/DetectionInfo/DetectionInfo';
import DetectionInfoAnnotation from '../pages/Detections/DetectionInfo/DetectionInfoAnnotation';
import Detections from '../pages/Detections/Detections';
import EvolutionComparison from '../pages/EvolutionComparison/EvolutionComparison';
import FaceRecognition from '../pages/FaceRecognition/FaceRecognition';
import FruitsVegetables from '../pages/FruitsVegetables/FruitsVegetables';
import FruitsVegetablesInfo from '../pages/FruitsVegetables/FruitsVegetablesInfo/FruitsVegetablesInfo';
import HSSE from '../pages/HSSE/HSSE';
import Home from '../pages/Home';
import Locations from '../pages/Locations/Locations';
import Login from '../pages/Login';
import MapLocationWrapper from '../pages/Map/MapLocationWrapper';
import QueueManagement from '../pages/QueueManagement/QueueManagement';
import ReportAProblem from '../pages/Support/ReportAProblem';
import TwoFactorAuth from '../pages/2FA/TwoFactorAuth';
import Hub from '../pages/Hub';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import Demo from '../pages/Demo/Demo';
import { EProductType } from '../redux/activeProductReducer';
import Mobility from '../pages/Mobility/Mobility';
import { DEMO_ACCOUNTS } from '../web_api/constants';
import MapStatistics from '../pages/Statistics/MapStatistics';
import AddNewProduce from '../pages/AddNewProduce/AddNewProduce';
import MapTrafficWrapper from '../pages/Traffic/MapTrafficWrapper';
import TrafficSearch from '../pages/Traffic/TrafficSearch';
import TrafficLPR from '../pages/Traffic/TrafficLPR';
import TrafficDashboard from '../pages/Traffic/TrafficDashboard';
import TrafficLocationDetails from '../pages/Traffic/TrafficLocationDetails';
import Training from '../pages/Training/Training';
import TrainingInfo from '../pages/Training/TrainingInfo';
import LastAlerts from '../pages/Alerts/LastAlerts';
import HeatmapDashboard from '../pages/Heatmap/HeatmapDashboard';
import LocationPlan from '../pages/Locations/LocationPlan';

export default function PrivateRoutes() {
  const userName = useSelector((state: RootState) => state.user.user_name);
  const activeProduct = useSelector((state: RootState) => state.activeProduct.activeProduct);
  const token = useSelector((state: RootState) => state.user.access_token);

  return (
    <Routes>
      {localStorage.getItem('is_valid') === 'true' ? (
        <Route element={<MasterLayout />}>
          {DEMO_ACCOUNTS.includes(userName) ? (
            activeProduct === EProductType.RETAIL ? (
              <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<QueueManagement />} />
            ) : activeProduct === EProductType.HSSE ? (
              <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<HSSE />} />
            ) : activeProduct === EProductType.MOBILITY ? (
              <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<Mobility />} />
            ) : (
              <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<Hub />} />
            )
          ) : (
            <Route path={process.env.REACT_APP_HOMEPAGE + '/home'} element={<QueueManagement />} />
          )}
          <Route path={process.env.REACT_APP_HOMEPAGE + '/people-counting'} element={<Home />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/queue-management'} element={<QueueManagement />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/alerts/:detectionType&:detectionTypeName'} element={<Alerts />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/alerts/last-alerts'} element={<LastAlerts />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/face-recognition'} element={<FaceRecognition />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/builder'} element={<Login />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/comparison'} element={<EvolutionComparison />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/detections'} element={<Detections />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/demo'} element={<Demo />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/detections/:id'} element={<DetectionInfo />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/detections/:id/:cameraId/annotation'} element={<DetectionInfoAnnotation />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} element={<PasswordReset />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/account/2fa'} element={<TwoFactorAuth />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/support/report-a-problem'} element={<ReportAProblem />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/account/users'} element={<Users />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/analytics'} element={<BakeryAnalytics />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/analytics/:locationId'} element={<LocationInfoBakery />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/bakery/reports'} element={<BakeryReports />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/fruits-and-vegetables'} element={<FruitsVegetables />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/fruits-and-vegetables/:locationId'} element={<FruitsVegetablesInfo />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/add-new-produce'} element={<AddNewProduce />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/cameras'} element={<Cameras />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/cameras/:id'} element={<CameraInfo />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/locations'} element={<Locations />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/locations/plan/:id'} element={<LocationPlan />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/map-locations'} element={<MapLocationWrapper />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/map-statistics'} element={<MapStatistics />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/mobility'} element={<Mobility />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/overview'} element={<Mobility />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/camera-models'} element={<CameraModels />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/detection-servers'} element={<DetectionServers />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/hsse'} element={<HSSE />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/traffic/lpr/:id'} element={<TrafficSearch />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/traffic/lpr'} element={<TrafficLPR />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/traffic/map'} element={<MapTrafficWrapper />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/traffic/dashboard/:id'} element={<TrafficLocationDetails />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/traffic/dashboard'} element={<TrafficDashboard />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/heatmap'} element={<HeatmapDashboard />} />
          {token !== null ? (
            <Route path={process.env.REACT_APP_HOMEPAGE + '/login/*'} element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/home'} />} />
          ) : (
            <Route
              path={process.env.REACT_APP_HOMEPAGE + '/login/*'}
              element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} />}
            />
          )}
          <Route path={process.env.REACT_APP_HOMEPAGE + '/training'} element={<Training />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/training/:id'} element={<TrainingInfo />} />
          <Route path='/*' element={<QueueManagement />} />
        </Route>
      ) : (
        <>
          <Route path='/*' element={<PasswordReset />} />
          <Route path={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} element={<PasswordReset />} />
          <Route
            path={process.env.REACT_APP_HOMEPAGE + '/login/*'}
            element={<Navigate to={process.env.REACT_APP_HOMEPAGE + '/account/password-reset'} />}
          />
        </>
      )}
    </Routes>
  );
}
