import callApiAxiosClient from "../../axios/axios_client";

// function deleteAlerts(ids: number[]): Promise<void> {
function deleteAlerts(ids: string[]): Promise<void> {
    if (ids.find(el => el === null)) {
        throw new Error("Invalid alert id");
    }
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "delete",
        url: "detections",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            ids: ids
        }
    })
}
export default deleteAlerts;