import callApiAxiosClient from "../../axios/axios_client";


function addUserLocation(user_id: string, location_id: number): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "post",
        url: "usersactions/add-user-location",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            user_id: user_id,
            location_id: location_id
        }
    })
}
export default addUserLocation;