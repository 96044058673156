import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import CommonTable from '../Common/CommonTable/CommonTable';
import { useEffect, useState } from 'react';
import LocationsFormModal from './LocationsFormModal';
import LocationsDeleteModal from './LocationsDeleteModal';
import useModals from '../../hooks/Common/useModals';
import CommonTableColumn from '../Common/CommonTable/CommonTableColumn';
import CommonTableRow from '../Common/CommonTable/CommonTableRow';
import CommonNewUserCard from '../Common/CommonNewUserCard';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type Location = {
  id: number;
  name: string;
  lat: number;
  long: number;
  county_id: number | null;
};

type LocationData = {
  checked: number[];
  current: Location;
};

const LocationsTable = () => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);

  const { show, toggleModal } = useModals([false, false]); // 0 for add and edit, 1 for delete and mass delete

  const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);

  const navigate = useNavigate();

  const [locationData, setLocationData] = useState<LocationData>({
    checked: [],
    current: {
      id: 0,
      name: '',
      lat: 0,
      long: 0,
      county_id: null,
    },
  });

  const onCheckboxChange = (locationId: number) => {
    const { checked } = locationData;
    if (checked.includes(locationId)) {
      setLocationData((prevState) => ({
        ...prevState,
        checked: prevState.checked.filter((id) => id !== locationId),
      }));
    } else {
      setLocationData((prevState) => ({
        ...prevState,
        checked: [...prevState.checked, locationId],
      }));
    }
  };

  const onCheckAll = () => {
    const allLocationIds = cachedLocationsList.list.map((location) => location.id);

    if (locationData.checked.length > 0 && isCheckAllChecked) {
      setLocationData((prev) => ({
        ...prev,
        checked: [],
      }));
    } else {
      setLocationData((prev) => ({
        ...prev,
        checked: allLocationIds,
      }));
    }
  };

  const onDelete = () => {
    setIsCheckAllChecked(false);
  };

  const onModalToggle = (index: number, locationId?: number) => {
    const modalIndex = index;
    if (!show[modalIndex]) {
      const location = locationId ? cachedLocationsList.list.find((loc) => loc.id === locationId) : undefined;
      setLocationData((prevState) => ({
        ...prevState,
        current: {
          id: locationId ?? 0,
          name: location?.name || '',
          lat: location?.lat || 0,
          long: location?.long || 0,
          county_id: location?.county_id || null,
        },
      }));
      toggleModal(modalIndex);
    } else {
      toggleModal(modalIndex);
      if (locationId) {
        setLocationData((prevState) => ({
          ...prevState,
          current: {
            id: 0,
            name: '',
            lat: 0,
            long: 0,
            county_id: null,
          },
        }));
      }
    }
  };

  useEffect(() => {
    setLocationData((prevState) => ({
      ...prevState,
      checked: prevState.checked.filter((id) => cachedLocationsList.list.some((loc) => loc.id === id)),
    }));
  }, [cachedLocationsList.list.length]);

  return (
    <>
      {cachedLocationsList.list.length > 0 ? (
        <div className='container py-3 py-lg-6 px-8 mb-12 mb-md-0'>
          <CommonTable
            title='Locations'
            subtitle={`${cachedLocationsList.list.length} locations`}
            addButtonText='New location'
            hasCheckedRows={locationData.checked.length}
            tableClassName={`${window.innerWidth > 576 ? 'table-row-dashed' : ''}`}
            onClickAdd={() => {
              setLocationData((prevState) => ({
                ...prevState,
                current: {
                  id: 0,
                  name: '',
                  lat: 0,
                  long: 0,
                  county_id: 0,
                },
              }));
              onModalToggle(0);
            }}
            onClickDelete={() => onModalToggle(1)}
            onCheckAll={onCheckAll}
            columns={
              <>
                <th className='col-sm-1'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid my-8 my-sm-0'>
                    <input
                      id='check-all'
                      onClick={() => onCheckAll()}
                      onChange={() => setIsCheckAllChecked((prev) => !prev)}
                      checked={isCheckAllChecked}
                      className='form-check-input widget-9-check'
                      type='checkbox'
                      value='1'
                    />
                    <span className='d-sm-none ms-5 text-dark'>Check all locations</span>
                  </div>
                </th>
                <CommonTableColumn className='col-sm-7 d-none d-sm-table-cell' name='Name' />
                <CommonTableColumn className='col-sm-2 d-none d-sm-table-cell' name='ID' />
                <CommonTableColumn className='col-sm-1 cursor-pointer text-center d-none d-sm-table-cell' name='Actions' />
              </>
            }
            rows={
              <>
                {cachedLocationsList &&
                  cachedLocationsList.list.map((location) => (
                    <CommonTableRow
                      className='d-flex flex-column text-justify text-sm-start d-sm-table-row'
                      key={location.id}
                      inputId={location.id.toString()}
                      cellOne={location.name}
                      cellOneTag={location.id}
                      checked={locationData.checked.includes(location.id)}
                      onCheckboxChange={() => onCheckboxChange(location.id)}
                    >
                      <td className='d-none d-sm-table-cell'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>{location.id}</span>
                      </td>
                      <td>
                        <div>
                          <div className='d-flex gap-1 justify-content-end justify-content-sm-center'>
                            <div className='btn btn-icon btn-light-warning btn-sm me-1' onClick={() => onModalToggle(0, location.id)}>
                              <i className='bi bi-gear' />
                            </div>
                            <div className='btn btn-icon btn-danger btn-sm me-1' onClick={() => onModalToggle(1, location.id)}>
                              <i className='bi bi-trash' />
                            </div>
                            <div
                              className='btn btn-icon btn-secondary btn-sm'
                              onClick={() => {
                                navigate(process.env.REACT_APP_HOMEPAGE + '/locations/plan/' + location.id);
                              }}
                            >
                              <i className='bi bi-map text-secondary-emphasis' />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className='d-block d-sm-none'>
                        <hr />
                      </td>
                    </CommonTableRow>
                  ))}
              </>
            }
          />
          <LocationsFormModal onModalToggle={() => onModalToggle(0)} show={show[0]} currentLocation={locationData.current} />
          <LocationsDeleteModal onDelete={onDelete} onModalToggle={() => onModalToggle(1)} show={show[1]} locationData={locationData} />
        </div>
      ) : (
        <div className='container py-3 py-lg-6 px-6 px-md-20 mb-6 flex-column flex-xl-row d-flex justify-content-center w-100'>
          <CommonNewUserCard
            title='Add your first location!'
            content={`Click on this card to add your first location.\n
                            Fill out the fields and press the "Confirm" button to register a location.`}
            className='w-xl-50'
            icon='bi bi-houses'
            buttonText='Add location'
            onClick={() => onModalToggle(0)}
            link={`${process.env.REACT_APP_HOMEPAGE}/locations`}
          />
          <LocationsFormModal onModalToggle={() => onModalToggle(0)} show={show[0]} currentLocation={locationData.current} />
        </div>
      )}
    </>
  );
};

export default LocationsTable;
