import callApiAxiosClient from '../../axios/axios_client';
import { objectClassesList } from '../../models';

function getTrainingObjectClasses(): Promise<objectClassesList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<objectClassesList>({
    method: 'get',
    url: 'training/object-classes',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getTrainingObjectClasses;
