import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface compareUserState {
  value: string
  id: string
}

const initialState: compareUserState = {
  id: localStorage.getItem('compareUserId1')!?.toString() != null ? localStorage.getItem('compareUserId1')!?.toString() : '0',
  value: localStorage.getItem('compareUserName1')!?.toString() != null ? localStorage.getItem('compareUserName1')!?.toString() : 'All'
};

export const compareUser1 = createSlice({
  name: 'compareUser1',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<compareUserState>) => {
      state.id = action.payload.id
      state.value = action.payload.value
      localStorage.setItem("compareUserId1", action.payload.id)
      localStorage.setItem("compareUserName1", action.payload.value)

    },
  },
});

export const { setValue } = compareUser1.actions;

export const compareUser1Reducer = compareUser1.reducer;
