import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ClusterLocationsState {
  cluster: boolean;
}
const initialState: ClusterLocationsState = {
  cluster: localStorage.getItem('cluster')!?.toString() != null ? !!localStorage.getItem('cluster')!?.toString() : true,
};

export const clusterLocations = createSlice({
  name: 'clusterLocations',
  initialState,
  reducers: {
    setCluster: (state, action: PayloadAction<ClusterLocationsState>) => {
      state.cluster = action.payload.cluster;
      localStorage.setItem('cluster', action.payload.cluster.toString());
    },
  },
});

export const { setCluster } = clusterLocations.actions;

export const clusterLocationsReducer = clusterLocations.reducer;
