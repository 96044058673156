import callApiAxiosClient from '../../axios/axios_client';
import bg_img from '../../../assets/images/bg_img.jpeg';

async function getSnapshot(camera_id: number, low_res: boolean): Promise<string> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  if (process.env.REACT_APP_API_BUILD_URL === 'http://10.10.0.139:5005/') return bg_img;
  try {
    const response: Blob = await callApiAxiosClient({
      method: 'get',
      url: `media/snapshot`,
      headers: {
        Authorization: authorization,
      },
      responseType: 'blob', // Set the response type to blob
      params: {
        camera_id: camera_id,
        low_res: low_res,
      },
    });
    if (response == null) return URL.createObjectURL(new Blob([]));
    // Convert blob response to object URL
    const objectURL = URL.createObjectURL(response);
    return objectURL;
  } catch (error) {
    console.error('Error fetching image:', error);
    return bg_img;
  }
}
export default getSnapshot;
