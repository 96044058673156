import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import useDelete from '../../hooks/Common/useDelete';
import useModals from '../../hooks/Common/useModals';
import deleteDetectionServers from '../../web_api/methods/settings/detectionServers/deleteDetectionServers';
import { CommonModal } from '../Common/CommonModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { CommonTooltip } from '../Common/CommonTooltip';
import usePut from '../../hooks/Common/usePut';
import updateDetectionServer from '../../web_api/methods/settings/detectionServers/updateDetectionServer';
import * as ReactBootstrap from 'react-bootstrap';
import { detectionServerInList } from '../../web_api/models';

type DetectionServerCardProps = {
  server: detectionServerInList;
  onDetectionServerCheck: (id: number) => void;
  onDetectionServerDelete: (id: number) => void;
};

const DetectionServerCard: React.FC<DetectionServerCardProps> = ({ server, onDetectionServerCheck, onDetectionServerDelete }) => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);

  const editableRef = useRef<HTMLSpanElement>(null);

  const locationName = cachedLocationsList.find((location) => location.id === server.location_id)?.name;
  const shortLocationName = locationName && locationName.length > 12 ? locationName.slice(0, 12) + '...' : locationName;

  const [name, setName] = useState({
    value: server.name,
    initialVal: '',
    isEditing: false,
  });

  const [externalEndpoint, setExternalEndpoint] = useState(server.external_endpoint);
  const [audioAlerts, setAudioAlerts] = useState(server.audio_alerts);
  const [ipAddress, setIpAddress] = useState({
    value: server.ip_address,
    initialVal: '',
    isEditing: false,
  });
  const [isEditing, setIsEditing] = useState(false);

  const { show, toggleModal } = useModals([false, false]); // 0 for delete det sv modal, 1 for toggle audio alerts modal

  const {
    deleteItems: handleDeleteDetection,
    errors,
    isDeleting,
  } = useDelete(
    () => deleteDetectionServers([server.id]),
    () => {
      toggleModal(0);
      onDetectionServerDelete(server.id);
      toast.success('Detection server deleted successfully!', {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    }
  );

  const {
    data,
    handleUpdateData,
    errors: putErrors,
    isUpdating,
  } = usePut(
    {
      name: name.value,
      ipAddress: ipAddress.value,
      externalEndpoint: externalEndpoint,
      audioAlerts: audioAlerts,
    },
    () => {
      console.log('updateDetectionServer');
      updateDetectionServer({
        id: server.id,
        name: name.value,
        location_id: server.location_id,
        audio_alerts: audioAlerts,
        external_endpoint: externalEndpoint,
        ip_address: ipAddress.value,
      });
    },
    undefined,
    undefined,
    undefined,
    undefined,
    () => {
      toast.success('Detection server updated successfully!', {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
      setName({ ...name, isEditing: false });
      setIsEditing(false);
    }
  );

  const handleUpdateDetectionServer = () => {
    console.log('isUpdating', isUpdating);
    if (name.value === '') {
      editableRef.current?.focus();
      toast.error(`Please provide a server name.`, {
        position: 'top-right',
        theme: 'colored',
      });
    } else if (name.value.length > 25) {
      toast.error(`Please provide a server name shorter than 25 characters.`, {
        position: 'top-right',
        theme: 'colored',
      });
    } else {
      console.log('update data', {
        id: server.id,
        name: name.value,
        location_id: server.location_id,
        audio_alerts: audioAlerts,
        external_endpoint: externalEndpoint,
        ip_address: ipAddress.value,
      });
      handleUpdateData();
    }
  };

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
    if (putErrors) {
      toast.error(`${putErrors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors?.length, putErrors?.length]);

  const handleEditableContentFocus = () => {
    if (isEditing && editableRef.current) {
      editableRef.current.focus();

      const range = document.createRange();
      const selection = window.getSelection();

      if (selection) {
        const textContent = editableRef.current.textContent;
        if (textContent !== null) {
          range.setStart(editableRef.current.firstChild || editableRef.current, textContent.length);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }
  };

  useEffect(() => {
    console.log('isEditing', isEditing);
    handleEditableContentFocus();
  }, [isEditing]);

  return (
    <>
      <div className='animated zoomIn col-12 col-md-6 col-xxl-4 justify-self-end'>
        <div className={`card my-3 h-min-300px animated ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100`}>
          <div className='card-body p-0'>
            <div
              className={`px-9 pt-7 card-rounded h-275px w-100`}
              style={{
                backgroundImage: 'linear-gradient(to bottom, #186FE0, #009EF7)',
              }}
            >
              {/* begin::Heading */}

              <div className='d-flex flex-stack'>
                <div className={`form-check form-check-sm form-check-custom form-check-solid`}>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='1'
                    data-kt-check='true'
                    data-kt-check-target='.widget-9-check'
                    id={server.name}
                    onClick={() => onDetectionServerCheck(server.id)}
                  />
                </div>

                <div className={`ms-1 d-flex flex-row gap-2`}>
                  {/* begin::Menu */}
                  <CommonTooltip text='Toggle location audio alerts' bg='' textColor=''>
                    <div
                      className={`btn btn-primary p-2 ps-3 ${audioAlerts ? 'btn-success' : 'btn-danger'}`}
                      onClick={() => {
                        toggleModal(1);
                      }}
                    >
                      <i className='bi bi-headset fs-5 text-light' />
                    </div>
                  </CommonTooltip>
                  <CommonTooltip text='Delete detection server' bg='' textColor=''>
                    <button
                      type='button'
                      className={`btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3`}
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      onClick={() => toggleModal(0)}
                    >
                      <i className='svg-icon-2 bi bi-trash fa-3x' />
                    </button>
                  </CommonTooltip>
                  {/* <Dropdown1 /> */}
                  {/* end::Menu */}
                </div>
              </div>
              {/* end::Heading */}
              {/* begin::Balance */}
              <div className='d-flex text-center flex-column text-white pt-8'>
                <span className='fw-semibold fs-7'>&nbsp;</span>
                <span
                  className='fw-bold fs-2x pt-1 border-0 background-transparent'
                  ref={editableRef}
                  contentEditable={isEditing ? true : false}
                  suppressContentEditableWarning={true}
                  spellCheck={false}
                  onInput={(e) => {
                    e.preventDefault();
                    const inputValue = e.currentTarget.textContent;
                    setName({ ...name, value: inputValue || '' });
                  }}
                  onKeyDown={(e) => {
                    const inputValue = e.currentTarget.textContent;
                    const currentLength = inputValue?.length;

                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleUpdateDetectionServer();
                    }
                    if (currentLength && currentLength >= 25 && e.key !== 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                >
                  {server.name}
                </span>
                <span
                  className='fw-bold fs-6 text-secondary pt-1 border-0 background-transparent'
                  ref={editableRef}
                  contentEditable={isEditing ? true : false}
                  suppressContentEditableWarning={true}
                  spellCheck={false}
                  onInput={(e) => {
                    e.preventDefault();
                    const inputValue = e.currentTarget.textContent;
                    setIpAddress({ ...name, value: inputValue || '' });
                  }}
                  onKeyDown={(e) => {
                    const inputValue = e.currentTarget.textContent;
                    const currentLength = inputValue?.length;

                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleUpdateDetectionServer();
                    }
                    if (currentLength && currentLength >= 25 && e.key !== 'Backspace') {
                      e.preventDefault();
                    }
                  }}
                >
                  {server.ip_address || 'No IP address'}
                </span>
                {/* {name.isEditing ?
                                    <i
                                        className="bi bi-check ms-2 cursor-pointer text-white"
                                        style={{ fontSize: '1.5rem' }}
                                        onClick={handleUpdateDetectionServer}
                                    />
                                    :

                                    <i
                                        className="bi bi-pencil ms-2 cursor-pointer text-white"
                                        style={{ fontSize: '1.5rem' }}
                                        onClick={() => {
                                            setName({ ...name, isEditing: true });
                                        }}
                                    />

                                } */}
                {/* } */}
              </div>
              {/* end::Balance */}
            </div>
            {/* end::Header */}
            {/* begin::Items */}
            <div className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body' style={{ marginTop: '-50px' }}>
              <div>
                <div className='d-flex align-items-center mb-6'>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <a className='fs-5 text-gray-800 text-hover-primary fw-bold'>Server ID</a>
                      <div className='text-gray-400 fw-semibold fs-7'></div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <CommonTooltip text={server.id.toString()} bg='' textColor=''>
                        <div className='fw-bold fs-5 text-gray-800 pe-1'>{server.id}</div>
                      </CommonTooltip>
                      <i className='svg-icon-2 bi bi-three-dots-vertical fa-3x' />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='d-flex align-items-center mb-6'>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <a className='fs-5 text-gray-800 text-hover-primary fw-bold'>External Endpoint</a>
                      <div className='text-gray-400 fw-semibold fs-7'></div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <CommonTooltip text={externalEndpoint || 'external endpoint'} bg='' textColor=''>
                        <span
                          className='fw-bold fs-5 text-gray-800 pe-1'
                          ref={editableRef}
                          contentEditable={isEditing ? true : false}
                          suppressContentEditableWarning={true}
                          spellCheck={false}
                          onInput={(e) => {
                            e.preventDefault();
                            const inputValue = e.currentTarget.textContent;
                            setExternalEndpoint(inputValue || '');
                          }}
                          onKeyDown={(e) => {
                            const inputValue = e.currentTarget.textContent;
                            const currentLength = inputValue?.length;

                            if (e.key === 'Enter') {
                              e.preventDefault();
                              handleUpdateDetectionServer();
                            }
                            if (currentLength && currentLength >= 25 && e.key !== 'Backspace') {
                              e.preventDefault();
                            }
                          }}
                        >
                          {externalEndpoint || 'N/A'}
                        </span>
                      </CommonTooltip>
                      <i className='svg-icon-2 bi bi-three-dots-vertical fa-3x' />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='d-flex align-items-center mb-6'>
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <a className='fs-5 text-gray-800 text-hover-primary fw-bold'>Location Name</a>
                      <div className='text-gray-400 fw-semibold fs-7'></div>
                    </div>
                    <div className='d-flex align-items-center'>
                      <CommonTooltip text={locationName || 'location name'} bg='' textColor=''>
                        <div className='fw-bold fs-5 text-gray-800 pe-1'>{shortLocationName}</div>
                      </CommonTooltip>
                      <i className='svg-icon-2 bi bi-three-dots-vertical fa-3x' />
                    </div>
                  </div>
                </div>
              </div>
              <div className={`d-flex gap-2 ${isEditing ? 'flex-column' : 'flex-column-reverse'}`}>
                {!isEditing ? (
                  <div className={`d-flex align-items-center`}>
                    <div
                      className='btn btn-primary w-100'
                      onClick={() => {
                        setName({ ...name, isEditing: true, initialVal: name.value });
                        setIpAddress({ ...ipAddress, isEditing: true, initialVal: ipAddress.value! });
                        setIsEditing(true);
                      }}
                    >
                      <span className='text-white'>Edit server</span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={`d-flex align-items-center`}>
                      <div
                        className='btn btn-success w-100'
                        onClick={() => {
                          !isUpdating && handleUpdateDetectionServer();
                        }}
                      >
                        <span aria-disabled={isUpdating} className='text-white'>
                          Save Changes
                          {isUpdating && <ReactBootstrap.Spinner animation='border' size='sm' className='ms-2' />}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <div className={`d-flex align-items-center ${isEditing ? 'visible' : 'd-none'}`}>
                  <div
                    className='btn btn-secondary w-100'
                    onClick={() => {
                      setName({ ...name, value: name.initialVal, isEditing: false });
                      setIsEditing(false);
                      if (editableRef.current) {
                        editableRef.current.textContent = name.initialVal;
                      }
                    }}
                  >
                    <span aria-disabled={isUpdating} className='text-secondary-light'>
                      Cancel
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* begin::Body */}

          {/* end::Body */}
        </div>
      </div>
      <CommonModal
        title={'Confirm'}
        show={show[0]}
        disabled={isDeleting}
        showModalCallback={() => toggleModal(0)}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3 text-white',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleDeleteDetection,
        }}
      >
        <div className='text-justify'>Are you sure you want to delete the following detection server?</div>
        <div className='fw-bolder mt-2'>{`${server.id} - ${server.name}`}</div>
      </CommonModal>
      <CommonModal
        title={'Confirm'}
        show={show[1]}
        disabled={isDeleting}
        showModalCallback={() => toggleModal(1)}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-check text-white',
          variant: 'custom',
          class: `text-white ${audioAlerts ? 'bg-danger' : 'bg-success'}`,
          onClick: () => {
            updateDetectionServer({
              id: server.id,
              name: name.value,
              location_id: server.location_id,
              audio_alerts: !audioAlerts,
              external_endpoint: externalEndpoint,
              ip_address: ipAddress.value,
            }).then((e) => {
              toast.success('Detection server alerts toggled!', {
                position: 'top-right',
                theme: 'colored',
                autoClose: 2000,
              });
              setName({ ...name, isEditing: false });
              setIsEditing(false);
            });
            setAudioAlerts((prev) => !prev);
            toggleModal(1);
          },
        }}
      >
        <div className='text-justify'>{`Are you sure you want to turn ${audioAlerts ? 'OFF' : 'ON'} the following location's audio alerts?`}</div>
        <div className='fw-bolder mt-2'>{`${server.id} - ${server.name}`}</div>
      </CommonModal>
    </>
  );
};

export default DetectionServerCard;
