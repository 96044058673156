import callApiAxiosClient from "../../axios/axios_client";


async function getDetectionVideos(detection_name: string, detection_id: number): Promise<string[]> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<string[]>({
        method: "get",
        url: "detections/get-detection-videos",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            detection_name: detection_name,
            detection_id: detection_id
        }
    })
}
export default getDetectionVideos;