import { lprDetectionList } from '../../models';
import callApiAxiosClient from '../../axios/axios_client';
import moment from 'moment';

interface getLatestLPRArgs {
  county?: string;
  camera_id?: number;
  location_id?: number;
  confidence?: number;
  page_number?: number;
  page_size?: number;
  search_term?: string;
  from_param: Date;
  to_param: Date;
}

function getLatestLPR({
  county,
  camera_id,
  location_id,
  confidence,
  page_number = 1,
  page_size = 30,
  search_term,
  from_param,
  to_param,
}: getLatestLPRArgs): Promise<lprDetectionList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  const from = moment(from_param).format('YYYYMMDD');
  const to = moment(to_param).format('YYYYMMDD');

  return callApiAxiosClient<lprDetectionList>({
    method: 'get',
    url: 'programmability/get-last-lpr',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      county: county,
      camera_id: camera_id,
      location_id: location_id,
      confidence: confidence,
      page_number: page_number,
      page_size: page_size,
      search_term: search_term,
      from: from,
      to: to,
    },
  });
}
export default getLatestLPR;
