import callApiAxiosClient from '../../axios/axios_client';
import { menuResponse } from '../../models';

async function getMenu(): Promise<menuResponse> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  return callApiAxiosClient<menuResponse>({
    method: 'get',
    url: 'menu',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getMenu;
