import { CommonModal } from '../Common/CommonModal';
import FormField from '../Common/CommonFormField';
import { toast } from 'react-toastify';
import usePut from '../../hooks/Common/usePut';
import updateCameraModel from '../../web_api/methods/settings/cameraModels/updateCameraModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useEffect, useState } from 'react';
import { setcachedCameraModelList } from '../../redux/cameraModelListReducer';
import addCameraModel from '../../web_api/methods/settings/cameraModels/addCameraModel';

type CameraModelsFormModalProps = {
  show: boolean;
  onModalToggle: () => void;
  currentModel: {
    id: number;
    name: string;
    command: string;
    command_local: string;
  };
};

const CameraModelsFormModal: React.FC<CameraModelsFormModalProps> = ({ show, onModalToggle, currentModel }) => {
  const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    isFormValid,
    setIsFormValid,
    data,
    setData,
    handleUpdateData: handleUpdateCameraModel,
    errors,
    isUpdating,
  } = usePut(
    {
      name: currentModel.name,
      command: currentModel.command,
      command_local: currentModel.command_local,
    },
    () =>
      updateCameraModel({
        model_id: currentModel.id ? currentModel.id : 0,
        name: data.name,
        command: data.command,
        command_local: data.command_local,
      }),
    setcachedCameraModelList,
    cachedCameraModelList,
    'id',
    currentModel.id,
    () => {
      onModalToggle();
      toast.success(`Camera model updated successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    }
  );

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors?.length]);

  const resetFields = () => {
    setData({
      name: '',
      command: '',
      command_local: 0,
    });
  };

  const handleAddCameraModel = async () => {
    if (!data.name || !data.command || !data.command_local) {
      setIsFormValid({
        name: !data.name ? 'is-invalid' : 'is-valid',
        command: !data.command ? 'is-invalid' : 'is-valid',
        command_local: !data.command_local ? 'is-invalid' : 'is-valid',
      });
      return;
    }
    try {
      setIsAdding(true);
      const cameraModelToAdd = await addCameraModel({
        name: data.name,
        command: data.command,
        command_local: data.command_local,
      });

      const updatedList = [...cachedCameraModelList];

      updatedList.unshift({
        name: data.name,
        id: cameraModelToAdd.id,
        command: data.command,
        command_local: data.command_local,
      });

      dispatch(setcachedCameraModelList({ list: updatedList }));
      resetFields();
      onModalToggle();
      setIsFormValid({});
      toast.success(`Camera model added successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    } catch (e) {
      console.log(e);
      setIsFormValid({});
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    } finally {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    if (currentModel.id) {
      setData({
        name: currentModel.name,
        command: currentModel.command,
        command_local: currentModel.command_local,
      });
    } else {
      setData({
        name: '',
        command: '',
        command_local: '',
      });
    }
  }, [currentModel.id]);

  return (
    <div>
      <CommonModal
        title={currentModel.id ? 'Edit the camera model' : 'Add a camera model'}
        disabled={currentModel.id ? isUpdating : isAdding}
        size='lg'
        show={show}
        showModalCallback={onModalToggle}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100 ps-15 pe-15 pb-10'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-check-lg',
          variant: 'custom',
          class: 'bg-primary text-white',
          onClick: currentModel.id ? handleUpdateCameraModel : handleAddCameraModel,
        }}
      >
        <FormField
          inputClass={isFormValid.name}
          id='name'
          label='Name'
          placeholder='Model name'
          type='text'
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
          invalidFeedback=''
          feedbackClass='d-none'
        />
        <FormField
          inputClass={isFormValid.command}
          id='command'
          label='Command'
          placeholder='rtsp://_USER_:_PASS_@_IP_:_PORT_/casa34'
          type='text'
          value={data.command}
          onChange={(e) => setData({ ...data, command: e.target.value })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='mt-2'
        />
        <FormField
          inputClass={isFormValid.command_local}
          id='command-local'
          label='Command Local'
          placeholder='rtsp://_USER_:_PASS_@_IP_:_PORT_/casa34'
          type='text'
          value={data.command_local}
          onChange={(e) => setData({ ...data, command_local: e.target.value })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='mt-2'
        />
      </CommonModal>
    </div>
  );
};

export default CameraModelsFormModal;
