import callApiAxiosClient from '../../../axios/axios_client';
import { planData } from '../../../models';

function getPlanData(locationId: number): Promise<planData> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<planData>({
    method: 'get',
    url: 'settings/locations/plan',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      locationId: locationId,
    },
  });
}
export default getPlanData;
