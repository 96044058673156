import React, { useState } from 'react';
import { CommonModal } from '../Common/CommonModal';
import verify2fa from '../../web_api/methods/account/2FA/verify-2fa';
import { loginResBodySuccess } from 'common/api/services/authService/models';
import { toast } from 'react-toastify';
import TwoFactorAuthLost from './TwoFactorAuthLostModal';

type TwoFactorAuthLoginModalProps = {
  show: boolean;
  toggleModal: () => void;
  storeUserCredentials: (loginResponse: loginResBodySuccess) => void;
  mail: string;
};

const TwoFactorAuthLoginModal: React.FC<TwoFactorAuthLoginModalProps> = ({ show, toggleModal, storeUserCredentials, mail }) => {
  const [otp, setOtp] = useState({
    value: '',
    isOtpValid: '',
    isVerifying: false,
  });

  const [lost2FA, setLost2FA] = useState(false);

  const handleInputChange = (otpInput: string) => {
    const sanitizedInput = otpInput.replace(/[^0-9]/g, '');
    const otpValue = sanitizedInput.slice(0, 6);
    setOtp({ ...otp, value: otpValue });
  };

  const verifyOtp = async () => {
    try {
      setOtp({ ...otp, isVerifying: true });
      if (otp.value.length < 6) {
        setOtp({ ...otp, isOtpValid: otp.value.length < 6 ? 'is-invalid' : 'is-valid' });
        return;
      }
      let verifiedOtp = await verify2fa(otp.value, mail);
      storeUserCredentials(verifiedOtp);
      setOtp({ ...otp, isOtpValid: '', isVerifying: false });
    } catch (e) {
      console.log(e);
      setOtp({ ...otp, isOtpValid: '', isVerifying: false });
      toast.error(`An error occured during login.`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  const handleToggleModal = () => {
    toggleModal();
    setTimeout(
      () => {
        setOtp({
          isOtpValid: '',
          value: '',
          isVerifying: false,
        });
      },
      show ? 200 : 0
    );
  };

  return (
    <>
      <CommonModal
        title='Two-factor Authentification'
        show={show}
        disabled={otp.isVerifying}
        showModalCallback={handleToggleModal}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-check',
          variant: 'custom',
          class: 'bg-primary text-white',
          text: 'Log in',
          onClick: verifyOtp,
        }}
      >
        <div className='px-4 py-1 px-sm-12 py-sm-2 d-flex flex-column justify-content-center align-items-center gap-5'>
          <i className='bi bi-lock' style={{ fontSize: '15em' }} />
          <div className='w-100'>
            <input
              type='tel'
              autoFocus
              value={otp.value}
              onChange={(e) => handleInputChange(e.target.value)}
              className={`form-control form-control-lg form-control-solid ${otp.isOtpValid}`}
              placeholder='Enter authentication code'
              name='code'
              maxLength={6}
              pattern='[0-9]*'
              inputMode='numeric'
            />
          </div>
        </div>
        <div
          className='px-4 px-sm-14 mt-3 link-primary cursor-pointer fs-7'
          onClick={() => {
            setLost2FA(true);
            handleToggleModal();
          }}
        >
          Lost your 2FA Device or Removed Your Code?
        </div>
      </CommonModal>
      <TwoFactorAuthLost show={lost2FA} toggleModal={() => setLost2FA((prev) => !prev)} toggleLoginModal={() => handleToggleModal()} />
    </>
  );
};

export default TwoFactorAuthLoginModal;
