import callApiAxiosClient from '../../../axios/axios_client';
import { addCameraResponse } from '../../../models';

async function addCamera(
  name: string,
  ip_address: string,
  port: string,
  user: string,
  password: string,
  model_id: number,
  camera_location_id: number,
  long?: number,
  lat?: number,
  width?: number,
  height?: number
): Promise<addCameraResponse> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  const data =
    long && lat
      ? {
          name: name,
          ip_address: ip_address,
          port: port,
          user: user,
          password: password,
          model_id: model_id,
          camera_location_id: camera_location_id,
          long: long,
          lat: lat,
          width: width,
          height: height,
        }
      : {
          name: name,
          ip_address: ip_address,
          port: port,
          user: user,
          password: password,
          model_id: model_id,
          camera_location_id: camera_location_id,
          width: width,
          height: height,
        };
  return callApiAxiosClient<addCameraResponse>({
    method: 'post',
    url: 'settings/cameras',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: data,
  });
}
export default addCamera;
