import React from 'react';
import { CommonModal } from '../Common/CommonModal';

type ConfirmDeleteProps = {
  show: boolean;
  onModalToggle: () => void;
  onDelete: () => void;
};

const ConfirmDeleteModal: React.FC<ConfirmDeleteProps> = ({ show, onModalToggle, onDelete }) => {
  return (
    <div>
      <CommonModal
        title='Confirm Delete'
        closeButton={true}
        show={show}
        showModalCallback={onModalToggle}
        closeButtonOptions={{
          icon: 'bi bi-x-lg',
          variant: 'secondary',
          class: 'd-flex flex-row align-items-center',
        }}
        confirmButton={true}
        confirmButtonOptions={{
          icon: 'bi bi-trash fs-4',
          variant: 'danger',
          class: 'd-flex flex-row align-items-center',
          onClick: () => {
            onDelete();
            onModalToggle();
          },
        }}
      >
        <div className='fs-3'>Are you sure you want to delete all changes?</div>
      </CommonModal>
    </div>
  );
};

export default ConfirmDeleteModal;
