import { useState } from 'react';

const useModals = (initialModalStates: boolean[]) => {
    const [show, setShow] = useState<boolean[]>(initialModalStates);

    const toggleModal = (modalIndex: number) => {
        setShow((prevShow) =>
            prevShow.map((state, index) => (index === modalIndex ? !state : state))
        );
    };

    return {
        show,
        toggleModal,
    };
};

export default useModals;
