import { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { ELoadType, detectionRegion, detectionSettingsItem, detectionSettingsList, polygonObjectList, planData } from '../../web_api/models';
import getDetectionSettings from '../../web_api/methods/detectionSettings/detectionSettings';
import useLoadImage from '../../hooks/FileManagement/useLoadImage';
import getSnapshot from '../../web_api/methods/files/getSnapshot';
import useGet from '../../hooks/Common/useGet';
import getDetectionRegions from '../../web_api/methods/settings/detectionRegions/getDetectionRegions';
import CommonAnnotatedImage from '../Common/CommonAnnotatedImage';
import { toast } from 'react-toastify';
import defaultPlan from './Penny_Plan.jpg';
import addAreaInPlan from '../../web_api/methods/detectionSettings/addAreaInPlan';
import modifyAreaInPlan from '../../web_api/methods/detectionSettings/modifyAreaInPlan';
import deleteAreaInPlan from '../../web_api/methods/detectionSettings/deleteAreaInPlan';
import getPlanData from '../../web_api/methods/settings/locations/getPlanData';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import CommonFormField from '../Common/CommonFormField';
import updatePlan from '../../web_api/methods/settings/locations/modifyPlan';

const LocationPlan = () => {
  const { setToolbar } = useToolbarSetter();
  const [locationCameras, setLocationCameras] = useState<detectionSettingsList>([]);
  const [selectedCamera, setSelectedCamera] = useState<detectionSettingsItem>();
  const [planDetectionRegions, setPlanDetectionRegions] = useState<detectionRegion[]>([]);
  const [isLoadingPlan, setIsLoadingPlan] = useState(false);
  const [planData, setPlanData] = useState<planData>();
  const [planPicture, setPlanPicture] = useState<string>('');

  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const params = useParams();

  const [save, setSave] = useState(false);

  const id = Number(params.id);

  useEffect(() => {
    setToolbar('annotate-location', 'Annotate Store Plan');
  });

  const computePlanPicture = async () => {
    const res = await getAzureFile('alerts-media/' + tenantKey + '/locations/' + id + '.jpg');
    setPlanPicture(res);
    return res;
  };

  const loadDetections = async () => {
    setIsLoadingPlan(true);
    setPlanDetectionRegions([]);
    const response = await getDetectionSettings(65, id);
    setLocationCameras(response);
    computePlanPicture();

    const planDataRes = await getPlanData(id);
    setPlanData(planDataRes);

    console.log('planData', planData);

    for (const camera of response) {
      const res = await getDetectionRegions(camera.id);
      if (res.polygons.length > 0) {
        res.polygons[0].body.push({
          id: Math.random(),
          type: 'area',
          purpose: 'tagging',
          value: res.detection_setting_id.toString(),
        });
        res.polygons = res.polygons.filter((polygon) => polygon.body.find((body) => body.purpose === 'tagging') !== undefined);
        setPlanDetectionRegions((prev) => [...prev, res]);
      }
    }
    setIsLoadingPlan(false);
  };

  const savePlanSettings = async () => {
    await updatePlan({ location_id: id, resolution: planData?.resolution, time_delay: planData?.time_delay }).then(() => {
      toast.success('Plan Settings Updated!');
    });
  };

  useEffect(() => {
    console.log('planDetectionRegions', planDetectionRegions);
  }, [planDetectionRegions]);

  const { loadingImage, url } = useLoadImage(selectedCamera ? selectedCamera?.camera_id : undefined, false, getSnapshot, ELoadType.SINGLE);
  const { data, setData, loadItems, loading } = useGet([], () => getDetectionRegions(selectedCamera?.id));

  useEffect(() => {
    loadDetections();
  }, [id]);

  useEffect(() => {
    loadItems();
  }, [selectedCamera]);

  const saveChanges = async (currentPolygons: polygonObjectList) => {
    console.log('saveChanges', data);
    if (save && data) {
      const originalPolygons = [...planDetectionRegions.flatMap((region) => region.polygons)];

      console.log('originalPolygons', originalPolygons);

      console.log('currentPolygons', currentPolygons);

      const addedPolygons = currentPolygons.filter((currentPolygon) => !originalPolygons.some((p) => p.id === currentPolygon.id));
      console.log('addedPolygons', addedPolygons);

      const deletedPolygons = originalPolygons.filter((originalPolygon) => !currentPolygons.some((p) => p.id === originalPolygon.id));
      console.log('deletedPolygons', deletedPolygons);

      const deletedPolygonIds = deletedPolygons.map(
        (polygon) => polygon.body.find((b) => b.purpose === 'tagging' && b.value !== 'homography')?.value || '0'
      );
      console.log('deletedPolygonIds', deletedPolygonIds);

      const updatedPolygons = currentPolygons.filter((currentPolygon) => {
        const originalPolygon = originalPolygons.find((p) => p.id === currentPolygon.id);
        if (!originalPolygon) {
          return false;
        }
        const polygonChanged = originalPolygon.polygon !== currentPolygon.polygon;
        const bodyChanged = JSON.stringify(originalPolygon.body) !== JSON.stringify(currentPolygon.body);
        return polygonChanged || bodyChanged;
      });
      console.log('updatedPolygons', updatedPolygons);

      const errors: string[] = [];

      const handleAddAnnotation = async () => {
        try {
          const areaPlans = addedPolygons.map((p) => {
            return { detection_id: p.body[0].id, area_in_plan: p.polygon };
          });
          await addAreaInPlan(areaPlans);
        } catch (error) {
          errors.push(`Add Error: ${error}`);
        }
      };

      const handleDeleteAnnotation = async () => {
        try {
          await deleteAreaInPlan(deletedPolygonIds);
        } catch (error) {
          errors.push(`Delete Error: ${error}`);
        }
      };

      const handleUpdateAnnotation = async () => {
        try {
          const areaPlans = updatedPolygons.map((p) => {
            return {
              detection_id: parseInt(p.body.find((b) => b.purpose === 'tagging' && b.value !== 'homography')?.value || '0'),
              area_in_plan: p.polygon,
            };
          });
          await modifyAreaInPlan(areaPlans);
        } catch (error) {
          errors.push(`Update Error: ${error}`);
        }
      };

      if (addedPolygons.length > 0) {
        await handleAddAnnotation().then(async (res) => {
          if (deletedPolygons.length > 0) {
            await handleDeleteAnnotation().then(async (res) => {
              if (updatedPolygons.length > 0) {
                await handleUpdateAnnotation();
              }
            });
          } else {
            if (updatedPolygons.length > 0) {
              await handleUpdateAnnotation();
            }
          }
        });
      } else {
        if (deletedPolygons.length > 0) {
          await handleDeleteAnnotation().then(async (res) => {
            if (updatedPolygons.length > 0) {
              await handleUpdateAnnotation();
            }
          });
        } else {
          if (updatedPolygons.length > 0) {
            await handleUpdateAnnotation();
          }
        }
      }

      if (errors.length > 0) {
        errors.forEach((error) => {
          toast.error(error, {
            position: 'top-right',
            theme: 'colored',
            autoClose: 2000,
          });
        });
      } else if (addedPolygons.length > 0 || deletedPolygons.length > 0 || updatedPolygons.length > 0) {
        toast.success('Changes saved successfully!', {
          position: 'top-right',
          theme: 'colored',
          autoClose: 2000,
        });
      }
      setData({
        detection_setting_id: Number(id),
        polygons: currentPolygons,
      });
      setSave(false);
      setPlanDetectionRegions((prev) =>
        prev.map((region) => ({
          ...region,
          polygons: currentPolygons.filter(
            (polygon) => polygon.body.find((b) => b.purpose === 'tagging')?.value === region.detection_setting_id.toString()
          ),
        }))
      );
    } else {
      console.log('saveChanges false', save, data);
    }
  };

  return (
    <div className='container p-4'>
      <div className='d-flex flex-column gap-3'>
        <div className='d-flex flex-row gap-4 w-100'>
          <Dropdown drop='end'>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
              {selectedCamera?.camera_name ? `${selectedCamera.camera_name}` : 'All Cameras'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setSelectedCamera(undefined);
                }}
              >
                All Cameras
              </Dropdown.Item>
              {locationCameras.map((camera) => (
                <Dropdown.Item
                  key={camera.camera_id}
                  onClick={() => {
                    setSelectedCamera(camera);
                  }}
                >
                  {camera.camera_name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className='d-flex flex-row gap-2 align-items-center '>
            <Form.Label>Patch Size</Form.Label>
            <Form.Control
              defaultValue={planData?.resolution}
              className='w-100px'
              onChange={(e) => {
                const newResolution = parseInt(e.target.value, 10);
                setPlanData((prev) => ({
                  resolution: isNaN(newResolution) ? 0 : newResolution,
                  filename: prev?.filename ?? '',
                  location_id: prev?.location_id ?? 0,
                  picture_width: prev?.picture_width ?? 0,
                  picture_height: prev?.picture_height ?? 0,
                  time_delay: prev?.time_delay ?? 0,
                }));
              }}
            />
          </div>
          <div className='d-flex flex-row gap-2 align-items-center'>
            <Form.Label>Time Delay</Form.Label>
            <Form.Control
              defaultValue={planData?.time_delay}
              className='w-100px'
              onChange={(e) => {
                const newTimeDelay = parseInt(e.target.value, 10);
                setPlanData((prev) => ({
                  resolution: prev?.resolution ?? 0,
                  filename: prev?.filename ?? '',
                  location_id: prev?.location_id ?? 0,
                  picture_width: prev?.picture_width ?? 0,
                  picture_height: prev?.picture_height ?? 0,
                  time_delay: isNaN(newTimeDelay) ? 0 : newTimeDelay,
                }));
              }}
            />
          </div>
          <Button variant='secondary' className='ms-4' onClick={savePlanSettings}>
            Save Settings
          </Button>
        </div>
        <div className='d-flex flex-md-row justify-content-between gap-4 align-items-top'>
          <div className='card p-3' style={{ width: '70%', height: 'fit-content' }}>
            {/* Add the next bit after dynamic plan loading */}
            {/* <div className='bg-secondary d-flex align-items-center justify-content-center gap-3 rounded' style={{ height: '70vh' }}>
              <i className='bi bi-exclamation-triangle fs-1 text-secondary-emphasis'></i>
              <div className='text-secondary-emphasis fw-bold'>No Images</div>
            </div> */}
            {(selectedCamera || !selectedCamera) && !isLoadingPlan && planPicture !== '' && (
              <CommonAnnotatedImage
                key={selectedCamera?.id || 0}
                img={planPicture}
                className='rounded img-fluid w-100'
                saveChanges={saveChanges}
                data={planDetectionRegions.flatMap((region) => region.polygons)}
                save={save}
                active={true}
                isPlanArea={true}
                selectedCamera={selectedCamera}
                width={planData?.picture_width}
                height={planData?.picture_height}
              />
            )}
          </div>
          <div style={{ width: '30%' }} className='d-flex flex-column justify-content-between'>
            <div className='card p-3'>
              {!selectedCamera && (
                <div className='bg-secondary d-flex align-items-center justify-content-center gap-3 rounded' style={{ height: '30vh' }}>
                  <i className='bi bi-exclamation-triangle fs-1 text-secondary-emphasis'></i>
                  <div className='text-secondary-emphasis fw-bold'>No Images</div>
                </div>
              )}
              {selectedCamera && !loadingImage && !loading && (
                <CommonAnnotatedImage
                  img={url}
                  className='rounded img-fluid w-100'
                  saveChanges={() => {}}
                  data={data!.polygons}
                  save={false}
                  active={false}
                />
              )}
            </div>
            <Button variant='secondary' className='align-self-end' onClick={() => setSave(true)}>
              Save annotations
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationPlan;
