import React, { useEffect, useState } from 'react'
import peopleCountingMonthlyReoprts from '../../web_api/methods/peopleCountingDash/monthlyReports';
import { monthlyReportsList } from '../../web_api/models';
import { ChartsWidget8 } from '../../_metronic/widgets/charts/ChartsWidget8';

type Props = {
    className: string
}

const MonthlyReportsPeopleCounting: React.FC<Props> = ({ className }) => {
    const [values, setValues] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [months, setMonths] = useState<string[]>([])
    const [valuesLastYear, setValuesLastYear] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [average, setAverage] = useState<number>(0)
    const [loading, setLoading] = useState(false)
    const [max, setMax] = useState<number>(0)


    useEffect(() => {
        loadMonthlyReports();
    }, []);

    const loadMonthlyReports = async () => {
        try {
            const to = new Date()
            const from = new Date(new Date().getFullYear(), 0, 1);
            const fromLastYear = new Date(new Date().getFullYear() - 1, 0, 1);
            const currentYear = new Date().getFullYear()
            let monthlyReports: monthlyReportsList = await peopleCountingMonthlyReoprts(fromLastYear, to);
            let forValues = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            let forMonths = new Array()
            let forValuesLastYear = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            let sum = 0;
            let max = 0;

            monthlyReports.forEach((item) => {
                if (item.an !== currentYear) {
                    forValuesLastYear[item.luna - 1] = item.average
                }
                else {
                    forValues[item.luna - 1] = item.average
                    sum += item.average
                }
                // forValuesLastYear.push(item.last_year)
                if (item.average > max)
                    max = item.average

            })
            setMonths(forMonths)
            setValues(forValues)
            setValuesLastYear(forValuesLastYear)
            setAverage(Math.floor(sum / forValues.length))
            setMax(max)
            setLoading(true)
        }
        catch (e) {
            console.log(e)
        }
    };

    return (
        <ChartsWidget8
            className={className}
            chartColor='primary'
            secondaryColor='danger'
            values={values}
            valuesLastYear={valuesLastYear}
            months={months}
            average={average}
            max={max}
            loading={loading} />
    )
}

export { MonthlyReportsPeopleCounting }