import { useEffect } from 'react';

const SidebarMobileToggle = () => {
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, []);

    const handleToggle = () => {
        const sidebar = document.getElementById('kt_app_sidebar');
        const classes = ['drawer', 'drawer-start', 'drawer-on'];
        classes.forEach((x) => sidebar?.classList.toggle(x));
        sidebar?.scrollTo({ top: 0, behavior: 'auto' });

    };

    const handleOutsideClick = (e: MouseEvent) => {
        const sidebar = document.getElementById('kt_app_sidebar');
        const toggleButton = document.getElementById('kt_app_sidebar_mobile_toggle');
        if (sidebar && toggleButton && !sidebar.contains(e.target as Node) && !toggleButton.contains(e.target as Node)) {
            sidebar.classList.remove('drawer', 'drawer-start', 'drawer-on');
        }
    };

    return (
        <div className="d-lg-none min-h-45px p-3" style={{  backgroundColor: '#1E1E2D' }}>
            <i
                id="kt_app_sidebar_mobile_toggle"
                className="bi bi-list-nested"
                style={{ fontSize: '25px' }}
                onClick={handleToggle}
            ></i>
        </div>
    );
};

export default SidebarMobileToggle;
