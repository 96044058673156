/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils'
import { DayWeekMonthYearEnum, entriesHoursList } from '../../../web_api/models'
import peopleCountingEntriesHours from '../../../web_api/methods/peopleCountingDash/entriesHours'
import * as ReactBootstrap from 'react-bootstrap'
import { numberFormat } from '../../../web_api/methods/helpers'
import LoadingPage from '../../../layout/LoadingPage'


type Props = {
  className: string
}

const ChartsWidget10: React.FC<Props> = ({ className }) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [filter, setFilter] = useState<DayWeekMonthYearEnum>(DayWeekMonthYearEnum.WEEK)
  const [entries, setEntries] = useState<number[]>([])
  const [period, setPeriod] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadEntriesHours(filter)
  }, [filter])

  let loadEntriesHours = async (filter: DayWeekMonthYearEnum) => {
    try {
      var from = new Date()
      var to = new Date()
      if (filter === DayWeekMonthYearEnum.DAY) {
        from = new Date(new Date().setHours(0, 0, 0, 0));
        to.setDate(to.getDate() + 1)
      }
      else if (filter === DayWeekMonthYearEnum.WEEK) {
        from.setDate(from.getDate() - 7)
      }
      else {
        from = new Date(new Date().setMonth(new Date().getMonth() - 1));
      }
      let loadEntriesWithHours: entriesHoursList = await peopleCountingEntriesHours(from, to);
      let forEntries = new Array()
      let forHours = new Array()
      loadEntriesWithHours.forEach((item) => {
        forEntries.push(item.entries)
        forHours.push(item.period)
      })
      setEntries(forEntries)
      setPeriod(forHours)
      setLoading(true)
    }
    catch (e) {
      console.log(e)
    }
  };

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, period, entries])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, period, entries))
    if (chart) {
      chart.render()
    }

    return chart
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Hourly</span>
          <span className='text-muted fw-semibold fs-7'>Entries in hour intervals</span>
        </h3>
        <div className='d-flex align-items-center flex-shrink-0'>
          <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
            Filter By:
          </span>
          <div className='flex-shrink-0 '>
            <ul className='nav'>
              <li className='nav-item' onClick={() => { setFilter(DayWeekMonthYearEnum.DAY); setLoading(false) }}>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filter === DayWeekMonthYearEnum.DAY ? ' active' : '')}
                  data-bs-toggle='tab'
                >
                  Today
                </a>
              </li>

              <li className='nav-item' onClick={() => { setFilter(DayWeekMonthYearEnum.WEEK); setLoading(false) }}>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filter === DayWeekMonthYearEnum.WEEK ? ' active' : '')}
                  data-bs-toggle='tab'
                >
                  Week
                </a>
              </li>

              <li className='nav-item' onClick={() => { setFilter(DayWeekMonthYearEnum.MONTH); setLoading(false) }}>
                <a
                  className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (filter === DayWeekMonthYearEnum.MONTH ? ' active' : '')}
                  data-bs-toggle='tab'
                >
                  Month
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        {
          loading ?
            (<div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '450px' }} />)
            :
            (<LoadingPage />)
        }
      </div>
    </div>
  )
}

export { ChartsWidget10 }

function getChartOptions(height: number, xAxis: string[], yAxis: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  const baseColor = getCSSVariableValue('--kt-primary')
  const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
    series: [
      {
        name: 'Alerts',
        data: yAxis,
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return numberFormat(val) + ' entries'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true
        }
      }
    },
  }
}
