import { useState, useEffect } from 'react';
import { locationsList, userItemList } from '../web_api/models';
import getLocations from '../web_api/methods/settings/locations/getLocations';
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../redux/activeLocationReducer';
import { RootState } from '../redux/store';
import { DateRange, DayPicker } from 'react-day-picker';
import * as ReactBootstrap from 'react-bootstrap';
import { setValue as setDateFilter } from '../redux/dateFilterReducer';
import { setValue as setHourParameters } from '../redux/hourParametersReducer';
import { setValue as setSelectedUser } from '../redux/selectedUserReducer';
import { setValue as setUserList } from '../redux/userListReducer';
import getUsers from '../web_api/methods/account/getUsers';
import { setCachedLocationList } from '../redux/cachedLocationsReducer';
import { Dropdown } from 'react-bootstrap';

const ToolbarReports = () => {
  const [locations, setLocations] = useState<locationsList>([]);
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations);
  const locationIds = JSON.stringify(cachedLocationList.list.map((location) => location.id));
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const [fromHour, setFromHour] = useState<number>(parseInt(hourParameters.from));
  const [toHour, setToHour] = useState<number>(parseInt(hourParameters.to));
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const userList = useSelector((state: RootState) => state.userList);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const defaultSelected: DateRange = {
    from: new Date(dateFilter.from),
    to: new Date(dateFilter.to),
  };
  const [period, setPeriod] = useState<string>('');

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const handleCloseModal = () => {
    setShow(false);
    dispatch(
      setDateFilter({
        from: range!?.from!.toISOString(),
        to: range!?.to!.toISOString(),
      })
    );
    dispatch(
      setHourParameters({
        from: fromHour.toString(),
        to: toHour.toString(),
      })
    );
  };

  const handleFromHourChange = (event: any) => {
    const number = parseInt(event.target.value);
    if (number >= 0 && number <= 24) {
      setFromHour(number);
    }
  };

  const handleToHourChange = (event: any) => {
    const number = parseInt(event.target.value);
    if (number == -0) setToHour(24);
    if (number >= 0 && number <= 24) {
      setToHour(number);
    }
  };

  const handleShowModal = () => setShow(true);

  let selectedRange = <>Date picker</>;
  if (range?.from) {
    if (!range.to) {
      range.to = range.from;
      // selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
      selectedRange = (
        <>
          {range.from
            .toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/\//g, '-')}
        </>
      );
    } else if (range.to) {
      selectedRange =
        range.to.toString() === range.from.toString() ? (
          <>
            {range.from
              .toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        ) : (
          <>
            {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
            {range.from
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}{' '}
            -{' '}
            {range.to
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        );
    }
  }
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
    if (cachedLocationList.list.length === 0) loadLocations();
    if (userList.list.length === 0) loadUsers();
  }, []);

  useEffect(() => {
    loadLocations();
  }, [locationIds]);

  const loadUsers = async () => {
    try {
      let loadedUsers: userItemList = await getUsers();
      dispatch(
        setUserList({
          list: loadedUsers,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const loadLocations = async () => {
    try {
      let loadedLocations: locationsList = await getLocations();
      setLocations(loadedLocations);
      if (loadedLocations.length == 1)
        dispatch(
          setValue({
            value: loadedLocations[0].name,
            id: loadedLocations[0].id.toString(),
          })
        );
      dispatch(
        setCachedLocationList({
          list: loadedLocations,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (period !== '') {
      let from = new Date();
      let to = new Date();
      switch (period) {
        case 'Current Week':
          from.setDate(from.getDate() - from.getDay());
          to.setDate(to.getDate() + (6 - to.getDay()));
          break;
        case 'Last Week':
          from.setDate(from.getDate() - from.getDay() - 7);
          to.setDate(to.getDate() + (6 - to.getDay() - 7));
          break;
        case 'Current Month':
          from.setDate(1);
          to.setMonth(to.getMonth() + 1);
          to.setDate(0);
          break;
        case 'Last 3 Months':
          from.setMonth(from.getMonth() - 3);
          from.setDate(1);
          to.setDate(0);
          break;
        case 'Last 6 months':
          from.setMonth(from.getMonth() - 6);
          from.setDate(1);
          to.setDate(0);
          break;
        case 'Current Year':
          from.setMonth(0);
          from.setDate(1);
          to.setMonth(11);
          to.setDate(31);
          break;
        case 'Last Year':
          from.setFullYear(from.getFullYear() - 1);
          from.setMonth(0);
          from.setDate(1);
          to.setFullYear(to.getFullYear() - 1);
          to.setMonth(11);
          to.setDate(31);
          break;
      }
      setRange({ from: from, to: to });
    }
  }, [period]);

  return (
    <div
      className={`d-flex align-items-center flex-column flex-md-row gap-3 gap-md-0 mt-4 mt-md-0 w-100 w-md-auto ${
        window.innerWidth > 768 ? 'overflow-auto' : ''
      }`}
    >
      {/* begin::Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto p-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>User:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedUser.value}
          onChange={(e) => {
            dispatch(
              setSelectedUser({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );

            dispatch(
              setValue({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {userList.list.map((item) => (
            <option key={item.id} value={item.name} data-id={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto p-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Location:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={activeLocation.value}
          onChange={(e) => {
            dispatch(
              setValue({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {selectedUser.value === 'All'
            ? locations.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>
                  {item.id + ' - ' + item.name}
                </option>
              ))
            : userList.list
                .find((x) => x.id === selectedUser.id)
                ?.locations.map((item) => (
                  <option key={item.id} value={item.name} data-id={item.id}>
                    {item.id + ' - ' + item.name}
                  </option>
                ))}
        </select>
      </div>
      {/* end::Wrapper */}

      {/* begin::Separartor */}
      <div className='bullet bg-secondary h-35px w-1px mx-5 d-none d-md-block'></div>
      {/* end::Separartor */}

      {/* begin::Wrapper */}
      <div className='d-flex align-items-center justify-content-center w-100 w-md-auto p-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Range:</span>
        {/* end::Label */}

        {/* begin::Select */}
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
            <i className='bi bi-calendar mb-1 align-self-center' />
            <span className='text-nowrap'>{selectedRange}</span>
          </div>
        </div>
        {/* end::Select */}
        {/* <div className='cursor-pointer symbol symbol-35px symbol-md-40px d-flex flex-row mx-2 fs-7 fw-bold text-gray-700 '>
          <i className="bi bi-person mx-2 fs-3 fw-bold text-gray-700 " />
          <span className='m-auto '>{userName}</span>
          <i className="bi bi-caret-down-fill ms-1 m-auto pt-1" style={{ fontSize: '1rem' }} />

        </div> */}
        {/* begin::Actions */}
        {/* end::Actions */}
      </div>

      {/* end::Wrapper */}
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row align-items-center justify-content-between px-5 gap-2'>
              <h4 className='d-flex justify-content-center text-secondary-emphasis'>Predefined Periods</h4>
              <Dropdown>
                <Dropdown.Toggle size='sm' variant='primary' id='dropdown-basic'>
                  {period === '' ? `Select a period` : period}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setPeriod('Current Week')}>Current Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Last Week')}>Last Week</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Current Month')}>Current Month</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Last 3 Months')}>Last 3 Months</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Last 6 months')}>Last 6 Months</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Current Year')}>Current Year</Dropdown.Item>
                  <Dropdown.Item onClick={() => setPeriod('Last Year')}>Last Year</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='d-flex flex-row'>
              <DayPicker
                id='1'
                fromYear={2020}
                toYear={new Date().getFullYear()}
                toMonth={new Date()}
                toDate={new Date()}
                defaultMonth={range?.to === undefined ? range?.from : range.to}
                mode='range'
                selected={range}
                onSelect={setRange}
                footer={selectedRange}
                captionLayout='dropdown'
              />
            </div>
            <div className='d-flex flex-row px-5'>
              <span className='d-flex fs-3 fw-bold flex-start'>Between</span>
              <div className='d-flex m-auto flex-row'>
                <select
                  defaultValue={fromHour}
                  className='d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5'
                  onChange={handleFromHourChange}
                >
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
                <span className='d-flex m-auto'>:</span>
                <select
                  defaultValue={toHour}
                  className='d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5'
                  onChange={handleToHourChange}
                >
                  {[...Array(25)].map(
                    (_, i) =>
                      i >= fromHour && (
                        <option key={i} value={i}>
                          {i}
                        </option>
                      )
                  )}
                </select>
              </div>
            </div>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarReports };
