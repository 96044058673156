import { useState, useEffect } from 'react';
import { locationsList, userItemList } from '../web_api/models';
import getLocations from '../web_api/methods/settings/locations/getLocations';
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../redux/activeLocationReducer';
import { RootState } from '../redux/store';
import { DayPicker } from 'react-day-picker';
import * as ReactBootstrap from 'react-bootstrap';
import { setValue as setBakeryDateFilter } from '../redux/toolbarDateFilterReducer';
import { setValue as setBakeryHourFilter } from '../redux/toolbarHourFilterReducer';
import { setValue as setSelectedUser } from '../redux/selectedUserReducer';
import { setValue as setUserList } from '../redux/userListReducer';
import { setValue as setSelectedMetric } from '../redux/selectedMetricReducer';
import getUsers from '../web_api/methods/account/getUsers';

export enum Metric {
  METRIC_1 = 'metric-1',
  METRIC_2 = 'metric-2',
  METRIC_3 = 'metric-3',
  METRIC_4 = 'metric-4',
}

const ToolbarStatistics = () => {
  const [locations, setLocations] = useState<locationsList>([]);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter.date);

  const selectedMetric = useSelector((state: RootState) => state.selectedMetric);

  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const userList = useSelector((state: RootState) => state.userList);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const toolbar = useSelector((state: RootState) => state.toolbar);

  const [selectedDay, setSelectedDay] = useState<Date | undefined>(new Date(toolbarDateFilter));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const handleCloseModal = () => {
    setShow(false);
    dispatch(
      setBakeryDateFilter({
        date: selectedDay!.toString(),
      })
    );
  };

  const handleShowModal = () => setShow(true);

  const footer = selectedDay ? (
    <p className='m-auto'>
      {selectedDay
        .toLocaleString('en-US', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
        .replace(/\//g, '-')}
      .
    </p>
  ) : (
    <p className='m-auto'>Pick a day.</p>
  );
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
    loadLocations();
    if (userList.list.length === 0) {
      loadUsers();
    }
  }, []);

  const loadUsers = async () => {
    try {
      let loadedUsers: userItemList = await getUsers();
      dispatch(
        setUserList({
          list: loadedUsers,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const loadLocations = async () => {
    try {
      let loadedLocations: locationsList = await getLocations();
      setLocations(loadedLocations);
      if (loadedLocations.length == 1)
        dispatch(
          setValue({
            value: loadedLocations[0].name,
            id: loadedLocations[0].id.toString(),
          })
        );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className='d-flex flex-column flex-md-row gap-3 gap-md-0 align-items-center mt-6 mt-md-0 w-100 w-md-auto'>
      {/* begin::Wrapper */}

      <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Metric:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-175px form-select-solid w-75 w-md-auto'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedMetric.value}
          onChange={(e) => {
            dispatch(
              setSelectedMetric({
                value: e.target.value,
              })
            );
          }}
        >
          <option key={0} value={Metric.METRIC_1} data-id={0}>
            Metric 1
          </option>
          <option key={1} value={Metric.METRIC_2} data-id={1}>
            Metric 2
          </option>
          <option key={2} value={Metric.METRIC_3} data-id={2}>
            Metric 3
          </option>
          <option key={3} value={Metric.METRIC_4} data-id={3}>
            Metric 4
          </option>
          {/* {userList.list.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>
                  {item.name}
                </option>
              ))} */}
        </select>
      </div>
      <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>User:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75 w-md-auto'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedUser.value}
          onChange={(e) => {
            dispatch(
              setSelectedUser({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
            dispatch(
              setValue({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {userList.list.map((item) => (
            <option key={item.id} value={item.name} data-id={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className='bullet d-none d-md-block bg-secondary h-35px w-1px mx-5'></div>

      {/* begin::Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Day:</span>
        {/* end::Label */}

        {/* begin::Select */}
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
            <i className='bi bi-calendar mb-1 align-self-center' />
            <span className='text-nowrap'>{footer}</span>
          </div>
        </div>
        {/* end::Select */}
      </div>

      {/* end::Separartor */}
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row'>
              <DayPicker mode='single' selected={selectedDay} onSelect={setSelectedDay} />
            </div>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarStatistics };
