import callApiAxiosClient from "../../axios/axios_client";

function deleteAlertDetail(detection_gu_id: string | null): Promise<void> {
    if (detection_gu_id === null) {
        throw new Error("Invalid alert id");
    }
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "delete",
        url: "detections/detail",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            detection_gu_id: detection_gu_id
        }
    })
}
export default deleteAlertDetail;