import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { convertToSvgString, extractPolygonGeometry } from '../../web_api/methods/helpers';
import { detectionSettingsItem, polygonObjectInList, polygonObjectList } from '../../web_api/models';

type CommonAnnotatedImageProps = {
  data: polygonObjectList;
  img: string;
  className?: string;
  saveChanges: (polygons: polygonObjectList) => void;
  save: boolean;
  active?: boolean;
  isPlanArea?: boolean;
  selectedCamera?: detectionSettingsItem;
  width?: number;
  height?: number;
};

const CommonAnnotatedImage: React.FC<CommonAnnotatedImageProps> = ({
  data,
  img,
  className,
  saveChanges,
  save,
  active = true,
  isPlanArea = false,
  selectedCamera,
  width,
  height,
}) => {
  const [anno, setAnno] = useState<any>();

  const [image, setImage] = useState({
    height: 0,
    width: 0,
  });

  const [polygons, setPolygons] = useState<polygonObjectList>([]);

  const imgEl = useRef<HTMLImageElement>(null);

  useEffect(() => {
    setPolygons(data);
  }, []);

  useEffect(() => {
    let annotorious: any = null;

    if (imgEl.current) {
      //   console.log('isPlanArea', isPlanArea);
      annotorious = new Annotorious({
        image: imgEl.current,
        drawOnSingleClick: true,
        readOnly: !active,
        disableSelect: !active,
        disableEditor: !active,
        allowEmpty: isPlanArea,
      });

      annotorious.setDrawingTool('polygon');

      annotorious.on('createAnnotation', (annotation: any) => {
        const polygonStringRaw = annotation.target.selector.value;
        const polygonGeometry = extractPolygonGeometry(polygonStringRaw);
        console.log('selectedCamera', selectedCamera);
        if (isPlanArea) {
          let polygonObj: polygonObjectInList = {
            id: annotation.id,
            body:
              isPlanArea && selectedCamera
                ? [...annotation.body, { id: selectedCamera.id, purpose: 'tagging', value: selectedCamera.id }]
                : annotation.body,
            area_in_plan: polygonGeometry,
            polygon: polygonGeometry,
          };
          setPolygons((prevPolygons) => {
            console.log('prevPolygons', prevPolygons);
            console.log('polygonObj areainPlan', polygonObj);
            return [...prevPolygons, polygonObj];
          });
        } else {
          let polygonObj: polygonObjectInList = {
            id: annotation.id,
            body:
              isPlanArea && selectedCamera
                ? [...annotation.body, { id: selectedCamera.id, purpose: 'tagging', value: selectedCamera.id }]
                : annotation.body,
            polygon: polygonGeometry,
          };
          setPolygons((prevPolygons) => {
            console.log('prevPolygons', prevPolygons);
            console.log('polygonObj normal', polygonObj);
            return [...prevPolygons, polygonObj];
          });
        }
      });

      annotorious.on('updateAnnotation', (annotation: any, previous: any) => {
        setPolygons((prevPolygons) =>
          prevPolygons.map((polygon) =>
            polygon.id === previous.id
              ? {
                  id: previous.id,
                  body: annotation.body,
                  polygon: extractPolygonGeometry(annotation.target.selector.value),
                }
              : polygon
          )
        );
      });

      annotorious.on('deleteAnnotation', (annotation: any) => {
        setPolygons((prevPolygons) => prevPolygons.filter((polygon) => polygon.id !== annotation.id));
      });
    }

    setAnno(annotorious);

    return () => {
      if (annotorious) {
        annotorious.destroy();
      }
    };
  }, []);

  useEffect(() => {
    console.log('polygons', polygons);
    console.log('isPlanArea', isPlanArea);
    if (anno && polygons.length > 0) {
      anno.clearAnnotations();
      polygons.forEach((polygon) => {
        if (isPlanArea && polygon.area_in_plan) {
          const annotation = {
            context: 'http://www.w3.org/ns/anno.jsonld',
            id: polygon.id,
            target: {
              selector: {
                type: 'SvgSelector',
                value: convertToSvgString(polygon.area_in_plan),
              },
            },
            body: polygon.body,
            type: 'Annotation',
          };
          anno.addAnnotation(annotation);
        }
        if (!isPlanArea && polygon.polygon) {
          const annotation = {
            context: 'http://www.w3.org/ns/anno.jsonld',
            id: polygon.id,
            target: {
              selector: {
                type: 'SvgSelector',
                value: convertToSvgString(polygon.polygon),
              },
            },
            body: polygon.body,
            type: 'Annotation',
          };
          anno.addAnnotation(annotation);
        }
      });
    }
  }, [anno, polygons.length]);

  useEffect(() => {
    const resize = () => {
      setImage({
        height: imgEl.current?.clientWidth ?? 0,
        width: imgEl.current?.clientHeight ?? 0,
      });
    };
    resize();
    window.addEventListener('resize', resize);
  }, [imgEl.current?.clientHeight, imgEl.current?.clientWidth]);

  useEffect(() => {
    saveChanges(polygons);
  }, [save]);

  return (
    <div>
      {width && height ? (
        <img className={`${className}`} ref={imgEl} src={img} alt='' width={width} height={height} />
      ) : (
        <img className={`${className}`} ref={imgEl} src={img} alt='' />
      )}
    </div>
  );
};

export default CommonAnnotatedImage;
