import { useEffect, useRef, useState } from 'react';
import { Annotorious } from '@recogito/annotorious';
import '@recogito/annotorious/dist/annotorious.min.css';
import { convertToSvgString, extractPolygonGeometry } from '../../web_api/methods/helpers';
import { polygonObjectInList, polygonObjectList } from '../../web_api/models';
import getSnapshot from '../../web_api/methods/files/getSnapshot';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

type CommonAnnotatedImageRectProps = {
  data: polygonObjectList;
  img: string;
  className?: string;
  saveChanges: (newPolygons: polygonObjectList, changedPolygons: polygonObjectList, deletedPolygons: polygonObjectList, img: string) => void;
  save: boolean;
};

const CommonAnnotatedImageRect: React.FC<CommonAnnotatedImageRectProps> = ({ data, img, className, saveChanges, save }) => {
  const [anno, setAnno] = useState<any>();
  const annotationType = useSelector((state: RootState) => state.training.annotation_type);

  const [image, setImage] = useState({
    height: 0,
    width: 0,
  });

  const [polygons, setPolygons] = useState<polygonObjectList>([]);
  const [newPolygons, setNewPolygons] = useState<polygonObjectList>([]);
  const [changedPolygons, setChangedPolygons] = useState<polygonObjectList>([]);
  const [deletedPolygons, setDeletedPolygons] = useState<polygonObjectList>([]);

  const imgEl = useRef<HTMLImageElement>(null);

  useEffect(() => {
    console.log('data', data);
    setPolygons(data);
  }, []);

  useEffect(() => {
    console.log('rect annotation tyoe', annotationType);
  }, [annotationType]);

  useEffect(() => {
    let annotorious: any = null;

    if (imgEl.current) {
      annotorious = new Annotorious({
        image: imgEl.current,
        drawOnSingleClick: false,
        widgets: ['COMMENT', { widget: 'TAG', vocabulary: ['Animal', 'Building', 'Waterbody'] }],
        allowEmpty: true,
        disableEditor: true,
      });

      annotorious.setDrawingTool('rect');

      annotorious.on('createAnnotation', (annotation: any) => {
        const polygonStringRaw = annotation.target.selector.value;
        // const polygonGeometry = extractPolygonGeometry(polygonStringRaw);
        console.log('createAnnotation - annotation type', annotationType);
        console.log('annotationType', annotationType.name);

        let polygonObj: polygonObjectInList = {
          id: annotation.id,
          body: [
            ...annotation.body,
            {
              type: 'TextualBody',
              value: annotationType.name,
              purpose: 'tagging',
            },
          ],
          polygon: polygonStringRaw, // polygonGeometry
          // ano_type: annotationType.id.toString(),
        };

        setNewPolygons((prevPolygons) => [...prevPolygons, polygonObj]);
      });

      annotorious.on('updateAnnotation', (annotation: any, previous: any) => {
        console.log('updateAnnotation', annotation, 'previous', previous);
        setChangedPolygons((prevPolygons) => [
          ...prevPolygons,
          {
            id: previous.id,
            body: annotation.body,
            // polygon: extractPolygonGeometry(annotation.target.selector.value),
            polygon: annotation.target.selector.value,
            ano_type: annotation.ano_type ? annotation.ano_type : annotationType,
          },
        ]);
      });

      annotorious.on('deleteAnnotation', (annotation: any) => {
        console.log('deleteAnnotation', annotation);
        setDeletedPolygons((prevPolygons) => [...prevPolygons, annotation]);
      });
    }

    setAnno(annotorious);

    return () => {
      if (annotorious) {
        annotorious.destroy();
      }
    };
  }, []);

  useEffect(() => {
    console.log('annotationType', annotationType);
  }, [annotationType]);

  useEffect(() => {
    if (!anno) {
      console.log('FAILED TO CREATE ANNOTORIOUS');
      return;
    }
    anno.off('createAnnotation');

    anno.on('createAnnotation', (annotation: any) => {
      const polygonStringRaw = annotation.target.selector.value;
      // const polygonGeometry = extractPolygonGeometry(polygonStringRaw);
      console.log('createAnnotation - annotation type', annotationType);
      console.log('annotationType', annotationType.name);
      let polygonObj: polygonObjectInList = {
        id: annotation.id,
        body: [
          ...annotation.body,
          {
            type: 'TextualBody',
            value: annotationType.name,
            purpose: 'tagging',
          },
        ],
        polygon: polygonStringRaw, // polygonGeometry
        // ano_type: annotationType.id.toString(),
      };

      setNewPolygons((prevPolygons) => [...prevPolygons, polygonObj]);
    });
  }, [annotationType]);

  useEffect(() => {
    if (anno && polygons.length > 0) {
      anno.clearAnnotations();
      polygons.forEach((polygon) => {
        const annotation = {
          context: 'http://www.w3.org/ns/anno.jsonld',
          id: polygon.id,
          target: {
            selector: { conformsTo: 'http://www.w3.org/TR/media-frags/', type: 'FragmentSelector', value: polygon.polygon },
          },
          body: polygon.body,
          type: 'Annotation',
        };

        anno.addAnnotation(annotation);
      });
    }
  }, [anno, polygons.length]);

  useEffect(() => {
    const resize = () => {
      setImage({
        height: imgEl.current?.clientWidth ?? 0,
        width: imgEl.current?.clientHeight ?? 0,
      });
    };
    resize();
    window.addEventListener('resize', resize);
  }, [imgEl.current?.clientHeight, imgEl.current?.clientWidth]);

  useEffect(() => {
    if (save) {
      console.log('annotationc', anno.getAnnotations());
      console.log('triggered savechanges');
      console.log('polygons', polygons, 'img', img);
      saveChanges(newPolygons, changedPolygons, deletedPolygons, img);
    }
    // console.log('triggered savechanges');
    // console.log('polygons', polygons, 'img', img);
    // saveChanges(polygons, img);
  }, [save]);

  // useEffect(() => {
  //   console.log('imgEl', imgEl.current);
  //   // NU MERGE
  // }, [imgEl.current?.src]);

  return (
    <div>
      <img className={`${className}`} ref={imgEl} src={img} alt='' />
    </div>
  );
};

export default CommonAnnotatedImageRect;
