import { useState, useEffect } from 'react';
import { camerasList } from '../web_api/models';
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../redux/activeLocationReducer';
import { RootState } from '../redux/store';
import { setValue as setSelectedCamera } from '../redux/selectedCameraReducer';
import { setValue as setSelectedPerson } from '../redux/selectedPersonReducer';
import { setValue as setDistinctPersonList } from '../redux/distinctPersonListReducer';
import getCameras from '../web_api/methods/settings/cameras/getCameras';
import { setCachedCameraList } from '../redux/camerasListReducer';
import getDistinctPersons from '../web_api/methods/faceRecognitionDash/getDistinctPersons';
import * as ReactBootstrap from 'react-bootstrap';
import { DateRange, DayPicker } from 'react-day-picker';
import { setValue as setDateFilter } from '../redux/dateFilterReducer';
import { setValue as setHourParameters } from '../redux/hourParametersReducer';

const ToolbarFaceRecognition = () => {
  const [cameras, setCameras] = useState<camerasList>([]);
  const [distinctPersons, setDistinctPersons] = useState<string[]>([]);
  const selectedCamera = useSelector(
    (state: RootState) => state.selectedCamera
  );
  const selectedPerson = useSelector(
    (state: RootState) => state.selectedPerson
  );
  const cachedPersonList = useSelector(
    (state: RootState) => state.personList.list
  );
  const distinctPersonList = useSelector(
    (state: RootState) => state.distinctPersonList.list
  );
  const hourParameters = useSelector(
    (state: RootState) => state.hourParameters
  );
  const dateFilter = useSelector((state: RootState) => state.dateFilter);

  const [show, setShow] = useState(false);

  const defaultSelected: DateRange = {
    from: new Date(dateFilter.from),
    to: new Date(dateFilter.to),
  };

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);

  const dispatch = useDispatch();

  const handleShowModal = () => setShow(true);
  let selectedRange = <>Date picker</>;

  if (range?.from) {
    if (!range.to) {
      range.to = range.from;
      // selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
      selectedRange = (
        <>
          {range.from
            .toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/\//g, '-')}
        </>
      );
    } else if (range.to) {
      selectedRange =
        range.to.toString() === range.from.toString() ? (
          <>
            {range.from
              .toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        ) : (
          <>
            {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
            {range.from
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}{' '}
            -{' '}
            {range.to
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        );
    }
  }

  const handleCloseModal = () => {
    setShow(false);

    dispatch(
      setDateFilter({
        from: range!?.from!.toISOString(),
        to: range!?.to!.toISOString(),
      })
    );
  };

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
    loadData();
  }, []);

  // useEffect(() => {
  //     console.log(distinctPersonList)
  // }, [cachedPersonList])

  const loadData = async () => {
    try {
      let loadedCameras: camerasList = await getCameras();
      let distinctPersons: string[] = await getDistinctPersons();
      setDistinctPersons(distinctPersons);
      setCameras(loadedCameras);
      dispatch(
        setCachedCameraList({
          list: loadedCameras,
        })
      );
      dispatch(
        setDistinctPersonList({
          list: distinctPersons,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className='d-flex align-items-center overflow-auto'>
      {/* begin::Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
          Camera:
        </span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedCamera.value}
          onChange={(e) => {
            dispatch(
              setSelectedCamera({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );

            dispatch(
              setValue({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {cameras.map((item) => (
            <option key={item.id} value={item.name} data-id={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 mx-2'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
          Person:
        </span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedPerson.value}
          onChange={(e) => {
            dispatch(
              setSelectedPerson({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
          }}
        >
          <option key={0} value={'all'} data-id={0}>
            All
          </option>
          {distinctPersons.map((item, index) => (
            <option key={index} value={item} data-id={index}>
              {item}
            </option>
          ))}
        </select>
      </div>
      {/* end::Wrapper */}

      {/* begin::Separartor */}
      <div className='bullet bg-secondary h-35px w-1px mx-5 d-none d-md-block'></div>
      {/* end::Separartor */}

      {/* begin::Wrapper */}
      <div className='d-flex align-items-center justify-content-center w-100 w-md-auto p-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>
          Range:
        </span>
        {/* end::Label */}

        {/* begin::Select */}
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div
            className={
              'd-flex flex-row align-items-center justify-content-center nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'
            }
          >
            <i className='bi bi-calendar align-self-center' />
            <span className='text-nowrap'>{selectedRange}</span>
          </div>
        </div>
        {/* end::Select */}
        {/* <div className='cursor-pointer symbol symbol-35px symbol-md-40px d-flex flex-row mx-2 fs-7 fw-bold text-gray-700 '>
          <i className="bi bi-person mx-2 fs-3 fw-bold text-gray-700 " />
          <span className='m-auto '>{userName}</span>
          <i className="bi bi-caret-down-fill ms-1 m-auto pt-1" style={{ fontSize: '1rem' }} />

        </div> */}
        {/* begin::Actions */}
        {/* end::Actions */}
      </div>

      {/* end::Wrapper */}
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <DayPicker
              id='1'
              fromYear={2020}
              toYear={2030}
              defaultMonth={range?.to === undefined ? range?.from : range.to}
              mode='range'
              selected={range}
              onSelect={setRange}
              footer={selectedRange}
            />
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarFaceRecognition };
