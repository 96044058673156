import React, {  } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { DataWidget2 } from '../../_metronic/widgets/data_widgets/DataWidget2';

type Props = {
    className: string
}

const MostQueueAlertsListQueueManagement: React.FC<Props> = ({ className }) => {
    const totalQueueAlerts = useSelector((state: RootState) => state.totalQueueAlerts.value);
    const activeLocationId = useSelector((state: RootState) => state.activeLocation.id);

    return (
        <DataWidget2
            className={className}
            title={activeLocationId !== '0' ? 'Cash registers' : 'Locations'}
            totalQueueAlerts={totalQueueAlerts}
            loading={true}
        />
    )
}
export { MostQueueAlertsListQueueManagement }