import React, { useEffect, useState } from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub/SidebarMenuItemWithSub';
import { useDispatch, useSelector } from 'react-redux';
import { ValidMenuKeys, detectionType, menuItem, menuItemList } from '../web_api/models';
import getMenu from '../web_api/methods/menu/getMenu';
import { setMenu } from '../redux/menuReducer';
import { RootState } from '../redux/store';
import { EProductType } from '../redux/activeProductReducer';
import useGet from '../hooks/Common/useGet';
import { setDetectionTypes } from '../redux/detectionTypesReducer';
import getDetectionTypes from '../web_api/methods/alerts/getDetectionTypes';

export default function SidebarMenuMain() {
  const [activeSub, setActiveSub] = useState('');
  const [menus, setMenus] = useState<menuItemList>();
  const activeProduct = useSelector((state: RootState) => state.activeProduct);
  const userName = useSelector((state: RootState) => state.user.user_name);
  const menu = useSelector((state: RootState) => state.menu.menu);
  const dispatch = useDispatch();
  const isCloud = process.env.REACT_APP_CLOUD === 'true';

  const { loading, data } = useGet([], getDetectionTypes, setDetectionTypes);
  const cachedDetectionTypes = useSelector((state: RootState) => state.detectionTypes.list);
  const cachedDetectionTypesEntireList = useSelector((state: RootState) => state.detectionList.list);
  const [alertTypesMenu, setAlertTypesMenu] = useState<detectionType[]>([]);
  const handleToggleSub = (title: string) => {
    if (activeSub === title) {
      setActiveSub('');
    } else {
      setActiveSub(title);
    }
  };

  const loadInitialData = async () => {
    const res = await getMenu();
    var alertTypes;
    if (userName === 'orange@demo.com') {
      switch (activeProduct.activeProduct) {
        case EProductType.RETAIL:
          const menu = JSON.parse(res.menu1) as menuItemList;
          setMenus(menu);
          dispatch(setMenu(menu));
          alertTypes = cachedDetectionTypes.filter(
            (x) => cachedDetectionTypesEntireList.find((y) => y.detection_type === x.detection_type)?.product_id === 1
          );
          setAlertTypesMenu(alertTypes);
          break;
        case EProductType.HSSE:
          const menu2 = JSON.parse(res.menu2) as menuItemList;
          setMenus(menu2);
          dispatch(setMenu(menu2));
          alertTypes = cachedDetectionTypes.filter(
            (x) => cachedDetectionTypesEntireList.find((y) => y.detection_type === x.detection_type)?.product_id === 2
          );
          setAlertTypesMenu(alertTypes);
          break;
        case EProductType.MOBILITY:
          const menu3 = JSON.parse(res.menu3) as menuItemList;
          setMenus(menu3);
          dispatch(setMenu(menu3));
          alertTypes = cachedDetectionTypes.filter(
            (x) => cachedDetectionTypesEntireList.find((y) => y.detection_type === x.detection_type)?.product_id === 3
          );
          setAlertTypesMenu(alertTypes);
          break;
        default:
          const menu1 = JSON.parse(res.menu1) as menuItemList;
          setMenus(menu1);
          dispatch(setMenu(menu1));
          alertTypes = cachedDetectionTypes;
          setAlertTypesMenu(alertTypes);
          break;
      }
    } else {
      const menu1 = JSON.parse(res.menu1) as menuItemList;
      setMenus(menu1);
      dispatch(setMenu(menu1));
      alertTypes = cachedDetectionTypes;
      setAlertTypesMenu(alertTypes);
    }
    // const local_menu = JSON.parse(res.menu1) as menuItemList;
    // setMenus(local_menu);
    // dispatch(setMenu(local_menu));
  };

  useEffect(() => {
    loadInitialData();
  }, [activeProduct.activeProduct, userName]);

  return (
    <>
      {menus &&
        Object.keys(menus).map((menuTitle: string, index) => {
          return (
            <div key={menuTitle}>
              <div className='menu-item'>
                <div className={`menu-content ${index !== 0 && 'pt-8'} pb-2`}>
                  <span className={`menu-section text-muted text-uppercase fs-8 ls-1`}>{menuTitle}</span>
                </div>
              </div>
              {menus[menuTitle as ValidMenuKeys].map((menu: menuItem, index) => {
                if (menu.id_grup === 'MenuAlertsGroup')
                  return (
                    !isCloud && (
                      <SidebarMenuItemWithSub
                        key={index}
                        active={activeSub}
                        handleChangeActiveState={handleToggleSub}
                        to={process.env.REACT_APP_HOMEPAGE + '/alerts'}
                        title='Alerts'
                        hasBullet={false}
                        fontIcon='bi-exclamation-octagon'
                      >
                        {alertTypesMenu.map((detection) => {
                          return (
                            <SidebarMenuItem
                              key={detection.detection_type}
                              to={`${process.env.REACT_APP_HOMEPAGE}/alerts/${detection.detection_type}&${detection.detection_type_name}`}
                              icon=''
                              title={`Alerts ${detection.detection_type_name}`}
                              tab='alerts'
                              fontIcon='bi-bell'
                            />
                          );
                        })}
                      </SidebarMenuItemWithSub>
                    )
                  );
                else if (menu.url && menu.toolbar)
                  return (
                    <SidebarMenuItem
                      key={menu.id || menu.id_grup}
                      to={process.env.REACT_APP_HOMEPAGE + menu.url}
                      icon=''
                      fontIcon={menu.icon_class}
                      title={menu.text}
                      tab={menu.toolbar}
                      handleToggleSub={handleToggleSub}
                    />
                  );
                else {
                  return (
                    <SidebarMenuItemWithSub
                      key={menu.id || menu.id_grup}
                      title={menu.text}
                      fontIcon={menu.icon_class}
                      handleChangeActiveState={handleToggleSub}
                      active={activeSub}
                    >
                      {menu.children.map((subMenu: menuItem) => {
                        if (subMenu.url && subMenu.toolbar)
                          return (
                            <SidebarMenuItem
                              key={subMenu.id || subMenu.id_grup}
                              to={process.env.REACT_APP_HOMEPAGE + subMenu.url}
                              icon=''
                              fontIcon={subMenu.icon_class}
                              title={subMenu.text}
                              tab={subMenu.toolbar}
                              handleToggleSub={handleToggleSub}
                            />
                          );
                      })}
                    </SidebarMenuItemWithSub>
                  );
                }
              })}
            </div>
          );
        })}
    </>
  );
}
