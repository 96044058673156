import React, { useEffect, useRef } from 'react';
import { bboxWithDescription } from '../../web_api/models';

interface ICanvasProps {
  imageUrl: string;
  actualHeight: number;
  actualWidth: number;
  multiple_bboxes: bboxWithDescription[];
  canvasHeight: string;
  canvasWidth: string;
  big: boolean;
}

const AlertCanvasComponent: React.FC<ICanvasProps> = ({ imageUrl, actualHeight, actualWidth, multiple_bboxes, canvasHeight, canvasWidth, big }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    canvas.style.width = canvasWidth;
    canvas.style.height = canvasHeight;
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const img = new Image();
    let rectColor = 'yellow';
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      const height = canvas.height;
      const width = canvas.width;

      multiple_bboxes.forEach((item) => {
        let x1 = (item.bboxes[0] * width) / actualWidth;
        let y1 = (item.bboxes[1] * height) / actualHeight;
        let x2 = (item.bboxes[2] * width) / actualWidth;
        let y2 = (item.bboxes[3] * height) / actualHeight;
        let w = x2 - x1;
        let h = y2 - y1;
        ctx.beginPath();
        ctx.rect(x1, y1, w, h);

        ctx.lineWidth = 3;
        ctx.strokeStyle = rectColor;
        ctx.stroke();
        if (big) {
          let filledRectX = x1 - ctx.lineWidth / 2;
          let filledRectY = y1;
          let filledRectW = w + ctx.lineWidth;
          let filledRectH = 15;

          // Adding text
          let textX = filledRectX + filledRectW / 2;
          let textY = filledRectY - filledRectH / 2;
          let textContent = item.detail_description; // Replace this with your actual text
          ctx.measureText(textContent);
          console.log('textWidth', ctx.measureText(textContent).width);

          if (ctx.measureText(textContent).width > filledRectW) {
            filledRectW = ctx.measureText(textContent).width + ctx.lineWidth;
            filledRectX = x1 - (filledRectW - w) / 2;
          }

          ctx.beginPath();
          ctx.rect(filledRectX, filledRectY - filledRectH, filledRectW, filledRectH);
          ctx.fillStyle = 'yellow';
          ctx.fill();

          ctx.font = '10px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(textContent, textX, textY);
        }
      });
    };

    img.src = imageUrl;
  }, [imageUrl]);

  return (
    <div>
      <canvas ref={canvasRef} />
    </div>
  );
};

export default AlertCanvasComponent;
