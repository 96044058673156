/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import * as ReactBootstrap from 'react-bootstrap';
import LoadingPage from '../../layout/LoadingPage';

type Props = {
  className: string;
  values: number[];
  labels: string[];
  total: number;
  loaded: boolean;
};

const AlertTypesChart: FC<Props> = ({ className, values, labels, total, loaded }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(values, labels, total));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, values, labels, total, loaded]);

  return loaded ? <div ref={chartRef} id='kt_charts_widget_2_chart' /> : <LoadingPage />;
};
const chartOptions = (counts: number[], labels: string[], totalCount: number): ApexOptions => {
  return {
    chart: {
      type: 'donut',
      width: 500,
    },
    series: counts,
    labels: labels,
    colors: ['#008FFB', '#FF4560', '#50cd89'],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'Total',
              formatter: () => totalCount.toString(),
            },
          },
        },
      },
    },
  };
};
export { AlertTypesChart };
