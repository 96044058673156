import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface toolbarSearchState {
  searchText: string;
}
const initialState: toolbarSearchState = {
  searchText: localStorage.getItem('toolbarSearchState')!?.toString() != null ? localStorage.getItem('toolbarSearchState')!?.toString() : '',
};

export const toolbarSearchFilter = createSlice({
  name: 'toolbarSearchFilter',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<toolbarSearchState>) => {
      state.searchText = action.payload.searchText;
      localStorage.setItem('toolbarSearchFilter', action.payload.searchText);
    },
  },
});

export const { setValue } = toolbarSearchFilter.actions;

export const toolbarSearchFilterReducer = toolbarSearchFilter.reducer;
