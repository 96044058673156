import { useSelector } from 'react-redux';
import useGet from '../../hooks/Common/useGet';
import { setCachedLocationList } from '../../redux/cachedLocationsReducer';
import { RootState } from '../../redux/store';
import getLocations from '../../web_api/methods/settings/locations/getLocations';
import FruitsVegetablesCard from './FruitsVegetablesCard';
import LoadingPage from '../../layout/LoadingPage';
import { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import getLFLocations from '../../web_api/methods/settings/locations/getLFLocations';

const FruitsVegetables = () => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
  const userList = useSelector((state: RootState) => state.userList);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);

  let selectedUserLocations: number[] = [];
  const [lfLocations, setLfLocations] = useState<number[]>([]);

  const { setToolbar } = useToolbarSetter();

  useEffect(() => {
    setToolbar('fruits-and-vegetables', 'Fruits & Vegetables');
  });

  const loadLF = async () => {
    const res = await getLFLocations();
    setLfLocations(res);
  };

  useEffect(() => {
    loadLF();
  }, []);

  const { loading } = useGet([], getLocations, setCachedLocationList);

  if (selectedUser) {
    const user = userList.list.find((user) => user.id === selectedUser.id);
    if (user) {
      selectedUserLocations = user.locations.map((location) => location.id);
    }
  }

  return (
    <div className='container'>
      <div className='d-flex flex-column flex-md-row'>
        <div className='d-flex flex-row flex-md-column col-12'>
          <div className='p-5 pb-0'>
            <div className='row'>
              {loading ? (
                <LoadingPage />
              ) : (
                cachedLocationsList &&
                cachedLocationsList.list.map((location) => {
                  if ((selectedUserLocations.length === 0 || selectedUserLocations.includes(location.id)) && lfLocations.length > 0) {
                    return <FruitsVegetablesCard key={location.id} location={location} loading={loading} lf={lfLocations.includes(location.id)} />;
                  }
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FruitsVegetables;
