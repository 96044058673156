import callApiAxiosClient from '../../axios/axios_client';
import { alertByTypeList, interval } from '../../models';

async function getAlertsByType(interval: interval): Promise<alertByTypeList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;

  return callApiAxiosClient<alertByTypeList>({
    method: 'get',
    url: 'programmability/get-hsse-alerts-by-type',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      interval: interval,
    },
  });
}
export default getAlertsByType;
