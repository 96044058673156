import callApiAxiosClient from '../axios/axios_client';

async function addRTConnId(connectionId: string) {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'usersactions/add-rt-connection',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      connection_id: connectionId,
    },
  });
}
export default addRTConnId;
