import { useSelector } from "react-redux";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";
import { RootState } from "../../redux/store";
import { detectionServerInList } from "../../web_api/models";
import CommonNewUserCard from "../Common/CommonNewUserCard";
import DetectionServerAdd from "./DetectionServerAdd";

type DetectionServerNewUserCardsProps = {
    show: boolean,
    dataLength: number,
    onDetectionServerAdd: (detectionServer: detectionServerInList) => void;
    toggleModal: () => void
};

const DetectionServerNewUserCards: React.FC<DetectionServerNewUserCardsProps> = ({
    dataLength,
    show,
    toggleModal,
    onDetectionServerAdd

}) => {

    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);

    const { setToolbar } = useToolbarSetter();

    return (
        <>
            <div className="container py-3 py-lg-6 px-6 px-md-20 mb-6">
                {cachedLocationsList.length > 0 && dataLength === 0 ? (
                    <DetectionServerAdd
                        onDetectionServerAdd={onDetectionServerAdd}
                        show={show}
                        toggleModal={toggleModal}
                        dataLength={dataLength}
                    />
                ) : null}
                <div className="row row-cols-xl-2 g-5 justify-content-space-between">

                    {cachedLocationsList.length === 0 ? (
                        <div className="mx-auto" style={{ minHeight: '450px' }}>
                            <CommonNewUserCard
                                title="Get started with detection servers!"
                                content={`Before you can add detection servers, you must add a location.\n
                                    Click on this card and follow the instructions to get started.`}
                                className="h-100"
                                icon="bi bi-houses"
                                link={`${process.env.REACT_APP_HOMEPAGE}/locations`}
                                buttonText="To Locations"
                                onClick={() => setToolbar('locations', "Locations")}
                            />
                        </div>

                    ) : null}

                </div>

            </div>

        </>

    )
}

export default DetectionServerNewUserCards;