import React from "react";
import { Link } from "react-router-dom";

type CommonNewUserCardProps = {
    content: string,
    title: string,
    buttonText?: string,
    className?: string,
    icon?: string,
    link?: string,
    onClick?: () => void
}

const CommonNewUserCard: React.FC<CommonNewUserCardProps> = ({
    content,
    title,
    icon,
    link,
    buttonText,
    className,
    onClick
}) => {
    return (
            <div className={`card ${className} cursor-pointer animated hover-grow p-2 p-sm-0`} >
                <Link to={link || ""} className="text-dark" onClick={onClick}>

                <div className="card-header pt-4 pt-sm-0">
                    <h3 className="card-title">{title}</h3>
                    <div className="card-toolbar">
                        {buttonText &&
                            <button type="button" className="btn btn-sm btn-light">
                                {buttonText}
                            </button>
                        }
                    </div>
                </div>
                <div className="card-body p-0 d-flex flex-column justify-content-center">
                    <div className="card-p">
                        {content.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                <div className="mt-2">
                                    {line}
                                </div>
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="p-4 mb-12 d-flex justify-content-center align-items-center">
                        <i className={icon} style={{ fontSize: "15em" }}></i>
                    </div>
                </div>
            </Link>

            </div>
    );
}

export default CommonNewUserCard;