/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  } from 'react'
import { locationsList } from '../../../web_api/models'
import 'react-day-picker/dist/style.css';
import { UserData } from '../../../pages/Account/Users/UserData'
type Props = {
    className: string
    locationsOnUser: locationsList
    isLoadingLocations: boolean
}

const ChartsWidget16: React.FC<Props> = ({ className, locationsOnUser, isLoadingLocations }) => {
    return (
        <>
            <div className={`card ${className}`}>
                <div className='card-body p-9'>
                    <div className='d-flex flex-column flex-sm-row'>
                        <div className='d-flex flex-column flex-sm-row col-sm-9'>
                            <UserData locationsOnUser={locationsOnUser} isLoadingLocations={isLoadingLocations} />
                        </div>
                        <div className='mt-8 mt-s-0 d-flex align-self-start align-self-sm-end col-sm-3 justify-content-sm-end'>
                            <a
                                className='text-primary bg-light-primary p-3 rounded cursor-pointer'
                                href='/account/password-reset'>
                                Reset password
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { ChartsWidget16 }
