import callApiAxiosClient from '../../../axios/axios_client';
import { polygonObjectList } from '../../../models';

async function modifyDetectionRegions(id: number | string, polygons: polygonObjectList): Promise<polygonObjectList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'put',
    url: 'settings/put-detection-regions',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      detection_setting_id: id,
      polygons: polygons,
    },
  });
}
export default modifyDetectionRegions;
