import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import CommonFormDropdownField from '../Common/CommonFormDropdownField';
import { CommonModal } from '../Common/CommonModal';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { detectionServerInList } from '../../web_api/models';
import addDetectionServer from '../../web_api/methods/settings/detectionServers/addDetectionServer';
import CommonFormField from '../Common/CommonFormField';

type DetectionServerAddFormModalProps = {
  handleToggleModal: () => void;
  show: boolean;
  onDetectionServerAdd: (detectionServer: detectionServerInList) => void;
};

const DetectionServerAddFormModal: React.FC<DetectionServerAddFormModalProps> = ({ show, handleToggleModal, onDetectionServerAdd }) => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);

  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [detectionServer, setDetectionServer] = useState<detectionServerInList>({
    name: '',
    location_id: -1,
    ip_address: '',
    external_endpoint: '',
    audio_alerts: true,
    id: 0,
    tenant_id: 1,
  });

  const [isFormValid, setIsFormValid] = useState({
    name: '',
    location_id: '',
    ip_address: '',
    external_endpoint: '',
    audio_alerts: true,
  });

  const resetFields = () => {
    setDetectionServer({
      name: '',
      location_id: -1,
      ip_address: '',
      external_endpoint: '',
      audio_alerts: true,
      id: 0,
      tenant_id: 1,
    });
    setIsFormValid({
      name: '',
      location_id: '',
      ip_address: '',
      external_endpoint: '',
      audio_alerts: true,
    });
  };

  const handleAddDetectionServer = async () => {
    if (
      !detectionServer.name ||
      detectionServer.location_id === -1 ||
      (detectionServer.ip_address && !/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(detectionServer.ip_address)) ||
      (detectionServer.external_endpoint && !/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(detectionServer.external_endpoint))
    ) {
      setIsFormValid({
        name: detectionServer.name ? 'is-valid' : 'is-invalid',
        location_id: detectionServer.location_id !== -1 ? 'is-valid' : 'is-invalid',
        ip_address:
          detectionServer.ip_address && /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(detectionServer.ip_address) ? 'is-valid' : 'is-invalid',
        audio_alerts: true,
        external_endpoint:
          detectionServer.external_endpoint && /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/.test(detectionServer.external_endpoint)
            ? 'is-valid'
            : 'is-invalid',
      });
      return;
    }

    try {
      setIsAdding(true);
      console.log('det sv', detectionServer);
      let addedDetectionServer = await addDetectionServer(detectionServer);

      onDetectionServerAdd(addedDetectionServer);

      handleToggleModal();

      toast.success('Detection server added successfully!', {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });

      setIsAdding(false);
      setTimeout(() => {
        resetFields();
      }, 500);
    } catch (e) {
      setIsAdding(false);
      setIsFormValid({
        name: '',
        location_id: '',
        ip_address: '',
        external_endpoint: '',
        audio_alerts: false,
      });
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  return (
    <CommonModal
      title='Add Detection Server'
      size='lg'
      disabled={isAdding}
      show={show}
      showModalCallback={handleToggleModal}
      closeButton={true}
      confirmButton={true}
      bodyClass='flex-column w-100 ps-sm-15 pe-sm-15 pb-sm-10'
      closeButtonOptions={{
        icon: 'bi bi-x',
        variant: 'secondary',
      }}
      confirmButtonOptions={{
        icon: 'bi bi-check-lg',
        variant: 'custom',
        class: 'bg-primary text-white',
        onClick: handleAddDetectionServer,
      }}
    >
      <CommonFormField
        inputClass={isFormValid.name}
        id='name'
        label='Name'
        placeholder='Server name'
        type='text'
        value={detectionServer.name}
        onChange={(e) => setDetectionServer({ ...detectionServer, name: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <CommonFormField
        inputClass={isFormValid.ip_address}
        id='ip'
        label='IP Address'
        placeholder='Server IP'
        type='text'
        value={detectionServer.ip_address}
        onChange={(e) => setDetectionServer({ ...detectionServer, ip_address: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <CommonFormField
        inputClass={isFormValid.external_endpoint}
        id='ip'
        label='External Endpoint'
        placeholder='External IP'
        type='text'
        value={detectionServer.external_endpoint}
        onChange={(e) => setDetectionServer({ ...detectionServer, external_endpoint: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <CommonFormDropdownField
        inputClass={isFormValid.location_id}
        label='Location'
        value={detectionServer.location_id}
        onChange={(e) => setDetectionServer({ ...detectionServer, location_id: parseInt(e.target.value) })}
      >
        <option value='' hidden>
          Select a location
        </option>
        {cachedLocationsList.map((location) => {
          return (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          );
        })}
      </CommonFormDropdownField>
    </CommonModal>
  );
};

export default DetectionServerAddFormModal;
