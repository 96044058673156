import React from 'react';
import { TopFlopCharts } from './TopFlopCharts';
import { DayWeekMonthYearEnum } from '../../web_api/models';
import { barColors } from '../FruitsVegetables/FruitsVegetablesInfo/FruitsVegetablesCharts';

type Props = {
  title: string;
  cat1: string;
  cat2: string;
  param1Nr: number;
  setParam1Nr: (value: number) => void;
  param2Nr: number;
  setParam2Nr: (value: number) => void;
  options?: number[];
};

const ComparisonTab: React.FC<Props> = ({ param1Nr, setParam1Nr, param2Nr, setParam2Nr, options = [5, 10, 15], title, cat1, cat2 }) => {
  return (
    <div className='d-flex flex-column flex-lg-row col-sm-12 p-2'>
      <div className='d-flex flex-column col-12 col-md-6 pe-md-2'>
        <div className='card w-100'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title flex-column'>
              <div className='card-label fw-bold fs-3 mb-1 '>{cat1}</div>
              <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                {title}
              </div>
            </h3>
            <select
              className='form-select form-select-solid w-25'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={param1Nr}
              onChange={(event) => {
                setParam1Nr(parseInt(event.target.value));
                console.log(parseInt(event.target.value));
              }}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <TopFlopCharts
            type='bar'
            className='w-sm-100'
            chartColor='danger'
            chartHeight='150px'
            filter={DayWeekMonthYearEnum.WEEK}
            barColors={barColors}
            nr={param1Nr}
            top={true}
          />
        </div>
      </div>
      <div className='d-flex flex-column col-12 col-md-6 ps-md-2'>
        <div className='card w-100'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title flex-column'>
              <div className='card-label fw-bold fs-3 mb-1 '>{cat2}</div>
              <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                {title}
              </div>
            </h3>
            <select
              className='form-select form-select-solid w-25'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={param2Nr}
              onChange={(event) => {
                setParam2Nr(parseInt(event.target.value));
              }}
            >
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <TopFlopCharts
            type='bar'
            className='w-sm-100'
            chartColor='danger'
            chartHeight='150px'
            filter={DayWeekMonthYearEnum.WEEK}
            barColors={barColors}
            nr={param2Nr}
            top={false}
          />
        </div>
      </div>
    </div>
  );
};

export { ComparisonTab };
