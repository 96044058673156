
import React, { useEffect, useState } from 'react'
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'


type Props = {
  className?: string
  filter: string
  alert: string
}

const AlertEvolution: React.FC<Props> = ({ className, filter, alert }) => {
  const dayWeekMonthYear = convertStringToEnumValue(filter)//dependency
  const activeLocation = useSelector((state: RootState) => state.activeLocation)
  const selectedUser = useSelector((state: RootState) => state.selectedUser)
  //dependencies
  const [evo, setEvo] = useState<number>(0)
  const [value, setValue] = useState<number>(0)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    loadAlertEvo(new Date(), new Date())
    // console.log(filter)
  }, [dayWeekMonthYear, activeLocation, selectedUser])
  let loadAlertEvo = async (from: Date, to: Date) => {
    try {
      setLoaded(false)
      // let loadGender: genderResponse = await peopleCountingGender(from, to);
      //call here
      setEvo(0.5)
      setValue(103)
      setLoaded(true)

    }
    catch (e) {
      console.log(e)
    }
  };

  return (
    <div className={`d-flex card ${className} `}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Alerts: {alert}</span>
        </h3>
      </div>
      <div className='card-body d-flex flex-row pt-0'>
        <div className='d-flex col-2'>
          <i className={`bi fs-2x w-100 d-flex flex-center ` +
            (evo > 0 ? 'bi-arrow-up-right text-success' : evo < 0 ? 'bi-arrow-down-right text-danger' : 'bi-arrow-right text-info')} />
        </div>
        <div className='d-flex col-10'>
          <div className='  d-flex flex-row flex-start m-auto ms-0 '>
            <h1 className='m-auto'>{value}</h1>
            <div className='d-flex m-auto ms-3'>
              <span className={'fw-bolder me-2 rounded-2 fs-7 px-1 ' +
                (evo > 0 ? 'bg-light-success text-success' : evo < 0 ? 'bg-light-danger text-danger' : 'bg-light-info text-info')}>
                {evo > 0 ? '+' : ''}
                {(evo != null && evo != undefined) && evo.toFixed(2)}%
              </span>
              <span className='fw-bolder me-2 rounded-2 fs-7 px-1'>on last week</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}
export { AlertEvolution }
