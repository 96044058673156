import React, { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { ComparisonSeparateChart } from '../../_metronic/widgets/charts/ComparisonSeparateChart';
import {
  ComparisonEnum,
  lprCameraInList,
  lprCameraList,
  lprDetectionInList,
  lprDetectionList,
  lprLocationInList,
  lprLocationList,
  lprTotalByCountyList,
} from '../../web_api/models';
import picture from './dummy/traffic_camera_pic.jpg';
import CanvasComponent from '../FaceRecognition/CanvasComponent';
import useModals from '../../hooks/Common/useModals';
import { CommonModal } from '../Common/CommonModal';
import AlertCanvasComponent from '../Alerts/AlertCanvasComponent';
import Form from 'react-bootstrap/Form';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import getLatestLPR from '../../web_api/methods/traffic/getLatestLPR';
import getLPRCameras from '../../web_api/methods/traffic/getLPRCameras';
import getLPRLocations from '../../web_api/methods/traffic/getLPRLocations';
import CommonPagination from '../Common/CommonPagination';
import LoadingPage from '../../layout/LoadingPage';
import getLPRTotalsByCounty from '../../web_api/methods/traffic/getLPRTotalsByCounty';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
// dummy data
import latestLPRDummy from './dummy/getLastestLPR.json';
import lprCamerasDummy from './dummy/lprCameras.json';
import lprLocationsDummy from './dummy/lprLocations.json';
import lprTotalsByCountyDumy from './dummy/lprTotalsByCounty.json';

const counties = [
  { full_name: 'Alba', abbreviation: 'AB' },
  { full_name: 'Arad', abbreviation: 'AR' },
  { full_name: 'Argeș', abbreviation: 'AG' },
  { full_name: 'Bacău', abbreviation: 'BC' },
  { full_name: 'Bihor', abbreviation: 'BH' },
  { full_name: 'Bistrița-Năsăud', abbreviation: 'BN' },
  { full_name: 'Botoșani', abbreviation: 'BT' },
  { full_name: 'Brașov', abbreviation: 'BV' },
  { full_name: 'Brăila', abbreviation: 'BR' },
  { full_name: 'Buzău', abbreviation: 'BZ' },
  { full_name: 'Caraș-Severin', abbreviation: 'CS' },
  { full_name: 'Călărași', abbreviation: 'CL' },
  { full_name: 'Cluj', abbreviation: 'CJ' },
  { full_name: 'Constanța', abbreviation: 'CT' },
  { full_name: 'Covasna', abbreviation: 'CV' },
  { full_name: 'Dâmbovița', abbreviation: 'DB' },
  { full_name: 'Dolj', abbreviation: 'DJ' },
  { full_name: 'Galați', abbreviation: 'GL' },
  { full_name: 'Giurgiu', abbreviation: 'GR' },
  { full_name: 'Gorj', abbreviation: 'GJ' },
  { full_name: 'Harghita', abbreviation: 'HR' },
  { full_name: 'Hunedoara', abbreviation: 'HD' },
  { full_name: 'Ialomița', abbreviation: 'IL' },
  { full_name: 'Iași', abbreviation: 'IS' },
  { full_name: 'Ilfov', abbreviation: 'IF' },
  { full_name: 'Maramureș', abbreviation: 'MM' },
  { full_name: 'Mehedinți', abbreviation: 'MH' },
  { full_name: 'Mureș', abbreviation: 'MS' },
  { full_name: 'Neamț', abbreviation: 'NT' },
  { full_name: 'Olt', abbreviation: 'OT' },
  { full_name: 'Prahova', abbreviation: 'PH' },
  { full_name: 'Satu Mare', abbreviation: 'SM' },
  { full_name: 'Sălaj', abbreviation: 'SJ' },
  { full_name: 'Sibiu', abbreviation: 'SB' },
  { full_name: 'Suceava', abbreviation: 'SV' },
  { full_name: 'Teleorman', abbreviation: 'TR' },
  { full_name: 'Timiș', abbreviation: 'TM' },
  { full_name: 'Tulcea', abbreviation: 'TL' },
  { full_name: 'Vaslui', abbreviation: 'VS' },
  { full_name: 'Vâlcea', abbreviation: 'VL' },
  { full_name: 'Vrancea', abbreviation: 'VN' },
  { full_name: 'București', abbreviation: 'B' },
  { full_name: 'Călărași', abbreviation: 'CL' },
];

// const countiesTraffic = [
//   { county: 'AB', number: 123 },
//   { county: 'AR', number: 456 },
//   { county: 'AG', number: 789 },
//   { county: 'BC', number: 101 },
//   { county: 'BH', number: 202 },
//   { county: 'BN', number: 1231 },
//   { county: 'BT', number: 456 },
//   { county: 'BV', number: 45 },
//   { county: 'VS', number: 242 },
//   { county: 'VL', number: 343 },
//   { county: 'VN', number: 444 },
//   { county: 'B', number: 2200 },
//   // Add more objects as needed
// ];

const newVisitors = [
  { county: 'AB', number: 23 },
  { county: 'BC', number: 45 },
  { county: 'CJ', number: 23 },
  { county: 'TM', number: 54 },
  { county: 'MS', number: 65 },
  { county: 'B', number: 34 },
  { county: 'HR', number: 23 },
  { county: 'VL', number: 56 },
  { county: 'CT', number: 87 },
  { county: 'PH', number: 34 },
  // Add more objects as needed
];

const TrafficLPR = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('traffic', 'Traffic LPR');
  });

  const { show, toggleModal } = useModals([false]); // 0 for changesModal, 1 for confirmDeleteModal
  const [selectedInstance, setSelectedInstance] = useState<lprDetectionInList>();
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const userName = useSelector((state: RootState) => state.user.user_name);

  const [vehicleType, setVehicleType] = useState<string>('All');
  const [county, setCounty] = useState<string>('All');
  const [search, setSearch] = useState<string>('');
  const [selectedCamera, setSelectedCamera] = useState<lprCameraInList>(); // cameras[0]
  const [selectedLocation, setSelectedLocation] = useState<lprLocationInList>(); // locations[0]
  const [selectedConfidence, setSelectedConfidence] = useState<number>(0);
  const [results, setResults] = useState<lprDetectionList>([]);
  const [cameras, setCameras] = useState<lprCameraList>([]);
  const [locations, setLocations] = useState<lprLocationList>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(30);
  const [loading, setLoading] = useState<boolean>(true);
  const [countiesLoading, setCountiesLoading] = useState<boolean>(true);
  const [countiesTraffic, setCountiesTraffic] = useState<lprTotalByCountyList>([]);

  const image = new Image();
  image.src = picture;

  const computeUrlAlert = async (alert: lprDetectionInList) => {
    const res = await getAzureFile(alert.filename);
    return res;
  };

  const loadCountiesTraffic = async () => {
    setCountiesLoading(true);
    if (userName === 'orange@demo.com') {
      setCountiesTraffic(lprTotalsByCountyDumy);
    } else {
      const countiesTraffic = await getLPRTotalsByCounty({ from_param: new Date(dateFilter.from), to_param: new Date(dateFilter.to) });
      setCountiesTraffic(countiesTraffic);
    }

    setCountiesLoading(false);
  };

  const loadItems = async () => {
    setLoading(true);
    if (userName === 'orange@demo.com') {
      setResults(latestLPRDummy);
      setCameras(lprCamerasDummy);
      setLocations(lprLocationsDummy);
      setLoading(false);
      return;
    }
    let res = await getLatestLPR({ from_param: new Date(dateFilter.from), to_param: new Date(dateFilter.to) });
    res = await Promise.all(
      res.map(async (alert) => {
        alert.filename = await computeUrlAlert(alert);
        return alert;
      })
    );
    console.log('results', res);
    setResults(res);
    const cameras = await getLPRCameras();
    setCameras(cameras);
    const locations = await getLPRLocations();
    setLocations(locations);
    setLoading(false);
  };

  useEffect(() => {
    loadItems();
    loadCountiesTraffic();
  }, []);

  const triggerSearch = async (changedPage: number) => {
    setLoading(true);

    setCurrentPage(changedPage);

    if (userName === 'orange@demo.com') {
      setLoading(false);
      return;
    }

    let res = await getLatestLPR({
      county: county !== 'All' ? county : undefined,
      camera_id: selectedCamera ? selectedCamera.camera_id : undefined,
      location_id: selectedLocation ? selectedLocation.location_id : undefined,
      confidence: selectedConfidence > 0 ? selectedConfidence / 100 : undefined,
      search_term: search !== '' ? search : undefined,
      page_number: changedPage,
      page_size: pageSize,
      from_param: new Date(dateFilter.from),
      to_param: new Date(dateFilter.to),
    });

    res = await Promise.all(
      res.map(async (alert) => {
        alert.filename = await computeUrlAlert(alert);
        return alert;
      })
    );

    setResults(res);

    const totalsByCounty = await getLPRTotalsByCounty({
      camera_id: selectedCamera ? selectedCamera.camera_id : undefined,
      location_id: selectedLocation ? selectedLocation.location_id : undefined,
      from_param: new Date(dateFilter.from),
      to_param: new Date(dateFilter.to),
    });
    setCountiesTraffic(totalsByCounty);

    setLoading(false);
  };
  const computeHasNextPage = () => {
    if (results.length === 0) {
      return false;
    }
    return results[0].has_next_page;
  };

  const computeTotalOfCounties = () => {
    return countiesTraffic.reduce((acc, item) => acc + item.total, 0);
  };

  useEffect(() => {
    triggerSearch(1);
  }, [dateFilter]);

  useEffect(() => {
    console.log({ currentPage });
  }, [currentPage]);

  return (
    <div className='p-4'>
      <div
        className='d-flex bg-light flex-row gap-5 justify-content-start align-items-end pb-4 pt-2'
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 100,
          // , boxShadow: '0px 29px 43px -26px rgba(0,0,0,0.25)'
        }}
      >
        <div>
          <Form.Label htmlFor='searchHead'>Filter by vehicle type</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic'>
              Vehicle type : {vehicleType}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setVehicleType('Van')}>Van</Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType('Car')}>Car</Dropdown.Item>
              <Dropdown.Item onClick={() => setVehicleType('Lorry')}>Lorry</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Form.Label htmlFor='searchHead'>Filter by county of origin</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic' className='w-100 d-flex align-items-center justify-content-between'>
              County : {county}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ overflowY: 'auto', maxHeight: '200px' }}>
              <Dropdown.Item onClick={() => setCounty('All')}>All</Dropdown.Item>
              {counties.map((county) => (
                <Dropdown.Item id={county.abbreviation} onClick={() => setCounty(county.abbreviation)}>
                  {county.full_name} - {county.abbreviation}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Form.Label htmlFor='searchHead'>Filter by camera</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic' className='w-100 d-flex align-items-center justify-content-between'>
              Camera : {selectedCamera ? selectedCamera.name : 'All'}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ overflowY: 'auto', maxHeight: '200px' }}>
              <Dropdown.Item onClick={() => setSelectedCamera(undefined)}>All</Dropdown.Item>
              {cameras.map((camera) => (
                <Dropdown.Item id={camera.camera_id.toString()} onClick={() => setSelectedCamera(camera)}>
                  {camera.camera_id} - {camera.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div>
          <Form.Label htmlFor='searchHead'>Filter by location</Form.Label>
          <Dropdown>
            <Dropdown.Toggle variant='secondary' id='dropdown-basic' className='w-100 d-flex align-items-center justify-content-between'>
              Location : {selectedLocation ? selectedLocation.name : 'All'}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ overflowY: 'auto', maxHeight: '200px' }}>
              <Dropdown.Item onClick={() => setSelectedLocation(undefined)}>All</Dropdown.Item>
              {locations.map((location) => (
                <Dropdown.Item id={location.location_id.toString()} onClick={() => setSelectedLocation(location)}>
                  {location.location_id} - {location.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='col-md-1'>
          <Form.Label htmlFor='searchConf'>Confidence</Form.Label>
          <Form.Control
            type='number'
            id='searchConf'
            value={selectedConfidence > 0 ? selectedConfidence : ''}
            width='500px'
            onChange={(e) => setSelectedConfidence(parseFloat(e.currentTarget.value))}
          />
        </div>
        <div className='col-md-3'>
          <Form.Label htmlFor='searchHead'>Search licence plate</Form.Label>
          <Form.Control type='text' id='searchBar' value={search} width='500px' onChange={(e) => setSearch(e.target.value.toUpperCase())} />
        </div>
        <div>
          <Button className='btn btn-success px-4 enter-tooltip' onClick={() => triggerSearch(1)}>
            <i className='bi bi-search fs-3 p-0'></i>
          </Button>
        </div>
      </div>
      <div className='d-flex flex-row pb-4 pt-2' style={{ height: '60vh' }}>
        <div className='d-flex flex-column col-12 col-md-6 pe-md-2' style={{ display: 'flex', flexGrow: 1 }}>
          <div className='card w-100' style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title flex-column'>
                <div className='card-label fw-bold fs-3 mb-1 '>Detections by county</div>
                <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                  Vehicles detected
                </div>
              </h3>
            </div>
            {countiesLoading ? (
              <LoadingPage />
            ) : computeTotalOfCounties() === 0 ? (
              <div className='d-flex flex-row w-100 align-items-center justify-content-center gap-3' style={{ flexGrow: 1 }}>
                <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
                <div className='fs-3 text-secondary-emphasis'>No detections</div>
              </div>
            ) : (
              <ComparisonSeparateChart
                type={'bar'}
                className='w-sm-100'
                chartColor='primary'
                chartHeight='150px'
                comparison={ComparisonEnum.TRAFFIC}
                loading={false}
                values={countiesTraffic.sort((a, b) => b.total - a.total).map((item) => item.total)}
                labels={countiesTraffic.sort((a, b) => b.total - a.total).map((item) => item.county)}
                hasErrors={false}
              />
            )}
          </div>
        </div>
        <div className='d-flex flex-column col-12 col-md-6 ps-md-2' style={{ display: 'flex', flexGrow: 1 }}>
          <div className='card w-100' style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title flex-column'>
                <div className='card-label fw-bold fs-3 mb-1 '>New visitors by county</div>
                <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                  Vehicles detected
                </div>
              </h3>
            </div>
            <ComparisonSeparateChart
              type={'bar'}
              className='w-sm-100'
              chartColor='success'
              chartHeight='150px'
              comparison={ComparisonEnum.TRAFFIC}
              loading={false}
              values={newVisitors.sort((a, b) => b.number - a.number).map((item) => item.number)}
              labels={newVisitors.sort((a, b) => b.number - a.number).map((item) => item.county)}
              hasErrors={false}
            />
          </div>
        </div>
      </div>
      <div className='pt-4'>
        <div className='col-xxl-12'>
          <div className='card'>
            {loading ? (
              <LoadingPage />
            ) : results.length === 0 ? (
              <div className='d-flex flex-row w-100 h-350px align-items-center justify-content-center gap-3'>
                <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
                <div className='fs-3 text-secondary-emphasis'>No detections</div>
              </div>
            ) : (
              <>
                <div className='card-header d-flex flex-row align-items-center'>
                  <h3 className='card-title'>Latest Alerts - {results.length}</h3>
                  <CommonPagination
                    disablePrevious={currentPage === 1 || loading}
                    disableNext={loading || !computeHasNextPage()}
                    startIndex={currentPage}
                    handlePrevChange={() => {
                      if (!loading) {
                        // setCurrentPage((prev) => prev - 1);
                        triggerSearch(currentPage - 1);
                      }
                    }}
                    handleNextChange={() => {
                      if (!loading) {
                        // setCurrentPage((prev) => prev + 1);
                        triggerSearch(currentPage + 1);
                      }
                    }}
                  />
                </div>
                <div className='card-body'>
                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    <thead>
                      <tr className='fw-bolder fs-6 text-gray-800'>
                        <th>Photo</th>
                        <th>Plate Number</th>
                        <th>Detection Date</th>
                        <th>Detection Confidence</th>
                        <th>Camera Name</th>
                        <th>Camera Location</th>
                        <th>View History</th>
                      </tr>
                    </thead>
                    <tbody className='fw-bold text-gray-600'>
                      {results.length > 0 &&
                        results.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td
                                onClick={() => {
                                  setSelectedInstance(item);
                                  toggleModal(0);
                                }}
                                style={{ cursor: 'pointer', width: '200px' }}
                              >
                                <div
                                  className='position-absolute cursor-pointer '
                                  style={{
                                    // make it the same height and width as the image
                                    width: '200px',
                                    height: `${200 * (9 / 16)}px`, // 16 : 9 is the aspect ratio of the image
                                    top: '',
                                    backgroundColor: 'transparent',
                                    zIndex: '',
                                    opacity: '0',
                                    transition: 'opacity 0.2s',
                                  }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                                    e.currentTarget.style.opacity = '0.8';
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.opacity = '0';
                                  }}
                                >
                                  <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                    <button
                                      type='button'
                                      className='btn rounded btn-outline-dark border text-white'
                                      style={{ opacity: '1', transition: 'opacity 0.4s' }}
                                    >
                                      View Image
                                    </button>
                                  </div>
                                </div>
                                {userName === 'orange@demo.com' ? (
                                  <CanvasComponent
                                    // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                                    imageUrl={picture}
                                    actualHeight={image.naturalHeight}
                                    actualWidth={image.naturalWidth}
                                    bboxes={[{ x1: 200, y1: 100, x2: 550, y2: 500 }]}
                                    canvasHeight={`${200 * (9 / 16)}px`}
                                    canvasWidth={'200px'}
                                  />
                                ) : (
                                  <CanvasComponent
                                    imageUrl={item.filename}
                                    actualHeight={item.picture_height}
                                    actualWidth={item.picture_width}
                                    bboxes={[{ x1: item.bbox1, y1: item.bbox2, x2: item.bbox3, y2: item.bbox4 }]}
                                    canvasHeight={`${200 * (9 / 16)}px`}
                                    canvasWidth={'200px'}
                                  />
                                )}
                              </td>
                              <td>{item.plate_number}</td>
                              <td>{new Date(item.detection_time).toLocaleString('en-GB')}</td>
                              <td>{(item.confidence * 100).toFixed(1)} %</td>
                              <td>{item.camera_name}</td>
                              <td>{item.camera_location_name}</td>
                              <td>
                                <Button variant='secondary btn-sm d-flex flex-row gap-2 align-items-center px-2'>
                                  <Link to={`/traffic/lpr/${item.plate_number}`} className='d-flex flex-row gap-2 align-items-center px-2 text-dark'>
                                    View History
                                    <i className='bi bi-search'></i>
                                  </Link>
                                </Button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {selectedInstance && (
        <CommonModal
          closeButton={true}
          title={`Photo of ${selectedInstance?.plate_number} on ${new Date(selectedInstance!.detection_time).toLocaleString('en-GB')} at ${
            selectedInstance?.camera_location_name
          }`}
          show={show[0]}
          showModalCallback={() => toggleModal(0)}
          size='lg'
          closeButtonOptions={{}}
          confirmButton={false}
          confirmButtonOptions={{}}
        >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            {userName === 'orange@demo.com' ? (
              <AlertCanvasComponent
                // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                imageUrl={picture}
                actualHeight={image.naturalHeight}
                actualWidth={image.naturalWidth}
                multiple_bboxes={[{ bboxes: [200, 100, 550, 500], detail_description: `${selectedInstance?.plate_number}` }]}
                canvasHeight={`${750 * (9 / 16)}px`}
                canvasWidth={'750px'}
                big={true}
              />
            ) : (
              <AlertCanvasComponent
                // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                imageUrl={selectedInstance.filename}
                actualHeight={selectedInstance.picture_height}
                actualWidth={selectedInstance.picture_width}
                multiple_bboxes={[
                  {
                    bboxes: [selectedInstance.bbox1, selectedInstance.bbox2, selectedInstance.bbox3, selectedInstance.bbox4],
                    detail_description: `${selectedInstance?.plate_number}`,
                  },
                ]}
                canvasHeight={`${750 * (9 / 16)}px`}
                canvasWidth={'750px'}
                big={true}
              />
            )}
          </div>
        </CommonModal>
      )}
    </div>
  );
};

export default TrafficLPR;
