import { useEffect, useState } from 'react';
import useToolbarSetter from '../hooks/Common/useToolbarSetter';
import { EProductType, setActiveProduct } from '../redux/activeProductReducer';
import { useDispatch, useSelector } from 'react-redux';
// import hsse from '../assets/images/products/hsse.png'
// import retail from '../assets/images/products/retail.png'

import alerts from '../assets/images/products/alerts.png';
import analytics from '../assets/images/products/analytics.png';
import reports from '../assets/images/products/reports.png';
import smartphone from '../assets/images/products/smartphone.png';

import shopper from '../assets/images/products/shopper2.png';

import retail from '../assets/images/products/retail-face.jpg';
import hsse from '../assets/images/products/hsse-face.jpg';
import smartcity from '../assets/images/products/smartcity-face.jpg';
import { RootState } from '../redux/store';
import { setDemoProduct } from '../redux/demoProductReducer';
import Slideshow, { EmbeddedVideoData } from './Common/Slider';

export default function Hub() {
  const activeProduct = useSelector((state: RootState) => state.activeProduct.activeProduct);
  const demoProduct = useSelector((state: RootState) => state.demoProduct.demoProduct);
  const [videoDescription, setVideoDescription] = useState<string>('');
  const [videoTitle, setVideoTitle] = useState<string>('');
  const dispatch = useDispatch();
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('hub', 'Complete software solution');
  });

  const videoData: EmbeddedVideoData[] =
    demoProduct === EProductType.RETAIL
      ? [
          {
            src: 'https://www.youtube.com/embed/mgwHvwO1Fr0',
            title: 'Age and Gender',
            description: 'This product utilizes AI to estimate the age and gender of individuals in surveillance footage.',
          },
          {
            src: 'https://www.youtube.com/embed/f1rVmWVLvOc',
            title: 'Heatmap',
            description: 'The heatmap is created based on customers movement patterns, providing insights into attention span.',
          },
          {
            src: 'https://www.youtube.com/embed/fZCKxfDGAe4',
            title: 'Product Layout',
            description:
              'This solution helps you analyze the layout of products in a given space, assisting in retail optimization and product placement.',
          },
          {
            src: 'https://www.youtube.com/embed/GcuIF6ZVFIU',
            title: 'Missing Products',
            description: 'With this tool, you can identify missing products within a store or inventory, enhancing loss prevention.',
          },
          {
            src: 'https://www.youtube.com/embed/OlzozbsRltg',
            title: 'Product Count',
            description: 'Product count uses AI to accurately count items in real-time, aiding in inventory management.',
          },
          {
            src: 'https://www.youtube.com/embed/DPcn-eiklaY',
            title: 'Product Recognition',
            description:
              'This product enables the recognition and identification of specific products, streamlining inventory tracking and retail analytics.',
          },
          {
            src: 'https://www.youtube.com/embed/jegcASPpJ08',
            title: 'Queue Counting',
            description: 'Queue Counting helps businesses monitor and manage customer queues efficiently.',
          },
        ]
      : demoProduct === EProductType.HSSE
      ? [
          {
            src: '/alerts-media/presentation/detections/PERSON COUNTING/aworker-count.mp4',
            title: 'Workers Counter',
            description: 'This product is designed for accurately counting and monitoring the presence of workers at a site.',
          },
          {
            src: '/alerts-media/presentation/detections/VEHICLE DETECTION/v1.mp4',
            title: 'Vehicle Detection',
            description: 'Vehicle Detection helps identify and track vehicles within a construction or worksite.',
          },
          {
            src: '/alerts-media/presentation/detections/DANGEROUS SITUATIONS/ds2.mp4',
            title: 'Perimeter Monitoring',
            description: 'Perimeter Monitoring ensures secure boundaries and alerts you to any unauthorized access.',
          },
          {
            src: '/alerts-media/presentation/detections/EQUIPMENT/EQUIPMENT_2.mp4',
            title: 'Equipment Detection',
            description: 'This product recognizes and tracks equipment and machinery on the site for safety and security purposes.',
          },
          {
            src: '/alerts-media/presentation/detections/DANGEROUS SITUATIONS/ds1.mp4',
            title: 'Dangerous Situations',
            description: 'It detects potentially hazardous situations and sends alerts for timely action.',
          },
          {
            src: '/alerts-media/presentation/detections/SUSPENDED LOAD/dangerous-situations.mp4',
            title: 'Working under suspended load',
            description: 'This feature provides alerts when work is being performed under a suspended load, enhancing safety protocols.',
          },
          {
            src: '/alerts-media/presentation/detections/PHONE/1.mp4',
            title: 'Phone Detection',
            description: 'Phone Detection helps ensure that safety regulations regarding mobile phone use are followed on-site.',
          },
        ]
      : demoProduct === EProductType.MOBILITY
      ? [
          {
            src: '/alerts-media/presentation/detections/SPEED/s1.mp4',
            title: 'Speed Estimation',
            description:
              'Discover the power of precise Speed Estimation as it analyzes video data to determine vehicle speeds, enhancing traffic management and safety measures.',
          },
          {
            src: '/alerts-media/presentation/detections/PARCARE INTERZISA/parc_.mp4',
            title: 'Forbidden Parking',
            description:
              'Uncover the efficiency of Forbidden Parking technology, employing advanced algorithms for Car Counting to identify and prevent unauthorized parking, ensuring orderly traffic spaces.',
          },
          {
            src: '/alerts-media/presentation/detections/GUNOI ARUNCAT/2.mp4',
            title: 'Garbage Detection',
            description: 'Garbage Detection identifies and tracks the presence of garbage or waste in specified locations.',
          },
          {
            src: '/alerts-media/presentation/detections/FIRE/tracking_2_202311152204510085.mp4',
            title: 'Fire Detector',
            description:
              'Safeguard properties with the Fire Detector feature, utilizing cutting-edge Vandalization Detection to identify unusual behavior and protect against potential fire threats and property damage.',
          },
          {
            src: '/alerts-media/presentation/detections/PARCARE INTERZISA IN STATIA DE AUTOBUZ/pi_1.mp4',
            title: 'Parked cars in bus stop detection',
            description: 'This feature helps ensure bus stops are not obstructed by parked vehicles, improving public transportation services.',
          },
          {
            src: '/alerts-media/presentation/detections/GUNOI ARUNCAT/1.mp4',
            title: 'Garbage Truck Detection',
            description: 'Garbage Truck Detection recognizes and tracks garbage trucks, facilitating waste management.',
          },
          {
            src: '/alerts-media/presentation/detections/PERSON/tracking_2_202311151825320350.mp4',
            title: 'Person Counting',
            description:
              'Optimize public transportation services with Person Counting, a feature that accurately counts individuals in specified areas, providing valuable data for monitoring and improving transit operations.',
          },
        ]
      : [];

  const handleSetDemoProduct = (product: EProductType) => {
    // dispatch(setActiveProduct({
    //     activeProduct: product
    // }))
    dispatch(
      setDemoProduct({
        demoProduct: product,
      })
    );
  };

  const handleActiveIndex = (index: number) => {
    setVideoDescription(videoData[index].description);
    setVideoTitle(videoData[index].title);
  };
  const handleSetActiveProduct = (product: EProductType) => {
    dispatch(
      setActiveProduct({
        activeProduct: product,
      })
    );
    let path = '';
    switch (product) {
      case EProductType.RETAIL: {
        path = '/queue-management';
        break;
      }
      case EProductType.HSSE: {
        path = '/hsse';
        break;
      }
      case EProductType.MOBILITY: {
        path = '/mobility';
        break;
      }
      default:
        path = '/home';
    }
    window.location.href = process.env.REACT_APP_HOMEPAGE + path;
  };
  return (
    <div className=''>
      <div className='d-flex flex-column py-5'>
        <div className='d-flex flex-row container '>
          <div className=' col-sm-4 p-5 animated fadeInUp ' style={{ animationDelay: '0.2s' }}>
            <img
              src={retail}
              alt='Retail'
              className={` d-flex cursor-pointer w-100 rounded-5 box-shadow hover-grow ${demoProduct !== EProductType.RETAIL && 'black-and-white'}`}
              onClick={() => handleSetDemoProduct(EProductType.RETAIL)}
            />
          </div>
          <div className=' col-sm-4 p-5 animated fadeInUp' style={{ animationDelay: '0.4s' }}>
            <img
              src={hsse}
              alt='HSSE'
              className={`d-flex cursor-pointer w-100 rounded-5 box-shadow hover-grow ${demoProduct !== EProductType.HSSE && 'black-and-white'}`}
              onClick={() => handleSetDemoProduct(EProductType.HSSE)}
            />
          </div>
          <div className=' col-sm-4 p-5 animated fadeInUp' style={{ animationDelay: '0.6s' }}>
            <img
              src={smartcity}
              alt='Mobility'
              className={`d-flex cursor-pointer w-100 rounded-5 box-shadow hover-grow ${demoProduct !== EProductType.MOBILITY && 'black-and-white'}`}
              onClick={() => handleSetDemoProduct(EProductType.MOBILITY)}
            />
          </div>
        </div>
        {demoProduct !== EProductType.NONE && (
          <div className='d-flex flex-row container'>
            <div className=' offset-4 col-sm-4 p-5 animated fadeIn' style={{ animationDelay: '0.2s' }}>
              <div className='btn btn btn-primary w-100 rounded-5' onClick={() => handleSetActiveProduct(demoProduct)}>
                See Demo
              </div>
            </div>
          </div>
        )}
      </div>
      {demoProduct === EProductType.NONE && (
        <div className='d-flex flex-column bg-white animated fadeIn' style={{ animationDelay: '0.8s' }}>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-12 p-5 pb-0'>
              <span className='fs-1'>
                Unlock the <b>Full Potential</b> of our <b>AI-Powered Solutions</b>
              </span>

              <p className='mt-4 fs-2'>
                Our cutting-edge, <b>fully customizable</b> detection systems are designed to provide valuable insights.
              </p>
            </div>
          </div>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-12 p-5 pb-0'>
              <p className='mt-4 fs-2'>
                Experience <b>comprehensive software solutions</b> for video data analytics, extending beyond AI. Explore our <b>versatile</b> and
                customizable detection systems.
              </p>
            </div>
          </div>

          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-6 p-5'>
              <div className='d-flex flex-row'>
                <div className='d-flex col-3'>
                  <img src={reports} className='w-100 hover-grow' alt='reports' />
                </div>
                <div className='d-flex col-9  my-auto'>
                  <span className='fs-2'>
                    {' '}
                    <b>Back-office app</b> with reports, analytics and alert management.
                  </span>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='d-flex col-3'>
                  <img src={analytics} className='w-100 hover-grow' alt='analytics' />
                </div>
                <div className='d-flex col-9  my-auto'>
                  <span className='fs-2'>
                    {' '}
                    <b>Extended analytics</b>. All the data generated by the AI Systems are available for extended analytics using Power BI or other
                    Business Intelligence app.
                  </span>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='d-flex col-3'>
                  <img src={smartphone} className='w-100 hover-grow' alt='smartphone' />
                </div>
                <div className='d-flex col-9  my-auto'>
                  <span className='fs-2'>
                    {' '}
                    <b>Dedicated SmartPhone App</b> – IOS and Android.
                  </span>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className='d-flex col-3'>
                  <img src={alerts} className='w-100 hover-grow' alt='alerts' />
                </div>
                <div className='d-flex col-9  my-auto'>
                  <span className='fs-2'>
                    {' '}
                    The system sends <b>real time alerts</b> (push notification, email) when an alert occurs. User can define alerts (e.g.: missing
                    product for more than 5 minutes, or the number of products is less than 5.)
                  </span>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column col-6 p-5'>
              <img className='w-100 m-auto flex-center hover-grow' src={shopper} />
            </div>
          </div>
        </div>
      )}
      {demoProduct === EProductType.RETAIL && (
        <div className='d-flex flex-column bg-white animated fadeIn' style={{ animationDelay: '0s' }}>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-12 p-5 pb-0'>
              <span className='fs-2'>
                <b>Retail Revolution</b>: Explore the future of retail with our AI-driven solutions.
              </span>
            </div>
          </div>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-8 p-5 animated fadeInLeft' style={{ animationDelay: '0.2s' }}>
              <Slideshow data={videoData} activeIndex={handleActiveIndex} width={600} />
            </div>
            <div className='d-flex flex-column col-4 p-5 animated fadeInRight' style={{ animationDelay: '0.2s' }}>
              <h2>{videoTitle}</h2>
              <p className='mt-4 fs-2'>{videoDescription}</p>
              <div className='d-flex flex-row container'>
                <div className='col-sm-12 p-5 animated fadeIn' style={{ animationDelay: '0.2s' }}>
                  <div className='btn btn btn-primary w-100 rounded-5' onClick={() => handleSetActiveProduct(demoProduct)}>
                    See Retail Demo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {demoProduct === EProductType.HSSE && (
        <div className='d-flex flex-column bg-white animated fadeIn' style={{ animationDelay: '0s' }}>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-12 p-5 pb-0'>
              <span className='fs-2'>
                <b>Safety First</b>: Prioritize health, safety, security, and the environment with our HSSE products.
              </span>
            </div>
          </div>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-8 p-5 animated fadeInLeft' style={{ animationDelay: '0.2s' }}>
              <Slideshow data={videoData} activeIndex={handleActiveIndex} width={600} />
            </div>
            <div className='d-flex flex-column col-4 p-5 animated fadeInRight' style={{ animationDelay: '0.2s' }}>
              <h2>{videoTitle}</h2>
              <p className='mt-4 fs-2'>{videoDescription}</p>
              <div className='d-flex flex-row container'>
                <div className='col-sm-12 p-5 animated fadeIn' style={{ animationDelay: '0.2s' }}>
                  <div className='btn btn btn-primary w-100 rounded-5' onClick={() => handleSetActiveProduct(demoProduct)}>
                    See HSSE Demo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {demoProduct === EProductType.MOBILITY && (
        <div className='d-flex flex-column bg-white animated fadeIn' style={{ animationDelay: '0s' }}>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-12 p-5 pb-0'>
              <span className='fs-2'>
                <b>Mobility</b>: Discover innovative solutions for efficient and sustainable urban mobility.
              </span>
            </div>
          </div>
          <div className='d-flex flex-row container'>
            <div className='d-flex flex-column col-8 p-5 animated fadeInLeft' style={{ animationDelay: '0.2s' }}>
              <Slideshow data={videoData} activeIndex={handleActiveIndex} width={600} />
            </div>
            <div className='d-flex flex-column col-4 p-5 animated fadeInRight' style={{ animationDelay: '0.2s' }}>
              <h2>{videoTitle}</h2>
              <p className='mt-4 fs-2'>{videoDescription}</p>
              <div className='d-flex flex-row container'>
                <div className='col-sm-12 p-5 animated fadeIn' style={{ animationDelay: '0.2s' }}>
                  <div className='btn btn btn-primary w-100 rounded-5' onClick={() => handleSetActiveProduct(demoProduct)}>
                    See Mobility Demo
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
