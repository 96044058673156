import React, { useEffect, useState } from 'react'
import peopleCountingDailyReoprts from '../../web_api/methods/peopleCountingDash/dailyReports';
import { dailyReportsList } from '../../web_api/models';
import { ChartsWidget9 } from '../../_metronic/widgets/charts/ChartsWidget9';

type Props = {
    className: string
}

const WeeklyReportsPeopleCounting: React.FC<Props> = ({ className }) => {

    const [values, setValues] = useState<number[]>([])
    const [days, setDays] = useState<string[]>([])
    const [valuesLastWeek, setValuesLastWeek] = useState<number[]>([])
    const [daysLastWeek, setDaysLastWeek] = useState<string[]>([])
    const [average, setAverage] = useState<number>(0)
    const [max, setMax] = useState<number>(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadDailyReports();
    }, []);
    const getMonday = () => {

        const today = new Date();
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(today.setDate(diff));
    };

    const loadDailyReports = async () => {
        try {
            const to = new Date()
            const from = getMonday()
            const lastWeek = new Date()
            lastWeek.setDate(from.getDate() - 7)
            let dailyReports: dailyReportsList = await peopleCountingDailyReoprts(from, to);
            let forValues = new Array()
            let weekdays = new Array()
            let sum = 0;
            let max = 0;
            var dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            dailyReports.forEach((item) => {
                forValues.push(item.average)
                weekdays.push(dayNames[item.zi - 1]);
                if (item.average > max)
                    max = item.average
            })
            while (forValues.length < 7) {
                weekdays.push(dayNames[forValues.length]);
                forValues.push(0)
            }
            setValues(forValues)
            setDays(weekdays)

            let dailyReportsLastWeek: dailyReportsList = await peopleCountingDailyReoprts(lastWeek, from);
            forValues = new Array()
            weekdays = new Array()

            dailyReportsLastWeek.forEach((item) => {
                forValues.push(item.average)
                weekdays.push(dayNames[item.zi - 1]);
                sum += item.average
                if (item.average > max)
                    max = item.average
            })
            while (forValues.length < 7) {
                weekdays.push(dayNames[forValues.length]);
                forValues.push(0)
            }
            setAverage(Math.floor(sum / forValues.length))
            setValuesLastWeek(forValues)
            setDaysLastWeek(weekdays)
            setLoading(true)
            setMax(max)

        }
        catch (e) {
            console.log(e)
        }
    };
    return (
        <ChartsWidget9
            className={className}
            chartColor='primary'
            chartSecondColor="danger"
            values={values}
            valuesLastWeek={valuesLastWeek}
            days={days}
            daysLastWeek={daysLastWeek}
            average={average}
            max={max}
            loading={loading}
        />
    )
}

export { WeeklyReportsPeopleCounting }