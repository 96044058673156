import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { DateRange, DayPicker } from 'react-day-picker';
import * as ReactBootstrap from 'react-bootstrap';
import { setValue as setDateFilter } from '../redux/dateFilterReducer';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';

const ToolbarTraffic = () => {
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const defaultSelected: DateRange = {
    from: new Date(dateFilter.from),
    to: new Date(dateFilter.to),
  };
  const [period, setPeriod] = useState<string>('');

  const [range, setRange] = useState<DateRange | undefined>(defaultSelected);
  const handleCloseModal = () => {
    setShow(false);
    dispatch(
      setDateFilter({
        from: range!?.from!.toISOString(),
        to: range!?.to!.toISOString(),
      })
    );
  };

  const handleShowModal = () => setShow(true);

  let selectedRange = <>Date picker</>;
  if (range?.from) {
    if (!range.to) {
      range.to = range.from;
      // selectedRange = <p className='w-100'>{format(range.from, 'PPP')}</p>;
      selectedRange = (
        <>
          {range.from
            .toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/\//g, '-')}
        </>
      );
    } else if (range.to) {
      selectedRange =
        range.to.toString() === range.from.toString() ? (
          <>
            {range.from
              .toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        ) : (
          <>
            {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
            {range.from
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}{' '}
            -{' '}
            {range.to
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        );
    }
  }

  let formattedSelectedRange = (
    <div className='d-flex fs-4 bg-secondary rounded align-items-end justify-content-center py-1'>Range: {selectedRange}</div>
  );

  useEffect(() => {
    if (period !== '') {
      let from = new Date();
      let to = new Date();
      switch (period) {
        case 'Current Week':
          from.setDate(from.getDate() - from.getDay());
          to = new Date();
          break;
        case 'Last Week':
          from.setDate(from.getDate() - from.getDay() - 7);
          to.setDate(to.getDate() + (6 - to.getDay() - 7));
          break;
        case 'Current Month':
          from.setDate(1);
          to = new Date();
          break;
        case 'Last 3 Months':
          from.setMonth(from.getMonth() - 3);
          from.setDate(1);
          to.setDate(0);
          break;
        case 'Last 6 months':
          from.setMonth(from.getMonth() - 6);
          from.setDate(1);
          to.setDate(0);
          break;
        case 'Current Year':
          from.setMonth(0);
          from.setDate(1);
          to = new Date();
          break;
        case 'Last Year':
          from.setFullYear(from.getFullYear() - 1);
          from.setMonth(0);
          from.setDate(1);
          to.setFullYear(to.getFullYear() - 1);
          to.setMonth(11);
          to.setDate(31);
          break;
      }
      setRange({ from: from, to: to });
    }
  }, [period]);

  const handleToggleChange = (val: any) => {
    if (period === val) setPeriod('');
    else setPeriod(val);
  };

  return (
    <div
      className={`d-flex align-items-center flex-column flex-md-row gap-3 gap-md-0 mt-4 mt-md-0 w-100 w-md-auto ${
        window.innerWidth > 768 ? 'overflow-auto' : ''
      }`}
    >
      <div className='d-flex align-items-center justify-content-center w-100 w-md-auto p-0'>
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Range:</span>
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
            <i className='bi bi-calendar mb-1 align-self-center' />
            <span className='text-nowrap'>{selectedRange}</span>
          </div>
        </div>
      </div>

      <ReactBootstrap.Modal show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-row align-items-center'>
            <div className='border border-2 border-secondary rounded'>
              <ToggleButtonGroup
                type='radio'
                name='predefinedPeriods'
                vertical
                className='d-flex flex-column gap-2'
                value={period}
                onChange={handleToggleChange}
              >
                <ReactBootstrap.ToggleButton
                  id={'Current Week'}
                  variant='outline-secondary'
                  checked={period === 'Current Week'}
                  value={'Current Week'}
                >
                  Current Week
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton id={'Last Week'} variant='outline-secondary' checked={period === 'Last Week'} value={'Last Week'}>
                  Last Week
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton
                  id={'Current Month'}
                  variant='outline-secondary'
                  checked={period === 'Current Month'}
                  value={'Current Month'}
                >
                  Current Month
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton
                  id={'Last 3 Months'}
                  variant='outline-secondary'
                  checked={period === 'Last 3 Months'}
                  value={'Last 3 Months'}
                >
                  Last 3 Months
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton
                  id={'Last 6 months'}
                  variant='outline-secondary'
                  checked={period === 'Last 6 months'}
                  value={'Last 6 months'}
                >
                  Last 6 months
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton
                  id={'Current Year'}
                  variant='outline-secondary'
                  checked={period === 'Current Year'}
                  value={'Current Year'}
                >
                  Current Year
                </ReactBootstrap.ToggleButton>
                <ReactBootstrap.ToggleButton id={'Last Year'} variant='outline-secondary' checked={period === 'Last Year'} value={'Last Year'}>
                  Last Year
                </ReactBootstrap.ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className='d-flex flex-column'>
              <div className='d-flex flex-row'>
                <DayPicker
                  id='1'
                  fromYear={2020}
                  toYear={new Date().getFullYear()}
                  toMonth={new Date()}
                  toDate={new Date()}
                  defaultMonth={range?.to === undefined ? range?.from : range.to}
                  mode='range'
                  selected={range}
                  onSelect={(val) => {
                    setPeriod('');
                    setRange(val);
                  }}
                  footer={formattedSelectedRange}
                  captionLayout='dropdown'
                  disabled={{ after: new Date() }}
                />
              </div>
            </div>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarTraffic };
