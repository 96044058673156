import { MostQueueAlertsQueueManagement } from './MostQueueAlertsQueueManagement'
import { AudioAlertsQueueManagement } from './AudioAlertsQueueManagement'
import { MostQueueAlertsListQueueManagement } from './MostQueueAlertsListQueueManagement'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { DataWidgetPerDays } from '../../_metronic/widgets/data_widgets/DataWidgetPerDays'
import { LocationsAudioAlertQueueManagement } from './LocationsAudioAlertsQueueManagement'
import { AvgTimesQueueManagement } from './AvgTimesQueueManagement'
import { AvgTimesOrderedListQueueManagement } from './AvgTimesOrderedListQueueManagement'
import TablePDF from '../PDFTest'
import { useEffect, useState } from 'react'
import { PdfOrCsvEnum, audioAlertsList, mostQueueAlertsList } from '../../web_api/models'
import * as ReactBootstrap from 'react-bootstrap'
import { exportBigCSVQueueManagement } from '../../web_api/methods/helpers'
import useToolbarSetter from '../../hooks/Common/useToolbarSetter'
import { Button, Dropdown, InputGroup } from 'react-bootstrap'
export default function QueueManagement() {

    const dateFilter = useSelector((state: RootState) => state.dateFilter);
    const location = useSelector((state: RootState) => state.activeLocation);
    const user_name = useSelector((state: RootState) => state.user.user_name);
    const hourParameters = useSelector((state: RootState) => state.hourParameters);
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const [fromDate, setFromDate] = useState<string>(new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    const [toDate, setToDate] = useState<string>(new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
    const [hourParametersToSend, setHourParametersToSend] = useState<string>(((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00'))
    const [audioAlertsPDF, setAudioAlertsPDF] = useState<audioAlertsList>()
    const [queueAlertsPDF, setQueueAlertsPDF] = useState<mostQueueAlertsList>()
    const [show, setShow] = useState(false);
    const [isError, setIsError] = useState<boolean>(false);

    const totalQueueAlerts = useSelector((state: RootState) => state.totalQueueAlerts);
    const totalAudioAlerts = useSelector((state: RootState) => state.audioAlerts);
    const mostQueueAlerts = useSelector((state: RootState) => state.mostQueueAlerts.list);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);

    const avgTimeCashRegisterAllLocations = useSelector((state: RootState) => state.avgTimeCashRegisterAllLocations.value);
    const queueDurationAllLocations = useSelector((state: RootState) => state.queueDurationAllLocations.value);


    const avgTimesCashRegisterOpenList = useSelector((state: RootState) => state.avgTimeCashRegisterOpenList.list);
    const queueDurationList = useSelector((state: RootState) => state.queueDurationList.list);
    const [pdfOrCsv, setPdfOrCsv] = useState<PdfOrCsvEnum>(PdfOrCsvEnum.PDF)
    const [csvSeparator, setCsvSeparator] = useState<string>('')


    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'queue-management',
            activeLocation.value + ' - ' + ((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : `${hourParameters.from}:00 - ${hourParameters.to}:00`)
        )
    })


    useEffect(() => {
        setFromDate(new Date(dateFilter.from).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }))
        setToDate(new Date(dateFilter.to).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' }))
        setHourParametersToSend(((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00'))
    }, [dateFilter, hourParameters])

    const handleDataAudioAlerts = (data: audioAlertsList) => {
        setAudioAlertsPDF(data)
    };
    const handleDataQueueAlerts = (data: mostQueueAlertsList) => {
        setQueueAlertsPDF(data)
    };

    const handleShowModal = () => setShow(true);
    const handleCloseModal = () => {
        setShow(false);
    }
    const handleDownload = () => {
        setIsError(false)
        if (!csvSeparator) {
            setIsError(true)
        }
        else {
            exportBigCSVQueueManagement(
                (activeLocation.id === '0') ? ['Id', 'Name', 'Audio alerts', 'Time'] : ['Id', 'Audio alerts', 'Time'],

                audioAlertsPDF!,
                (activeLocation.id === '0') ? ['Id', 'Name', 'Queue alerts', 'Day'] : ['Name', 'Queue alerts', 'Day'],
                ['Location Id', 'Name', 'Response time', 'Queue duration'],
                mostQueueAlerts,
                { id: location.id === '0' ? 'All Locations' : location.id, name: location.value === 'All' ? selectedUser.value === 'All' ? 'All Users' : selectedUser.value : location.value },
                user_name,
                (fromDate === toDate) ?
                    (`${fromDate} - ${hourParametersToSend}`)
                    :
                    (`${fromDate} - ${toDate} - ${hourParametersToSend}`),
                totalQueueAlerts.value,
                totalAudioAlerts.value,
                avgTimeCashRegisterAllLocations,
                queueDurationAllLocations,
                avgTimesCashRegisterOpenList,
                queueDurationList,
                `Queue management - ${activeLocation.value} - ${dateFilter.from === dateFilter.to ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
                    :
                    new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
                    + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}, ${((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00')}`,
                csvSeparator
            )
            setShow(false);
        }

    };
    return (
        <div className="container">
            <div className='d-flex flex-column flex-md-row p-5 pb-0 flex-start flex-md-end mt-2 mt-md-0'>
                <div className='btn btn-primary' onClick={() => { handleShowModal() }}>Generate report</div>
            </div>
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex flex-row flex-md-column col-12 col-xxl-6">
                    <div className="d-flex flex-column flex-md-row w-sm-100 h-100 flex-fill">
                        <div className="p-5 w-md-50">
                            <AudioAlertsQueueManagement className='h-md-100 mb-xl-5 animated zoomIn' from={new Date(dateFilter.from)} to={new Date(dateFilter.to)} callbackData={handleDataAudioAlerts} />
                        </div>
                        <div className="p-5 w-md-50">
                            <MostQueueAlertsQueueManagement className='h-md-100 mb-xl-5 animated zoomIn' from={new Date(dateFilter.from)} to={new Date(dateFilter.to)} callbackData={handleDataQueueAlerts} />
                        </div>
                    </div>
                </div>
                <div className="d-flex col-12 col-xxl-6 p-5">
                    <MostQueueAlertsListQueueManagement className='flex-fill animated zoomIn' />
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row">
                <div className="d-flex flex-row flex-md-column col-md-5 col-xxl-6 p-0">
                    <div className="p-5 flex-fill">
                        <DataWidgetPerDays
                            className={'h-md-100 mb-xl-5 ' + dateFilter.from === dateFilter.to ? 'pb-5' : ''}
                            // color='#F1416C'
                            color='white'
                        />
                    </div>
                </div>
                <div className="d-flex flex-row flex-md-column col-md-7 col-xxl-6 p-0">
                    <div className="p-5 flex-fill">
                        <LocationsAudioAlertQueueManagement
                            className={'h-md-100 mb-xl-5'}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-xxl-row">
                <div className="d-flex flex-row flex-md-column col-xxl-7 p-0">
                    <div className="p-5 flex-fill">
                        <AvgTimesQueueManagement className={'h-md-100 animated zoomIn'} from={new Date(dateFilter.from)} to={new Date(dateFilter.to)} />
                    </div>
                </div>
                <div className="d-flex flex-row flex-md-column col-xxl-5 p-0 mb-12 mb-xxl-0">
                    <div className="p-5 flex-fill w-100">
                        <AvgTimesOrderedListQueueManagement className={'h-400px h-md-100 animated zoomIn'} />
                    </div>
                </div>
            </div>
            <ReactBootstrap.Modal size='lg' show={show} onHide={handleCloseModal}>
                <ReactBootstrap.Modal.Header>
                    <h2>{activeLocation.value}</h2>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModal}>
                        <i className='bi bi-x' style={{ fontSize: '2rem' }} />
                    </div>
                </ReactBootstrap.Modal.Header>
                <ReactBootstrap.Modal.Body className='d-flex flex-column flex-center'>
                    <div className='flex-shrink-0 d-flex flex-start'>
                        <ul className='nav'>
                            <li className='nav-item' onClick={() => { setPdfOrCsv(PdfOrCsvEnum.PDF); }}>
                                <a
                                    className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (pdfOrCsv === PdfOrCsvEnum.PDF ? ' active' : '')}
                                    data-bs-toggle='tab'
                                >
                                    PDF
                                </a>
                            </li>

                            <li className='nav-item' onClick={() => { setPdfOrCsv(PdfOrCsvEnum.CSV); }}>
                                <a
                                    className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1' + (pdfOrCsv === PdfOrCsvEnum.CSV ? ' active' : '')}
                                    data-bs-toggle='tab'
                                >
                                    CSV
                                </a>
                            </li>
                        </ul>
                    </div>
                    {pdfOrCsv === PdfOrCsvEnum.PDF ? (
                        <TablePDF
                            headers={(activeLocation.id === '0') ? ['Id', 'Name', 'Audio alerts', 'Time'] : ['Id', 'Audio alerts', 'Time']}
                            data={audioAlertsPDF!}
                            location={{ id: location.id === '0' ? 'All Locations' : location.id, name: location.value === 'All' ? selectedUser.value === 'All' ? 'All Users' : selectedUser.value : location.value }}
                            user_name={user_name}
                            date={fromDate === toDate ?
                                (`${fromDate} - ${hourParametersToSend}`)
                                :
                                (`${fromDate} - ${toDate} - ${hourParametersToSend}`)}
                            headersQueueAlerts={(activeLocation.id === '0') ? ['Id', 'Name', 'Queue alerts', 'Day'] : ['Name', 'Queue alerts', 'Day']}
                            dataQueueAlerts={mostQueueAlerts}
                            headersAvgTimes={['Location Id', 'Name', 'Response time', 'Queue duration']}
                            totalQueueAlerts={totalQueueAlerts.value}
                            totalAudioAlerts={totalAudioAlerts.value}
                            responseTimeAll={avgTimeCashRegisterAllLocations}
                            responseTimeList={avgTimesCashRegisterOpenList}
                            queueDurationAll={queueDurationAllLocations}
                            queueDurationList={queueDurationList}
                            fileName={`Queue management - ${activeLocation.value} - ${dateFilter.from === dateFilter.to ? ('on ' + new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }))
                                :
                                new Date(dateFilter.from).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) + ' - '
                                + new Date(dateFilter.to).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}, ${((hourParameters.from === "0" && hourParameters.to === "24") ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00')}`}
                        />
                    ) : (
                        isError && (
                            <i className='text-danger'>Please select a separator</i>
                        )
                    )}

                </ReactBootstrap.Modal.Body>
                <ReactBootstrap.Modal.Footer className='d-flex justify-content-between'>
                    <ReactBootstrap.Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </ReactBootstrap.Button>
                    {pdfOrCsv === PdfOrCsvEnum.CSV && (
                        <>
                            {/* <ReactBootstrap.Button variant="primary" onClick={handleDownload}>
                                Download CSV
                            </ReactBootstrap.Button> */}
                            <InputGroup className="input-group-sm"
                            //  style={{ width: '50%' }}
                            >

                                <div
                                    className={`btn btn-sm px-3 d-flex btn-primary`}
                                    onClick={handleDownload}>
                                    <div className='d-flex align-items-center'>
                                        <span>Download CSV</span>
                                    </div>
                                </div>

                                <ReactBootstrap.DropdownButton
                                    variant="sm bg-white border border-2 border-primary text-primary h-25 min-h-35px d-flex align-items-center"
                                    title={`${csvSeparator ? `${csvSeparator} separated` : 'Choose separator'}`}
                                    id="input-group-dropdown-1"

                                >
                                    {/* {registries.filter(x => x.nume_tip_registru === 'Facturi').map((item) => { */}
                                    <div style={{ maxHeight: '250px', overflowY: 'auto' }}>

                                        <Dropdown.Item
                                            className='px-0 py-1 dropdown-item my-auto d-flex'
                                            key={0}
                                            onClick={() => { setCsvSeparator(',') }}>
                                            <div className='d-flex justify-content-between flex-fill'><span className='ms-2 my-auto'>Column separated</span></div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className='px-0 py-1 dropdown-item my-auto d-flex'
                                            key={1}
                                            onClick={() => { setCsvSeparator(';') }}>
                                            <div className='d-flex justify-content-between flex-fill'><span className='ms-2 my-auto'>Semicolon separated</span></div>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item
                                            className='px-0 py-1 dropdown-item my-auto d-flex'
                                            key={2}
                                            onClick={() => { setCsvSeparator('\t') }}>
                                            <div className='d-flex justify-content-between flex-fill'><span className='ms-2 my-auto'>Semicolon separated</span></div>
                                        </Dropdown.Item> */}

                                    </div>
                                </ReactBootstrap.DropdownButton>

                            </InputGroup>
                        </>
                    )}
                </ReactBootstrap.Modal.Footer>
            </ReactBootstrap.Modal>
        </div>
    )
}
