import React, { ChangeEventHandler } from 'react';
import FormField from '../Common/CommonFormField';

type ProduceFormProps = {
  title: string;
  value: string | number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  autofocus?: boolean;
};

const ProduceFormField: React.FC<ProduceFormProps> = ({ title, value, onChange = () => {}, disabled = false, autofocus = false }) => {
  return (
    <FormField
      id='category'
      inputClass={disabled ? 'text-black-50' : ''}
      label={title}
      placeholder={title}
      type='text'
      value={value}
      onChange={onChange}
      invalidFeedback=''
      feedbackClass='d-none'
      disabled={disabled}
      containerClass='mb-3'
      endIcon={disabled ? 'bi bi-lock-fill' : undefined}
      autofocus={autofocus}
    />
  );
};

export default ProduceFormField;
