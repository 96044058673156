import callApiAxiosClient from "../../../axios/axios_client";
import { addDetectionResponse } from "../../../models";

async function addDetection(detectionType?: number, cameraId?: number, serverId?: number): Promise<addDetectionResponse> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<addDetectionResponse>({
        method: "post",
        url: "settings/add-detection-settings",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            detection_type: detectionType,
            camera_id: cameraId,
            server_id: serverId
        }
    })
}
export default addDetection;