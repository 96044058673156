import axios, { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

async function callApiAxiosClient<T>(apiCall: AxiosRequestConfig, showErrorToast?: boolean): Promise<T> {
  try {
    apiCall.url = `api/v1/${apiCall.url}`;
    // apiCall.baseURL=document.location.origin
    apiCall.baseURL = process.env.REACT_APP_API_BUILD_URL;
    var response = (await axios(apiCall)).data;
    return Promise.resolve(response);
  } catch (error: any) {
    if (!showErrorToast) {
      throw error;
    }
    throw handleApiErrors(error);
  }
}

function handleApiErrors(error: any) {
  if (error.response) {
    let concatError: any = 'Error received from API: ' + error.response.data.error.message;
    // let errorObj = error.response.data.error.message;
    // for (let keys in errorObj) {
    //     for (let i = 0; i < errorObj[keys].length; i++) {
    //         concatErrors += errorObj[keys][i] + "\n";
    //     }
    // }
    toast.error(error.response.data.error.message, {
      position: 'top-right',
      theme: 'colored',
    });
    throw concatError;
  } else if (error.request) {
    toast.error(error.request, {
      position: 'top-right',
      theme: 'colored',
    });
    throw error.request;
  } else {
    toast.error(error.request, {
      position: 'top-right',
      theme: 'colored',
    });
    // Something happened in setting up the request that triggered an Error
    throw error.request;
  }
}

export default callApiAxiosClient;
