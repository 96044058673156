import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { compareUserState } from './compareUser1Reducer';

const initialState: compareUserState = {
  id: localStorage.getItem('compareUserId2')!?.toString() != null ? localStorage.getItem('compareUserId2')!?.toString() : '0',
  value: localStorage.getItem('compareUserName2')!?.toString() != null ? localStorage.getItem('compareUserName2')!?.toString() : 'All'
};

export const compareUser2 = createSlice({
  name: 'compareUser2',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<compareUserState>) => {
      state.id = action.payload.id
      state.value = action.payload.value
      localStorage.setItem("compareUserId2", action.payload.id)
      localStorage.setItem("compareUserName2", action.payload.value)

    },
  },
});

export const { setValue } = compareUser2.actions;

export const compareUser2Reducer = compareUser2.reducer;
