import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { locationsInList } from '../../web_api/models';
import { CommonModal } from '../Common/CommonModal';
import FormDropdownField from '../Common/CommonFormDropdownField';
import FormField from '../Common/CommonFormField';
import { Accordion } from 'react-bootstrap';
import { LeafMap } from '../Map/LeafMap';
import { useEffect } from 'react';

type CameraAddFormModalProps = {
  isAdding: boolean;
  isFormValid: {
    locationId: string;
    modelId: string;
    name: string;
    ip: string;
    port: string;
    user: string;
    password: string;
    long?: number | undefined;
    lat?: number | undefined;
    width?: number | undefined;
    height?: number | undefined;
  };
  camera: {
    locationId: number;
    modelId: number;
    name: string;
    ip: string;
    port: string;
    user: string;
    password: string;
    long?: number | undefined;
    lat?: number | undefined;
    width?: number | undefined;
    height?: number | undefined;
  };
  onClick: () => void;
  handleToggleModal: () => void;
  setCamera: React.Dispatch<
    React.SetStateAction<{
      locationId: number;
      modelId: number;
      name: string;
      ip: string;
      port: string;
      user: string;
      password: string;
      long?: number | undefined;
      lat?: number | undefined;
      width?: number | undefined;
      height?: number | undefined;
    }>
  >;
  show: boolean;
  title: string;
  confirmIcon: string;
  confirmClass: string;
};

const CameraAddFormModal: React.FC<CameraAddFormModalProps> = ({
  title,
  isFormValid,
  show,
  camera,
  confirmIcon,
  confirmClass,
  onClick,
  handleToggleModal,
  setCamera,
  isAdding,
}) => {
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations);
  const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);

  const handleMarkerAddedOrChanged = (name: string, lat: number, long: number) => {
    console.log('handleMarkerAddedOrChanged', name, lat, long);
    setCamera({ ...camera, long: long, lat: lat });
  };

  return (
    <CommonModal
      title={title}
      size='lg'
      disabled={isAdding}
      show={show}
      showModalCallback={handleToggleModal}
      closeButton={true}
      confirmButton={true}
      bodyClass='flex-column w-100 ps-sm-15 pe-sm-15 pb-sm-10'
      closeButtonOptions={{
        icon: 'bi bi-x',
        variant: 'secondary',
        class: '',
      }}
      confirmButtonOptions={{
        icon: `${confirmIcon} text-light `,
        variant: 'custom',
        class: `${confirmClass} d-flex flex-row align-items-center gap-1`,
        onClick: onClick,
      }}
    >
      <FormDropdownField
        label='Camera model'
        inputClass={isFormValid.modelId}
        value={camera.modelId}
        onChange={(e) => setCamera({ ...camera, modelId: parseInt(e.target.value) })}
        containerClass='my-0'
      >
        <option value='' hidden>
          Select a camera model
        </option>
        {cachedCameraModelList &&
          cachedCameraModelList.map((model) => (
            <option key={model.id} value={model.id}>
              {`${model.id} - ${model.name}`}
            </option>
          ))}
      </FormDropdownField>
      <FormDropdownField
        label='Location'
        inputClass={isFormValid.locationId}
        value={camera.locationId}
        onChange={(e) => setCamera({ ...camera, locationId: parseInt(e.target.value) })}
        containerClass='my-0'
      >
        <option value='' hidden>
          Select a location
        </option>
        {cachedLocationList &&
          cachedLocationList.list.map((location: locationsInList) => (
            <option key={location.id} value={location.id}>
              {`${location.id} - ${location.name}`}
            </option>
          ))}
      </FormDropdownField>
      <FormField
        inputClass={isFormValid.name}
        id='name'
        label='Name'
        placeholder='Camera name'
        type='text'
        value={camera.name}
        onChange={(e) => setCamera({ ...camera, name: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <FormField
        inputClass={isFormValid.ip}
        id='ip'
        label='IP'
        placeholder='IP address'
        type='text'
        value={camera.ip}
        onChange={(e) => setCamera({ ...camera, ip: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <FormField
        inputClass={isFormValid.port}
        id='port'
        label='Port'
        placeholder='Port number'
        type='text'
        value={camera.port}
        onChange={(e) => setCamera({ ...camera, port: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <FormField
        inputClass={isFormValid.user}
        id='user'
        label='User'
        placeholder='User name'
        type='text'
        value={camera.user}
        onChange={(e) => setCamera({ ...camera, user: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
      />
      <FormField
        inputClass={isFormValid.password}
        id='password'
        label='Password'
        placeholder='Password'
        type='password'
        value={camera.password}
        onChange={(e) => setCamera({ ...camera, password: e.target.value })}
        invalidFeedback=''
        feedbackClass='d-none'
        containerClass='pb-3'
      />
      <div className='fw-bold border-top border-muted border-2 text-muted fs-6 pb-3 pt-1'>Precise camera location (optional)</div>
      <div className='d-flex flex-row gap-3 pb-3'>
        <FormField
          inputClass={isFormValid.lat?.toString()}
          id='latitude'
          label='Latitude'
          placeholder='Latitude'
          type='number'
          value={camera.lat?.toString() || ''}
          onChange={(e) => setCamera({ ...camera, lat: parseFloat(e.target.value) })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='w-50'
        />
        <FormField
          inputClass={isFormValid.long?.toString()}
          id='longitude'
          label='Longitude'
          placeholder='Longitude'
          type='number'
          value={camera.long?.toString() || ''}
          onChange={(e) => setCamera({ ...camera, long: parseFloat(e.target.value) })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='w-50'
        />
      </div>
      <div className='d-flex flex-row gap-3 pb-3'>
        <FormField
          inputClass={isFormValid.width?.toString()}
          id='Width'
          label='Width'
          placeholder='Width'
          type='number'
          value={camera.width?.toString() || ''}
          onChange={(e) => setCamera({ ...camera, width: parseInt(e.target.value) })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='w-50'
        />
        <FormField
          inputClass={isFormValid.height?.toString()}
          id='Height'
          label='Height'
          placeholder='Height'
          type='number'
          value={camera.height?.toString() || ''}
          onChange={(e) => setCamera({ ...camera, height: parseInt(e.target.value) })}
          invalidFeedback=''
          feedbackClass='d-none'
          containerClass='w-50'
        />
      </div>
      <LeafMap
        className='h-300px bg-secondary'
        requireName={false}
        allowAdd={true}
        allowDelete={true}
        allowMultipleMarkers={false}
        markers={camera.lat && camera.long ? [{ id: 1, position: { lat: camera.lat, long: camera.long }, name: 'Camera location' }] : []}
        onMarkerAddedOrChanged={handleMarkerAddedOrChanged}
      />
    </CommonModal>
  );
};

export default CameraAddFormModal;
