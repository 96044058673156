import React, { } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { DataWidget3 } from '../../_metronic/widgets/data_widgets/DataWidget3';

type Props = {
    className: string
}

const AvgTimesOrderedListQueueManagement: React.FC<Props> = ({ className }) => {
    const avgTimeCashRegisterAllLocations = useSelector((state: RootState) => state.avgTimeCashRegisterAllLocations.value);
    const queueDurationAllLocations = useSelector((state: RootState) => state.queueDurationAllLocations.value);
    return (
        <DataWidget3
            className={className}
            title={'Average times'}
            averageTime={avgTimeCashRegisterAllLocations}
            queueDuration={queueDurationAllLocations}
            loading={true}
        />

    )
}
export { AvgTimesOrderedListQueueManagement }