import { useState } from 'react';

type InitialCallback = () => void;

type InitialValidation = () => void;

type Data<T> = {
    [key: string]: T;
};

const usePost = <T,>(
    dataProps: Data<T> = {},
    axiosCall: any,
    initialCallback?: InitialCallback,
    initialValidation?: InitialValidation,
) => {

    const [data, setData] = useState<any>(dataProps);
    const [errors, setErrors] = useState<string>();
    const [isAdding, setIsAdding] = useState<boolean>();
    const formData: { [key: string]: string } = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, '']));
    const [isFormValid, setIsFormValid] = useState(formData);

    const handleAddData = async () => {
        setErrors('');
        let isValid = true;
        setIsAdding(true);
        const updatedFormValidity = { ...isFormValid };

        for (const key in data) {
            if (!data[key]) {
                updatedFormValidity[key] = 'is-invalid';
                isValid = false;
            } else {
                updatedFormValidity[key] = 'is-valid';
            }
        }

        if (initialValidation) {
            initialValidation();
        };


        if (!isValid) {
            setIsFormValid(updatedFormValidity);
            return;
        };

        try {

            let addedData = await axiosCall();

            if (initialCallback) {
                initialCallback()
            };

        } catch (e) {
            setIsFormValid(formData);
            setErrors(e?.toString());
            console.log(e);
        } finally {
            setIsAdding(false);
        };
    };

    return {
        data,
        setData,
        errors,
        isFormValid,
        handleAddData,
        setIsFormValid,
        isAdding
    };
};

export default usePost;
