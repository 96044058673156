import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { useLayout } from './core';
import { PageTitle } from './PageTitle';
import { Link } from 'react-router-dom';

const PageTitleWrapper = () => {
  const { config } = useLayout();
  const toolbar = useSelector((state: RootState) => state.toolbar);

  if (!config.app?.pageTitle?.display) {
    return null;
  }

  return (
    <>
      {toolbar.displayBack ? (
        <div className='d-flex flex-row align-items-center gap-5'>
          <Link to='..' relative='path'>
            <i className='bi bi-arrow-left fs-3' style={{ cursor: 'pointer' }}></i>
          </Link>

          <PageTitle text={toolbar.title} />
        </div>
      ) : (
        <>
          <PageTitle text={toolbar.title} />
        </>
      )}
    </>
  );
};

export { PageTitleWrapper };
