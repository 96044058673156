import callApiAxiosClient from '../../axios/axios_client';
import { trainingModelList } from '../../models';

function getTrainingModels(): Promise<trainingModelList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<trainingModelList>({
    method: 'get',
    url: 'training',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getTrainingModels;
