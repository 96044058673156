import callApiAxiosClient from '../../axios/axios_client';

function addTrainingPicture(picture: Blob, modelID: number): Promise<string> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<string>({
    method: 'post',
    url: 'training/images',
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: authorization,
    },
    data: {
      image: picture,
      model_id: modelID,
    },
  });
}
export default addTrainingPicture;
