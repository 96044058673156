import { useEffect } from 'react';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import LocationsBakery from './LocationsBakery';

export default function BakeryAnalytics() {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'bakery',
            `Bakery - Analytics`
        )
    })
    return (
        <div className="container">
            <div className="d-flex flex-column flex-md-row">
                <div className="d-flex flex-row flex-md-column col-12">
                    <div className="p-3 p-md-1 p-lg-0 p-xl-5 pb-0">
                        <LocationsBakery />
                    </div>
                    {/* <div className="p-5">
                        <ChartsWidget3 className='' title={`Bakery - ${activeLocation.value} - ${bakeryDateFilter.date?.toLocaleString('en-US', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'short'
                        }).replace(/\//g, '-')}, ${bakeryHourFilter.hour}:00`} take={null} shelfStatus={shelfStatus!} />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
