import callApiAxiosClient from '../../axios/axios_client';

async function addTrainingModel(name: string, description: string, detection_server_id: number, runs: number, date?: string): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'training',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      name: name,
      description: description,
      detection_server_id: detection_server_id,
      date: date !== '' ? date : null,
      runs: runs,
    },
  });
}

export default addTrainingModel;
