type CommonTableRowProps = {
  inputId: string;
  children?: React.ReactNode;
  className?: string;
  onCheckboxChange?: () => void;
  checked?: boolean;
  cellOne?: string | null;
  cellOneTag?: string | number;
  cellTwo?: string | number;
  cellThree?: string | number;
  cellFour?: string | number;
  cellFive?: string | number;
};

const CommonTableRow: React.FC<CommonTableRowProps> = ({
  inputId,
  children,
  className,
  checked,
  onCheckboxChange,
  cellOne,
  cellOneTag,
  cellTwo,
  cellThree,
  cellFour,
  cellFive,
}) => {
  return (
    <tr className={className}>
      <td>
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
          <input id={inputId} checked={checked} onChange={onCheckboxChange} className='form-check-input widget-9-check' type='checkbox' value='1' />
        </div>
      </td>
      <td>
        <div className='d-flex align-items-center'>
          <div className='d-flex justify-content-start flex-column'>
            <div className='text-dark fw-bold text-hover-primary fs-6'>{cellOne}</div>
            <span className='text-muted fw-semibold text-muted d-block fs-7'>{cellOneTag}</span>
          </div>
        </div>
      </td>

      {cellTwo ? (
        <td>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>{cellTwo}</span>
        </td>
      ) : null}

      {cellThree ? (
        <td>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>{cellThree}</span>
        </td>
      ) : null}

      {cellFour ? (
        <td>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>{cellFour}</span>
        </td>
      ) : null}

      {cellFive ? (
        <td>
          <span className='text-muted fw-semibold text-muted d-block fs-7'>{cellFive}</span>
        </td>
      ) : null}
      {children}
    </tr>
  );
};

export default CommonTableRow;
