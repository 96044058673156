import CommonSettingsField from '../../Common/CommonSettingsField';
import usePut from '../../../hooks/Common/usePut';
import updateDetection, { UpdateDetectionArgs } from '../../../web_api/methods/settings/detections/updateDetection';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { cameraDetectionInList, detectionServerInList } from '../../../web_api/models';
import { useEffect } from 'react';
import * as ReactBootstrap from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setCachedDetectionsSettingsList } from '../../../redux/detectionsSettingsReducer';

type DetectionInfoSettingsProps = {
  detection: cameraDetectionInList;
};

const DetectionInfoSettings: React.FC<DetectionInfoSettingsProps> = ({ detection }) => {
  const cachedCameraServerList = useSelector((state: RootState) => state.detectionServerList);
  const cachedDetectionsSettingsList = useSelector((state: RootState) => state.detectionsSettingsList.list);
  const params = useParams();

  const id = Number(params.id);

  const { data, setData, handleUpdateData, isFormValid, errors, isUpdating } = usePut(
    {
      id: id,
      minNrObjects: detection?.min_nr_objects,
      maxNrObjects: detection?.max_nr_objects,
      nrOccurrences: detection?.nr_occurrences,
      processingWindow: detection?.processing_window,
      threshold: detection?.threshold,
      fps: detection?.fps,
      // region_name: detection?.region_name,
      // detection_server_id: detection?.detection_server_id
    },
    () => {
      updateDetection({
        id: id,
        min_nr_objects: data.minNrObjects,
        max_nr_objects: data.maxNrObjects,
        nr_occurrences: data.nrOccurrences,
        processing_window: data.processingWindow,
        threshold: data.threshold,
        fps: data.fps,
        // region_name: data.region_name ?? 1,
        // detection_server_id: data.detection_server_id ?? 1
      } as UpdateDetectionArgs);
    },
    setCachedDetectionsSettingsList,
    cachedDetectionsSettingsList,
    'id',
    detection.id,
    () => {
      toast.success(`Detection updated successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    },
    undefined,
    true
  );

  useEffect(() => {
    if (errors) {
      toast.error(`${errors}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  }, [errors]);

  return (
    <div id='kt_account_profile_details' className='collapse show'>
      <form noValidate className='form'>
        <div className='card-body border-top p-sm-9 p-6'>
          <CommonSettingsField
            id='min-objects'
            value={data.minNrObjects}
            onChange={(e) => setData({ ...data, minNrObjects: parseInt(e.target.value) })}
            inputClass={isFormValid.minNrObjects}
            label='Min. number of objects'
            placeholder='Min. number of objects'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <CommonSettingsField
            id='max-objects'
            value={data.maxNrObjects}
            inputClass={isFormValid.maxNrObjects}
            onChange={(e) => setData({ ...data, maxNrObjects: parseInt(e.target.value) })}
            label='Max. number of objects'
            placeholder='Max. number of objects'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <CommonSettingsField
            id='min-occurence'
            value={data.nrOccurrences}
            inputClass={isFormValid.nrOccurrences}
            onChange={(e) => setData({ ...data, nrOccurrences: parseInt(e.target.value) })}
            label='Min. number of occurences'
            placeholder='Min. number of occurences'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <CommonSettingsField
            id='fps'
            value={data.fps}
            onChange={(e) => setData({ ...data, fps: parseFloat(e.target.value) })}
            inputClass={isFormValid.fps}
            label='FPS'
            placeholder='FPS'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <CommonSettingsField
            id='process-window'
            value={data.processingWindow}
            inputClass={isFormValid.processingWindow}
            onChange={(e) => setData({ ...data, processingWindow: parseInt(e.target.value) })}
            label='Processing window'
            placeholder='Processing window'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          <CommonSettingsField
            id='threshold'
            value={data.threshold}
            inputClass={isFormValid.threshold}
            onChange={(e) => setData({ ...data, threshold: parseFloat(e.target.value) })}
            label='Threshold'
            placeholder='Threshold'
            type='number'
            invalidFeedback=''
            feedbackClass='d-none'
          />
          {/* <CommonSettingsField
                        id="region"
                        value={data.region_name}
                        inputClass={isFormValid.region_name}
                        onChange={(e) => setData({ ...data, region_name: parseInt(e.target.value) })}
                        label="Region name"
                        placeholder="Region name"
                        type="number"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    />
                    <CommonSettingsField
                        id="server-id"
                        value={data.detection_server_id}
                        onChange={(e) => { setData({ ...data, detection_server_id: parseInt(e.target.value) }) }}
                        label="Detection server id"
                        placeholder="Detection server id"
                        type="text"
                        invalidFeedback=""
                        feedbackClass="d-none"
                    >
                        {cachedCameraServerList &&
                            cachedCameraServerList.list.map((server: detectionServerInList) => (
                                <option key={server.id} value={server.id}>
                                    {`${server.id} - ${server.name}`}
                                </option>
                            ))}
                    </CommonSettingsField> */}
          <div className='d-flex flex-end'>
            <button disabled={isUpdating} className='btn btn-primary' type='button' onClick={handleUpdateData}>
              Save Changes
              {isUpdating && <ReactBootstrap.Spinner animation='border' size='sm' className='ms-2' />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DetectionInfoSettings;
