/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../assets/ts/_utils';
import { DayWeekMonthYearEnum, evolutionValueList } from '../../../web_api/models';
import peopleCountingEvolutionValue from '../../../web_api/methods/peopleCountingDash/evolutionValue';
import { format } from 'date-fns';
import { Modal, Button } from 'react-bootstrap';
import { DateRange, DayPicker } from 'react-day-picker';
import { bakeryNumberToStatus, bakeryProductNameFormat, numberFormat } from '../../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { toolbarDateFilter } from '../../../redux/toolbarDateFilterReducer';
import placeholderImage from '../../../assets/images/placeholder-denmark.jpg';

type Props = {
  className: string;
  chartHeight: string;
  chartColor: string;
  type: ApexChart['type'];
  product: string;
  xAxis: string[];
  images: string[];
  yAxis: number[];
};

const ChartsWidget17: React.FC<Props> = ({ className, chartHeight, chartColor, type, product, xAxis, images, yAxis }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    var chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor, xAxis, images, yAxis, type, product, tenantKey, new Date(toolbarDateFilter.date))
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, xAxis, yAxis, product]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>
            {product !== '' ? (
              <>
                {bakeryProductNameFormat(product)}
                <span className='fw-normal'>
                  {` on ${new Date(toolbarDateFilter.date)
                    .toLocaleString('en-US', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'short',
                    })
                    .replace(/\//g, '-')}`}
                </span>
              </>
            ) : (
              <span className='fw-normal'>Choose a product</span>
            )}
          </span>
        </h3>
      </div>
      <div className='card-body p-5 d-flex flex-column'>
        <div
          ref={chartRef}
          className='mixed-widget-3-chart card-rounded-bottom'
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  );
};

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  xAxis: string[],
  images: string[],
  yAxis: number[],
  type: ApexChart['type'],
  title: string,
  tenant_key: string,
  date: Date
): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800');
  const strokeColor = getCSSVariableValue('--kt-gray-300');
  const baseColor = getCSSVariableValue('--kt-' + chartColor);
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light');
  const month = date.getMonth() + 1;
  const url = `/alerts-media/${tenant_key}/${date.getFullYear()}/${month}`;
  return {
    series: [
      {
        name: 'Status',
        data: yAxis,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          csv: {
            filename: title,
          },
        },
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [baseColor, lightColor],
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.5,
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: -1,
      max: 4,
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return bakeryNumberToStatus(val);
        },
      },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        return (
          '<div class="arrow_box product-tooltip">' +
          // '<img src="' + url + '/' + images[dataPointIndex] + '" width="533" height="300">' +
          '<img class="img-fluid w-300px w-md-550px" src="' +
          images[dataPointIndex] +
          '" >' +
          // '<img class="img-fluid w-300px w-md-550px" src="https://wallpapercave.com/wp/wp1894121.jpg" alt="Image">' +
          '</div>' +
          '<span>' +
          bakeryNumberToStatus(series[seriesIndex][dataPointIndex]) +
          '</span>'
        );
      },
      fixed: {
        enabled: window.innerWidth < 1399,
        position: 'topLeft',
        offsetY: -50,
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  };
};
export { ChartsWidget17 };
