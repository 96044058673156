import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import LocationsTable from "./LocationsTable";

import getLocations from "../../web_api/methods/settings/locations/getLocations";
import useGet from "../../hooks/Common/useGet";
import LoadingPage from "../../layout/LoadingPage";
import { useEffect } from "react";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";

const Locations = () => {

    const { loading } = useGet([], getLocations, setCachedLocationList);
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'locations',
            'Location List'
        )
    })
    return (
        <>
            {loading ?
                <LoadingPage />
                :
                <LocationsTable />
            }
        </>
    );
};

export default Locations;