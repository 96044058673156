import callApiAxiosClient from '../../axios/axios_client';
import moment from 'moment';
import { audioAlertsList } from '../../models';

async function queueManagementAudioAlerts(
  locationId: number,
  fromParam: Date,
  toParam: Date,
  fromHour: number,
  toHour: number,
  userId: string
): Promise<audioAlertsList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const from = moment(fromParam).format('YYYYMMDD');
  const to = moment(toParam).format('YYYYMMDD');
  const authorization = 'Bearer ' + token.token;

  return callApiAxiosClient<audioAlertsList>({
    method: 'get',
    url: 'programmability/audio-alerts',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      locationId: locationId,
      from: from,
      to: to,
      fromHour: fromHour,
      toHour: toHour,
      userId: userId,
    },
  });
}
export default queueManagementAudioAlerts;
