import React, { useEffect } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import { useParams } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { LeafMap } from '../Map/LeafMap';
import { Button, Card, Dropdown } from 'react-bootstrap';

const response = [
  {
    id: 4324,
    camera_name: 'Camera 1',
    detections: 3248,
    long: 26.1552,
    lat: 44.4149,
    detections_per_hour: [
      {
        time: '17:00',
        value: 172,
      },
      {
        time: '18:00',
        value: 122,
      },
      {
        time: '19:00',
        value: 232,
      },
      {
        time: '20:00',
        value: 372,
      },
    ],
    detections_by_type: [
      {
        time: 'Car',
        value: 172,
      },
      {
        time: 'Van',
        value: 122,
      },
      {
        time: 'Bicycle',
        value: 232,
      },
      {
        time: 'Carriage',
        value: 12,
      },
    ],
    avg_speed_per_hour: [
      {
        time: '17:00',
        value: 37.5,
      },
      {
        time: '18:00',
        value: 40,
      },
      {
        time: '19:00',
        value: 31,
      },
      {
        time: '20:00',
        value: 37,
      },
    ],
  },
  {
    id: 3434,
    camera_name: 'Camera 2',
    detections: 1111,
    long: 26.1536,
    lat: 44.4149,
    detections_per_hour: [
      {
        time: '17:00',
        value: 111,
      },
      {
        time: '18:00',
        value: 222,
      },
      {
        time: '19:00',
        value: 333,
      },
      {
        time: '20:00',
        value: 232,
      },
    ],
    detections_by_type: [
      {
        time: 'Car',
        value: 23,
      },
      {
        time: 'Van',
        value: 43,
      },
      {
        time: 'Bicycle',
        value: 12,
      },
      {
        time: 'Carriage',
        value: 55,
      },
    ],
    avg_speed_per_hour: [
      {
        time: '17:00',
        value: 57.5,
      },
      {
        time: '18:00',
        value: 55,
      },
      {
        time: '19:00',
        value: 44,
      },
      {
        time: '20:00',
        value: 65,
      },
    ],
  },
  {
    id: 3453,
    camera_name: 'Camera 3',
    detections: 233,
    long: 26.1537,
    lat: 44.4145,
    detections_per_hour: [
      {
        time: '17:00',
        value: 34,
      },
      {
        time: '18:00',
        value: 54,
      },
      {
        time: '19:00',
        value: 76,
      },
      {
        time: '20:00',
        value: 90,
      },
    ],
    detections_by_type: [
      {
        time: 'Car',
        value: 44,
      },
      {
        time: 'Van',
        value: 6,
      },
      {
        time: 'Bicycle',
        value: 44,
      },
      {
        time: 'Carriage',
        value: 33,
      },
    ],
    avg_speed_per_hour: [
      {
        time: '17:00',
        value: 22.5,
      },
      {
        time: '18:00',
        value: 12,
      },
      {
        time: '19:00',
        value: 32,
      },
      {
        time: '20:00',
        value: 56,
      },
    ],
  },
];

const TrafficLocationDetails = () => {
  const { setToolbar, setBackButton } = useToolbarSetter();
  useEffect(() => {
    setToolbar('traffic-search', `Location Details - ${selectedLocation}`);
    setBackButton(true);

    return () => {
      setBackButton(false);
    };
  });

  const params = useParams();
  const selectedLocation = String(params.id);

  const allTypes = response.flatMap((item) => item.detections_by_type);

  const summedTypes = allTypes.reduce((acc: any, { time, value }) => {
    acc[time] = (acc[time] || 0) + value;
    return acc;
  }, {});

  const uniqueTypesWithSum: { type: string; sum: any }[] = Object.entries(summedTypes).map(([type, sum]) => ({ type, sum }));

  const pieChart: { options: ApexOptions; series: any[] } = {
    options: {
      chart: {
        type: 'pie',
        width: 500,
      },
      legend: {
        position: 'bottom',
      },
      title: {
        text: 'Vehicle types %',
        align: 'center',
      },
      dataLabels: {
        enabled: true,
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          return `${(value as number).toFixed(2)}%`; // (${uniqueTypesWithSum.map((x) => x.sum)[seriesIndex]})
        },
        dropShadow: {
          enabled: true,
        },
      },
      labels: uniqueTypesWithSum.map((x) => x.type),
    },
    series: uniqueTypesWithSum.map((x) => x.sum),
  };

  const calculateCenter: () => [number, number] = () => {
    const lat = response.reduce((acc, item) => acc + item.lat, 0) / response.length;
    const long = response.reduce((acc, item) => acc + item.long, 0) / response.length;
    return [lat, long];
  };

  const passedVehiclesLineChart: { options: ApexOptions; series: any[] } = {
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Vehicle no. by time of day',
        align: 'left',
        offsetY: 7,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: response[0].detections_per_hour.map((x) => x.time),
      },
    },
    series: response.map((item, index) => ({
      name: `${item.camera_name} - ${item.detections} detections`,
      data: item.detections_per_hour.map((x) => x.value),
    })),
  };

  const avgSpeedLineChart: { options: ApexOptions; series: any[] } = {
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: 'Avg speed by time of day',
        align: 'left',
        offsetY: 7,
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: response[0].avg_speed_per_hour.map((x) => x.time),
      },
    },
    series: response.map((item, index) => ({
      name: `${item.camera_name} - ${item.detections} detections`,
      data: item.avg_speed_per_hour.map((x) => x.value),
    })),
  };

  return (
    <div className='p-4 h-100 w-100 pb-0'>
      <div className='d-flex flex-column gap-3'>
        <div className='d-flex flex-row gap-3 pe-2 h-100'>
          <div className='col-md-4 h=100'>
            <Card className='shadow-sm h-100 d-flex align-items-center justify-content-center ps-4'>
              <Chart options={pieChart.options} series={pieChart.series} type='pie' width={500} height={400} />
            </Card>
          </div>
          <div className='col-md-8'>
            <Card className='shadow-sm h-100'>
              <LeafMap
                className={'h-400px w-100% bg-secondary'}
                allowMultipleMarkers={true}
                allowDelete={false}
                markers={response.map((x) => ({ id: x.id, position: { lat: x.lat, long: x.long }, name: x.camera_name }))}
                allowAdd={false}
                draggableMarkers={false}
                center={calculateCenter()}
                zoom={18}
              />
            </Card>
          </div>
        </div>
        <div className='d-flex flex-row gap-3 pe-2 pb-0 w=100 justify-content-between'>
          <Card className='shadow-sm h-100 col-md-6  d-flex align-items-center justify-content-center ps-4'>
            <Chart options={passedVehiclesLineChart.options} series={passedVehiclesLineChart.series} type='line' width={600} height={285} />
          </Card>
          <Card className='shadow-sm h-100 col-md-6 d-flex align-items-center justify-content-center ps-4'>
            <Chart options={avgSpeedLineChart.options} series={avgSpeedLineChart.series} type='line' width={600} height={285} />
          </Card>
        </div>
      </div>
    </div>
  );
};
export default TrafficLocationDetails;
