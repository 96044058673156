import callApiAxiosClient from '../../axios/axios_client';

async function getRoleId(): Promise<number> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<number>({
    method: 'get',
    url: 'account/role',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getRoleId;
