import callApiAxiosClient from "../../../axios/axios_client";

async function updateTrackingStatus(id: number, status: boolean): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "put",
        url: "settings/tracking",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        data: {
            id: id,
            status: status
        }
    })
}
export default updateTrackingStatus;