import { useState } from "react";

type useDeleteParams<T> = {
    axiosCall: () => Promise<T>,
    initialCallback?: () => void
};


const useDelete = <T,>(
    axiosCall: useDeleteParams<T>["axiosCall"],
    initialCallback?: useDeleteParams<T>["initialCallback"],
) => {

    const [errors, setErrors] = useState<string>();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const deleteItems = async () => {
        setIsDeleting(true);
        setErrors('');

        try {

            let deletedItems = await axiosCall();

            if (initialCallback) {
                initialCallback();
            };

        } catch (e) {
            setErrors(e?.toString());
            console.log(e);
        } finally {
            setIsDeleting(false);
        }
    };

    return {
        deleteItems,
        errors,
        isDeleting
    };
};

export default useDelete;
