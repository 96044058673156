import { useState, useEffect } from 'react';
import { locationsList, userItemList } from '../web_api/models';
import getLocations from '../web_api/methods/settings/locations/getLocations';
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../redux/activeLocationReducer';
import { RootState } from '../redux/store';
import { DayPicker } from 'react-day-picker';
import * as ReactBootstrap from 'react-bootstrap';
import { setValue as setBakeryDateFilter } from '../redux/toolbarDateFilterReducer';
import { setValue as setBakeryHourFilter } from '../redux/toolbarHourFilterReducer';
import { setValue as setBakerySearchFilter } from '../redux/toolbarSearchFilterReducer';
import { setValue as setSelectedUser } from '../redux/selectedUserReducer';
import { setValue as setUserList } from '../redux/userListReducer';
import getUsers from '../web_api/methods/account/getUsers';

const ToolbarBakery = () => {
  const [locations, setLocations] = useState<locationsList>([]);
  const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
  const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter.date);
  const toolbarSearchFilter = useSelector((state: RootState) => state.toolbarSearchFilter);

  const [fromHour, setFromHour] = useState<number>(parseInt(toolbarHourFilter.hour));
  const [toHour, setToHour] = useState<number>(parseInt(toolbarHourFilter.toHour));
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const userList = useSelector((state: RootState) => state.userList);
  const dateFilter = useSelector((state: RootState) => state.dateFilter);
  const toolbar = useSelector((state: RootState) => state.toolbar);

  const [selectedDay, setSelectedDay] = useState<Date | undefined>(new Date(toolbarDateFilter));
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showHour, setShowHour] = useState(false);
  //   const [searchText, setSearchText] = useState('');

  const handleSearchTextChange = (text: string) => {
    console.log('text is: ', text);
    dispatch(setBakerySearchFilter({ searchText: text }));
  };

  useEffect(() => {
    setFromHour(parseInt(toolbarHourFilter.hour));
    setToHour(parseInt(toolbarHourFilter.toHour));
  }, [toolbarHourFilter]);

  const handleCloseModalHour = () => {
    setShowHour(false);
    dispatch(
      setBakeryHourFilter({
        hour: fromHour.toString(),
        toHour: toHour.toString(),
      })
    );
  };
  const handleCloseModal = () => {
    setShow(false);
    dispatch(
      setBakeryDateFilter({
        date: selectedDay!.toString(),
      })
    );
  };

  const handleFromHourChange = (event: any) => {
    const number = parseInt(event.target.value);
    if (number < 7) setFromHour(7);
    else if (number >= 0 && number <= 24) {
      setFromHour(number);
    }
  };

  const handleToHourChange = (event: any) => {
    const number = parseInt(event.target.value);
    if (number > 21) setToHour(21);
    else if (number >= 0 && number <= 24) {
      setToHour(number);
    }
  };

  const handleShowModal = () => setShow(true);
  const handleShowModalHour = () => setShowHour(true);

  const footer = selectedDay ? (
    <p className='m-auto'>
      {selectedDay
        .toLocaleString('en-US', {
          weekday: 'long',
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
        .replace(/\//g, '-')}
      .
    </p>
  ) : (
    <p className='m-auto'>Pick a day.</p>
  );
  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
    loadLocations();
    if (userList.list.length === 0) {
      loadUsers();
    }
  }, []);

  const loadUsers = async () => {
    try {
      let loadedUsers: userItemList = await getUsers();
      dispatch(
        setUserList({
          list: loadedUsers,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const loadLocations = async () => {
    try {
      let loadedLocations: locationsList = await getLocations();
      setLocations(loadedLocations);
      if (loadedLocations.length == 1)
        dispatch(
          setValue({
            value: loadedLocations[0].name,
            id: loadedLocations[0].id.toString(),
          })
        );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className='d-flex flex-column flex-md-row gap-3 gap-md-0 align-items-center mt-6 mt-md-0 w-100 w-md-auto'>
      {/* begin::Wrapper */}
      {toolbar.toolbar !== 'date-and-hour' && (
        <>
          <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Search:</span>
          <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
            <ReactBootstrap.InputGroup>
              <ReactBootstrap.FormControl
                size='sm'
                placeholder='All'
                aria-label='Search'
                autoFocus={true}
                value={toolbarSearchFilter.searchText}
                onChange={(e) => handleSearchTextChange(e.currentTarget.value)}
              />
            </ReactBootstrap.InputGroup>
          </div>
          <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
            {/* begin::Label */}
            <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>User:</span>
            {/* end::Label */}

            <select
              className='form-select form-select-sm w-md-125px form-select-solid w-75 w-md-auto'
              data-control='select2'
              data-placeholder='Latest'
              data-hide-search='true'
              value={selectedUser.value}
              onChange={(e) => {
                dispatch(
                  setSelectedUser({
                    value: e.target.value,
                    id: e.target.options[e.target.selectedIndex].dataset.id!,
                  })
                );
                dispatch(
                  setValue({
                    value: 'All',
                    id: '0',
                  })
                );
              }}
            >
              <option key={0} value={'All'} data-id={0}>
                All
              </option>
              {userList.list.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className='bullet d-none d-md-block bg-secondary h-35px w-1px mx-5'></div>
        </>
      )}

      {/* 
      <div className='d-flex align-items-center flex-shrink-0 mx-2'>
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>
          Location:
        </span>
        <select
          className='form-select form-select-sm w-md-125px form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={activeLocation.value}
          onChange={
            (e) => {
              if (e.target.value !== 'All')
                dispatch(setValue(
                  {
                    value: e.target.value,
                    id: e.target.options[e.target.selectedIndex].dataset.id!
                  }))
            }
          }
        >
          <option key={0} value={'All'} data-id={0}>All</option>
          {selectedUser.value === 'All' ?
            (
              locations.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>{item.id + ' - ' + item.name}</option>
              ))
            )
            :
            (
              userList.list.find(x => x.id === selectedUser.id)?.locations.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>{item.id + ' - ' + item.name}</option>
              ))
            )
          }

        </select>
      </div>
 */}
      {/* begin::Separartor */}
      {/* end::Separartor */}

      {/* begin::Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Day:</span>
        {/* end::Label */}

        {/* begin::Select */}
        <div
          className='nav-item m-md-auto w-75 w-md-auto'
          onClick={() => {
            handleShowModal();
          }}
        >
          <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
            <i className='bi bi-calendar mb-1 align-self-center' />
            <span className='text-nowrap'>{footer}</span>
          </div>
        </div>
        {/* end::Select */}
        {/* <div className='cursor-pointer symbol symbol-35px symbol-md-40px d-flex flex-row mx-2 fs-7 fw-bold text-gray-700 '>
          <i className="bi bi-person mx-2 fs-3 fw-bold text-gray-700 " />
          <span className='m-auto '>{userName}</span>
          <i className="bi bi-caret-down-fill ms-1 m-auto pt-1" style={{ fontSize: '1rem' }} />

        </div> */}
        {/* begin::Actions */}

        {/* end::Actions */}
      </div>

      {/* end::Separartor */}

      {/* begin::Wrapper */}
      {toolbar.toolbar === 'date-and-hour' && (
        <>
          <div className='bullet bg-secondary h-35px w-1px mx-5 d-none d-md-block'></div>
          <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto'>
            {/* begin::Label */}
            <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Time:</span>
            {/* end::Label */}

            {/* begin::Select */}
            <div
              className='nav-item m-md-auto w-75 w-md-auto'
              onClick={() => {
                handleShowModalHour();
              }}
            >
              <div className={'d-flex flex-row nav-link btn btn-sm btn-color-grey btn-active-color-primary btn-light fw-semibold fs-7 px-2 me-md-1'}>
                <i className='bi bi-clock mb-1 align-self-center' />
                {fromHour}:00 to {toHour}:00
              </div>
            </div>
            {/* end::Select */}
            {/* <div className='cursor-pointer symbol symbol-35px symbol-md-40px d-flex flex-row mx-2 fs-7 fw-bold text-gray-700 '>
     <i className="bi bi-person mx-2 fs-3 fw-bold text-gray-700 " />
     <span className='m-auto '>{userName}</span>
     <i className="bi bi-caret-down-fill ms-1 m-auto pt-1" style={{ fontSize: '1rem' }} />

   </div> */}
            {/* begin::Actions */}

            {/* end::Actions */}
          </div>
        </>
      )}

      {/* end::Wrapper */}
      <ReactBootstrap.Modal size='sm' show={show} onHide={handleCloseModal}>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row'>
              <DayPicker mode='single' selected={selectedDay} onSelect={setSelectedDay} />
            </div>
            {/* <div className='d-flex flex-row px-5'>
              <span className="d-flex fs-3 fw-bold flex-start">Hour</span>
              <div className='d-flex m-auto flex-row'>
                <select defaultValue={fromHour} className="d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5" onChange={handleFromHourChange}>
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>{i}</option>
                  ))}
                </select>
                <span className="d-flex m-auto">:</span>
                <select defaultValue={toHour} className="d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5" onChange={handleToHourChange}>
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>{i}</option>
                  ))}
                </select>
              </div>
            </div> */}
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModal}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
      <ReactBootstrap.Modal size='sm' show={showHour} onHide={handleCloseModalHour}>
        <ReactBootstrap.Modal.Header>
          <h2>Choose a time interval</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleCloseModalHour}>
            <i className='bi bi-x' style={{ fontSize: '2rem' }} />
          </div>
        </ReactBootstrap.Modal.Header>
        <ReactBootstrap.Modal.Body className='d-flex justify-content-center'>
          <div className='d-flex flex-column'>
            <div className='d-flex flex-row px-5'>
              {/* <span className="d-flex fs-3 fw-bold flex-start">From</span> */}
              <div className='d-flex m-auto flex-row'>
                <select
                  defaultValue={fromHour}
                  className='d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5'
                  onChange={handleFromHourChange}
                >
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
                <span className='d-flex m-auto'>to</span>
                <select
                  defaultValue={toHour}
                  className='d-flex flex-column bg-light p-3 rounded fs-3 cursor-pointer mx-5'
                  onChange={handleToHourChange}
                >
                  {[...Array(25)].map((_, i) => (
                    <option key={i} value={i}>
                      {i}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </ReactBootstrap.Modal.Body>
        <ReactBootstrap.Modal.Footer>
          <ReactBootstrap.Button variant='secondary' onClick={handleCloseModalHour}>
            Close
          </ReactBootstrap.Button>
        </ReactBootstrap.Modal.Footer>
      </ReactBootstrap.Modal>
    </div>
  );
};

export { ToolbarBakery };
