import callApiAxiosClient from "../../axios/axios_client";
import { monthlyReportsList } from "../../models";
import moment from "moment";

function peopleCountingMonthlyReoprts(fromParam: Date, toParam: Date): Promise<monthlyReportsList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<monthlyReportsList>({
        method: "get",
        url: "programmability/monthly-reports",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params:{
            from: from,
            to: to
        }
    })
}
export default peopleCountingMonthlyReoprts;