import callApiAxiosClient from "../../../axios/axios_client";
import { locationsList } from "../../../models";

function getLocations(): Promise<locationsList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<locationsList>({
        method: "get",
        url: "settings/locations",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
    })
}
export default getLocations;