import { Link, useParams } from "react-router-dom";
import placeholderImage from '../../../assets/images/placeholder-denmark.jpg'
import CameraInfoSettings from "./CameraInfoSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CameraDetectionAddModal from "../CameraDetectionAddModal";
import useModals from "../../../hooks/Common/useModals";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import useToolbarSetter from "../../../hooks/Common/useToolbarSetter";
import getSnapshot from "../../../web_api/methods/files/getSnapshot";
import LoadingPage from "../../../layout/LoadingPage";
import useLoadImage from "../../../hooks/FileManagement/useLoadImage";
import { ELoadType } from "../../../web_api/models";


const CameraInfo = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar(
      'cameras-info',
      `Camera - ${cameraName} - ${id}`
    )
  })
  const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);

  const params = useParams();

  const id = Number(params.id);

  const cameraName = cachedCameraList.find((camera) => camera.id === id)?.name

  const { show, toggleModal } = useModals([false]);

  const { loadingImage, url } = useLoadImage(id ? id : undefined, true, getSnapshot, ELoadType.BULK);

  return (
    <div className="container pt-3 px-4">
      <div className="d-flex p-1">
        <Link to={`${process.env.REACT_APP_HOMEPAGE}/cameras`}>
          <button className="btn btn-primary mb-4">
            <i className="bi bi-arrow-return-left"></i>
            Return
          </button>
        </Link>
      </div>

      <div className="card mb-5 mb-xl-10">
        <div id="kt_account_profile_details" className="collapse show">
          <div className="card-body border-top p-5">
            <div className="row">
              <div className="col-lg-6 position-relative">
                {loadingImage ? (
                  <LoadingPage />
                ) :
                  (
                    <img
                      src={url}
                      alt="camera"
                      className="img-fluid rounded w-100"
                    />
                  )}

              </div>
              <div className="col-lg-3 col-xxl-4 order-lg-2 mt-3 mb mt-sm-2">
                <p className="fw-bold mb-2">{`${cameraName}`}</p>
              </div>
              <div className="col-lg-3 col-xxl-2 order-lg-3 mt-2">
                <div className="d-flex justify-content-lg-end mb-3">
                  <button type="submit" className="btn btn-primary" onClick={() => toggleModal(0)}>
                    Add Detection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          role='button'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0 d-flex flex-row w-100'>
            <h3 className='fw-bolder m-0'>Settings</h3>
          </div>
        </div>
        <CameraInfoSettings id={id} />
        <CameraDetectionAddModal
          show={show[0]}
          toggleModal={() => toggleModal(0)}
          camera={{ name: cameraName ? cameraName : "Current camera", id: id }}
        />
      </div>
      
    </div>
  )
}

export default CameraInfo