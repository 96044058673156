import React, { useEffect } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import AlertCanvasComponent from './AlertCanvasComponent';
import picture from './traffic_camera_pic.jpg';
import { Button } from 'react-bootstrap';
import { CommonModal } from '../Common/CommonModal';
import useModals from '../../hooks/Common/useModals';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getDetections from '../../web_api/methods/alerts/getDetections';
import { setAlerts } from '../../redux/alertsReducer';
import { detectionItemInList } from '../../web_api/models';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import LoadingPage from '../../layout/LoadingPage';

const LastAlerts = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('recent-alerts', `Recent alerts`);
  });

  const dispatch = useDispatch();
  const alerts = useSelector((state: RootState) => state.alerts);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const [selectedInstance, setSelectedInstance] = React.useState<detectionItemInList>();
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const { show, toggleModal } = useModals([false]);
  const [loading, setLoading] = React.useState<boolean>(true);

  // const image = new Image();
  // image.src = picture;

  const computeUrl = async (alert: detectionItemInList) => {
    console.log('alert.filename', alert.filename);
    const res = await getAzureFile(alert.filename);
    return res;
  };

  const loadInitialData = async () => {
    setLoading(true);
    let res = await getDetections(undefined, undefined, 1, 5, new Date(Date.now()), new Date(Date.now()), true);
    // set each alert filename to computeUrl(filename)

    res = await Promise.all(
      res.map(async (alert) => {
        alert.filename = await computeUrl(alert);
        return alert;
      })
    );

    dispatch(setAlerts(res));
    setLoading(false);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const isLoaded = (image: HTMLImageElement) => {
    return image.naturalHeight !== 0 && image.naturalWidth !== 0;
  };

  const getReadableDate = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${
      date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    }`;
  };

  return (
    <div className='p-4 pt-1 d-flex flex-column align-items-end'>
      <div className='d-flex flex-row gap-2 py-1'>
        <Button
          variant='secondary'
          className='mb-1 p-2 py-2 d-flex flex-row gap-3 align-items-center justify-content-end cinema-mode'
          onClick={() =>
            setIsExpanded((prev) => {
              return !prev;
            })
          }
        >
          <div className='d-flex card p-2 bg-light'>
            <i className='bi bi-tv fs-2 p-0'></i>
          </div>
        </Button>
        <Button
          variant='secondary'
          className='mb-1 p-2 py-2 d-flex flex-row gap-3 align-items-center justify-content-end refresh-button'
          onClick={loadInitialData}
        >
          <div className='d-flex card p-2 bg-light'>
            <i className='bi bi-arrow-clockwise fs-2 p-0'></i>
          </div>
        </Button>
      </div>
      <div className={`d-flex ${isExpanded ? 'flex-column align-items-center' : 'flex-row align-items-start'} gap-4 w-100`}>
        <div className={`d-flex flex-column gap-4 card p-5  ${isExpanded ? 'col-md-12' : 'col-md-7'} shadow-md`}>
          {loading ? (
            <LoadingPage />
          ) : (
            <>
              <div className='d-flex flex-column justify-content-center align-items-center'>
                {alerts.alerts.length > 0 && (
                  <AlertCanvasComponent
                    // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
                    imageUrl={alerts.alerts[0].filename}
                    actualHeight={alerts.alerts[0].picture_height}
                    actualWidth={alerts.alerts[0].picture_width}
                    multiple_bboxes={alerts.alerts[0].multiple_bboxes}
                    canvasHeight={isExpanded ? `${1200 * (9 / 16)}px` : `${700 * (9 / 16)}px`}
                    canvasWidth={isExpanded ? '1200px' : `700px`}
                    key={isExpanded.toString()}
                    big={true}
                  />
                )}
                {alerts.alerts.length === 0 && (
                  <div
                    style={{
                      width: isExpanded ? '1200px' : `700px`,
                      height: isExpanded ? `${1200 * (9 / 16)}px` : `${700 * (9 / 16)}px`,
                    }}
                    className='d-flex flex-column justify-content-center align-items-center bg-light gap-4'
                  >
                    <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '12rem' }}></i>
                    <div className='h4 text-secondary-emphasis'>No picture found</div>
                  </div>
                )}
              </div>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bolder fs-6 text-gray-800'>
                    <th className='text-primary-emphasis'>Alert Type</th>
                    <th className='text-primary-emphasis'>Confidence</th>
                    <th className='text-primary-emphasis'>Data</th>
                    <th className='text-primary-emphasis'>Location</th>
                    <th className='text-primary-emphasis'>Camera ID/IP</th>
                  </tr>
                </thead>
                <tbody>
                  {alerts.alerts.length > 0 && (
                    <tr>
                      <td>
                        {alerts.alerts[0].detection_description} {alerts.alerts[0].feature1} {alerts.alerts[0].feature2}
                      </td>
                      <td>{(alerts.alerts[0].threshold * 100).toFixed(0)}%</td>
                      <td>{getReadableDate(new Date(alerts.alerts[0].detection_time))}</td>
                      <td>{alerts.alerts[0].camera_name}</td>
                      <td>{alerts.alerts[0].camera_id}</td>
                    </tr>
                  )}
                  {alerts.alerts.length === 0 && (
                    <tr>
                      <td colSpan={5} className='text-center'>
                        <div className='h3 text-secondary-emphasis'>No alerts found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
        <div
          className={`d-flex flex-column card px-5 py-0 flex-grow  ${isExpanded ? 'col-md-12' : 'col-md-5'} shadow-md h-auto`}
          style={{ overflowY: 'auto' }}
        >
          {loading ? (
            <LoadingPage />
          ) : (
            <table className='table table-row-dashed table-row-gray-300 align-middle gx-0 gap-3'>
              <thead>
                <tr className='fw-bolder fs-6 text-gray-800' key='table-head'>
                  <th className='text-primary-emphasis pe-2'>Alert Type</th>
                  <th className='text-primary-emphasis pe-2'>Confidence</th>
                  <th className='text-primary-emphasis pe-2'>Data</th>
                  <th className='text-primary-emphasis pe-2'>Location</th>
                  <th className='text-primary-emphasis pe-2'>Camera ID/IP</th>
                </tr>
              </thead>
              <tbody>
                {alerts.alerts.length > 0 &&
                  alerts.alerts.map((alert, index) => {
                    return (
                      <tr key={`${index}`}>
                        <td>
                          {alert.detection_description} {alert.feature1} {alert.feature2}
                        </td>
                        <td>{(alert.threshold * 100).toFixed(0)}%</td>
                        <td>{getReadableDate(new Date(alert.detection_time))}</td>
                        <td>{alert.camera_name}</td>
                        <td>{alert.camera_id}</td>
                        <td>
                          <Button
                            variant='secondary'
                            size='sm'
                            className='p-2 d-flex flex-row align-items-center justify-content-center'
                            onClick={() => {
                              setSelectedInstance(alert);
                              toggleModal(0);
                            }}
                          >
                            <i className='bi bi-arrows-angle-expand p-0' />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                {alerts.alerts.length === 0 && (
                  <tr>
                    <td colSpan={5} className='text-center'>
                      <div className='h3 text-secondary-emphasis'>No alerts found</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {selectedInstance && (
        <CommonModal
          closeButton={true}
          title={`Photo of ${selectedInstance?.detection_description} on ${getReadableDate(new Date(selectedInstance.detection_time))} on ${
            selectedInstance?.camera_id
          }`}
          show={show[0]}
          showModalCallback={() => toggleModal(0)}
          size='lg'
          closeButtonOptions={{}}
          confirmButton={false}
          confirmButtonOptions={{}}
        >
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <AlertCanvasComponent
              // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${personItem.filename}`}
              imageUrl={selectedInstance.filename}
              actualHeight={selectedInstance.picture_height}
              actualWidth={selectedInstance.picture_width}
              multiple_bboxes={selectedInstance.multiple_bboxes}
              canvasHeight={`${750 * (9 / 16)}px`}
              canvasWidth={'750px'}
              big={true}
            />
          </div>
        </CommonModal>
      )}
      <Tooltip anchorSelect='.cinema-mode' place='bottom-start'>
        Cinema Mode
      </Tooltip>
      <Tooltip anchorSelect='.refresh-button' place='bottom-start'>
        Refresh
      </Tooltip>
    </div>
  );
};

export default LastAlerts;
