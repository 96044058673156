import { ToastContainer } from "react-toastify";
import twoFactorImage from "../../assets/images/two-factor.svg";
import useModals from "../../hooks/Common/useModals";
import TwoFactorAuthSetupModal from "./TwoFactorAuthSetupModal";
import TwoFactorAuthDisableModal from "./TwoFactorAuthDisableModal";
import { useEffect, useState } from "react";
import TwoFactorAuthLostModal from "./TwoFactorAuthLostModal";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";


export default function TwoFactorAuth() {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            '2fa',
            `Two Factor Auth (2FA)`
        )
    })
    const { toggleModal, show } = useModals([false, false]); // 0 for setup, 1 for disable

    const [lost2FA, setLost2FA] = useState(false);

    const twoFactorAuth = localStorage.getItem("two_factor_enabled");
    const isTwoFactorEnabled = twoFactorAuth === 'true';
    
    return (
        <>
            
            <div className="container my-lg-auto">
                <div className='row row-cols-lg-2 g-5'>
                    <div className="card-px text-center pt-15 pb-15">
                        <h2 className="fs-2x fw-bold mb-0">Two-factor Authentication</h2>
                        <p className="text-gray-400 fs-4 fw-semibold py-7">
                            {isTwoFactorEnabled ?
                                <>
                                    Click on the button below to disable <br /> the two-factor authentication.
                                </>
                                :
                                <>
                                    Click on the button below to initiate <br /> the two-factor authentication setup.
                                </>
                            }
                        </p>
                        <a
                            className={`btn er fs-6 px-8 py-4 ${isTwoFactorEnabled ? 'btn-danger' : 'btn-primary'}`}
                            onClick={() => isTwoFactorEnabled? toggleModal(1) : toggleModal(0)}
                        >
                            {isTwoFactorEnabled ? 'Disable Two-factor Authentication' : 'Enable Two-factor Authentication'}
                        </a>
                        {isTwoFactorEnabled && 
                            <div className='link-primary cursor-pointer mt-5 fs-9' onClick={() => {
                                setLost2FA(true);
                            }}>
                                Lost your 2FA Device or Removed Your Code?
                            </div>
                        }
                    </div>
                    
                    <div className="text-center pb-15 px-5">
                        <img
                            src={twoFactorImage}
                            alt="Two factor presentation"
                            className="mw-100 h-200px h-sm-325px"
                        />
                    </div>
                </div>
            </div>

            <TwoFactorAuthSetupModal
                show={show[0]}
                toggleModal={() => toggleModal(0)}
            />
            <TwoFactorAuthDisableModal
                show={show[1]}
                toggleModal={() => toggleModal(1)}
            />
            <TwoFactorAuthLostModal
                show={lost2FA}
                toggleModal={() => setLost2FA((prev) => !prev)}
            />
        </>
    )
}
