import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { detectionServerInList } from "../../web_api/models";
import CommonNewUserCard from "../Common/CommonNewUserCard";
import DetectionServerAddFormModal from "./DetectionServerAddFormModal";

type DetectionServerAddProps = {
    onDetectionServerAdd: (detectionServer: detectionServerInList) => void,
    toggleModal: () => void,
    show: boolean,
    dataLength: number,
}

const DetectionServerAdd: React.FC<DetectionServerAddProps> = ({
    dataLength,
    onDetectionServerAdd,
    show,
    toggleModal
}) => {

    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);

    return (
        <>
            {  dataLength === 0 && cachedLocationsList.length > 0 ?
                <CommonNewUserCard
                    title="Add your first detection server!"
                    content={`Click on this card to add your first detection server.\n
                        Fill out the fields and press the "Confirm" button to register a detection server.`}
                    className="w-xl-50 mx-auto"
                    icon="bi bi-hdd-network"
                    buttonText="Add detection server"
                    onClick={toggleModal}
                />
            :
                (
                    <div className='d-flex flex-column flex-md-row p-1 flex-end'>
                        <button className="btn btn-primary" onClick={toggleModal}>
                            Add Server
                        </button>
                    </div>
                )
            }
            <DetectionServerAddFormModal
                show={show}
                handleToggleModal={toggleModal}
                onDetectionServerAdd={onDetectionServerAdd}
            />
        </>

    )
}

export default DetectionServerAdd