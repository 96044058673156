import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AvgTimeCashRegisterAllLocationsState {
  value: string
}
const initialState: AvgTimeCashRegisterAllLocationsState = {
  value: localStorage.getItem('avgTimeCashRegisterAllLocations')!?.toString()!=null?localStorage.getItem('avgTimeCashRegisterAllLocations')!?.toString():'undefined'
};

export const avgTimeCashRegisterAllLocations = createSlice({
  name: 'avgTimeCashRegisterAllLocations',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<AvgTimeCashRegisterAllLocationsState>) => {
      state.value = action.payload.value
      localStorage.setItem("avgTimeCashRegisterAllLocations", action.payload.value)

    },
  },
});

export const { setValue } = avgTimeCashRegisterAllLocations.actions;

export const avgTimeCashRegisterAllLocationsReducer = avgTimeCashRegisterAllLocations.reducer;
