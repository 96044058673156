import React, { useEffect, useState } from 'react';
import { DayWeekMonthYearEnum, interval } from '../../web_api/models';
import { AlertsByAreaChart } from './AlertsByAreaChart';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getAlertsByArea from '../../web_api/methods/hsse/getAlertsByArea';
type Props = {
  className?: string;
  filter: interval;
};

const AlertsByArea: React.FC<Props> = ({ className, filter }) => {
  const dayWeekMonthYear = convertStringToEnumValue(filter); //dependency
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  //dependencies
  const [values, setValues] = useState<number[][]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [labels, setLabels] = useState<string[][]>([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadGenderReports();
  }, [dayWeekMonthYear, activeLocation, selectedUser]);

  const loadGenderReports = async () => {
    try {
      setLoaded(false);
      const response = await getAlertsByArea(filter);

      // 1. Group by camera, then by date
      const groupedByCamera = response.reduce((cameraAcc: Record<string, Record<string, number>>, item) => {
        if (!cameraAcc[item.camera_name]) {
          cameraAcc[item.camera_name] = {};
        }
        const date = getReadableDate(new Date(item.date));
        cameraAcc[item.camera_name][date] = item.detections_number;
        return cameraAcc;
      }, {});

      // 2. Get unique camera names and dates
      const cameraNames = Object.keys(groupedByCamera);
      const allDates = Array.from(new Set(response.map((item) => getReadableDate(new Date(item.date)))));

      // 3. Prepare values with equal-length arrays
      const valuesArray = cameraNames.map((camera) => allDates.map((date) => groupedByCamera[camera][date] || 0));

      setValues(valuesArray);
      setLabels(cameraNames.map((camera) => [camera]));
      setDates(allDates);
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  const getReadableDate = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return (
    <div className={`card h-100 ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Alerts by area</span>
          <span className='text-muted fw-semibold fs-7'>Alerts every day</span>
        </h3>
      </div>
      <div className='card-body p-0'>
        {values.length === 0 && (
          <div className='d-flex flex-row align-items-center justify-content-center gap-4 h-200px w-100'>
            <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
            <div className='fs-3 text-secondary-emphasis'>No alerts recorded</div>
          </div>
        )}
        {values.length > 0 && (
          <AlertsByAreaChart
            type='bar'
            className='w-sm-100'
            chartColor='danger'
            chartHeight='150px'
            filter={DayWeekMonthYearEnum.WEEK}
            dates={dates}
            labels={labels}
            values={values}
            loaded={loaded}
          />
        )}
      </div>
    </div>
  );
};
export { AlertsByArea };
