import callApiAxiosClient from '../../../axios/axios_client';

type updatePlanArgs = {
  location_id: number;
  resolution?: number;
  time_delay?: number;
};

async function updatePlan({ location_id, resolution, time_delay }: updatePlanArgs) {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'put',
    url: 'settings/locations/plan',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      location_id: location_id,
      resolution: resolution,
      time_delay: time_delay,
    },
  });
}
export default updatePlan;
