import callApiAxiosClient from "../../axios/axios_client";


function resetPasswordUser(id: string): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "POST",
        url: "account/reset-user-password",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
           user_id:id
        }
    })
}
export default resetPasswordUser;