/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { ComparisonEnum } from '../../../web_api/models'
import 'react-day-picker/dist/style.css';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store'
import { LocationData } from '../../../pages/EvolutionComparison/LocationData'


type Props = {
    className: string
    callbackComparison: (active: ComparisonEnum) => void
}

const ChartsWidget12: React.FC<Props> = ({ className, callbackComparison }) => {

    const compareLocation1 = useSelector((state: RootState) => state.compareLocation1);
    const compareLocation2 = useSelector((state: RootState) => state.compareLocation2);
    const compareUser1 = useSelector((state: RootState) => state.compareUser1);
    const compareUser2 = useSelector((state: RootState) => state.compareUser2);

    const [chartComparison, setChartComparison] = useState<ComparisonEnum>(ComparisonEnum.QUEUE_MANAGEMENT)

    return (
        <>
            <div className={`card ${className}`}>
                <div className='card-body p-10 pb-0 '>
                    <div className='d-flex flex-column flex-xl-row'>
                        <div className='d-flex mb-3 col-xl-6'>
                            <LocationData location={compareLocation1} user={compareUser1.value} />
                        </div>
                        <div className='d-flex ms-xl-2 mb-3 col-xl-6'>
                            <LocationData location={compareLocation2} user={compareUser2.value} />
                        </div>
                    </div>

                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>

                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.QUEUE_MANAGEMENT)
                                setChartComparison(ComparisonEnum.QUEUE_MANAGEMENT)
                            }
                            }
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (chartComparison === ComparisonEnum.QUEUE_MANAGEMENT && 'active')
                                }
                                to=''
                            >
                                {ComparisonEnum.QUEUE_MANAGEMENT} 
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.BAKERY)
                                setChartComparison(ComparisonEnum.BAKERY)
                            }
                            }
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (chartComparison === ComparisonEnum.BAKERY && 'active')
                                }
                                to=''
                            >
                                {ComparisonEnum.BAKERY}
                            </Link>
                        </li>

                        <li className='nav-item'>
                            <Link onClick={() => {
                                callbackComparison(ComparisonEnum.FRUITS_AND_VEGETABLES)
                                setChartComparison(ComparisonEnum.FRUITS_AND_VEGETABLES)
                            }
                            }
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (chartComparison === ComparisonEnum.FRUITS_AND_VEGETABLES && 'active')
                                }
                                to=''
                            >
                                {ComparisonEnum.FRUITS_AND_VEGETABLES}
                            </Link>
                        </li>

                    </ul>
                </div>
            </div>
        </>
    )
}

export { ChartsWidget12 }
