import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import permissionsJSON from '../assets/permissions.json';

export interface PermissionsState {
  permissions: any;
}
const initialState: PermissionsState = {
  permissions: permissionsJSON,
};

export const permissions = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<PermissionsState>) => {
      state.permissions = action.payload.permissions;
    },
  },
});

export const { setValue } = permissions.actions;

export const permissionsReducer = permissions.reducer;
