import callApiAxiosClient from '../../axios/axios_client';

function deleteTrainingModel(modelId: number): Promise<string> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<string>({
    method: 'delete',
    url: 'training',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      model_id: modelId,
    },
  });
}
export default deleteTrainingModel;
