import moment from 'moment';
import callApiAxiosClient from '../../axios/axios_client';
import { personList } from '../../models';

async function getPersons(
  person?: string,
  cameraId?: number,
  pageIndex?: number,
  pageSize?: number,
  fromParam?: Date,
  toParam?: Date
): Promise<personList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  const from = moment(fromParam).format('YYYYMMDD');
  const to = moment(toParam).format('YYYYMMDD');

  console.log('from', from);
  console.log('to', to);

  return callApiAxiosClient<personList>({
    method: 'get',
    url: 'programmability/get-persons',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      person: person,
      cameraId: cameraId,
      pageIndex: pageIndex,
      pageSize: pageSize,
      from: from,
      to: to,
    },
  });
}
export default getPersons;
