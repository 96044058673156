import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface toolbarHourState {
  hour: string
  toHour: string
}
const initialState: toolbarHourState = {
  hour: localStorage.getItem('toolbarHourFilter')!?.toString() != null ? localStorage.getItem('toolbarHourFilter')!?.toString() : '9',
  toHour: localStorage.getItem('toToolbarHourFIlter')!?.toString() != null ? localStorage.getItem('toToolbarHourFIlter')!?.toString() : '21',
};

export const toolbarHourFilter = createSlice({
  name: 'toolbarHourFilter',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<toolbarHourState>) => {
      state.hour = action.payload.hour
      state.toHour = action.payload.toHour
      localStorage.setItem("toolbarHourFilter", action.payload.hour)
      localStorage.setItem("toToolbarHourFIlter", action.payload.toHour)
    },
  },
});

export const { setValue } = toolbarHourFilter.actions;

export const toolbarHourFilterReducer = toolbarHourFilter.reducer;
