import { useEffect, useState } from 'react';
import useToolbarSetter from '../../hooks/Common/useToolbarSetter';
import TrainingCard from './TrainingCard';
import CommonPagination from '../Common/CommonPagination';
import { Button, Form } from 'react-bootstrap';
import useModals from '../../hooks/Common/useModals';
import { CommonModal } from '../Common/CommonModal';
import CommonFormField from '../Common/CommonFormField';
import { objectClassInList, trainingModelInList } from '../../web_api/models';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setTrainings, setObjectClasses } from '../../redux/trainingReducer';
import getTrainingModels from '../../web_api/methods/training/getTrainingModels';
import getTrainingObjectClasses from '../../web_api/methods/training/getTrainingObjectClasses';
import addTrainingObjectClass from '../../web_api/methods/training/addTrainingObjectClass';
import addTrainingModel from '../../web_api/methods/training/addTrainingModel';

const defaultNewTraining: trainingModelInList = {
  id: 0,
  name: '',
  description: '',
  created_date: '',
  runs: 0,
  object_classes: [],
};

const defaultNewObjectClass: objectClassInList = {
  id: 0,
  name: '',
  parent_id: null,
  detection_type_id: null,
};

const Training = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('training', `Training`);
  });

  const [page, setPage] = useState({
    index: 0,
    size: 29,
  });
  const [checkedTrainings, setCheckedTrainings] = useState<number[]>([]);
  const { toggleModal, show } = useModals([false, false]); // 0: add training, 1: add object class
  const [newSession, setNewSession] = useState<trainingModelInList>(defaultNewTraining);
  const [newObjectClass, setNewObjectClass] = useState<objectClassInList>(defaultNewObjectClass);

  const trainings = useSelector((state: RootState) => state.training.trainings);
  const object_classes = useSelector((state: RootState) => state.training.object_classes);
  const dispatch = useDispatch();

  useEffect(() => {
    const getInitialData = async () => {
      const response_trainings = await getTrainingModels();
      dispatch(setTrainings(response_trainings));
      console.log('response_trainings', response_trainings);
      const object_classes_response = await getTrainingObjectClasses();
      dispatch(setObjectClasses(object_classes_response));
    };
    getInitialData();
  }, []);

  const handleIndexChange = (action: string) => {
    if (true) {
      //!loading
      if (action === 'next') {
        setPage({ ...page, index: page.index + 1 });
      } else if (action === 'previous') {
        setPage({ ...page, index: Math.max(page.index - 1, 0) });
      }
      setCheckedTrainings([]);
    }
  };

  const handleTrainingCheck = (id: number) => {
    if (checkedTrainings.includes(id)) {
      setCheckedTrainings(checkedTrainings.filter((detection) => detection !== id));
    } else {
      setCheckedTrainings([...checkedTrainings, id]);
    }
  };

  const handleTrainingDelete = (id: number) => {
    // onDetectionMassDelete(checkedDetections);
    dispatch(setTrainings(trainings.filter((training) => training.id !== id)));
    setCheckedTrainings([]);
  };

  return (
    <>
      <div className='container pt-3 px-4 d-flex flex-wrap justify-content-between'>
        <div className='d-flex'>
          {/* <DetectionAdd onDetectionAdd={onDetectionAdd} dataLength={data?.length} show={show[0]} toggleModal={() => toggleModal(0)} /> */}
          <Button variant='primary' className='me-2' onClick={() => toggleModal(0)}>
            Add Training
          </Button>
          <Button variant='primary' onClick={() => toggleModal(1)}>
            Add Object Class
          </Button>
          {/* <DetectionMassDelete
            checkedDetections={checkedDetections}
            onShowMassDeleteModal={onShowMassDeleteModal}
            onDetectionMassDelete={onDetectionMassDelete}
          /> */}
        </div>
        <CommonPagination
          disablePrevious={page.index === 0 || false} // loading
          disableNext={true} //loading || data?.[0].has_next_page === false
          startIndex={page.index + 1}
          handlePrevChange={() => handleIndexChange('previous')}
          handleNextChange={() => handleIndexChange('next')}
        />
      </div>
      <div className='container px-sm-5'>
        <div className='row'>
          {trainings.map((trainingModel) => (
            <TrainingCard
              key={trainingModel.id}
              trainingModel={trainingModel}
              onDetectionCheck={handleTrainingCheck}
              onDetectionDelete={handleTrainingDelete}
            />
          ))}
        </div>
      </div>
      <CommonModal
        show={show[0]}
        showModalCallback={() => toggleModal(0)}
        title='Add New Training Session'
        closeButton={true}
        confirmButton={true}
        confirmButtonOptions={{
          onClick: async () => {
            if (!newSession?.name || !newSession?.description) return;
            else {
              dispatch(
                setTrainings([
                  ...trainings,
                  {
                    ...newSession,
                    id: trainings.length + 1,
                    created_date: newSession.created_date === '' ? new Date(Date.now()).toString() : newSession.created_date,
                  },
                ])
              );
              toggleModal(0);
              console.log('newSession', newSession);
              setNewSession(defaultNewTraining);
              await addTrainingModel(newSession.name, newSession.description, 0, newSession.runs, newSession.created_date);
            }
          },
          icon: 'bi bi-check-lg fs-3',
          class: 'btn-success',
        }}
        closeButtonOptions={{
          onClick: () => {
            toggleModal(0);
          },
          icon: 'bi bi-x-lg',
        }}
        bodyClass='flex-column gap-2'
      >
        <CommonFormField
          label='Name'
          type='text'
          id='name'
          onChange={(e) =>
            setNewSession((prev) => {
              return { ...prev, name: e.target.value };
            })
          }
          placeholder='Enter name'
          value={newSession?.name || ''}
        />
        <CommonFormField
          label='Description'
          type='text'
          id='description'
          onChange={(e) =>
            setNewSession((prev) => {
              return { ...prev, description: e.target.value };
            })
          }
          placeholder='Enter description'
          value={newSession?.description || ''}
        />
        <CommonFormField
          label='Runs'
          type='number'
          id='runs'
          onChange={(e) =>
            setNewSession((prev) => {
              return { ...prev, runs: parseInt(e.target.value) };
            })
          }
          placeholder='Enter the amount of runs'
          value={newSession?.runs || ''}
        />
        <CommonFormField
          label='Date (optional)'
          type='date'
          id='date'
          onChange={(e) =>
            setNewSession((prev) => {
              return { ...prev, created_date: e.target.value };
            })
          }
          placeholder='Enter the date (optional)'
          value={newSession?.created_date || ''}
        />
      </CommonModal>
      <CommonModal
        show={show[1]}
        showModalCallback={() => toggleModal(1)}
        title='Add New Object Class'
        closeButton={true}
        confirmButton={true}
        confirmButtonOptions={{
          onClick: async () => {
            if (!newObjectClass?.name) return;
            else {
              dispatch(setObjectClasses([...object_classes, { ...newObjectClass, id: object_classes.length + 1 }]));
              toggleModal(1);
              await addTrainingObjectClass(newObjectClass.name, newObjectClass.parent_id ?? null, newObjectClass.detection_type_id ?? null);
              setNewObjectClass(defaultNewObjectClass);
            }
          },
          icon: 'bi bi-check-lg fs-3',
          class: 'btn-success',
        }}
        closeButtonOptions={{
          onClick: () => {
            setNewObjectClass(defaultNewObjectClass);
            toggleModal(1);
          },
          icon: 'bi bi-x-lg',
        }}
        bodyClass='flex-column gap-2'
      >
        <CommonFormField
          label='Name'
          type='text'
          id='name'
          onChange={(e) =>
            setNewObjectClass((prev) => {
              return { ...prev, name: e.target.value };
            })
          }
          placeholder='Enter name'
          value={newObjectClass?.name || ''}
        />
        <div className='fw-bolder fs-6'>Parent ID (optional)</div>
        <Form.Select
          onChange={(e) =>
            setNewObjectClass((prev) => {
              return { ...prev, parent_id: parseInt(e.target.value) };
            })
          }
          title='Parent ID'
        >
          <option>None</option>
          {object_classes.map(
            (objectClass) =>
              !objectClass.parent_id && (
                <option key={objectClass.id} value={objectClass.id}>
                  {objectClass.name}
                </option>
              )
          )}
        </Form.Select>
      </CommonModal>
    </>
  );
};

export default Training;
