import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../redux/store';
import { setCachedLocationList } from '../../redux/cachedLocationsReducer';
import { CommonModal } from '../Common/CommonModal';
import { toast } from 'react-toastify';
import deleteLocations from '../../web_api/methods/settings/locations/deleteLocations';
import { useState } from 'react';

type LocationsDeleteModalProps = {
  onModalToggle: () => void;
  show: boolean;
  locationData: {
    checked: number[];
    current: {
      id: number;
      name: string;
      lat: number;
      long: number;
    };
  };
  onDelete: () => void;
};

const LocationsDeleteModal: React.FC<LocationsDeleteModalProps> = ({ onModalToggle, onDelete, show, locationData }) => {
  const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const dispatch = useDispatch();

  const checkedLocationNames = locationData.checked.map((locationId) => {
    const location = cachedLocationsList.list.find((loc) => loc.id === locationId);
    return location ? `${location.id} - ${location.name}` : '';
  });

  const handleDeleteLocation = async () => {
    try {
      setIsDeleting(true);
      if (locationData.checked.length > 0 && !locationData.current.id) {
        await deleteLocations(locationData.checked);

        const updatedLocationList = cachedLocationsList.list.filter((locationItem) => !locationData.checked.includes(locationItem.id));
        dispatch(setCachedLocationList({ list: updatedLocationList }));
      } else {
        await deleteLocations([locationData.current.id]);
        const updatedLocationList = cachedLocationsList.list.filter((locationItem) => locationItem.id !== locationData.current.id);
        dispatch(setCachedLocationList({ list: updatedLocationList }));
      }
      onModalToggle();
      onDelete();
      toast.success(`Location${locationData.checked && !locationData.current.id ? 's' : ''} deleted successfully!`, {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    } catch (e) {
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <CommonModal
        title='Confirm'
        size={locationData.checked && !locationData.current.id ? 'lg' : undefined}
        show={show}
        disabled={isDeleting}
        showModalCallback={onModalToggle}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100 ps-15 pe-15 pb-10'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash text-white',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleDeleteLocation,
        }}
      >
        <div>{`Are you sure you want to delete the following location${locationData.checked && !locationData.current.id ? 's' : ''}?`}</div>
        {locationData.checked && !locationData.current.id ? (
          checkedLocationNames.map((name, index) => (
            <div key={index} className='fw-bolder mt-3'>
              {name}
            </div>
          ))
        ) : (
          <div className='fw-bolder mt-3 text-break'>{`${locationData.current.id} - ${locationData.current.name}`}</div>
        )}
      </CommonModal>
    </>
  );
};

export default LocationsDeleteModal;
