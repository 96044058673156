import callApiAxiosClient from "../../axios/axios_client";
import { entriesHoursList } from "../../models";
import moment from "moment";


function peopleCountingEntriesHours(fromParam:Date, toParam:Date): Promise<entriesHoursList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const from = moment(fromParam).format("YYYYMMDD");
    const to = moment(toParam).format("YYYYMMDD");
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<entriesHoursList>({
        method: "get",
        url: "programmability/entries-hours",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        },
        params:{
            from: from,
            to: to
        }
    })
}
export default peopleCountingEntriesHours;