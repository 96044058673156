import React from 'react';
import { ComparisonSeparateChart } from '../../_metronic/widgets/charts/ComparisonSeparateChart';
import { ComparisonMixedChart } from '../../_metronic/widgets/charts/ComparisonMixedChart';
import { ChartTypeEnum, ComparisonEnum } from '../../web_api/models';
import { DateFilterState } from '../../redux/dateFilterReducer';

type Props = {
  chartType: ChartTypeEnum;
  setChartType: (chartType: ChartTypeEnum) => void;
  chartComparison: ComparisonEnum;
  dateFilter: DateFilterState;
  averagesLoading: boolean;
  values1: number[];
  values2: number[];
  labels1: string[]; 
  labels2: string[];
  averagesError: boolean;
};

const QueueManagementComparison: React.FC<Props> = ({
  chartType,
  setChartType,
  chartComparison,
  dateFilter,
  averagesLoading,
  values1,
  values2,
  labels1,
  labels2,
  averagesError,
}) => {
  return (
    <>
      <div className='d-flex flex-row col-sm-12 p-2'>
        <div className='card w-100'>
          <div className='card-header border-0 pt-5'>
            <h3 className='card-title flex-column'>
              <div className='card-label fw-bold fs-3 mb-1 '>Audio alerts sent</div>
              <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                Compare similar periods of time
              </div>
            </h3>
            <div className='d-flex align-items-center flex-shrink-0'>
              <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>View:</span>
              <div className='flex-shrink-0 '>
                <ul className='nav mt-2 mt-sm-0'>
                  <li
                    className='nav-item'
                    onClick={() => (chartType === ChartTypeEnum.AREA ? setChartType(ChartTypeEnum.BAR) : setChartType(ChartTypeEnum.AREA))}
                  >
                    <a
                      href='/#'
                      className={'nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 active'}
                      data-bs-toggle='tab'
                    >
                      {chartType === ChartTypeEnum.AREA ? <i className='bi bi-graph-up p-0' /> : <i className='bi bi-bar-chart p-0' />}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <ComparisonMixedChart
            type={chartType === ChartTypeEnum.BAR ? 'bar' : 'area'}
            className='w-sm-100'
            chartColor='primary'
            chartSecondaryColor='danger'
            chartHeight='150px'
            comparison={chartComparison}
            from={new Date(dateFilter.from)}
            to={new Date(dateFilter.to)}
          />
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row col-sm-12 p-2'>
        <div className='d-flex flex-column col-12 col-md-6 pe-md-2'>
          <div className='card w-100'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title flex-column'>
                <div className='card-label fw-bold fs-3 mb-1 '>Employee response time after audio alert</div>
                <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                  Data is computed in minutes
                </div>
              </h3>
            </div>
            <ComparisonSeparateChart
              type={'bar'}
              className='w-sm-100'
              chartColor='primary'
              chartHeight='150px'
              comparison={chartComparison}
              loading={averagesLoading}
              values={values1}
              labels={labels1}
              hasErrors={averagesError}
            />
          </div>
        </div>
        <div className='d-flex flex-column col-12 col-md-6 ps-md-2'>
          <div className='card w-100'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title flex-column'>
                <div className='card-label fw-bold fs-3 mb-1 '>Queue duration</div>
                <div className='text-muted fw-semibold fs-7 mt-2 mt-sm-0' style={{ marginLeft: '0.1rem' }}>
                  Data is computed in minutes
                </div>
              </h3>
            </div>
            <ComparisonSeparateChart
              type={'bar'}
              className='w-sm-100'
              chartColor='danger'
              chartHeight='150px'
              comparison={chartComparison}
              loading={averagesLoading}
              values={values2}
              labels={labels2}
              hasErrors={averagesError}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { QueueManagementComparison };
