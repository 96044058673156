import React from 'react';
import logo from '../assets/images/logo-footer.png';
import orange from '../assets/images/oragne_business_black.svg';

const ToolbarHub = () => {
  return (
    <div className='d-flex flex-column align-items-end'>
      <img src={logo} className='w-100px' alt='logo' />
      <div className='d-flex flex-row gap-1 pt-1'>
        <div className='fs-base fw-bold'>powered by</div>
        <img src={orange} alt='orange' className='w-90px' />
      </div>
    </div>
  );
};

export default ToolbarHub;
