import { personItemInList } from "../../web_api/models"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

type Props = {
    backgroundColor: string
    textColor: string
    personItem: personItemInList
}

const FaceRecognitionCardInfo: React.FC<Props> = ({ backgroundColor, textColor, personItem }) => {
    const cachedPersonList = useSelector((state: RootState) => state.camerasList.list);
    let date = new Date(personItem.date)
    let hours = date.getHours();
    let minutes = date.getMinutes();

    let formattedHours = hours < 10 ? '0' + hours : hours;
    let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return (
        <>
            <div
                className="card-content"
                style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '1.5rem',
                }}
            >
                <a
                    href="#!"
                >
                    <h2 className='text-dark fs-3 mb-4'>{personItem.person}</h2>
                </a>
                <p><span className='fw-bold fs-7'>Date:</span> {new Date(personItem.date).toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}</p>
                <p><span className='fw-bold fs-7'>Time:</span> {`${formattedHours}:${formattedMinutes}`}</p>
                <p><span className='fw-bold fs-7'>Camera:</span> {cachedPersonList.find(x => x.id === personItem.camera_id)?.name}</p>
                <p><span className='fw-bold fs-7'>Confidence:</span> {Math.round(personItem.recognition_confidence * 100)}%</p>
            </div>
        </>
    )
}

export default FaceRecognitionCardInfo