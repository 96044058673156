import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RootState } from "../../redux/store";
import { setCachedCameraList } from "../../redux/camerasListReducer";
import getCameras from "../../web_api/methods/settings/cameras/getCameras";
import useGet from "../../hooks/Common/useGet";

import CameraCard from "./CameraCard";
import CameraAdd from "./CameraAdd";
import CameraMassDelete from "./CameraMassDelete";
import CameraCardAdd from "./CameraCardAdd";
import useModals from "../../hooks/Common/useModals";
import { setCachedLocationList } from "../../redux/cachedLocationsReducer";
import { setcachedCameraModelList } from "../../redux/cameraModelListReducer";
import getCameraModels from "../../web_api/methods/settings/cameraModels/getCameraModels";
import getLocations from "../../web_api/methods/settings/locations/getLocations";
import CameraNewuserCards from "./CameraNewUserCards";
import LoadingPage from "../../layout/LoadingPage";
import CommonPagination from "../Common/CommonPagination";
import getDetectionServers from "../../web_api/methods/settings/detectionServers/getDetectionServers";
import { setCachedDetectionServerList } from "../../redux/detectionServerListReducer";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";

const Cameras = () => {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'cameras',
            `Cameras`
        )
    })
    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
    const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);
    
    
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const selectedUser = useSelector((state: RootState) => state.selectedUser);
    const dispatch = useDispatch();

    const [page, setPage] = useState({
        index: 0,
        size: 29,
    });

    const [checkedCameras, setCheckedCameras] = useState<number[]>([]);
    const [checking, setChecking] = useState<boolean>(true);

    const hasCameras = cachedLocationsList.length > 0 && cachedCameraList.length > 0 && cachedCameraModelList.length > 0;

    const { loading } = useGet(
        [selectedUser.id, activeLocation.id, page.index],
        () => getCameras(
            {
                pageIndex: page.index,
                pageSize: page.size,
                locationId: Number(activeLocation.id)
            }
        ),
        setCachedCameraList,
    );

    const { show, toggleModal } = useModals([false]);

    const onCameraAdd = () => {
        if (page.index !== 0) {
            setPage({ ...page, index: 0 });
        };
    };

    const onCameraDelete = (id: number) => {
        const updatedCameraList = cachedCameraList.filter(
            (cameraItem) => cameraItem.id !== id
        );
        dispatch(setCachedCameraList({ list: updatedCameraList }));
    };


    const onCameraMassDelete = () => {
        const updatedCameraList = cachedCameraList.filter(
            (cameraItem) => !checkedCameras.includes(cameraItem.id)
        );
        dispatch(setCachedCameraList({ list: updatedCameraList }));
        setCheckedCameras([]);
    };

    const onCameraCheck = (id: number) => {
        setCheckedCameras((prevState) => {
            const isChecked = !prevState.includes(id);
            if (isChecked) {
                return [...prevState, id];
            } else {
                return prevState.filter((cameraId) => cameraId !== id);
            }
        });
    };

    const onShowMassDeleteModal = (cameraIds: number[]) => {
        const cameraNames = cachedCameraList
            .filter(camera => cameraIds.includes(camera.id))
            .map(camera => camera.name);
        return cameraNames;
    };

    const checkExistingSettings = async () => {
        setChecking(true);
        try {
            if (cachedLocationsList.length === 0) {
                const locationList = await getLocations();
                dispatch(setCachedLocationList({ list: locationList }));
            };

            if (cachedDetectionServerList.length === 0) {
                const detectionServerList = await getDetectionServers();
                dispatch(setCachedDetectionServerList({ list: detectionServerList }));
            };


            if (cachedCameraModelList.length === 0) {
                const cameraModelList = await getCameraModels();
                dispatch(setcachedCameraModelList({ list: cameraModelList }));
            };

        } catch (error) {
            console.log(error);
        } finally {
            setChecking(false);
        };
    };

    const handleIndexChange = (action: string) => {
        if (!loading) {
            if (action === 'next') {
                setPage({ ...page, index: page.index + 1 })
            } else if (action === 'previous') {
                setPage({ ...page, index: Math.max(page.index - 1, 0) })
            };
            setCheckedCameras([]);
        };
    };

    useEffect(() => {
        checkExistingSettings();
    }, []);

    useEffect(() => {
        setPage({ ...page, index: 0 });
    }, [selectedUser.id, activeLocation.id]);

    return (
        <>
            {checking || hasCameras === undefined || (page.index === 0 && loading) ?
                <LoadingPage />
                :
                <>
                    {hasCameras ? (
                        <>
                            <div className="container pt-3 px-4 d-flex flex-wrap justify-content-between" >
                                <div className="d-flex">
                                    <CameraAdd
                                        onCameraAdd={onCameraAdd}
                                        show={show[0]}
                                        toggleModal={() => toggleModal(0)}
                                    />
                                    <CameraMassDelete
                                        onCameraMassDelete={onCameraMassDelete}
                                        onShowMassDeleteModal={onShowMassDeleteModal}
                                        checkedCameras={checkedCameras}
                                    />
                                </div>
                                <CommonPagination
                                    disablePrevious={page.index === 0 || loading}
                                    disableNext={loading || cachedCameraList[0].has_next_page === false}
                                    startIndex={page.index + 1}
                                    handlePrevChange={() => handleIndexChange('previous')}
                                    handleNextChange={() => handleIndexChange('next')}
                                />
                            </div>
                            {loading ?
                                <LoadingPage />
                                :
                                <>
                                    <div className="container py-1 px-sm-5">
                                        <div className="row">
                                            {cachedCameraList &&
                                                cachedCameraList.map((cameraItem) => (
                                                    <CameraCard
                                                        key={cameraItem.id}
                                                        cameraItem={cameraItem}
                                                        onCameraDelete={onCameraDelete}
                                                        onCameraCheck={onCameraCheck}
                                                    />
                                                ))}
                                            <CameraCardAdd
                                                toggleModal={() => toggleModal(0)}
                                            />
                                        </div>
                                    </div>
                                </>
                            }

                        </>
                    ) : (
                        <>
                            <CameraNewuserCards
                                onCameraAdd={onCameraAdd}
                                show={show[0]}
                                toggleModal={() => toggleModal(0)}
                            />
                        </>
                    )}
                </>
            }


        </>
    );
};

export default Cameras;
