import callApiAxiosClient from "../../../axios/axios_client";

function deleteDetections(ids: number[]): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "delete",
        url: "settings/delete-detection-settings",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            ids: ids
        }
    })
}
export default deleteDetections;