/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSS, getCSSVariableValue } from '../../assets/ts/_utils';
import * as ReactBootstrap from 'react-bootstrap';
import { numberFormat } from '../../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import LoadingPage from '../../../layout/LoadingPage';
import { GroupedArray } from '../../../pages/QueueManagement/AvgTimesQueueManagement';

type Props = {
  className: string;
  xAxis: string[];
  yAxis1: number[];
  yAxis2: number[];
  loaded: boolean;
  oneLocationSelected: boolean;
  orderByType: string;
  setOrderByType: (type: string) => void;
  timeGroupSelected: number;
  setTimeGroupSelected: (time: number) => void;
  timeGroupAvailable: number[];
  groupedArray: GroupedArray;
};

// type GroupedArray = {
//   waitingTime: number;
//   count: any;
//   locations: any;
// }[];

const ChartsWidget15: React.FC<Props> = ({
  className,
  xAxis,
  yAxis1,
  yAxis2,
  loaded,
  oneLocationSelected,
  orderByType,
  setOrderByType,
  setTimeGroupSelected,
  timeGroupAvailable,
  timeGroupSelected,
  groupedArray,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const range = useSelector((state: RootState) => state.dateFilter);
  const hourParameters = useSelector((state: RootState) => state.hourParameters);
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  // const [orderByType, setOrderByType] = useState('');
  // const [timeGroupSelected, setTimeGroupSelected] = useState(0);
  // const [timeGroupAvailable, setTimeGroupAvailable] = useState<number[]>([]);
  // const [groupedArray, setGroupedArray] = useState<GroupedArray>([]);

  let selectedRange = <>Date picker</>;
  if (range?.from) {
    if (!range.to) {
      range.to = range.from;
      selectedRange = (
        <>
          {new Date(range.from)
            .toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            .replace(/\//g, '-')}
        </>
      );
    } else if (range.to) {
      selectedRange =
        range.to.toString() === range.from.toString() ? (
          <>
            {new Date(range.from)
              .toLocaleString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        ) : (
          <>
            {/* {format(range.from, 'PPP')} - {format(range.to, 'PPP')} */}
            {new Date(range.from)
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}{' '}
            -{' '}
            {new Date(range.to)
              .toLocaleString('ro-RO', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })
              .replace(/\//g, '-')}
          </>
        );
    }
  }

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, xAxis, yAxis1, yAxis2]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }
    const height = parseInt(getCSS(chartRef.current, 'height'));

    // if (groupedArray?.length > 0 && timeGroupSelected > 0) {
    //   const newXAxis = xAxis.filter((item) => groupedArray.filter((g) => g.waitingTime === timeGroupSelected)[0].locations.includes(item));
    //   const newXAxisIndexes = newXAxis.map((item) => {
    //     return xAxis.findIndex((x) => x === item);
    //   });
    //   const newYAxis1 = yAxis1.filter((_, index) => newXAxisIndexes.includes(index));
    //   const newYAxis2 = yAxis2.filter((_, index) => newXAxisIndexes.includes(index));

    //   const chart = new ApexCharts(chartRef.current, getChartOptions(height, newXAxis, newYAxis1, newYAxis2));
    //   if (chart) {
    //     chart.render();
    //   }

    //   return chart;
    // }
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(
        height,
        xAxis.map((name) => parseInt(name).toString()),
        yAxis1,
        yAxis2
      )
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  // useEffect(() => {
  //   const combinedArray = xAxis.map((location, index) => ({
  //     location: location,
  //     waitingTime: orderByType === 'Queue duration' ? yAxis2[index] : yAxis1[index],
  //   }));

  //   // Step 2: Group by waiting time and count locations
  //   const waitingTimeMap = combinedArray.reduce((acc: any, item) => {
  //     if (acc[item.waitingTime]) {
  //       acc[item.waitingTime].count += 1;
  //       acc[item.waitingTime].locations.push(item.location);
  //     } else {
  //       acc[item.waitingTime] = { count: 1, locations: [item.location] };
  //     }
  //     return acc;
  //   }, {});

  //   // Step 3: Convert the map to an array of the desired format
  //   const groupedArray = Object.entries(waitingTimeMap).map(([waitingTime, data]: [string, any]) => ({
  //     waitingTime: parseInt(waitingTime),
  //     count: data.count,
  //     locations: data.locations,
  //   }));

  //   // Optional: Sort the grouped array by waiting time
  //   groupedArray.sort((a, b) => a.waitingTime - b.waitingTime);
  //   setGroupedArray(groupedArray);

  //   setTimeGroupAvailable(groupedArray.map((item) => item.waitingTime));
  // }, [orderByType]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5 pe-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Average times</span>
          <span className='text-muted fw-semibold fs-7'>{oneLocationSelected ? activeLocation.value : 'All locations'}</span>
        </h3>
        <div className='d-flex align-items-center flex-shrink-0'>
          <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-3 d-none d-md-block'>Filtered by:</span>
          <div className='flex-shrink-0 '>
            <ul className='nav'>
              <li className='nav-link bg-light text-muted rounded fw-semibold fs-7 px-2 me-1'>
                {selectedRange} |{' '}
                {hourParameters.from === '0' && hourParameters.to === '24' ? '24h' : hourParameters.from + ':00' + ' - ' + hourParameters.to + ':00'}
              </li>
            </ul>
          </div>
          {!oneLocationSelected && (
            <>
              <ReactBootstrap.DropdownButton
                className='pe-1'
                id='dropdown-type'
                title={orderByType !== '' ? orderByType : 'Order by type'}
                variant='secondary'
                size='sm'
              >
                <ReactBootstrap.Dropdown.Item
                  onClick={() => {
                    setOrderByType('');
                    setTimeGroupSelected(0);
                  }}
                >
                  All
                </ReactBootstrap.Dropdown.Item>
                <ReactBootstrap.Dropdown.Item
                  onClick={() => {
                    setOrderByType('Queue duration');
                    setTimeGroupSelected(0);
                  }}
                >
                  Queue duration
                </ReactBootstrap.Dropdown.Item>
                <ReactBootstrap.Dropdown.Item
                  onClick={() => {
                    setOrderByType('Response time');
                    setTimeGroupSelected(0);
                  }}
                >
                  Response time
                </ReactBootstrap.Dropdown.Item>
              </ReactBootstrap.DropdownButton>
              {orderByType !== '' && timeGroupAvailable.length > 0 && (
                <ReactBootstrap.DropdownButton
                  id='dropdown-times'
                  title={timeGroupSelected !== 0 ? `${timeGroupSelected} minutes` : 'Time groups'}
                  variant='secondary'
                  size='sm'
                  drop='down-centered'
                >
                  <ReactBootstrap.Dropdown.Item
                    onClick={() => {
                      setTimeGroupSelected(0);
                    }}
                  >{`All locations`}</ReactBootstrap.Dropdown.Item>
                  {timeGroupAvailable.map(
                    (item) =>
                      item > 0 && (
                        <ReactBootstrap.Dropdown.Item
                          onClick={() => {
                            setTimeGroupSelected(item);
                          }}
                        >{`${item} minutes - ${
                          groupedArray?.filter((g) => g.waitingTime === item)[0].locations.length
                        } location(s)`}</ReactBootstrap.Dropdown.Item>
                      )
                  )}
                </ReactBootstrap.DropdownButton>
              )}
            </>
          )}
        </div>
      </div>
      <div className='card-body pt-0'>
        {loaded ? <div ref={chartRef} id='kt_charts_widget_1_chart' style={{ height: '450px' }} /> : <LoadingPage />}
      </div>
    </div>
  );
};

export { ChartsWidget15 };

function getChartOptions(height: number, xAxis: string[], yAxis1: number[], yAxis2: number[]): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500');
  const borderColor = getCSSVariableValue('--kt-gray-200');
  const baseColor = getCSSVariableValue('--kt-primary');
  const secondaryColor = getCSSVariableValue('--kt-gray-300');

  return {
    series: [
      {
        name: 'Response time',
        data: yAxis1,
      },
      {
        name: 'Queue duration',
        data: yAxis2,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700,
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
          fontWeight: 700,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return numberFormat(val) + ' minutes';
        },
      },
    },
    colors: ['#7239EA', '#F1416C'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
