import callApiAxiosClient from "../../axios/axios_client";


function reportProblem(subject: string, description: string): Promise<void> {
    const token = JSON.parse(localStorage.getItem('user')!);
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<void>({
        method: "post",
        url: "support/report-a-problem",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        data: {
            subject: subject,
            description: description
        }
    })
}
export default reportProblem;