import callApiAxiosClient from "../../axios/axios_client";
import { personList } from "../../models";


async function getDistinctPersons(): Promise<string[]> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<string[]>({
        method: "get",
        url: "programmability/get-distinct-persons",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization

        }
    })
}
export default getDistinctPersons;