import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location } from '../web_api/models';

export interface locationsState {
  list: Location[];
}

const initialState: locationsState = {
  list: localStorage.getItem('cachedLocationList') != null ? JSON.parse(localStorage.getItem('cachedLocationList') ?? '') : [],
};

export const cachedLocations = createSlice({
  name: 'cachedLocations',
  initialState,
  reducers: {
    setCachedLocationList: (state, action: PayloadAction<locationsState>) => {
      state.list = action.payload.list;
      localStorage.setItem('cachedLocationList', JSON.stringify(action.payload.list));
    },
  },
});

export const { setCachedLocationList } = cachedLocations.actions;

export const cachedLocationsReducer = cachedLocations.reducer;
