import { useEffect } from "react"
import useGet from "../../hooks/Common/useGet"
import useToolbarSetter from "../../hooks/Common/useToolbarSetter"
import LoadingPage from "../../layout/LoadingPage"
import { setcachedCameraModelList } from "../../redux/cameraModelListReducer"
import getCameraModels from "../../web_api/methods/settings/cameraModels/getCameraModels"
import CameraModelsTable from "./CameraModelsTable"


const CameraModels = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar(
      'camera-models',
      `Camera Models`
    )
  })
  const { loading } = useGet([], getCameraModels, setcachedCameraModelList)

  return (
    <>
      {
        loading ?
          <LoadingPage /> :

          <CameraModelsTable />

      }
    </>
  )
}

export default CameraModels;