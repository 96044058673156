import callApiAxiosClient from '../../axios/axios_client';

function deleteTrainingDetectionRegions(deletedPolygonIds: string[]): Promise<string> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<string>({
    method: 'post',
    url: 'training/images',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      detectionIds: deletedPolygonIds,
    },
  });
}
export default deleteTrainingDetectionRegions;
