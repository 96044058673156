import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGet from '../hooks/Common/useGet';
import getCameras from '../web_api/methods/settings/cameras/getCameras';
import { setCachedCameraList } from '../redux/camerasListReducer';
import { RootState } from '../redux/store';
import { setSelectedDetectionType } from '../redux/selectedDetectionTypeReducer';
import { setValue as setSelectedCamera } from '../redux/selectedCameraReducer';
import { setValue as setActiveLocation } from '../redux/activeLocationReducer';

const ToolbarLocationsCamerasAlgorithms = () => {
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations.list);
  const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
  const cachedDetectionTypeList = useSelector((state: RootState) => state.detectionList.list);

  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
  const selectedDetectionType = useSelector((state: RootState) => state.selectedDetectionType);
  const [loading, setLoading] = useState<boolean>(false);

  useGet([], getCameras, setCachedCameraList);

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
  });

  const loadCameras = async () => {
    const cameras = await getCameras({ locationId: parseInt(activeLocation.id) });
    dispatch(setCachedCameraList({ list: cameras }));
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    loadCameras();
  }, [activeLocation.value]);

  return (
    <div className='d-flex flex-column flex-md-row gap-3 gap-md-0 align-items-center mt-6 mt-md-0 w-100 w-md-auto'>
      {/* begin::Wrapper */}

      <div className='d-flex align-items-center flex-shrink-0 mx-2 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Location:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75 w-md-auto'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={activeLocation.value}
          onChange={(e) => {
            dispatch(
              setActiveLocation({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
            dispatch(
              setSelectedCamera({
                value: 'All',
                id: '0',
              })
            );
            dispatch(
              setSelectedDetectionType({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {cachedLocationList.map((location) => {
            return (
              <option key={location.id} value={location.name || ''} data-id={location.id}>
                {location.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Camera:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedCamera.value}
          disabled={activeLocation.value === 'All' || loading}
          onChange={(e) => {
            dispatch(
              setSelectedCamera({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
            dispatch(
              setSelectedDetectionType({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {activeLocation.value === 'All'
            ? cachedCameraList.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>
                  {item.id + ' - ' + item.name}
                </option>
              ))
            : cachedCameraList
                .filter((camera) => camera.location_id === Number(activeLocation.id))
                .map((item) => {
                  return (
                    <option key={item.id} value={item.name} data-id={item.id}>
                      {item.id + ' - ' + item.name}
                    </option>
                  );
                })}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 mx-md-2 w-100 w-md-auto'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 w-25 w-md-auto'>Detection:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid w-75'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedDetectionType.value}
          onChange={(e) => {
            dispatch(
              setSelectedDetectionType({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {[...cachedDetectionTypeList]
            .sort((a, b) => a.detection_name.localeCompare(b.detection_name))
            .map((detection) => {
              return (
                <option data-id={detection.detection_type} key={detection.detection_name} value={detection.detection_name}>
                  {detection.detection_name}
                </option>
              );
            })}
        </select>
      </div>
      {/* end::Wrapper */}
    </div>
  );
};

export { ToolbarLocationsCamerasAlgorithms };
