import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface detectionType {
  detection_type: number
  detection_type_name: string
}
export interface detectionTypesState {
  list: detectionType[]
}
const initialState: detectionTypesState = {
  list: localStorage.getItem("detectionTypes") != null ? JSON.parse(localStorage.getItem("detectionTypes") ?? '') : []
};

export const detectionTypes = createSlice({
  name: 'detectionTypes',
  initialState,
  reducers: {
    setDetectionTypes: (state, action: PayloadAction<detectionTypesState>) => {
      state.list = action.payload.list
      localStorage.setItem("detectionTypes", JSON.stringify(action.payload.list))
    },
  },
});

export const { setDetectionTypes } = detectionTypes.actions;

export const detectionTypesReducer = detectionTypes.reducer;
