import { useState } from 'react';
import lost2fa from '../../web_api/methods/account/2FA/lost-2fa';
import { CommonModal } from '../Common/CommonModal';
import { toast } from 'react-toastify';

type TwoFactorAuthLostModalProps = {
  show: boolean;
  toggleModal: () => void;
  toggleLoginModal?: () => void;
};

const TwoFactorAuthLostModal: React.FC<TwoFactorAuthLostModalProps> = ({ show, toggleModal, toggleLoginModal }) => {
  const [mail, setMail] = useState({
    value: '',
    isValid: '',
  });

  const [isCalling, setIsCalling] = useState(false);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(mail.value);
    return isEmailValid;
  };

  const handleToggleModal = () => {
    toggleModal();
    setTimeout(
      () => {
        setMail({
          value: '',
          isValid: '',
        });
      },
      show ? 200 : 0
    );
  };

  const handleSubmit = async () => {
    setIsCalling(true);
    const isEmailValid = validateEmail();

    if (!isEmailValid) {
      setMail({
        ...mail,
        isValid: isEmailValid ? 'is-valid' : 'is-invalid',
      });
      setIsCalling(false);
      return;
    }
    try {
      let lostTwoFactor = await lost2fa(mail.value);
      toast.success(`Email sent successfully!`, {
        position: 'top-right',
        theme: 'colored',
      });
      handleToggleModal();
      setMail({ ...mail, isValid: '' });
      setIsCalling(false);
    } catch (e) {
      setMail({ ...mail, isValid: '' });
      setIsCalling(false);
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  return (
    <CommonModal
      title='Two-factor Authentification'
      show={show}
      disabled={isCalling}
      showModalCallback={handleToggleModal}
      closeButton={true}
      confirmButton={true}
      bodyClass='flex-column w-100'
      closeButtonOptions={{
        icon: 'bi bi-x',
        variant: 'secondary',
        class: '',
      }}
      confirmButtonOptions={{
        icon: 'bi bi-check',
        variant: 'custom',
        class: 'bg-primary text-white',
        text: 'Send email',
        onClick: handleSubmit,
      }}
    >
      <div className='px-4 py-1 px-sm-12 py-sm-2 d-flex flex-column justify-content-center align-items-center gap-5'>
        <i className='bi bi-lock' style={{ fontSize: '15em' }} />
        <div className='w-100'>
          <input
            type='email'
            autoFocus
            value={mail.value}
            onChange={(e) => setMail({ ...mail, value: e.target.value })}
            className={`form-control form-control-lg form-control-solid ${mail.isValid}`}
            placeholder='Email address'
            name='code'
          />
        </div>
      </div>
      <p className='fs-8 px-6 px-sm-14 mt-2 fw-semibold text-gray-400 text-break'>
        Enter your email address. If the email is valid, it will receive an email with instructions on how to reset your 2FA.
      </p>
      {toggleLoginModal && (
        <div
          className='px-4 px-sm-14 link-primary cursor-pointer fs-7 text-end mt-2'
          onClick={() => {
            handleToggleModal();
            if (toggleLoginModal) {
              toggleLoginModal();
            }
          }}
        >
          Try inserting another code
        </div>
      )}
    </CommonModal>
  );
};

export default TwoFactorAuthLostModal;
