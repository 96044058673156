import { CommonCard } from '../Common/CommonCard/CommonCard';

type DetectionServerCardAddProps = {
  toggleModal: () => void;
};

const DetectionServerCardAdd: React.FC<DetectionServerCardAddProps> = ({ toggleModal }) => {
  return (
    <div className='animated zoomIn col-12 col-md-6 col-xxl-4 cursor-pointer justify-self-end' onClick={toggleModal}>
      <CommonCard
        containerClass={`card my-3 animated h-min-100px  ${window.innerWidth > 576 ? 'hover-grow' : ''} w-100 mb-0`}
        background='linear-gradient(to bottom, #666666, #999999)'
        headingLarge='Add a new detection server'
        headingSmall='&nbsp;'
        items={
          <>
            <div className='text-center p-4 mb-10 d-flex justify-content-center align-items-center'>
              <i className='bi bi-hdd-network' style={{ fontSize: '9em' }}></i>
            </div>
          </>
        }
        buttons={<></>}
      />
    </div>
  );
};

export default DetectionServerCardAdd;
