import callApiAxiosClient from '../../axios/axios_client';
import { trainingSession } from '../../models';

function modifyTrainingSession(session: trainingSession): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'get',
    url: 'training/session',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: session,
  });
}
export default modifyTrainingSession;
