import callApiAxiosClient from '../../axios/axios_client';
import { categoryList } from '../../models';

function getCategories(): Promise<categoryList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<categoryList>({
    method: 'get',
    url: 'categories',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getCategories;
