import React, { useEffect, useState } from 'react';
import { AlertTypesChart } from './AlertTypesChart';
import { convertStringToEnumValue, upperCaseFirstLetterOfString } from '../../web_api/methods/helpers';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getAlertsByType from '../../web_api/methods/hsse/getAlertsByType';
import { interval } from '../../web_api/models';
type Props = {
  className?: string;
  filter: interval;
};

const AlertTypes: React.FC<Props> = ({ className, filter }) => {
  const dayWeekMonthYear = convertStringToEnumValue(filter); //dependency
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  //dependencies
  const [values, setValues] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    loadGenderReports(new Date(), new Date());
    // console.log(filter)
  }, [dayWeekMonthYear, activeLocation, selectedUser]);
  let loadGenderReports = async (from: Date, to: Date) => {
    try {
      setLoaded(false);
      // let loadGender: genderResponse = await peopleCountingGender(from, to);
      //call here
      const response = await getAlertsByType(filter);

      setValues(response.map((item) => item.detections_number));
      setLabels(response.map((item) => item.name));
      setTotal(response.reduce((acc, item) => acc + item.detections_number, 0));
      setLoaded(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={`card h-100 ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{upperCaseFirstLetterOfString(dayWeekMonthYear)}ly - Alert Types</span>
          <span className='text-muted fw-semibold fs-7'>Alert types percentage</span>
        </h3>
      </div>
      <div className='card-body p-0'>
        {values.length === 0 && (
          <div className='d-flex flex-row align-items-center justify-content-center gap-4 h-200px w-100'>
            <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
            <div className='fs-3 text-secondary-emphasis'>No alerts recorded</div>
          </div>
        )}
        {values.length > 0 && (
          <div className='d-flex flex-row w-sm-100 h-100 overflow-hidden'>
            <div className='d-flex flex-column m-auto'>
              <AlertTypesChart className='h-md-100 mb-xl-5' values={values} labels={labels} total={total} loaded={loaded} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export { AlertTypes };
