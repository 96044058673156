import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface detectionServerInListState {
    id: number,
    tenant_id: number,
    location_id: number,
    name: string
}

export interface detectionServerList {
    list: detectionServerInListState[]
}

const initialState: detectionServerList = {
    list: localStorage.getItem("cachedDetectionServerList") != null ? JSON.parse(localStorage.getItem("cachedDetectionServerList") ?? '') : []
}

export const detectionServerList = createSlice({
    name: 'detectionServerList',
    initialState,
    reducers: {
        setCachedDetectionServerList: (state, action: PayloadAction<detectionServerList>) => {
            state.list = action.payload.list
            localStorage.setItem("cachedDetectionServerList", JSON.stringify(action.payload.list))
        },
    },
});

export const { setCachedDetectionServerList } = detectionServerList.actions;

export const detectionServerListReducer = detectionServerList.reducer;
