import callApiAxiosClient from "../../../axios/axios_client";
import { cameraModelList } from "../../../models";

async function getCameraModels(id?: number): Promise<cameraModelList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<cameraModelList>({
        method: "get",
        url: "settings/get-camera-models",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            id: id
        }
    })
}
export default getCameraModels;