import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { cameraDetectionInList } from "../../web_api/models";
import DetectionAddFormModal from "./DetectionAddFormModal"
import CommonNewUserCard from "../Common/CommonNewUserCard";

type DetectionAddProps = {
    onDetectionAdd: (detection: cameraDetectionInList) => void;
    dataLength?: number,
    toggleModal: () => void,
    show: boolean
}

const DetectionAdd: React.FC<DetectionAddProps> = ({
    onDetectionAdd,
    dataLength,
    show,
    toggleModal
}) => {

    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    const cachedLocationsList = useSelector((state: RootState) => state.cachedLocations.list);
    const cachedCameraModelList = useSelector((state: RootState) => state.cameraModelList.list);
    const cachedDetectionServerList = useSelector((state: RootState) => state.detectionServerList.list);

    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const selectedCamera = useSelector((state: RootState) => state.selectedCamera);
    const selectedDetectionType = useSelector((state: RootState) => state.selectedDetectionType);

    const isFirstDetection =
        cachedDetectionServerList.length > 0 &&
        cachedCameraList.length > 0 &&
        cachedLocationsList.length > 0 &&
        cachedCameraModelList.length > 0 &&
        activeLocation.id === '0' &&
        selectedCamera.id === '0' &&
        selectedDetectionType.id === '0' &&
        dataLength === 0;
    
    const noDetectionsFound =
        cachedDetectionServerList.length > 0 &&
        cachedCameraList.length > 0 &&
        cachedLocationsList.length > 0 &&
        cachedCameraModelList.length > 0 &&
        dataLength === 0;

    return (
        <>
            {isFirstDetection &&
                <CommonNewUserCard
                    title="Add your first detection!"
                    content={`Click on this card to add your first detection.\n
                        Select the values from the dropdown menus and press the "Confirm" button to register a detection.`}
                    className="w-xl-50 mx-auto"
                    icon="bi bi-eye"
                    buttonText="Add detection"
                    onClick={toggleModal}
                />
            }

            {noDetectionsFound && !isFirstDetection && 
                <CommonNewUserCard
                    title="Oops!"
                    content="No detections found for this selection, please try another one."
                    className="w-xl-50 mx-auto"
                    icon="bi bi-info"
                />
            }
            
            {!isFirstDetection && !noDetectionsFound &&
                <div className='d-flex flex-column flex-md-row p-1 flex-end'>
                    <button className="btn btn-primary" onClick={toggleModal}>
                        Add Detection
                    </button>
                </div>
            }

            <DetectionAddFormModal
                show={show}
                handleToggleModal={toggleModal}
                onDetectionAdd={onDetectionAdd}
            />
        </>

    )
}

export default DetectionAdd