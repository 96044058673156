import React, { useEffect, useState } from 'react';
import { RecentAlertItem } from './RecentAlertItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getDetections from '../../web_api/methods/alerts/getDetections';
import { detectionItemInList } from '../../web_api/models';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import { setAlerts } from '../../redux/alertsReducer';

type Props = {
  className?: string;
};

const RecentAlerts: React.FC<Props> = ({ className }) => {
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const dispatch = useDispatch();
  const alerts = useSelector((state: RootState) => state.alerts);
  const [loading, setLoading] = useState(false);
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const computeUrl = async (alert: detectionItemInList) => {
    const res = await getAzureFile(alert.filename);
    return res;
  };

  const loadInitialData = async () => {
    setLoading(true);
    let res = await getDetections(undefined, undefined, 1, 5, new Date(Date.now()), new Date(Date.now()));
    // set each alert filename to computeUrl(filename)

    res = await Promise.all(
      res.map(async (alert) => {
        alert.filename = await computeUrl(alert);
        return alert;
      })
    );

    dispatch(setAlerts(res));
    setLoading(false);
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  const getReadableDate = (date: Date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  };

  return (
    <div className={`card h-100 ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent alerts</span>
          <span className='text-muted fw-semibold fs-7'>Last 5 recorded alerts</span>
        </h3>
      </div>
      <div className='card-body pt-0'>
        <div className='h-100'>
          {alerts.alerts.length > 0 &&
            alerts.alerts.slice(0, 5).map((alert) => {
              return (
                <RecentAlertItem
                  key={`${alert.detection_description}-${alert.detection_time}`}
                  text={alert.detection_description}
                  date={getReadableDate(new Date(alert.detection_time))}
                />
              );
            })}
          {alerts.alerts.length === 0 && (
            <div className='d-flex flex-row align-items-center justify-content-center gap-4 h-100'>
              <i className='bi bi-exclamation-circle text-secondary-emphasis' style={{ fontSize: '2rem' }} />
              <div className='fs-3 text-secondary-emphasis'>No alerts recorded</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export { RecentAlerts };
