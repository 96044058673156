import moment from 'moment';
import callApiAxiosClient from '../../axios/axios_client';
import { detectionList } from '../../models';

async function getDetections(
  detection_id?: number,
  location_id?: number,
  page_index?: number,
  page_size?: number,
  fromParam?: Date,
  toParam?: Date,
  only_alerts?: boolean,
  user_id?: string
): Promise<detectionList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const from = moment(fromParam).format('YYYYMMDD');
  const to = moment(toParam).format('YYYYMMDD');
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<detectionList>({
    method: 'get',
    url: 'detections',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    params: {
      detection_id: detection_id,
      location_id: location_id,
      page_index: page_index,
      page_size: page_size,
      from_date: from,
      to_date: to,
      only_alerts: only_alerts,
      user_id: '0',
    },
  });
}
export default getDetections;
