import { Link, useParams } from 'react-router-dom';
import CameraInfoSettings from '../../Cameras/CameraInfo/CameraInfoSettings';
import { useEffect, useState } from 'react';
import DetectionInfoSettings from './DetectionInfoSettings';
import useGet from '../../../hooks/Common/useGet';
import getDetections from '../../../web_api/methods/settings/detections/getDetections';
import LoadingPage from '../../../layout/LoadingPage';
import useToolbarSetter from '../../../hooks/Common/useToolbarSetter';
import getSnapshot from '../../../web_api/methods/files/getSnapshot';
import useLoadImage from '../../../hooks/FileManagement/useLoadImage';
import { ELoadType } from '../../../web_api/models';
import updateTrackingStatus from '../../../web_api/methods/settings/detections/updateTrackingStatus';
import getCameras from '../../../web_api/methods/settings/cameras/getCameras';
import { addToCachedCameraList } from '../../../redux/camerasListReducer';
import { useDispatch } from 'react-redux';

const DetectionInfo = () => {
  const { setToolbar } = useToolbarSetter();
  useEffect(() => {
    setToolbar('detections-info', `Detections - ${id}`);
  });

  const [camera, setCamera] = useState<any>();
  const dispatch = useDispatch();

  const loadInit = async (id: number) => {
    const resCam = await getCameras({ id: id });
    setCamera(resCam[0]);
    dispatch(addToCachedCameraList(resCam[0]));
  };

  const params = useParams();

  const id = Number(params.id);

  const { data: detection, loading } = useGet([], () => getDetections({ id: id }));

  useEffect(() => {
    if (!loading) loadInit(detection?.[0]?.camera_id!);
  }, [loading]);

  const detectionName = detection?.[0]?.detection_name;

  console.log('camera', camera);

  console.log(detection?.[0]);

  const [selectedSettings, setSelectedSettings] = useState('detection');

  const handleSettingsChange = (setting: string) => {
    setSelectedSettings(setting);
  };

  const { loadingImage, url } = useLoadImage(camera ? camera.id : undefined, true, getSnapshot, ELoadType.BULK);

  const [isCheckAllChecked, setIsCheckAllChecked] = useState<boolean | undefined>(detection?.[0].has_tracking);

  const updateTracking = async () => {
    try {
      if (isCheckAllChecked === undefined) return;
      await updateTrackingStatus(id, isCheckAllChecked);
    } catch (error) {}
  };

  useEffect(() => {
    if (isCheckAllChecked !== detection?.[0].has_tracking) updateTracking();
  }, [isCheckAllChecked]);

  useEffect(() => {
    setIsCheckAllChecked(detection?.[0].has_tracking);
  }, [detection]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <div className='container pt-3 px-4'>
          <div className='d-flex p-1'>
            <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections`}>
              <button className='btn btn-primary mb-5'>
                <i className='bi bi-arrow-return-left'></i>
                Return
              </button>
            </Link>
          </div>

          <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details' className='collapse show'>
              <div className='card-body border-top p-5'>
                <div className='row'>
                  <div className='col-lg-6 position-relative'>
                    {loadingImage ? <LoadingPage /> : <img src={url} alt='Camera Image' className='img-fluid rounded w-100' />}
                  </div>
                  <div className='col-lg-3 col-xxl-4 order-lg-2 mt-3 mb mt-sm-2'>
                    <p className='fw-bold mb-2'>{`${camera?.name} - ${detectionName}`}</p>
                    <div
                      className='form-check form-check-sm form-check-custom form-check-solid my-8 my-sm-0 cursor-pointer'
                      style={{ userSelect: 'none' }}
                      onClick={() => {
                        setIsCheckAllChecked((prev) => !prev);
                      }}
                    >
                      Tracking
                      <input id='check-all' checked={isCheckAllChecked} className='form-check-input widget-9-check ms-2' type='checkbox' value='1' />
                      <span className='d-sm-none ms-5 text-dark'>Check all servers</span>
                    </div>
                  </div>
                  <div className='col-lg-3 col-xxl-2 order-lg-3 mt-2'>
                    <div className='d-flex justify-content-lg-end mb-3'>
                      <Link to={`${process.env.REACT_APP_HOMEPAGE}/detections/${id}/${camera?.id}/annotation`}>
                        <button type='submit' className='btn btn-primary me-3'>
                          Annotate
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Settings</span>
              </h3>
              <div className='d-flex align-items-center flex-shrink-0'>
                <div className='flex-shrink-0'>
                  <ul className='nav'>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${
                          selectedSettings === 'detection' ? 'active' : ''
                        }`}
                        data-bs-toggle='tab'
                        onClick={() => handleSettingsChange('detection')}
                      >
                        Detection
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a
                        className={`nav-link btn btn-sm btn-color-muted btn-active-color-primary btn-active-light fw-semibold fs-7 px-4 me-1 ${
                          selectedSettings === 'camera' ? 'active' : ''
                        }`}
                        data-bs-toggle='tab'
                        onClick={() => handleSettingsChange('camera')}
                      >
                        Camera
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='card-body p-0'>
              <div className={`settings-panel ${selectedSettings === 'detection' ? '' : 'd-none'}`}>
                {detection ? <DetectionInfoSettings detection={detection[0]} /> : null}
              </div>
              <div className={`settings-panel ${selectedSettings === 'camera' ? '' : 'd-none'}`}>
                {detection && selectedSettings === 'camera' ? <CameraInfoSettings id={detection[0].camera_id} /> : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetectionInfo;
