import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface queueDurationItemInList {
  id:number
  queue_duration: number
  name: string
}
export interface queueDurationListState {
  list: queueDurationItemInList[]
}
const initialState: queueDurationListState = {
  list: localStorage.getItem("queueDurationList") != null ? JSON.parse(localStorage.getItem("queueDurationList") ?? '') : []
};

export const queueDurationList = createSlice({
  name: 'queueDurationList',
  initialState,
  reducers: {
    setValue: (state, action: PayloadAction<queueDurationListState>) => {
      state.list = action.payload.list
      localStorage.setItem("queueDurationList", JSON.stringify(action.payload.list))
    },
  },
});

export const { setValue } = queueDurationList.actions;

export const queueDurationListReducer = queueDurationList.reducer;
