import { loginResBodySuccess } from "common/api/services/authService/models";
import callApiAxiosClient from "../../axios/axios_client";

function registerUser(email: string, password: string, confirmPassword: string, company: string, country: string): Promise<loginResBodySuccess> {
    return callApiAxiosClient<loginResBodySuccess>({
        method: "post",
        url: "account/register",
        headers: {
            "Content-Type": "Application/json",
        },
        data: {
            user_name: email,
            password: password,
            confirm_password: confirmPassword, 
            company: company,
            country: country,
        }
    }, false)
}
export default registerUser;