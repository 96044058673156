import { lprLocationList } from '../../models';
import callApiAxiosClient from '../../axios/axios_client';

function getLPRLocations(): Promise<lprLocationList> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<lprLocationList>({
    method: 'get',
    url: 'programmability/get-lpr-locations',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getLPRLocations;
