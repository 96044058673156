import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { lprLocationList } from '../web_api/models';

export interface Location {
  id: number;
  name: string;
  lat: number;
  long: number;
}

export interface TrafficState {
  location: string;
  locations: lprLocationList;
  camera: string;
  date: string;
}
const initialState: TrafficState = {
  locations: [],
  location: 'All',
  camera: 'All',
  date: 'All',
};

export const traffic = createSlice({
  name: 'traffic',
  initialState,
  reducers: {
    setCamera: (state, action: PayloadAction<string>) => {
      state.camera = action.payload;
    },
    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setLocations: (state, action: PayloadAction<lprLocationList>) => {
      state.locations = action.payload;
    },
  },
});

export const { setCamera, setDate, setLocation, setLocations } = traffic.actions;

export const trafficReducer = traffic.reducer;
