import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface selectedDetectionTypeState {
    value: string
    id: string
}

const initialState: selectedDetectionTypeState = {
    id: localStorage.getItem('selectedDetectionType')!?.toString() != null ? localStorage.getItem('selectedDetectionType')!?.toString() : '0',
    value: localStorage.getItem('selectedDetectionType')!?.toString() != null ? localStorage.getItem('selectedDetectionType')!?.toString() : 'All'
};

export const selectedDetectionType = createSlice({
    name: 'selectedDetectionType',
    initialState,
    reducers: {
        setSelectedDetectionType: (state, action: PayloadAction<selectedDetectionTypeState>) => {
            state.id = action.payload.id
            state.value = action.payload.value
            localStorage.setItem("selectedDetectionTypeId", action.payload.id)
            localStorage.setItem("selectedDetectionTypeName", action.payload.value)

        },
    },
});

export const { setSelectedDetectionType } = selectedDetectionType.actions;

export const selectedDetectionTypeReducer = selectedDetectionType.reducer;
