import callApiAxiosClient from '../../axios/axios_client';

function deleteAreaInPlan(detectionIds: string[]): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<void>({
    method: 'delete',
    url: 'settings/area-in-plan',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      detection_ids: detectionIds,
    },
  });
}
export default deleteAreaInPlan;
