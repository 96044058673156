import { Link } from 'react-router-dom';
import logo from '../assets/images/logo-white.svg';
import penny from '../assets/images/Penny-Logo.png';
import securifai from '../assets/images/logo-white.svg';
import orange from '../assets/images/logo_orange_business.svg';

import { useLayout } from './core';

import { useDispatch } from 'react-redux';

export default function SidebarLogo() {
  const { config } = useLayout();

  const closeMobileSidebar = () => {
    const sidebar = document.getElementById('kt_app_sidebar');
    sidebar?.classList.remove('drawer', 'drawer-start', 'drawer-on');
  };

  // const logo = process.env.REACT_APP_TENANT === 'penny' ? penny : securifai;
  const logo = securifai;

  return (
    <>
      <div className='py-3 d-flex justify-content-center' id='kt_app_sidebar_logo' style={{ borderBottom: '1px dashed #393945' }}>
        <Link className='d-flex flex-column justify-content-center ps-5 gap-1' to={process.env.REACT_APP_HOMEPAGE + '/home'}>
          {/* <img
            alt='Logo'
            src={logo}
            className='h-100'
          /> */}
          {process.env.REACT_APP_TENANT === 'penny' ? <img alt='Logo' src={penny} className={`h-50px rounded`} /> : <img alt='Logo' src={logo} />}
          {/* <div className='d-flex flex-row gap-3 align-items-start'>
            <h4 className='text-light fs-base text-nowrap'>powered by</h4>
            <img src={orange} alt='Orange' />
          </div> */}
        </Link>
        <div className='d-lg-none'>
          <i className='bi bi-x-lg' style={{ fontSize: '1.5rem' }} onClick={closeMobileSidebar} />
        </div>
        {/* {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          id='kt_app_sidebar_toggle'
          className={clsx(
            'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
            { active: appSidebarDefaultMinimizeDefault }
          )}
          data-kt-toggle='true'
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target='body'
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <i className='svg-icon-2 rotate-180 bi bi-chevron-double-left' ></i>
        </div>
      )} */}
      </div>
    </>
  );
}
