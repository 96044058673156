import React from 'react';
import { PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import headset from '../assets/images/headset-icon.png'
import queue from '../assets/images/queue-icon.png'
// import logo from '../assets/images/logo-white.svg'
import logo from '../assets/images/Penny-Logo.png'

import { audioAlertsList, avgTimeCashRegisterOpenList, mostQueueAlertsList, queueDurationList } from '../web_api/models';
import { numberToTimeFormat } from '../web_api/methods/helpers';
interface TableProps {
    headers: string[];
    data: audioAlertsList;
    headersQueueAlerts: string[];
    headersAvgTimes: string[];
    dataQueueAlerts: mostQueueAlertsList;
    location: { id: string, name: string };
    user_name: string;
    date: string
    totalQueueAlerts: string
    totalAudioAlerts: string
    responseTimeAll: string
    queueDurationAll: string
    responseTimeList: avgTimeCashRegisterOpenList
    queueDurationList: queueDurationList
    fileName: string
}

const styles = StyleSheet.create({
    page: {
        padding: 20,
    },
    title: {
        fontSize: 18,

    },
    subtitle: {
        fontSize: 13,
    },
    purple: {
        backgroundColor: '#7239ea',
    },
    red: {
        backgroundColor: '#f1416c',
    },
    circle: {
        color: 'white',
        width: '100px',
        height: '100px',
        borderRadius: 50,
        marginRight: 5,
        marginVertical: 10
    },
    basicInformationView: {
        padding: 5,
        borderStyle: 'solid',
        color: 'white',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        borderRadius: 5,
        width: '100%',
        marginVertical: 10
    },
    header: {
        padding: 15,
        width: '100%',
        marginVertical: 10,
        backgroundColor: '#393945'
    },
    basicInformationText: {
        fontSize: 10,
        marginVertical: 5,
    },
    icon: {
        width: '40px',
        height: '40px',
        color: 'white',
        marginHorizontal: 'auto',
        marginTop: 10

    },
    centered_text: {
        margin: 'auto'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    table: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        borderTop: 0,
        marginBottom: 10,
        borderRadius: 5
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCell: {
        fontSize: 10,
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: 5,
        marginBottom: 5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 3,
        paddingBottom: 3,
        flexGrow: 1,
        textAlign: 'left',
        width: '100%'
    },
    tableHeader: {
        fontSize: 12,
        backgroundColor: '#bfbfbf',
    },
});

const PDF: React.FC<TableProps> = ({ headers, data, location, user_name, date,
    dataQueueAlerts, headersQueueAlerts, totalQueueAlerts, totalAudioAlerts, headersAvgTimes,
    responseTimeAll, queueDurationAll, responseTimeList, queueDurationList, fileName }) => {

    return (
        <div>
            <View style={styles.header}>
                <View>
                    <View style={[styles.basicInformationView, styles.row]}>
                        <View>
                            <Text style={styles.basicInformationText}>
                                Email: <Text>{user_name}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Location: <Text>{location.id !== '0' ? (location.id + ' - ' + location.name) : (location.name)}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Date range: <Text>{date}</Text>
                            </Text>
                            <Text style={styles.basicInformationText}>
                                Issued: <Text>{new Date().toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}</Text>
                            </Text>
                        </View>
                        <Image style={{ width: '80px' }} src={logo} />

                    </View>
                </View>
            </View>
            {/* <Text style={styles.subtitle}>
                {location.id !== 0 ? (location.id + ' - ' + location.name) : (location.name)}
            </Text>
            <Text style={styles.subtitle}>
                {date}
            </Text> */}



            {/* <View style={styles.basicInformationView}>
                <Text>here we ll have a table</Text>
            </View> */}
            {/* cercuri */}
            <Text>Totals</Text>

            <View style={styles.row}>
                <View style={[styles.circle, styles.red]}>
                    <View style={styles.icon}>
                        <Image src={headset} />
                    </View>
                    <Text style={styles.centered_text}>{totalAudioAlerts}</Text>
                </View>
                <View style={[styles.circle, styles.purple]}>
                    <View style={styles.icon}>
                        <Image src={queue} />
                    </View>
                    <Text style={styles.centered_text}>{totalQueueAlerts}</Text>
                </View>
                <View style={[styles.circle, styles.red]}>
                    <View style={styles.icon}>
                        <Image src={headset} />
                    </View>
                    <Text style={styles.centered_text}>{responseTimeAll}</Text>
                </View>
                <View style={[styles.circle, styles.purple]}>
                    <View style={styles.icon}>
                        <Image src={queue} />
                    </View>
                    <Text style={styles.centered_text}>{queueDurationAll}</Text>
                </View>
            </View>

            {/* audio alerts */}
            <View style={styles.table}>
                <Text>Audio alerts</Text>
                <View style={styles.tableRow}>
                    {headers.map((header, index) => (
                        <Text
                            key={index}
                            style={[styles.tableCell, styles.tableHeader]}
                        >
                            {header}
                        </Text>
                    ))}
                </View>
                {(data !== undefined) && data.map((cellData, cellIndex) => (
                    <View style={styles.tableRow}>

                        <>
                            <Text
                                style={[styles.tableCell]}
                            >
                                {cellData.location_id}
                            </Text>
                            {(headers.length !== 3) && (<Text
                                style={[styles.tableCell]}
                            >
                                {dataQueueAlerts.find(x => x.id === cellData.location_id)?.name}
                            </Text>)}
                            <Text
                                style={[styles.tableCell]}
                            >
                                {cellData.total}
                            </Text>
                            <Text
                                style={[styles.tableCell]}
                            >
                                {cellData.time.length > 2 ? new Date(cellData.time).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' }) : `${cellData.time}:00`}
                            </Text>
                        </>
                    </View>

                ))}
                {/* {tableData.map((rowData, index) => (
                    <View key={index} style={styles.tableRow}>
                        {rowData.map((cellData, cellIndex) => (
                            <Text
                                key={cellIndex}
                                style={[styles.tableCell, (index === 0) ? styles.tableHeader : styles.tableCell]}
                            >
                                {cellData}
                            </Text>
                        ))}
                    </View>
                ))} */}
            </View>

            {/* queue alerts */}
            <View style={styles.table}>
                <Text>Queue alerts</Text>
                <View style={styles.tableRow}>
                    {headersQueueAlerts.map((header, index) => (
                        <Text
                            key={index}
                            style={[styles.tableCell, styles.tableHeader]}
                        >
                            {header}
                        </Text>
                    ))}
                </View>
                {(dataQueueAlerts !== undefined) && dataQueueAlerts.map((cellData, cellIndex) => (
                    <View style={styles.tableRow}>
                        {(headersQueueAlerts.length !== 3) && (<Text
                            style={[styles.tableCell]}
                        >
                            {cellData.id}
                        </Text>)}

                        <Text
                            style={[styles.tableCell]}
                        >
                            {cellData.name}
                        </Text>
                        <Text
                            style={[styles.tableCell]}
                        >
                            {cellData.value}
                        </Text>
                        <Text
                            style={[styles.tableCell]}
                        >
                            {new Date(cellData.day).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' })}
                        </Text>
                    </View>

                ))}
                {/* {tableData.map((rowData, index) => (
                    <View key={index} style={styles.tableRow}>
                        {rowData.map((cellData, cellIndex) => (
                            <Text
                                key={cellIndex}
                                style={[styles.tableCell, (index === 0) ? styles.tableHeader : styles.tableCell]}
                            >
                                {cellData}
                            </Text>
                        ))}
                    </View>
                ))} */}
            </View>

            {/* avg times */}
            <View style={styles.table}>
                <Text>Average times</Text>
                <View style={styles.tableRow}>
                    {headersAvgTimes.map((header, index) => (
                        <Text
                            key={index}
                            style={[styles.tableCell, styles.tableHeader]}
                        >
                            {header}
                        </Text>
                    ))}
                </View>
                {(responseTimeList !== undefined) && responseTimeList.map((cellData, cellIndex) => (
                    <View style={styles.tableRow}>
                        <Text
                            style={[styles.tableCell]}
                        >
                            {cellData.id}
                        </Text>

                        <Text
                            style={[styles.tableCell]}
                        >
                            {cellData.name}
                        </Text>
                        <Text
                            style={[styles.tableCell]}
                        >
                            {numberToTimeFormat(cellData.avgTime)}
                        </Text>
                        <Text
                            style={[styles.tableCell]}
                        >
                            {numberToTimeFormat(queueDurationList[cellIndex].queue_duration)}
                        </Text>
                    </View>

                ))}
                {/* {tableData.map((rowData, index) => (
                    <View key={index} style={styles.tableRow}>
                        {rowData.map((cellData, cellIndex) => (
                            <Text
                                key={cellIndex}
                                style={[styles.tableCell, (index === 0) ? styles.tableHeader : styles.tableCell]}
                            >
                                {cellData}
                            </Text>
                        ))}
                    </View>
                ))} */}
            </View>
        </div>
    );
};

export default function TablePDF({ headers, data, location, user_name, date,
    dataQueueAlerts, headersQueueAlerts, totalQueueAlerts, totalAudioAlerts, headersAvgTimes,
    responseTimeAll, queueDurationAll, responseTimeList, queueDurationList, fileName }: TableProps) {
    return (
        <div className='d-flex flex-column w-100 p-md-5'>
            <div className='d-flex flex-row'>
                <PDFViewer width={'100%'} height={document.documentElement.clientHeight * 0.65}>
                    <Document>
                        <Page size="A4" style={styles.page}>
                            <PDF
                                headers={headers}
                                data={data}
                                location={location}
                                user_name={user_name}
                                date={date}
                                dataQueueAlerts={dataQueueAlerts}
                                headersQueueAlerts={headersQueueAlerts}
                                totalQueueAlerts={totalQueueAlerts}
                                totalAudioAlerts={totalAudioAlerts}
                                headersAvgTimes={headersAvgTimes}
                                responseTimeAll={responseTimeAll}
                                queueDurationAll={queueDurationAll}
                                responseTimeList={responseTimeList}
                                queueDurationList={queueDurationList}
                                fileName={fileName}
                            />
                        </Page>
                    </Document>
                </PDFViewer>
            </div>
            <div className='d-flex flex-row'>

                <PDFDownloadLink document={<Document>
                    <Page size="A4" style={styles.page}>
                        <PDF
                            headers={headers}
                            data={data}
                            location={location}
                            user_name={user_name}
                            date={date}
                            dataQueueAlerts={dataQueueAlerts}
                            headersQueueAlerts={headersQueueAlerts}
                            totalQueueAlerts={totalQueueAlerts}
                            totalAudioAlerts={totalAudioAlerts}
                            headersAvgTimes={headersAvgTimes}
                            responseTimeAll={responseTimeAll}
                            queueDurationAll={queueDurationAll}
                            responseTimeList={responseTimeList}
                            queueDurationList={queueDurationList}
                            fileName={fileName}
                        />
                    </Page>
                </Document>} fileName={`${fileName}.pdf`} className='btn btn-primary my-3'>
                    {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                    }
                </PDFDownloadLink>
            </div>
        </div>
    );
}