import callApiAxiosClient from '../../../axios/axios_client';

function getLFLocations(): Promise<number[]> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient<number[]>({
    method: 'get',
    url: 'settings/lf-locations',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
  });
}
export default getLFLocations;
