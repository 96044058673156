import { useState } from 'react';
import { toast } from 'react-toastify';

import deleteAlertDetail from '../../web_api/methods/alerts/deleteAlertDetail';
import { CommonModal } from '../Common/CommonModal';

interface PersonsMassDelete {
  onShowMassDeleteModal: (personIds: string[]) => string[];
  onPersonsMassDelete: (id: string) => void;
  checkedPersons: string[];
}

const PersonsMassDelete: React.FC<PersonsMassDelete> = ({ checkedPersons, onPersonsMassDelete, onShowMassDeleteModal }) => {
  const [showDelete, setShowDelete] = useState(false);
  const [personIdss, setPersonIdss] = useState<string[]>([]);

  const handleToggleDeleteModal = () => {
    setShowDelete((prevShowModal) => !prevShowModal);
    if (!showDelete) {
      setShowDelete(true);
      const names = onShowMassDeleteModal(checkedPersons);
      setPersonIdss(names);
    }
  };

  const handleMassDelete = async () => {
    try {
      checkedPersons.map(deleteAlertDetail);
      checkedPersons.forEach(onPersonsMassDelete);
      handleToggleDeleteModal();
      toast.success('Alerts deleted successfully!', {
        position: 'top-right',
        theme: 'colored',
        autoClose: 2000,
      });
    } catch (e) {
      console.log(e);
      toast.error(`${e}`, {
        position: 'top-right',
        theme: 'colored',
      });
    }
  };

  return (
    <>
      {checkedPersons.length > 0 ? (
        <div className='d-flex flex-column flex-md-row p-1 flex-end'>
          <div className='btn btn-danger' onClick={() => handleToggleDeleteModal()}>
            Delete alerts
          </div>
        </div>
      ) : null}
      <CommonModal
        title={'Confirm'}
        show={showDelete}
        showModalCallback={handleToggleDeleteModal}
        closeButton={true}
        confirmButton={true}
        bodyClass='flex-column w-100'
        closeButtonOptions={{
          icon: 'bi bi-x',
          variant: 'secondary',
          class: '',
        }}
        confirmButtonOptions={{
          icon: 'bi bi-trash3',
          variant: 'custom',
          class: 'bg-danger text-white',
          onClick: handleMassDelete,
        }}
      >
        <div>Are you sure you want to delete the following alerts?</div>
        {personIdss.map((id) => (
          <div key={id} className='fw-bolder mt-3'>
            {id}
          </div>
        ))}
      </CommonModal>
    </>
  );
};

export default PersonsMassDelete;
