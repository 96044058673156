import { detectionItemInList, personItemInList } from "../../web_api/models"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"

type Props = {
    backgroundColor: string
    textColor: string
    alert: detectionItemInList
}

const AlertsCardInfo: React.FC<Props> = ({ backgroundColor, textColor, alert }) => {
    const cachedCameraList = useSelector((state: RootState) => state.camerasList.list);
    let date = new Date(alert.detection_time)
    let hours = date.getHours();
    let minutes = date.getMinutes();

    let formattedHours = hours < 10 ? '0' + hours : hours;
    let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return (
        <>
            <div
                className="card-content"
                style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '1.5rem',
                }}
            >
                <a
                    href="#!"
                >
                    <h2 className='text-dark fs-3 mb-4'>{alert.detection_description}</h2>
                </a>
                <p><span className='fw-bold fs-7'>Date:</span> {new Date(alert.detection_time).toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}</p>
                <p><span className='fw-bold fs-7'>Time:</span> {`${formattedHours}:${formattedMinutes}`}</p>
                <p><span className='fw-bold fs-7'>Camera:</span> {cachedCameraList.find(x => x.id === alert.camera_id)?.name ?? alert.camera_id}</p>
                <p><span className='fw-bold fs-7'>Confidence:</span> {Math.round(alert.threshold * 100)}%</p>
            </div>
        </>
    )
}

export default AlertsCardInfo