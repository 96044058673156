import callApiAxiosClient from '../../axios/axios_client';

async function addTrainingSession(loss: number, accuracy: number, modelId: number, epochs: number, pictureIds: number[]): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'training/session',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      loss: loss,
      accuracy: accuracy,
      model_id: modelId,
      epochs: epochs,
      picture_ids: pictureIds,
    },
  });
}

export default addTrainingSession;
