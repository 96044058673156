import { useState, useEffect } from 'react';
import { locationsList, userItemList } from '../web_api/models';
import getLocations from '../web_api/methods/settings/locations/getLocations';
import { useSelector, useDispatch } from 'react-redux';
import { setValue } from '../redux/activeLocationReducer';
import { RootState } from '../redux/store';
import { setValue as setSelectedUser } from '../redux/selectedUserReducer';
import { setValue as setUserList } from '../redux/userListReducer';
import getUsers from '../web_api/methods/account/getUsers';
import { setCachedLocationList } from '../redux/cachedLocationsReducer';

const ToolbarRecentAlerts = () => {
  const [locations, setLocations] = useState<locationsList>([]);
  const cachedLocationList = useSelector((state: RootState) => state.cachedLocations);
  const locationIds = JSON.stringify(cachedLocationList.list.map((location) => location.id));
  const activeLocation = useSelector((state: RootState) => state.activeLocation);
  const selectedUser = useSelector((state: RootState) => state.selectedUser);
  const userList = useSelector((state: RootState) => state.userList);
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true');
    loadLocations();
    if (userList.list.length === 0) {
      loadUsers();
    }
  }, []);

  useEffect(() => {
    loadLocations();
  }, [locationIds]);

  const loadUsers = async () => {
    try {
      let loadedUsers: userItemList = await getUsers();
      dispatch(
        setUserList({
          list: loadedUsers,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  const loadLocations = async () => {
    try {
      let loadedLocations: locationsList = await getLocations();
      setLocations(loadedLocations);
      if (loadedLocations.length == 1)
        dispatch(
          setValue({
            value: loadedLocations[0].name,
            id: loadedLocations[0].id.toString(),
          })
        );
      dispatch(
        setCachedLocationList({
          list: loadedLocations,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className='d-flex align-items-center overflow-auto'>
      {/* begin::Wrapper */}
      <div className='d-flex align-items-center flex-shrink-0'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>User:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={selectedUser.value}
          onChange={(e) => {
            dispatch(
              setSelectedUser({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );

            dispatch(
              setValue({
                value: 'All',
                id: '0',
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {userList.list.map((item) => (
            <option key={item.id} value={item.name} data-id={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>

      <div className='d-flex align-items-center flex-shrink-0 mx-2'>
        {/* begin::Label */}
        <span className='fs-7 fw-bold text-gray-700 flex-shrink-0 pe-4 d-none d-md-block'>Location:</span>
        {/* end::Label */}

        <select
          className='form-select form-select-sm w-md-125px form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={activeLocation.value}
          onChange={(e) => {
            dispatch(
              setValue({
                value: e.target.value,
                id: e.target.options[e.target.selectedIndex].dataset.id!,
              })
            );
          }}
        >
          <option key={0} value={'All'} data-id={0}>
            All
          </option>
          {selectedUser.value === 'All'
            ? locations.map((item) => (
                <option key={item.id} value={item.name} data-id={item.id}>
                  {item.id + ' - ' + item.name}
                </option>
              ))
            : userList.list
                .find((x) => x.id === selectedUser.id)
                ?.locations.map((item) => (
                  <option key={item.id} value={item.name} data-id={item.id}>
                    {item.id + ' - ' + item.name}
                  </option>
                ))}
        </select>
      </div>
      {/* end::Wrapper */}
    </div>
  );
};

export { ToolbarRecentAlerts };
