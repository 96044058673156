import { LFProductNameFormat, bakeryProductNameFormat } from "../../../web_api/methods/helpers";
import { CommonBarChart } from "../../Common/CommonBarChart";
import { percentageFormat } from "../../../web_api/methods/helpers";
import dummyData from '../Placeholder.json';
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import bakeryMissingProduct from "../../../web_api/methods/bakeryDash/missingProduct";
import { lfProductAvailabilityList, missingProductList } from "../../../web_api/models";
import lfProductAvailability from "../../../web_api/methods/fruits-vegetables/lfProductAvailability";

export function barColors({ value }: { value: number }) {
    if (value < 0.25)
        return '#dc3545';
    else if (value < 0.5)
        return '#ffc107'
    else if (value < 0.75)
        return '#007bff'
    else
        return '#28a745'

    // if (value < 0.5) {
    //     return '#28a745';
    // } else if (value < 50) {
    //     return '#007bff';
    // } else if (value < 25) {
    //     return '#ffc107';
    // } else

};
const FruitsVegetablesCharts = () => {
    const [loading, setLoading] = useState(false)
    const [names, setNames] = useState<string[]>([])
    const [values, setValues] = useState<number[]>([])
    const activeLocation = useSelector((state: RootState) => state.activeLocation);
    const toolbarHourFilter = useSelector((state: RootState) => state.toolbarHourFilter);
    const toolbarDateFilter = useSelector((state: RootState) => state.toolbarDateFilter);
    const loadMissingProducts = async () => {
        try {
            setLoading(true)
            let forNames = new Array()
            let forValues = new Array()
            let productsAvailabilityRes: lfProductAvailabilityList = await lfProductAvailability(parseInt(activeLocation.id), new Date(toolbarDateFilter.date), parseInt(toolbarHourFilter.hour), parseInt(toolbarHourFilter.toHour));
            // console.log(shelfStatus)s
            productsAvailabilityRes.forEach((item) => {
                forNames.push(LFProductNameFormat(item.product))
                forValues.push(item.availability)
            })
            setNames(forNames)
            setValues(forValues)
            setLoading(false)
        }
        catch (e) {
            console.log(e)
        }
    };
    useEffect(() => {
        loadMissingProducts()
    }, [activeLocation.id, toolbarDateFilter.date, toolbarHourFilter])



    return (
        <>
            <CommonBarChart
                title="Fruit & Vegetables"
                className="mb-5"
                names={names}
                values={values}
                barColors={barColors}
                loading={loading}
                formatter={percentageFormat}
            />
        </>
    );
}

export default FruitsVegetablesCharts;