/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import ApexCharts, { ApexOptions } from 'apexcharts';
import { getCSSVariableValue } from '../../assets/ts/_utils';
import { ComparisonEnum } from '../../../web_api/models';
import peopleCountingEntriesHours from '../../../web_api/methods/peopleCountingDash/entriesHours';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import LoadingPage from '../../../layout/LoadingPage';
import queueManagementAvgCashRegisterOpen from '../../../web_api/methods/queueManagementDash/avgCashRegisterOpen';
import peopleCountingEntriesLocations from '../../../web_api/methods/peopleCountingDash/entriesLocations';
import { dateToShortDateStringFormat } from '../../../web_api/methods/helpers';

type Props = {
  className: string;
  chartHeight: string;
  chartColor: string;
  type: ApexChart['type'];
  comparison: ComparisonEnum;
  loading: boolean;
  hasErrors: boolean;
  values: number[];
  labels: string[];
};

const ComparisonSeparateChart: React.FC<Props> = ({ className, chartHeight, chartColor, type, comparison, loading, hasErrors, values, labels }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    var chart = new ApexCharts(chartRef.current, chartOptions(comparison, chartColor, labels, values, type));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    var chart = new ApexCharts(chartRef.current, chartOptions(comparison, chartColor, labels, values, type));
    if (chart) {
      chart.render();
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  // useEffect(() => {
  // const chart =
  // refreshChart();
  // return () => {
  //   if (chart) {
  //     chart.destroy()
  //   }
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chartRef, values, labels, type]);

  return (
    <div className={`card ${className}`}>
      <div className={`card-body p-5 pb-0 ${hasErrors ? 'd-flex flex-column align-items-center justify-content-center' : ''}`}>
        {loading && (
          <div className='position-absolute' style={{ right: '0', left: '0' }}>
            <LoadingPage />
          </div>
        )}

        {hasErrors && (
          <div className='position-absolute d-flex flex-column justify-content-center align-items-center' style={{}}>
            <i className='bi bi-exclamation-triangle text-danger opacity-75' style={{ fontSize: '10rem' }} />
            <span className='fs-8 mt-2 fw-semibold text-gray-400'>An error occured, try refreshing the page.</span>
          </div>
        )}
        <div
          ref={chartRef}
          className={`mixed-widget-3-chart card-rounded-bottom ${loading || hasErrors ? 'opacity-0' : ''}`}
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  );
};

const chartOptions = (comparison: string, chartColor: string, xAxis1: string[], yAxis: number[], type: ApexChart['type']): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800');
  const strokeColor = getCSSVariableValue('--kt-gray-300');
  const baseColor = getCSSVariableValue('--kt-' + chartColor);
  const lightColor = getCSSVariableValue('--kt-' + chartColor + '-light');
  const secondaryColor = getCSSVariableValue('--kt-danger');
  return {
    series: [
      {
        name: comparison,
        data: yAxis,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: type,
      height: 350,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: [baseColor, secondaryColor],
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        inverseColors: true,
        opacityFrom: 0.7,
        opacityTo: 0.5,
      },
    },
    noData: {},
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor, secondaryColor],
    },
    xaxis: {
      categories: xAxis1,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: strokeColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: [baseColor, secondaryColor],
      strokeWidth: 3,
    },
  };
};
export { ComparisonSeparateChart };
