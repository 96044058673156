


import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EmbeddedVideoData } from '../Common/Slider';
import { useSelector } from "react-redux";
import { EProductType } from "../../redux/activeProductReducer";
import { RootState } from "../../redux/store";
import { useEffect } from "react";
import useToolbarSetter from "../../hooks/Common/useToolbarSetter";



const Demo = () => {
    const { setToolbar } = useToolbarSetter();
    useEffect(() => {
        setToolbar(
            'demo',
            'Demo'
        )
    })
    const activeProduct = useSelector((state: RootState) => state.activeProduct.activeProduct);

    const data: EmbeddedVideoData[] = activeProduct === EProductType.RETAIL ?
        [
            { src: 'https://www.youtube.com/embed/mgwHvwO1Fr0', title: 'Age and Gender', description: 'This product utilizes AI to estimate the age and gender of individuals in surveillance footage.' },
            { src: 'https://www.youtube.com/embed/f1rVmWVLvOc', title: 'Heatmap', description: 'The heatmap is created based on customers movement patterns, providing insights into attention span.' },
            { src: 'https://www.youtube.com/embed/fZCKxfDGAe4', title: 'Product Layout', description: 'This solution helps you analyze the layout of products in a given space, assisting in retail optimization and product placement.' },
            { src: 'https://www.youtube.com/embed/GcuIF6ZVFIU', title: 'Missing Products', description: 'With this tool, you can identify missing products within a store or inventory, enhancing loss prevention.' },
            { src: 'https://www.youtube.com/embed/OlzozbsRltg', title: 'Product Count', description: 'Product count uses AI to accurately count items in real-time, aiding in inventory management.' },
            { src: 'https://www.youtube.com/embed/DPcn-eiklaY', title: 'Product Recognition', description: 'This product enables the recognition and identification of specific products, streamlining inventory tracking and retail analytics.' },
            { src: 'https://www.youtube.com/embed/jegcASPpJ08', title: 'Queue Counting', description: 'Queue Counting helps businesses monitor and manage customer queues efficiently.' },
        ]
        :
        activeProduct === EProductType.HSSE ?
            [
                { src: 'https://www.youtube.com/embed/34qCQ_uEwu8', title: 'Workers Counter', description: 'This product is designed for accurately counting and monitoring the presence of workers at a site.' },
                { src: 'https://www.youtube.com/embed/6Z9sW476PUg', title: 'Vehicle Detection', description: 'Vehicle Detection helps identify and track vehicles within a construction or worksite.' },
                { src: 'https://www.youtube.com/embed/thfOlERPd-c', title: 'Perimeter Monitoring', description: 'Perimeter Monitoring ensures secure boundaries and alerts you to any unauthorized access.' },
                { src: 'https://www.youtube.com/embed/3W0oqnx6gqo', title: 'Equipment Detection', description: 'This product recognizes and tracks equipment and machinery on the site for safety and security purposes.' },
                { src: 'https://www.youtube.com/embed/WGg163-J9Xs', title: 'Dangerous Situations', description: 'It detects potentially hazardous situations and sends alerts for timely action.' },
                { src: 'https://www.youtube.com/embed/R0qQjzO4z98', title: 'Working under suspended load', description: 'This feature provides alerts when work is being performed under a suspended load, enhancing safety protocols.' },
                { src: 'https://www.youtube.com/embed/TcRINT6_hMc', title: 'Phone Detection', description: 'Phone Detection helps ensure that safety regulations regarding mobile phone use are followed on-site.' },
            ]
            :
            activeProduct === EProductType.MOBILITY ?
                [
                    { src: 'https://www.youtube.com/embed/pj2UNf9uSzA', title: 'License Plate Recognition and Anonymization', description: 'License Plate Recognition and Anonymization assists in identifying license plates while protecting privacy.' },
                    { src: 'https://www.youtube.com/embed/-yKqf_ZW-bI', title: 'Car Counting', description: 'Car Counting accurately counts the number of vehicles passing through a given area.' },
                    { src: 'https://www.youtube.com/embed/GDdO-Yxx7kY', title: 'Garbage Detection', description: 'Garbage Detection identifies and tracks the presence of garbage or waste in specified locations.' },
                    { src: 'https://www.youtube.com/embed/k1gTfJ8xpu0', title: 'Vandalization Detector', description: 'Vandalization Detector helps protect against property damage and vandalism by detecting unusual behavior.' },
                    { src: 'https://www.youtube.com/embed/y1l40YLJPqw', title: 'Parked cars in bus stop detection', description: 'This feature helps ensure bus stops are not obstructed by parked vehicles, improving public transportation services.' },
                    { src: 'https://www.youtube.com/embed/plXDiJX9mFc', title: 'Garbage Truck Detection', description: 'Garbage Truck Detection recognizes and tracks garbage trucks, facilitating waste management.' },
                    { src: 'https://www.youtube.com/embed/jOfcqBcwruY', title: 'Public Transportation Detection', description: 'Public Transportation Detection aids in the monitoring and optimization of public transportation services.' },
                ]
                :
                []

    return (
        <div className="container p-5">
            <div className="row">
                {data.map((video, index) => {
                    return (
                        <div className="d-flex col-6">
                            <iframe
                                className='rounded-5 w-100 p-3'
                                height='500px'
                                src={`${video.src}?autoplay=1&rel=0&loop=1`}
                                title={video.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; "
                                allowFullScreen={false}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default Demo;
