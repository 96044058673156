import React, { useState } from "react";
import { Button, Collapse } from "react-bootstrap";


type ExpandableComponentProps = {
    className?: string;
    titleClassName?: string;
    badgeClassName?: string;
    label: string;
    children: React.ReactNode;
    length?: number
    active?: boolean
    customPadding?: boolean
};

const ExpandableComponent: React.FC<ExpandableComponentProps> = ({ className, titleClassName, badgeClassName, label, children, length, active, customPadding }) => {
    const [open, setOpen] = useState(active ?? true);

    return (
        <div className={` ${className}`}>
            <div
                className={`${(customPadding === undefined || customPadding === null) && 'px-3 py-5'} card rounded user-select-none cursor-pointer ${open && 'bg-secondary'} ${badgeClassName}`}
                onClick={() => length != 0 && setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
            >
                <div className={`d-flex ${open && 'text-primary'}`}>
                    <h3 className={`fw-bold mx-5 my-auto ${titleClassName}`}>
                        {label}
                    </h3>
                    <div className="d-flex my-auto flex-end flex-fill mx-5">
                        <span className={`${open ? 'bi bi-chevron-up' : 'bi bi-chevron-down'} `} />
                    </div>
                </div>
            </div>
            <Collapse in={open}>
                <div className="">
                    {children}
                </div>
            </Collapse>
        </div>
    );
};

export default ExpandableComponent;
