import callApiAxiosClient from '../../axios/axios_client';

async function addTrainingObjectClass(
  name: string,
  parentId: number | null,
  detectionTypeId?: number | null,
): Promise<void> {
  const token = JSON.parse(localStorage.getItem('user')!);
  const authorization = 'Bearer ' + token.token;
  return callApiAxiosClient({
    method: 'post',
    url: 'training/object-classes',
    headers: {
      'Content-Type': 'Application/json',
      Authorization: authorization,
    },
    data: {
      detection_type_id: detectionTypeId,
      name: name,
      parent_id: parentId,
    },
  });
}

export default addTrainingObjectClass;
