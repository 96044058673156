import React from 'react';
import { categoryInList, categoryList } from '../../web_api/models';
import { CommonModal } from '../Common/CommonModal';
import Table from 'react-bootstrap/Table';

type ChangesModalProps = {
  show: boolean;
  onModalToggle: () => void;
  changes: categoryList;
  getObjectById: (id: number | string | null) => categoryInList | null | undefined;
};

const ChangesModal: React.FC<ChangesModalProps> = ({ show, onModalToggle, changes, getObjectById }) => {
  return (
    <div>
      <CommonModal
        title='Changes'
        closeButton={true}
        show={show}
        showModalCallback={onModalToggle}
        closeButtonOptions={{
          icon: 'bi bi-x-lg',
          variant: 'secondary',
          class: '',
        }}
        confirmButton={false}
        confirmButtonOptions={{}}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Parent</th>
              <th>Name</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {changes.map((change) => (
              <tr>
                <td>
                  {change.parent_id || change.temporary_parent_id
                    ? getObjectById(change.parent_id || null || change.temporary_parent_id || null)!.name || 'N/A'
                    : 'N/A'}
                </td>
                <td>{change.name}</td>
                <td>{change.description ? change.description : 'No description'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CommonModal>
    </div>
  );
};

export default ChangesModal;
