import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'redux';

type ReduxSetter<T> = (data: { list: T }) => Action;
type InitialCallback = () => void;
type InitialValidation = () => void;

type Data = {
  [key: string]: any;
};

const usePut = <T,>(
  dataProps: Data = {},
  axiosCall: any,
  reduxSetter?: ReduxSetter<T>,
  reduxList?: any,
  reduxMatch?: string,
  targetMatch?: any,
  initialCallback?: InitialCallback,
  initialValidation?: InitialValidation,
  allowZeros?: boolean
) => {
  const dispatch = useDispatch();

  const [data, setData] = useState<any>(dataProps);
  const [errors, setErrors] = useState<string>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const formData: any = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, '']));
  const [isFormValid, setIsFormValid] = useState(formData);

  const handleUpdateData = async () => {
    setIsUpdating(true);
    setErrors('');
    let isValid = true;
    const updatedFormValidity = { ...isFormValid };

    for (const key in data) {
      if (!data[key] && !allowZeros) {
        updatedFormValidity[key] = 'is-invalid';
        isValid = false;
      } else {
        updatedFormValidity[key] = 'is-valid';
      }
    }

    if (initialValidation) {
      initialValidation();
    }

    if (!isValid) {
      setIsFormValid(updatedFormValidity);
      setIsUpdating(false);
      return;
    }

    try {
      setIsFormValid(formData);
      if (JSON.stringify(dataProps) !== JSON.stringify(data)) {
        await axiosCall();
        if (reduxSetter && reduxList && reduxMatch && targetMatch) {
          const updatedList = reduxList.map((cachedItem: any) => {
            if (cachedItem[reduxMatch] === targetMatch) {
              return {
                ...cachedItem,
                ...data,
              };
            }
            return cachedItem;
          });
          updatedList.reverse();
          dispatch(reduxSetter({ list: updatedList }));
        }

        if (initialCallback) {
          initialCallback();
        }
      }
    } catch (e) {
      setIsFormValid(formData);
      setErrors(e?.toString());
      console.log(e);
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    data,
    setData,
    isUpdating,
    isFormValid,
    setIsFormValid,
    handleUpdateData,
    errors,
  };
};

export default usePut;
