import callApiAxiosClient from "../../../axios/axios_client";
import { detectionTypeList } from "../../../models";

async function getDetectionTypes(detectionType?: number): Promise<detectionTypeList> {
    const token = JSON.parse(localStorage.getItem('user')!)
    const authorization = 'Bearer ' + token.token
    return callApiAxiosClient<detectionTypeList>({
        method: "get",
        url: "detections/get-all-detection-types",
        headers: {
            "Content-Type": "Application/json",
            "Authorization": authorization
        },
        params: {
            detection_type: detectionType
        }
    })
}
export default getDetectionTypes;