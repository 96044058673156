import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCameraImage, addCameraImage } from '../../redux/cameraImagesReducer';
import { ELoadType } from '../../web_api/models';


function useLoadImage(cameraId: number | undefined, lowRes: boolean, getSnapshot: any, loadType: ELoadType) {
    const [loadingImage, setLoadingImage] = useState(false);
    const [url, setUrl] = useState('');
    const dispatch = useDispatch();
    const cameraImages = useSelector((state: RootState) => state.cameraImages.list);


    const loadSnapshot = async () => {
        try {
            if (cameraId) {
                setLoadingImage(true);
                const getUrl: string = await getSnapshot(cameraId, lowRes);

                setUrl(getUrl);

                dispatch(addCameraImage({ id: cameraId, url: getUrl }));

                setLoadingImage(false);
            }
        } catch (e) {
            console.log(e);
        }
    };
    const tryLoadSnapshot = async () => {
        try {
            if (loadType === ELoadType.BULK) {
                var url = cameraImages.find(x => x.id === cameraId)?.url
                if (url)
                    setUrl(url)
                else
                    loadSnapshot()
            }
            else
                loadSnapshot()
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        tryLoadSnapshot();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cameraId]);

    return { loadingImage, url };
}

export default useLoadImage;
