import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { detectionItemInList, personItemInList } from '../../web_api/models';
import { CommonModal } from '../Common/CommonModal';
import AlertCanvasComponent from './AlertCanvasComponent';
import AlertsCardInfo from './AlertsCardInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import getAzureFile from '../../web_api/methods/files/getAzureFile';
import deleteAlerts from '../../web_api/methods/alerts/deleteAlerts';
type Props = {
  alert: detectionItemInList;
  onAlertSelect: (alertID: string) => void;
  onAlertDelete: (alertID: string) => void;
};

const AlertCard: React.FC<Props> = ({ alert, onAlertSelect, onAlertDelete }) => {
  const [showPicture, setShowPicture] = useState(false);
  const [url, setUrl] = useState('');
  const tenantKey = useSelector((state: RootState) => state.user.tenant_key);

  const handleSeePictureModal = () => {
    setShowPicture((prevShowPicture) => !prevShowPicture);
  };

  useEffect(() => {
    loadUrl();
  }, [alert.filename]);

  
  const loadUrl = async () => {
    try {
      let getUrl: string = await getAzureFile(alert.filename);
      setUrl(getUrl);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div className='d-flex flex-column col-12 col-md-4 my-3 h-min-300px animated zoomIn'>
        <div
          className='card  p-0 animated hover-grow w-xxl-370px'
          style={{
            margin: '1rem',
            boxShadow: '0 -1px 1px 0 rgba(0,0,0,.05), 0 1px 2px 0 rgba(0,0,0,.2)',
          }}
        >
          <div style={{ height: '3em' }} className='d-flex flex-row p-3 justify-content-between'>
            <div className='form-check'>
              <input
                className='form-check-input flexCheckDefault'
                type='checkbox'
                value=''
                name='check'
                // onClick={() => onPersonCheck(personItem.detection_id)}
                onClick={() => onAlertSelect(alert.detection_gu_id)}
              />
            </div>
            <h2>{alert.feature2 !== null && alert.feature2.length > 0 && alert.feature2}</h2>
            <div>
              <i
                role='button'
                className='bi bi-trash-fill text-danger'
                style={{ fontSize: '1.5em' }}
                // onClick={handleToggleDeleteModal}
                onClick={() => {
                  onAlertDelete(alert.detection_gu_id);
                }}
              />
            </div>
          </div>

          <div
            className='position-absolute cursor-pointer'
            onClick={() => handleSeePictureModal()}
            style={{
              height: '350px',
              width: '100%',
              top: '39px',
              backgroundColor: 'transparent',
              zIndex: '',
              opacity: '0',
              transition: 'opacity 0.2s',
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
              e.currentTarget.style.opacity = '0.8';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = 'transparent';
              e.currentTarget.style.opacity = '0';
            }}
          >
            <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
              <button
                type='button'
                className='btn rounded btn-outline-primary border text-white'
                style={{ opacity: '1', transition: 'opacity 0.4s' }}
              >
                View Image
              </button>
            </div>
          </div>

          <div>
            <AlertCanvasComponent
              imageUrl={url}
              // imageUrl={`/${(alert.detection_type===76?'alerts-media':(alert.detection_type===68)?'alerts-media':'alerts-media')}/${tenantKey}/${new Date(alert.detection_time).getFullYear()}/${new Date(alert.detection_time).getMonth() + 1}/${alert.filename}`}
              // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${alert.filename}`}
              actualHeight={alert.picture_height}
              actualWidth={alert.picture_width}
              big={false}
              canvasHeight={'350px'}
              canvasWidth={'100%'}
              multiple_bboxes={alert.multiple_bboxes}
            />
          </div>
          <AlertsCardInfo backgroundColor={''} textColor={''} alert={alert} />
        </div>
        {/* <CommonModal
                    title={"Confirm"}
                    show={showDelete}
                    showModalCallback={handleToggleDeleteModal}
                    closeButton={true}
                    confirmButton={true}
                    bodyClass="flex-column w-100"
                    closeButtonOptions={{
                        icon: 'bi bi-x',
                        variant: 'secondary',
                        class: "",
                    }}
                    confirmButtonOptions={{
                        icon: "bi bi-trash3",
                        variant: 'custom',
                        class: "bg-danger text-white",
                        onClick: handleDeleteAlert
                    }}
                >
                    <div className="text-justify">Are you sure you want to delete the following alert?</div>
                    <div className="fw-bolder mt-2">{personItem.detection_id}</div>
                </CommonModal> */}

        <CommonModal
          size='xl'
          title={alert.detection_description}
          show={showPicture}
          showModalCallback={handleSeePictureModal}
          closeButton={true}
          confirmButton={true}
          bodyClass='flex-column w-100'
          closeButtonOptions={{
            icon: 'bi bi-x-lg',
            variant: 'secondary',
            class: '',
          }}
          confirmButtonOptions={{
            icon: 'bi bi-trash3 text-white',
            variant: 'custom',
            class: 'bg-danger text-white d-flex flex-row align-items-center',
            onClick: () => {
              onAlertDelete(alert.detection_gu_id);
              toast.success('Alert deleted successfully!', {
                position: 'top-right',
                theme: 'colored',
                autoClose: 2000,
              });
            },
          }}
        >
          <AlertCanvasComponent
            imageUrl={url}
            // imageUrl={`/${(alert.detection_type === 76 ? 'alerts-media' : (alert.detection_type === 68) ? 'alerts-media' : 'alerts-media')}/${tenantKey}/${new Date(alert.detection_time).getFullYear()}/${new Date(alert.detection_time).getMonth() + 1}/${alert.filename}`}
            // imageUrl={`/alerts-media/${tenantKey}/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${alert.filename}`}
            actualHeight={alert.picture_height}
            actualWidth={alert.picture_width}
            canvasHeight={'500px'}
            canvasWidth={'100%'}
            multiple_bboxes={alert.multiple_bboxes}
            big={true}
          />
          {/* <div>
                    <img
                        ref={imgRef}
                        className='w-100'
                        src={`/alerts-media/2b04e972-e0a2-4592-a9c5-bd3d03424cfd/${new Date().getFullYear()}/${new Date().getMonth() + 1}/${filename}`}
                        alt="img"
                        // Hide the image
                    />
                    <canvas ref={cnvRef} />
                </div>
                {isNew && (
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        backgroundColor: 'green',
                        color: 'white',
                        padding: '5px 10px',
                        borderBottomLeftRadius: '5px',
                        fontSize: '16px',
                        zIndex: 2,  // make sure it's above all other elements
                    }}>
                        New
                    </div>
                )} */}
        </CommonModal>
      </div>
    </>
  );
};

export default AlertCard;
